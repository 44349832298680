import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHoc from '@apollo/react-hoc';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  Long: any;
  Upload: any;
};


export type Activity = {
  __typename?: 'Activity';
  all: Scalars['Int'];
  prio: Prio;
  userRelated: Scalars['Int'];
};

export type ActivityInput = {
  companyId: Scalars['Int'];
};

export type AddAdressInput = {
  country: Scalars['String'];
  houseNumber: Scalars['String'];
  legalName: Scalars['String'];
  place: Scalars['String'];
  postCode: Scalars['Int'];
  street: Scalars['String'];
};

export type AddContactPersonInput = {
  companyId: Scalars['Int'];
  email: Scalars['String'];
  name: Scalars['String'];
  picURL?: Maybe<Scalars['String']>;
  surname: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type AddCustomerCheckInput = {
  companyId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  shortName: Scalars['String'];
};

export type AddCustomerCheckResponse = {
  __typename?: 'AddCustomerCheckResponse';
  name: Scalars['Boolean'];
  short: Scalars['Boolean'];
};

export type AddCustomerInput = {
  country: Scalars['String'];
  houseNumber: Scalars['String'];
  isFreelancer?: Maybe<Scalars['Int']>;
  legalName: Scalars['String'];
  name: Scalars['String'];
  picURL?: Maybe<Scalars['String']>;
  place: Scalars['String'];
  postCode: Scalars['Int'];
  priceCategory?: Maybe<Scalars['Int']>;
  shortName: Scalars['String'];
  street: Scalars['String'];
};

export type AddDocumentToSendQueueInput = {
  attachPDF: Scalars['Boolean'];
  id: Scalars['Int'];
};

export type AddOfferInput = {
  isCostEstimate: Scalars['Boolean'];
  name: Scalars['String'];
};

export type AddOfferPositionInput = {
  companyId: Scalars['Int'];
  offerId: Scalars['Int'];
  positionName: Scalars['String'];
};

export type AddOfferRateInput = {
  at: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  offerId: Scalars['Int'];
};

export type Address = {
  __typename?: 'Address';
  country: Scalars['String'];
  houseNumber: Scalars['String'];
  id: Scalars['Int'];
  legalName: Scalars['String'];
  place: Scalars['String'];
  postCode: Scalars['Int'];
  street: Scalars['String'];
};

export type AddTaskInput = {
  companyId: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  invoiceId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  offerId?: Maybe<Scalars['Int']>;
  parentId?: Maybe<Scalars['Int']>;
  prioId?: Maybe<Scalars['Int']>;
  /** Assign task to user immediately */
  selfAssign?: Maybe<Scalars['Boolean']>;
};

export type AddTaskOutput = {
  __typename?: 'AddTaskOutput';
  addedTask: Task;
  affectedTasks: Array<Task>;
};

export type AnswerOfferInput = {
  companyShort: Scalars['String'];
  link: Scalars['String'];
  status: Scalars['Boolean'];
};

export type ArchiveOfferPositionInput = {
  offerId: Scalars['Int'];
  rateId?: Maybe<Scalars['Int']>;
  taskId?: Maybe<Scalars['Int']>;
};

export type AssignUserToTaskInput = {
  taskId: Scalars['Int'];
  userId: Scalars['Int'];
};

export type AuthenticationInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type AuthenticationResponse = {
  __typename?: 'AuthenticationResponse';
  expiry?: Maybe<Scalars['Date']>;
  message?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  refreshTokenExpiry?: Maybe<Scalars['Date']>;
  token?: Maybe<Scalars['String']>;
};

export type Base64File = {
  __typename?: 'Base64File';
  base64: Scalars['String'];
  filename: Scalars['String'];
  id: Scalars['String'];
};

export type BillingPosition = {
  __typename?: 'BillingPosition';
  rates: Array<Rate>;
  task: Task;
};

export type BillingTasksInput = {
  companyId: Scalars['Int'];
  selectedTaskIds: Array<Scalars['Int']>;
  uncollapsedTaskIds: Array<Scalars['Int']>;
};

export type BillingTasksResponse = {
  __typename?: 'BillingTasksResponse';
  selectedTaskIds: Array<Scalars['Int']>;
  tasks: Array<BillingPosition>;
  uncollapsableTaskIds: Array<Scalars['Int']>;
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export enum Charge {
  Currency = 'CURRENCY',
  Percent = 'PERCENT'
}

export type ChildrenWorkedTime = {
  __typename?: 'ChildrenWorkedTime';
  completed: Scalars['Long'];
  currentWork: Array<Workload>;
  notCompleted: Scalars['Long'];
};

export type Company = {
  __typename?: 'Company';
  activity: Array<Activity>;
  address: Address;
  favorite: Scalars['Boolean'];
  freelancer?: Maybe<Scalars['Boolean']>;
  hasActivity: Scalars['Boolean'];
  hasBillingActivity: Scalars['Int'];
  hasUserRelatedActivity: Scalars['Boolean'];
  hasUserRelatedBillingActivity: Scalars['Int'];
  id: Scalars['Int'];
  imageUrl?: Maybe<Scalars['String']>;
  legalName: Scalars['String'];
  name: Scalars['String'];
  nextBillingDeadline?: Maybe<Scalars['Date']>;
  nextDeadline?: Maybe<Scalars['Date']>;
  parentId?: Maybe<Scalars['Int']>;
  priceCategory: Scalars['Int'];
  short: Scalars['String'];
  sort: Scalars['Int'];
};

export type CompanyInput = {
  id: Scalars['Int'];
};

export type Configuration = {
  __typename?: 'Configuration';
  cPath: Scalars['String'];
  cVal: Scalars['String'];
};

export type ContactPerson = {
  __typename?: 'ContactPerson';
  companyId: Scalars['Int'];
  email: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  picURL?: Maybe<Scalars['String']>;
  surname: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type ContactPersonAddedInput = {
  companyId: Scalars['Int'];
};

export type ContactPersonInput = {
  companyId: Scalars['Int'];
  searchString: Scalars['String'];
};

export type CreateInvoiceInput = {
  afterText: Scalars['String'];
  afterTextTemplate: Scalars['Int'];
  attachPDF: Scalars['Boolean'];
  companyId: Scalars['Int'];
  contactPersonId?: Maybe<Scalars['Int']>;
  creationDate: Scalars['Date'];
  customContactEmail?: Maybe<Scalars['String']>;
  deliveryDate: Scalars['Date'];
  emailText: Scalars['String'];
  emailTextTemplate: Scalars['Int'];
  invoiceNumber?: Maybe<Scalars['Int']>;
  preText: Scalars['String'];
  preTextTemplate: Scalars['Int'];
  rateIds: Array<Scalars['Int']>;
  sendMail: Scalars['Boolean'];
  taskIds: Array<Scalars['Int']>;
};

export type CreateOfferDocument = {
  id: Scalars['Int'];
};


export type DeleteOfferInput = {
  id: Scalars['Int'];
};

export type DeleteOfferRateInput = {
  id: Scalars['Int'];
};

export type DeleteRecurringInvoiceInput = {
  recurringInvoiceId: Scalars['Int'];
};

export type DeleteTaskInput = {
  taskId: Scalars['Int'];
};

export type DeleteTaskResponse = {
  __typename?: 'DeleteTaskResponse';
  blockedTasks: Array<Task>;
  deletedTasks: Array<Task>;
};

export type DeleteTemplateInput = {
  id: Scalars['Int'];
};

export type DeleteTimeTrackingInput = {
  workloadId: Scalars['Int'];
};

export type Discount = {
  __typename?: 'Discount';
  id: Scalars['Int'];
  unit: Charge;
  value: Scalars['Float'];
};

export type DiscountDeleteInput = {
  id: Scalars['Int'];
};

export type DiscountInput = {
  productID: Scalars['Int'];
  unit: Charge;
  value: Scalars['Float'];
};

export type DiscountUpdateInput = {
  id: Scalars['Int'];
  productID: Scalars['Int'];
  unit: Charge;
  value: Scalars['Float'];
};

export type DistributeEqualOfferRateInput = {
  offerId: Scalars['Int'];
};

export type DuplicateOfferInput = {
  offerId: Scalars['Int'];
};

export type EditTimeTrackingInput = {
  end: Scalars['Date'];
  start: Scalars['Date'];
  workloadId: Scalars['Int'];
};

export type ExecutionHistoryItem = {
  __typename?: 'ExecutionHistoryItem';
  date: Scalars['Date'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

export type GeneratedInvoice = {
  __typename?: 'GeneratedInvoice';
  id: Scalars['Int'];
  number: Scalars['Int'];
};

export type HourlyWorkRate = {
  __typename?: 'hourlyWorkRate';
  hourlyWorkRate: Scalars['Float'];
  priceCategoryId: Scalars['Int'];
  taskCategoryId: Scalars['Int'];
};

export type HourlyWorkRatesInput = {
  companyId: Scalars['Int'];
};

export enum IntervalType {
  Day = 'DAY',
  Month = 'MONTH',
  Year = 'YEAR'
}

export type Invoice = {
  __typename?: 'Invoice';
  addressId: Scalars['Int'];
  afterText: Scalars['String'];
  afterTextTemplate: Scalars['Int'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  contactPerson?: Maybe<ContactPerson>;
  creationDate: Scalars['Date'];
  customContactEmail?: Maybe<Scalars['String']>;
  deliveryDate: Scalars['Date'];
  emailText: Scalars['String'];
  emailTextTemplate: Scalars['Int'];
  generatedChildren: Array<GeneratedInvoice>;
  id: Scalars['Int'];
  mailAttachPDF: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Int']>;
  pdf: Base64File;
  preText: Scalars['String'];
  preTextTemplate: Scalars['Int'];
  price: Price;
  repetitionRuleId?: Maybe<Scalars['Int']>;
  sendMail: Scalars['Boolean'];
  state: InvoiceState;
  tasks: Array<Task>;
  valueAddedTax: Scalars['Int'];
};

export type InvoiceEditorInput = {
  companyId: Scalars['Int'];
  contactPersonId?: Maybe<Scalars['Int']>;
  creationDate?: Maybe<Scalars['Date']>;
  deliveryDate?: Maybe<Scalars['Date']>;
  invoiceNumber?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  preText?: Maybe<Scalars['String']>;
  rateIds: Array<Scalars['Int']>;
  taskIds: Array<Scalars['Int']>;
};

export type InvoiceEditorResponse = {
  __typename?: 'InvoiceEditorResponse';
  nextInvoiceNumber: Scalars['Int'];
  pdfPreview: Base64File;
};

export type InvoiceInput = {
  invoiceNumber: Scalars['Int'];
};

export type InvoicePdfPreview = {
  __typename?: 'InvoicePDFPreview';
  base64: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type InvoicePreviewInput = {
  selectedRateIds: Array<Scalars['Int']>;
  selectedTaskIds: Array<Scalars['Int']>;
};

export type InvoicePreviewResponse = {
  __typename?: 'InvoicePreviewResponse';
  message?: Maybe<Scalars['String']>;
  pdf: Scalars['String'];
};

export enum InvoiceState {
  Canceled = 'CANCELED',
  Draft = 'DRAFT',
  Paid = 'PAID',
  Sending = 'SENDING',
  Sent = 'SENT'
}

export type InvoiceUpdateSubscriptionInput = {
  ids: Array<Scalars['Int']>;
};


export type ManualTimeTrackingInput = {
  end: Scalars['Date'];
  start: Scalars['Date'];
  taskId: Scalars['Int'];
};

export type MarkOfferAsSentInput = {
  id: Scalars['Int'];
};

export type MoveTaskInput = {
  id: Scalars['Int'];
  newCompanyId?: Maybe<Scalars['Int']>;
  newIndex: Scalars['Int'];
  newParentId?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addAddress: Address;
  addContactPerson: ContactPerson;
  addCustomer: Company;
  addDiscount: Task;
  addInvoiceToSendQueue: Invoice;
  addOffer: Offer;
  addOfferDiscount: Offer;
  addOfferRate: Offer;
  addOfferToSendQueue: Offer;
  addRecurringInvoice: RecurringInvoice;
  addTask: AddTaskOutput;
  addTimeTracking: TimeTrackingChangeResponse;
  addUserSettings: UserSettings;
  answerOffer: Offer;
  archiveOfferPositions: Offer;
  assignUserToTask: Task;
  authenticate: AuthenticationResponse;
  authenticateOfferWebview: AuthenticationResponse;
  createInvoice: Invoice;
  deleteDiscount: Task;
  deleteOffer: Offer;
  deleteOfferDiscount: Offer;
  deleteOfferRate: Offer;
  deleteRecurringInvoice: Scalars['Int'];
  deleteTask: DeleteTaskResponse;
  deleteTemplate: Template;
  deleteTimeTracking: TimeTrackingChangeResponse;
  deleteUserSettings: UserSettings;
  distributeOfferRatesEqual: Offer;
  downloadOffer: Scalars['String'];
  duplicateOffer: Offer;
  markOfferAsSent: Offer;
  moveTask: Array<Task>;
  refreshAuthentication: RefreshAuthenticationResponse;
  refreshClients: Scalars['Boolean'];
  refreshOfferWebviewAuthentication: RefreshAuthenticationResponse;
  sendInvoice: Invoice;
  startTimeTracking: StartTimeTrackingResponse;
  stopTimeTracking: Array<Workload>;
  triggerRecurringInvoiceFactoryCheck: Array<RecurringInvoice>;
  unassignUserFromTask: Task;
  updateCompany: Company;
  updateCompanyFavorite: Company;
  updateDiscount: Task;
  updateInvoice: Invoice;
  updateInvoiceMailSettings: Invoice;
  updateInvoiceState: Invoice;
  updateOffer: Offer;
  updateOfferDiscount: Offer;
  updateOfferPin: Offer;
  updateOfferPosition: Task;
  updateOfferRate: Offer;
  updateOrAddTemplateType: Template;
  updateRepetitionRule: RepetitionRule;
  updateTask?: Maybe<Task>;
  updateTaskCompleted: UpdateTaskCompletedResponse;
  updateTemplate: Template;
  updateTimeTracking: TimeTrackingChangeResponse;
  updateUserSettings: UserSettings;
};


export type MutationAddAddressArgs = {
  where: AddAdressInput;
};


export type MutationAddContactPersonArgs = {
  where: AddContactPersonInput;
};


export type MutationAddCustomerArgs = {
  where: AddCustomerInput;
};


export type MutationAddDiscountArgs = {
  where: DiscountInput;
};


export type MutationAddInvoiceToSendQueueArgs = {
  where: AddDocumentToSendQueueInput;
};


export type MutationAddOfferArgs = {
  where: AddOfferInput;
};


export type MutationAddOfferDiscountArgs = {
  where: DiscountInput;
};


export type MutationAddOfferRateArgs = {
  where: AddOfferRateInput;
};


export type MutationAddOfferToSendQueueArgs = {
  where: AddDocumentToSendQueueInput;
};


export type MutationAddTaskArgs = {
  where: AddTaskInput;
};


export type MutationAddTimeTrackingArgs = {
  where: ManualTimeTrackingInput;
};


export type MutationAddUserSettingsArgs = {
  where: UserSettingsUpdateInput;
};


export type MutationAnswerOfferArgs = {
  where: AnswerOfferInput;
};


export type MutationArchiveOfferPositionsArgs = {
  where: ArchiveOfferPositionInput;
};


export type MutationAssignUserToTaskArgs = {
  where: AssignUserToTaskInput;
};


export type MutationAuthenticateArgs = {
  where: AuthenticationInput;
};


export type MutationAuthenticateOfferWebviewArgs = {
  where: OfferWebviewAuthenticationInput;
};


export type MutationCreateInvoiceArgs = {
  where: CreateInvoiceInput;
};


export type MutationDeleteDiscountArgs = {
  where: DiscountDeleteInput;
};


export type MutationDeleteOfferArgs = {
  where: DeleteOfferInput;
};


export type MutationDeleteOfferDiscountArgs = {
  where: DiscountDeleteInput;
};


export type MutationDeleteOfferRateArgs = {
  where: DeleteOfferRateInput;
};


export type MutationDeleteRecurringInvoiceArgs = {
  where: DeleteRecurringInvoiceInput;
};


export type MutationDeleteTaskArgs = {
  where: DeleteTaskInput;
};


export type MutationDeleteTemplateArgs = {
  where: DeleteTemplateInput;
};


export type MutationDeleteTimeTrackingArgs = {
  where: DeleteTimeTrackingInput;
};


export type MutationDeleteUserSettingsArgs = {
  where: UserSettingsInput;
};


export type MutationDistributeOfferRatesEqualArgs = {
  where: DistributeEqualOfferRateInput;
};


export type MutationDownloadOfferArgs = {
  where: OfferByLinkInput;
};


export type MutationDuplicateOfferArgs = {
  where: DuplicateOfferInput;
};


export type MutationMarkOfferAsSentArgs = {
  where: MarkOfferAsSentInput;
};


export type MutationMoveTaskArgs = {
  where: MoveTaskInput;
};


export type MutationRefreshAuthenticationArgs = {
  where: RefreshAuthenticationInput;
};


export type MutationRefreshOfferWebviewAuthenticationArgs = {
  where: RefreshAuthenticationInput;
};


export type MutationSendInvoiceArgs = {
  where: SendInvoiceInput;
};


export type MutationStartTimeTrackingArgs = {
  where: TimeTrackingInput;
};


export type MutationUnassignUserFromTaskArgs = {
  where: UnassignUserFromTaskInput;
};


export type MutationUpdateCompanyArgs = {
  where: UpdateCompanyInput;
};


export type MutationUpdateCompanyFavoriteArgs = {
  where: UpdateCompanyFavoriteInput;
};


export type MutationUpdateDiscountArgs = {
  where: DiscountUpdateInput;
};


export type MutationUpdateInvoiceArgs = {
  where: UpdateInvoiceInput;
};


export type MutationUpdateInvoiceMailSettingsArgs = {
  where: UpdateInvoiceMailSettingsInput;
};


export type MutationUpdateInvoiceStateArgs = {
  where: UpdateInvoiceStateInput;
};


export type MutationUpdateOfferArgs = {
  where: UpdateOfferInput;
};


export type MutationUpdateOfferDiscountArgs = {
  where: DiscountUpdateInput;
};


export type MutationUpdateOfferPinArgs = {
  where: UpdateOfferPinInput;
};


export type MutationUpdateOfferPositionArgs = {
  where: UpdateOfferPositionInput;
};


export type MutationUpdateOfferRateArgs = {
  where: UpdateOfferRateInput;
};


export type MutationUpdateOrAddTemplateTypeArgs = {
  where: UpdateOrAddTemplateTypeInput;
};


export type MutationUpdateRepetitionRuleArgs = {
  where: UpdateRepetitionRuleInput;
};


export type MutationUpdateTaskArgs = {
  where: UpdateTaskInput;
};


export type MutationUpdateTaskCompletedArgs = {
  where: UpdateTaskCompleted;
};


export type MutationUpdateTemplateArgs = {
  where: UpdateTemplateInput;
};


export type MutationUpdateTimeTrackingArgs = {
  where: EditTimeTrackingInput;
};


export type MutationUpdateUserSettingsArgs = {
  where: UserSettingsUpdateInput;
};

export type Offer = {
  __typename?: 'Offer';
  addedToSendQueue?: Maybe<Scalars['Date']>;
  afterText: Scalars['String'];
  afterTextTemplate: Scalars['Int'];
  /** Price when all items are selected */
  aggregatedFullPrice: Scalars['Float'];
  /** Price when only the mandatory items are selected */
  aggregatedMinPrice: Scalars['Float'];
  aggregatedPrice: Scalars['Float'];
  /** Price for selected items */
  aggregatedPriceWithoutDiscount: Scalars['Float'];
  asAPackage: Scalars['Boolean'];
  companyId?: Maybe<Scalars['Int']>;
  contactPerson?: Maybe<ContactPerson>;
  costEstimate: Scalars['Boolean'];
  customContactEmail?: Maybe<Scalars['String']>;
  customer?: Maybe<Company>;
  discount: Array<Discount>;
  emailText: Scalars['String'];
  emailTextTemplate: Scalars['Int'];
  id: Scalars['Int'];
  link: Scalars['String'];
  mailAttachPDF: Scalars['Boolean'];
  name: Scalars['String'];
  pdf: Scalars['String'];
  pin?: Maybe<Scalars['String']>;
  preText: Scalars['String'];
  preTextTemplate: Scalars['Int'];
  rates: Array<Rate>;
  sendMail: Scalars['Boolean'];
  sent?: Maybe<Scalars['Date']>;
  status: OfferStatus;
  userDefinedTextId?: Maybe<Scalars['Int']>;
  validity?: Maybe<Scalars['Date']>;
  views: Array<OfferViewAction>;
};

export type OfferByLinkInput = {
  companyShort: Scalars['String'];
  link: Scalars['String'];
};

export type OfferDeletedResponse = {
  __typename?: 'OfferDeletedResponse';
  id: Scalars['Int'];
};

export type OfferInfoByLink = {
  __typename?: 'OfferInfoByLink';
  offer: Offer;
  positions: Array<Task>;
};

export type OfferInput = {
  id: Scalars['Int'];
};

export type OfferMailInput = {
  batch: Scalars['Int'];
};

export type OfferPdfInput = {
  id: Scalars['Int'];
  interactive: Scalars['Boolean'];
};

export type OfferPosition = {
  __typename?: 'OfferPosition';
  offerId: Scalars['Int'];
  optional: Scalars['Boolean'];
  preselected: Scalars['Boolean'];
  selected: Scalars['Boolean'];
  taskId: Scalars['Int'];
};

export type OfferPositionsInput = {
  offerId: Scalars['Int'];
};

export type OffersInput = {
  loadCostEstimates: Scalars['Boolean'];
  loadOffers: Scalars['Boolean'];
};

export enum OfferStatus {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Draft = 'DRAFT',
  Sending = 'SENDING',
  Sent = 'SENT'
}

export type OfferViewAction = {
  __typename?: 'OfferViewAction';
  timestamp: Scalars['Date'];
  type: OfferViewActionType;
};

export enum OfferViewActionType {
  Accepted = 'ACCEPTED',
  Download = 'DOWNLOAD',
  View = 'VIEW'
}

export type OfferWebviewAuthenticationInput = {
  companyShort: Scalars['String'];
  link: Scalars['String'];
  pin: Scalars['String'];
};

export type Price = {
  __typename?: 'Price';
  adjusted: Scalars['Float'];
  original: Scalars['Float'];
};

export enum PriceCalcMethodType {
  Fixed = 'FIXED',
  Time = 'TIME'
}

export type Prio = {
  __typename?: 'Prio';
  color: Scalars['String'];
  description: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  sort: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  activity: Array<Activity>;
  addCustomerCheck: AddCustomerCheckResponse;
  addresses: Array<Address>;
  billingTasks: BillingTasksResponse;
  companies: Array<Company>;
  company: Company;
  config: Array<Configuration>;
  contactPersons: Array<ContactPerson>;
  hourlyWorkRates: Array<HourlyWorkRate>;
  invoice: Invoice;
  invoiceEditor: InvoiceEditorResponse;
  invoices: Array<Invoice>;
  me: User;
  nextOfferId: Scalars['Int'];
  offer: Offer;
  offerByLink: OfferInfoByLink;
  offerPDF: Scalars['String'];
  offerPositions: Array<Task>;
  offers: Array<Offer>;
  parentTasks: Array<Task>;
  prios: Array<Prio>;
  recurringInvoice: RecurringInvoice;
  recurringInvoices: RecurringInvoiceList;
  superTask: Task;
  task: Task;
  taskCategories: Array<TaskCategory>;
  tasks: TasksResponse;
  template: Template;
  templates: Array<Template>;
  timeTracking: TimeTrackingResponse;
  users: Array<User>;
  userSettings: Array<UserSettings>;
  version: Scalars['String'];
};


export type QueryActivityArgs = {
  where: ActivityInput;
};


export type QueryAddCustomerCheckArgs = {
  where: AddCustomerCheckInput;
};


export type QueryBillingTasksArgs = {
  where: BillingTasksInput;
};


export type QueryCompanyArgs = {
  where: CompanyInput;
};


export type QueryContactPersonsArgs = {
  where: ContactPersonInput;
};


export type QueryHourlyWorkRatesArgs = {
  where?: Maybe<HourlyWorkRatesInput>;
};


export type QueryInvoiceArgs = {
  where: InvoiceInput;
};


export type QueryInvoiceEditorArgs = {
  where: InvoiceEditorInput;
};


export type QueryOfferArgs = {
  where: OfferInput;
};


export type QueryOfferByLinkArgs = {
  where: OfferByLinkInput;
};


export type QueryOfferPdfArgs = {
  where: OfferPdfInput;
};


export type QueryOfferPositionsArgs = {
  where: OfferPositionsInput;
};


export type QueryOffersArgs = {
  where: OffersInput;
};


export type QueryParentTasksArgs = {
  where: TaskInput;
};


export type QueryRecurringInvoiceArgs = {
  where: RecurringInvoiceInput;
};


export type QuerySuperTaskArgs = {
  where: TaskInput;
};


export type QueryTaskArgs = {
  where: TaskInput;
};


export type QueryTasksArgs = {
  where: TasksInput;
};


export type QueryTemplateArgs = {
  where: TemplateInput;
};


export type QueryTemplatesArgs = {
  where: TemplatesInput;
};


export type QueryUsersArgs = {
  where?: Maybe<UsersInput>;
};

export type Rate = {
  __typename?: 'Rate';
  amount: Scalars['Float'];
  archivedDate?: Maybe<Scalars['Date']>;
  at: Scalars['Int'];
  description: Scalars['String'];
  id: Scalars['Int'];
  invoiceId?: Maybe<Scalars['Int']>;
  offerId: Scalars['Int'];
};

export type RecurringInvoice = {
  __typename?: 'RecurringInvoice';
  id: Scalars['Int'];
  invoice: Invoice;
  repetitionRule: RepetitionRule;
};

export type RecurringInvoiceDeletedInput = {
  recurringInvoiceIds: Array<Scalars['Int']>;
};

export type RecurringInvoiceInput = {
  recurringInvoiceId: Scalars['Int'];
};

export type RecurringInvoiceList = {
  __typename?: 'RecurringInvoiceList';
  nextRecurringInvoiceId: Scalars['Int'];
  recurringInvoices: Array<RecurringInvoice>;
};

export type RefreshAuthenticationInput = {
  refreshToken: Scalars['String'];
};

export type RefreshAuthenticationResponse = {
  __typename?: 'RefreshAuthenticationResponse';
  expiry?: Maybe<Scalars['Date']>;
  message?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type RepetitionRule = {
  __typename?: 'RepetitionRule';
  announcementHistory: Array<ExecutionHistoryItem>;
  announcementPreview: Array<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
  executionHistory: Array<ExecutionHistoryItem>;
  executionPreview: Array<Scalars['Date']>;
  finalAnnouncement?: Maybe<Scalars['Date']>;
  finalExecution?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  interval: Scalars['Int'];
  intervalType: IntervalType;
  lastAnnouncedAt?: Maybe<Scalars['Date']>;
  lastExecutedAt?: Maybe<Scalars['Date']>;
  start?: Maybe<Scalars['Date']>;
  status: RepetitionRuleStatus;
};

export enum RepetitionRuleStatus {
  Active = 'ACTIVE',
  Draft = 'DRAFT',
  End = 'END',
  Error = 'ERROR',
  Inactive = 'INACTIVE'
}

export type SendInvoiceInput = {
  invoiceId: Scalars['Int'];
};

export type StartTimeTrackingResponse = {
  __typename?: 'StartTimeTrackingResponse';
  stoppedWorkload?: Maybe<Workload>;
  trackedTask: Task;
};

export type Subscription = {
  __typename?: 'Subscription';
  addressAdded: Address;
  backendUpdated: Scalars['Int'];
  companyAdded: Company;
  companyUpdated: Company;
  contactPersonAdded: ContactPerson;
  invoiceAdded: Invoice;
  invoiceDeleted: Scalars['Int'];
  invoiceUpdated: Invoice;
  meUpdated: User;
  offerAdded: Offer;
  offerDeleted: OfferDeletedResponse;
  offerPDFUpdated: Scalars['String'];
  offerUpdated: Offer;
  recurringInvoiceAdded: RecurringInvoice;
  recurringInvoiceDeleted: Scalars['Int'];
  repetitionRuleUpdated: RepetitionRule;
  taskAdded: AddTaskOutput;
  tasksDeleted: Array<Scalars['Int']>;
  tasksUpdated: Array<Task>;
  templateAdded: Template;
  templateDeleted: Template;
  templateUpdated: Template;
  timeTrackingUpdated: TimeTrackingResponse;
};


export type SubscriptionContactPersonAddedArgs = {
  where: ContactPersonAddedInput;
};


export type SubscriptionInvoiceUpdatedArgs = {
  where?: Maybe<InvoiceUpdateSubscriptionInput>;
};


export type SubscriptionOfferPdfUpdatedArgs = {
  where: OfferPdfInput;
};


export type SubscriptionRecurringInvoiceDeletedArgs = {
  where?: Maybe<RecurringInvoiceDeletedInput>;
};


export type SubscriptionRepetitionRuleUpdatedArgs = {
  where?: Maybe<UpdateRepetitionRuleSubscriptionInput>;
};


export type SubscriptionTaskAddedArgs = {
  where: TaskSubscriptionArgs;
};


export type SubscriptionTasksDeletedArgs = {
  where: TaskSubscriptionArgs;
};


export type SubscriptionTasksUpdatedArgs = {
  where: TaskSubscriptionArgs;
};


export type SubscriptionTemplateAddedArgs = {
  where: TemplateSubscriptionInput;
};


export type SubscriptionTemplateDeletedArgs = {
  where: TemplateSubscriptionInput;
};


export type SubscriptionTemplateUpdatedArgs = {
  where: TemplateSubscriptionInput;
};

export type Task = {
  __typename?: 'Task';
  archived: Scalars['Boolean'];
  archivedDate?: Maybe<Scalars['Date']>;
  assignedUsers: Array<User>;
  billingRelevantTime?: Maybe<TaskBillingRelevantTime>;
  childrenTaskIds: Array<Scalars['Int']>;
  childrenWorkedTime: ChildrenWorkedTime;
  companyId: Scalars['Int'];
  completed: Scalars['Boolean'];
  completedDate?: Maybe<Scalars['Date']>;
  currentTrackingUsers: Array<User>;
  currentWork: Array<Workload>;
  dateCreated: Scalars['Date'];
  deadline?: Maybe<Scalars['Date']>;
  deletionOption: TaskDeletionOption;
  description?: Maybe<Scalars['String']>;
  discount: Array<Discount>;
  effectiveHourlyWorkRate?: Maybe<Scalars['Float']>;
  finishedWork: Array<Workload>;
  flatRatePrice?: Maybe<Scalars['Float']>;
  hourlyWorkRate?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  index: Scalars['Int'];
  invoiceId?: Maybe<Scalars['Int']>;
  isBillable: Scalars['Boolean'];
  isMovable: Scalars['Boolean'];
  /** " Billing information */
  isNotCharged: Scalars['Boolean'];
  isTopTask: Scalars['Boolean'];
  name: Scalars['String'];
  offer?: Maybe<Offer>;
  /** " Offer information */
  offerId?: Maybe<Scalars['Int']>;
  optional?: Maybe<Scalars['Boolean']>;
  parentId?: Maybe<Scalars['Int']>;
  /** " Child task only */
  path: Array<Scalars['Int']>;
  preselected?: Maybe<Scalars['Boolean']>;
  price: Price;
  priceCalcMethod: PriceCalcMethodType;
  priceWithoutChildren: Price;
  prio: Prio;
  selected?: Maybe<Scalars['Boolean']>;
  shownPrio: Prio;
  taskCategory: Scalars['Int'];
  /** " Parent task only */
  timeBudget?: Maybe<Scalars['Int']>;
  timeBudgetViewUnit: TimeUnitType;
  workedTime: Scalars['Long'];
};

export type TaskBillingRelevantTime = {
  __typename?: 'TaskBillingRelevantTime';
  aggregated: Scalars['Float'];
  withoutChildren: Scalars['Float'];
};

export type TaskCategory = {
  __typename?: 'TaskCategory';
  id: Scalars['Int'];
  name: Scalars['String'];
  taxRate: Scalars['Float'];
};

export enum TaskDeletionOption {
  Archivable = 'ARCHIVABLE',
  Blocked = 'BLOCKED',
  Deletable = 'DELETABLE'
}

export type TaskInput = {
  id: Scalars['Int'];
};

export type TasksInput = {
  companyId: Scalars['Int'];
  showCompleted?: Maybe<Scalars['Boolean']>;
  showNotAssigned?: Maybe<Scalars['Boolean']>;
  showOfferPending?: Maybe<Scalars['Boolean']>;
  uncollapsedTaskIds: Array<Scalars['Int']>;
};

export type TasksResponse = {
  __typename?: 'TasksResponse';
  tasks: Array<Task>;
  uncollapsableTaskIds: Array<Scalars['Int']>;
};

export type TaskSubscriptionArgs = {
  all?: Maybe<Scalars['Boolean']>;
  companyId?: Maybe<Scalars['Int']>;
  offerId?: Maybe<Scalars['Int']>;
  recurringBill?: Maybe<Scalars['Int']>;
  taskIds?: Maybe<Array<Scalars['Int']>>;
};

export type Template = {
  __typename?: 'Template';
  id: Scalars['Int'];
  /** Type of the template,e.g.preText */
  templateType: TemplateType;
  /** The actual template text(might contain RichText) */
  text: Scalars['String'];
  /**
   * A key for templates where multiple instances of a templateType
   * exist,e.g.templateType = `taskDescription`,title = `Branding`
   */
  title?: Maybe<Scalars['String']>;
};

export type TemplateInput = {
  id: Scalars['Int'];
};

export type TemplatesInput = {
  templateType: TemplateType;
};

export type TemplateSubscriptionInput = {
  templateType: TemplateType;
};

export enum TemplateType {
  CostEstimateAftertext = 'COST_ESTIMATE_AFTERTEXT',
  CostEstimateEmailtext = 'COST_ESTIMATE_EMAILTEXT',
  CostEstimatePretext = 'COST_ESTIMATE_PRETEXT',
  InvoiceEmailtext = 'INVOICE_EMAILTEXT',
  InvoiceNotes = 'INVOICE_NOTES',
  InvoicePretext = 'INVOICE_PRETEXT',
  Offer = 'OFFER',
  OfferAftertext = 'OFFER_AFTERTEXT',
  OfferEmailtext = 'OFFER_EMAILTEXT',
  OfferPretext = 'OFFER_PRETEXT',
  RecurringInvoiceEmailtext = 'RECURRING_INVOICE_EMAILTEXT',
  RecurringInvoiceNotes = 'RECURRING_INVOICE_NOTES',
  RecurringInvoicePretext = 'RECURRING_INVOICE_PRETEXT',
  TaskDescription = 'TASK_DESCRIPTION'
}

export type TimeTrackingChangeResponse = {
  __typename?: 'TimeTrackingChangeResponse';
  tasks: Array<Task>;
  workloads: Array<Workload>;
};

export type TimetrackingCompany = {
  __typename?: 'TimetrackingCompany';
  id: Scalars['Int'];
  imgURL?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  short: Scalars['String'];
};

export type TimeTrackingInfo = {
  __typename?: 'TimeTrackingInfo';
  company: Company;
  shownPrio: Prio;
  superTask?: Maybe<Task>;
  task: Task;
  workload?: Maybe<Workload>;
};

export type TimetrackingInput = {
  companyId?: Maybe<Scalars['Int']>;
  taskId?: Maybe<Scalars['Int']>;
};

export type TimeTrackingInput = {
  taskId: Scalars['Int'];
};

export type TimeTrackingResponse = {
  __typename?: 'TimeTrackingResponse';
  quickSelection: Array<TimeTrackingInfo>;
  urgent: Array<TimeTrackingInfo>;
};

export type TimetrackingSuperTask = {
  __typename?: 'TimetrackingSuperTask';
  deadline?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  prio: Prio;
};

export type TimetrackingTask = {
  __typename?: 'TimetrackingTask';
  company: TimetrackingCompany;
  deadline?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  prio: Prio;
  superTask: TimetrackingSuperTask;
  topTaskId?: Maybe<Scalars['Int']>;
  workload?: Maybe<TimetrackingWorkload>;
};

export type TimetrackingWorkload = {
  __typename?: 'TimetrackingWorkload';
  end?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  start: Scalars['Date'];
};

export enum TimeUnitType {
  Days = 'DAYS',
  Hours = 'HOURS'
}

export type UnassignUserFromTaskInput = {
  taskId: Scalars['Int'];
  userId: Scalars['Int'];
};

export type UpdateCompanyFavoriteInput = {
  favorite: Scalars['Boolean'];
  id: Scalars['Int'];
};

export type UpdateCompanyInput = {
  country?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isFreelancer?: Maybe<Scalars['Int']>;
  legalName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  picURL?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['Int']>;
  priceCategory?: Maybe<Scalars['Int']>;
  shortName?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

export type UpdateInvoiceInput = {
  afterText?: Maybe<Scalars['String']>;
  afterTextTemplate?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  contactPersonId?: Maybe<Scalars['Int']>;
  customContactEmail?: Maybe<Scalars['String']>;
  emailText?: Maybe<Scalars['String']>;
  emailTextTemplate?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  preText?: Maybe<Scalars['String']>;
  preTextTemplate?: Maybe<Scalars['Int']>;
  sendMail?: Maybe<Scalars['Boolean']>;
};

export type UpdateInvoiceMailSettingsInput = {
  attachPDF?: Maybe<Scalars['Boolean']>;
  customContactEmail?: Maybe<Scalars['String']>;
  emailText?: Maybe<Scalars['String']>;
  emailTextTemplate?: Maybe<Scalars['Int']>;
  invoiceId: Scalars['Int'];
  sendMail?: Maybe<Scalars['Boolean']>;
};

export type UpdateInvoiceStateInput = {
  id: Scalars['Int'];
  state: InvoiceState;
};

export type UpdateOfferInput = {
  afterText?: Maybe<Scalars['String']>;
  afterTextTemplate?: Maybe<Scalars['Int']>;
  asAPackage?: Maybe<Scalars['Boolean']>;
  companyId?: Maybe<Scalars['Int']>;
  contactPersonId?: Maybe<Scalars['Int']>;
  costEstimate?: Maybe<Scalars['Boolean']>;
  customContactEmail?: Maybe<Scalars['String']>;
  emailText?: Maybe<Scalars['String']>;
  emailTextTemplate?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  mailAttachPDF?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  preText?: Maybe<Scalars['String']>;
  preTextTemplate?: Maybe<Scalars['Int']>;
  sendMail?: Maybe<Scalars['Boolean']>;
  status?: Maybe<OfferStatus>;
  userDefinedTextId?: Maybe<Scalars['Int']>;
  validity?: Maybe<Scalars['Date']>;
};

export type UpdateOfferPinInput = {
  id: Scalars['Int'];
  pin: Scalars['String'];
};

export type UpdateOfferPositionInput = {
  offerId: Scalars['Int'];
  optional?: Maybe<Scalars['Boolean']>;
  preselected?: Maybe<Scalars['Boolean']>;
  selected?: Maybe<Scalars['Boolean']>;
  taskId: Scalars['Int'];
};

export type UpdateOfferRateInput = {
  at?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

export type UpdateOrAddTemplateTypeInput = {
  /** Used to identify the template together with title */
  templateType: TemplateType;
  /** The new text */
  text: Scalars['String'];
  /** Used to identify the template together with templateType */
  title: Scalars['String'];
};

export type UpdateRepetitionRuleInput = {
  end?: Maybe<Scalars['Date']>;
  intervalFrequency?: Maybe<Scalars['Int']>;
  intervalType?: Maybe<IntervalType>;
  repetitionRuleId: Scalars['Int'];
  start?: Maybe<Scalars['Date']>;
  status?: Maybe<RepetitionRuleStatus>;
};

export type UpdateRepetitionRuleSubscriptionInput = {
  ids: Array<Scalars['Int']>;
};

export type UpdateTaskCompleted = {
  completed: Scalars['Boolean'];
  id: Scalars['Int'];
};

export type UpdateTaskCompletedResponse = {
  __typename?: 'UpdateTaskCompletedResponse';
  blockedTasks: Array<Task>;
  updatedTasks: Array<Task>;
};

export type UpdateTaskInput = {
  archived?: Maybe<Scalars['Boolean']>;
  customHourlyWorkRate?: Maybe<Scalars['Float']>;
  deadline?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  flatRatePrice?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  isNotCharged?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  priceCalcMethod?: Maybe<PriceCalcMethodType>;
  prioId?: Maybe<Scalars['Int']>;
  taskCategory?: Maybe<Scalars['Int']>;
  timeBudget?: Maybe<Scalars['Int']>;
  timeBudgetViewUnit?: Maybe<Scalars['String']>;
};

export type UpdateTemplateInput = {
  id: Scalars['Int'];
  text: Scalars['String'];
};


export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  profilePic?: Maybe<Scalars['String']>;
  role: UserRole;
  settings: Scalars['String'];
  surname: Scalars['String'];
};

export enum UserRole {
  Admin = 'ADMIN',
  Disabled = 'DISABLED',
  Dummy = 'DUMMY',
  Employee = 'EMPLOYEE',
  Owner = 'OWNER',
  ProjectManager = 'PROJECT_MANAGER'
}

export type UserSettings = {
  __typename?: 'UserSettings';
  id: UserSettingsKey;
  value?: Maybe<Scalars['String']>;
};

export type UserSettingsInput = {
  id: UserSettingsKey;
};

export enum UserSettingsKey {
  FilterCustomerSidebar = 'FilterCustomerSidebar',
  FilterPrioCustomerSidebar = 'FilterPrioCustomerSidebar',
  InvoiceMailAttachPdf = 'INVOICE_MAIL_ATTACH_PDF',
  InvoiceSendAutomatic = 'INVOICE_SEND_AUTOMATIC',
  LastSelectedCompanyId = 'LastSelectedCompanyId',
  OfferMailAttachPdf = 'OFFER_MAIL_ATTACH_PDF',
  OfferSendAutomatic = 'OFFER_SEND_AUTOMATIC',
  SortCustomerSidebar = 'SortCustomerSidebar'
}

export type UserSettingsUpdateInput = {
  id: UserSettingsKey;
  value?: Maybe<Scalars['String']>;
};

export type UsersInput = {
  companyId?: Maybe<Scalars['Int']>;
};

export type Workload = {
  __typename?: 'Workload';
  end?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  note?: Maybe<Scalars['String']>;
  start: Scalars['Date'];
  taskId: Scalars['Int'];
  userId: Scalars['Int'];
};

export type BillingCompanyListItemFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'short' | 'sort' | 'favorite' | 'hasBillingActivity' | 'hasUserRelatedBillingActivity' | 'nextBillingDeadline'>
);

export type GetBillingCompaniesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBillingCompaniesQuery = (
  { __typename?: 'Query' }
  & { companies: Array<(
    { __typename?: 'Company' }
    & BillingCompanyListItemFragment
  )> }
);

export type BillingCompanyAddedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type BillingCompanyAddedSubscription = (
  { __typename?: 'Subscription' }
  & { companyAdded: (
    { __typename?: 'Company' }
    & BillingCompanyListItemFragment
  ) }
);

export type BillingCompanyUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type BillingCompanyUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { companyUpdated: (
    { __typename?: 'Company' }
    & BillingCompanyListItemFragment
  ) }
);

export type BillingTaskListRateFragment = (
  { __typename?: 'Rate' }
  & Pick<Rate, 'id' | 'at' | 'amount' | 'description' | 'offerId' | 'invoiceId' | 'archivedDate'>
);

export type BillingTaskSidebarTaskFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'id' | 'companyId' | 'offerId' | 'index' | 'path' | 'childrenTaskIds' | 'isTopTask' | 'isBillable' | 'deletionOption' | 'isMovable' | 'parentId' | 'name' | 'description' | 'deadline' | 'timeBudget' | 'completed' | 'archived' | 'taskCategory' | 'workedTime' | 'timeBudgetViewUnit' | 'hourlyWorkRate' | 'priceCalcMethod' | 'flatRatePrice' | 'effectiveHourlyWorkRate' | 'isNotCharged'>
  & { offer?: Maybe<(
    { __typename?: 'Offer' }
    & Pick<Offer, 'id' | 'name' | 'status' | 'asAPackage' | 'aggregatedPrice'>
    & { rates: Array<(
      { __typename?: 'Rate' }
      & BillingTaskListRateFragment
    )> }
  )>, prio: (
    { __typename?: 'Prio' }
    & Pick<Prio, 'id' | 'sort' | 'color'>
  ), shownPrio: (
    { __typename?: 'Prio' }
    & Pick<Prio, 'id' | 'sort' | 'color'>
  ), assignedUsers: Array<(
    { __typename?: 'User' }
    & BillingTaskSidebarTaskUserFragment
  )>, currentTrackingUsers: Array<(
    { __typename?: 'User' }
    & BillingTaskSidebarTaskUserFragment
  )>, finishedWork: Array<(
    { __typename?: 'Workload' }
    & Pick<Workload, 'id' | 'start' | 'end' | 'userId'>
  )>, childrenWorkedTime: (
    { __typename?: 'ChildrenWorkedTime' }
    & BillingTaskSidebarTaskChildrenWorkedTimeFragment
  ), discount: Array<(
    { __typename?: 'Discount' }
    & BillingTaskSidebarTaskDiscountFragment
  )>, currentWork: Array<(
    { __typename?: 'Workload' }
    & BillingTaskSidebarTaskWorkloadFragment
  )>, price: (
    { __typename?: 'Price' }
    & Pick<Price, 'original' | 'adjusted'>
  ), priceWithoutChildren: (
    { __typename?: 'Price' }
    & Pick<Price, 'original' | 'adjusted'>
  ) }
);

export type BillingTaskSidebarTaskDiscountFragment = (
  { __typename?: 'Discount' }
  & Pick<Discount, 'id' | 'value' | 'unit'>
);

export type BillingTaskSidebarTaskWorkloadFragment = (
  { __typename?: 'Workload' }
  & Pick<Workload, 'id' | 'start' | 'end' | 'userId'>
);

export type BillingTaskSidebarTaskUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'name' | 'surname' | 'profilePic' | 'settings'>
);

export type BillingTaskSidebarTaskChildrenWorkedTimeFragment = (
  { __typename?: 'ChildrenWorkedTime' }
  & Pick<ChildrenWorkedTime, 'completed' | 'notCompleted'>
  & { currentWork: Array<(
    { __typename?: 'Workload' }
    & Pick<Workload, 'id' | 'start' | 'end' | 'userId'>
  )> }
);

export type GetBillingTaskSidebarQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetBillingTaskSidebarQuery = (
  { __typename?: 'Query' }
  & { task: (
    { __typename?: 'Task' }
    & BillingTaskSidebarTaskFragment
  ) }
);

export type BillingDeleteTaskMutationVariables = Exact<{
  taskId: Scalars['Int'];
}>;


export type BillingDeleteTaskMutation = (
  { __typename?: 'Mutation' }
  & { deleteTask: (
    { __typename?: 'DeleteTaskResponse' }
    & { deletedTasks: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'id'>
    )>, blockedTasks: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'id'>
      & { currentTrackingUsers: Array<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name' | 'surname' | 'profilePic'>
      )> }
    )> }
  ) }
);

export type BillingTaskSidebarUpdateTaskCompletedMutationVariables = Exact<{
  id: Scalars['Int'];
  completed: Scalars['Boolean'];
}>;


export type BillingTaskSidebarUpdateTaskCompletedMutation = (
  { __typename?: 'Mutation' }
  & { updateTaskCompleted: (
    { __typename?: 'UpdateTaskCompletedResponse' }
    & { updatedTasks: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'completed'>
    )>, blockedTasks: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'id'>
      & { currentTrackingUsers: Array<(
        { __typename?: 'User' }
        & BillingTaskSidebarTaskUserFragment
      )> }
    )> }
  ) }
);

export type BillingTaskSidebarUpdateTaskMutationVariables = Exact<{
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  prioId?: Maybe<Scalars['Int']>;
  deadline?: Maybe<Scalars['Int']>;
  timeBudget?: Maybe<Scalars['Int']>;
  taskCategory?: Maybe<Scalars['Int']>;
  priceCalcMethod?: Maybe<PriceCalcMethodType>;
  customHourlyWorkRate?: Maybe<Scalars['Float']>;
  timeBudgetViewUnit?: Maybe<Scalars['String']>;
  flatRatePrice?: Maybe<Scalars['Float']>;
  archived?: Maybe<Scalars['Boolean']>;
  isNotCharged?: Maybe<Scalars['Boolean']>;
}>;


export type BillingTaskSidebarUpdateTaskMutation = (
  { __typename?: 'Mutation' }
  & { updateTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'name' | 'description' | 'deadline' | 'timeBudget' | 'priceCalcMethod' | 'timeBudgetViewUnit' | 'hourlyWorkRate' | 'taskCategory' | 'flatRatePrice' | 'effectiveHourlyWorkRate' | 'archived' | 'isNotCharged'>
    & { prio: (
      { __typename?: 'Prio' }
      & Pick<Prio, 'id'>
    ), price: (
      { __typename?: 'Price' }
      & Pick<Price, 'adjusted' | 'original'>
    ) }
  )> }
);

export type BillingTaskSidebarArchiveOfferPositionsMutationVariables = Exact<{
  offerId: Scalars['Int'];
  taskId?: Maybe<Scalars['Int']>;
  rateId?: Maybe<Scalars['Int']>;
}>;


export type BillingTaskSidebarArchiveOfferPositionsMutation = (
  { __typename?: 'Mutation' }
  & { archiveOfferPositions: (
    { __typename?: 'Offer' }
    & Pick<Offer, 'id'>
  ) }
);

export type BillingTasksidebarEditTimeTrackingMutationVariables = Exact<{
  workloadId: Scalars['Int'];
  start: Scalars['Date'];
  end: Scalars['Date'];
}>;


export type BillingTasksidebarEditTimeTrackingMutation = (
  { __typename?: 'Mutation' }
  & { updateTimeTracking: (
    { __typename?: 'TimeTrackingChangeResponse' }
    & { workloads: Array<(
      { __typename?: 'Workload' }
      & Pick<Workload, 'id' | 'start' | 'end' | 'taskId'>
    )>, tasks: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'workedTime' | 'effectiveHourlyWorkRate'>
      & { price: (
        { __typename?: 'Price' }
        & Pick<Price, 'original' | 'adjusted'>
      ), currentWork: Array<(
        { __typename?: 'Workload' }
        & Pick<Workload, 'id' | 'start' | 'end' | 'userId'>
      )> }
    )> }
  ) }
);

export type BillingTasksidebarAddDiscountMutationVariables = Exact<{
  value: Scalars['Float'];
  unit: Charge;
  productID: Scalars['Int'];
}>;


export type BillingTasksidebarAddDiscountMutation = (
  { __typename?: 'Mutation' }
  & { addDiscount: (
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'name' | 'description' | 'deadline' | 'timeBudget' | 'priceCalcMethod' | 'timeBudgetViewUnit' | 'hourlyWorkRate' | 'effectiveHourlyWorkRate' | 'taskCategory'>
    & { price: (
      { __typename?: 'Price' }
      & Pick<Price, 'original' | 'adjusted'>
    ), prio: (
      { __typename?: 'Prio' }
      & Pick<Prio, 'id'>
    ), discount: Array<(
      { __typename?: 'Discount' }
      & Pick<Discount, 'id' | 'value' | 'unit'>
    )> }
  ) }
);

export type BillingTasksidebarUpdateDiscountMutationVariables = Exact<{
  id: Scalars['Int'];
  value: Scalars['Float'];
  unit: Charge;
  productID: Scalars['Int'];
}>;


export type BillingTasksidebarUpdateDiscountMutation = (
  { __typename?: 'Mutation' }
  & { updateDiscount: (
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'name' | 'description' | 'deadline' | 'timeBudget' | 'priceCalcMethod' | 'timeBudgetViewUnit' | 'hourlyWorkRate' | 'effectiveHourlyWorkRate' | 'taskCategory'>
    & { price: (
      { __typename?: 'Price' }
      & Pick<Price, 'original' | 'adjusted'>
    ), prio: (
      { __typename?: 'Prio' }
      & Pick<Prio, 'id'>
    ), discount: Array<(
      { __typename?: 'Discount' }
      & Pick<Discount, 'id' | 'value' | 'unit'>
    )> }
  ) }
);

export type BillingTasksidebarDeleteDiscountMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type BillingTasksidebarDeleteDiscountMutation = (
  { __typename?: 'Mutation' }
  & { deleteDiscount: (
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'name' | 'description' | 'deadline' | 'timeBudget' | 'priceCalcMethod' | 'timeBudgetViewUnit' | 'hourlyWorkRate' | 'effectiveHourlyWorkRate' | 'taskCategory'>
    & { price: (
      { __typename?: 'Price' }
      & Pick<Price, 'original' | 'adjusted'>
    ), prio: (
      { __typename?: 'Prio' }
      & Pick<Prio, 'id'>
    ), discount: Array<(
      { __typename?: 'Discount' }
      & Pick<Discount, 'id' | 'value' | 'unit'>
    )> }
  ) }
);

export type BillingTasksideAssignUserMutationVariables = Exact<{
  userId: Scalars['Int'];
  taskId: Scalars['Int'];
}>;


export type BillingTasksideAssignUserMutation = (
  { __typename?: 'Mutation' }
  & { assignUserToTask: (
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
    & { assignedUsers: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'surname' | 'profilePic'>
    )> }
  ) }
);

export type BillingTasksideUnassignUserMutationVariables = Exact<{
  userId: Scalars['Int'];
  taskId: Scalars['Int'];
}>;


export type BillingTasksideUnassignUserMutation = (
  { __typename?: 'Mutation' }
  & { unassignUserFromTask: (
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
    & { assignedUsers: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )> }
  ) }
);

export type BillingTasksideDeleteTimeTrackingMutationVariables = Exact<{
  workloadId: Scalars['Int'];
}>;


export type BillingTasksideDeleteTimeTrackingMutation = (
  { __typename?: 'Mutation' }
  & { deleteTimeTracking: (
    { __typename?: 'TimeTrackingChangeResponse' }
    & { workloads: Array<(
      { __typename?: 'Workload' }
      & Pick<Workload, 'id' | 'start' | 'end' | 'taskId'>
    )>, tasks: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'workedTime' | 'effectiveHourlyWorkRate'>
      & { price: (
        { __typename?: 'Price' }
        & Pick<Price, 'original' | 'adjusted'>
      ), currentWork: Array<(
        { __typename?: 'Workload' }
        & Pick<Workload, 'id' | 'start' | 'end' | 'userId'>
      )> }
    )> }
  ) }
);

export type BillingTasksideDeleteTaskMutationVariables = Exact<{
  taskId: Scalars['Int'];
}>;


export type BillingTasksideDeleteTaskMutation = (
  { __typename?: 'Mutation' }
  & { deleteTask: (
    { __typename?: 'DeleteTaskResponse' }
    & { deletedTasks: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'id'>
    )>, blockedTasks: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'id'>
      & { currentTrackingUsers: Array<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name' | 'surname' | 'profilePic'>
      )> }
    )> }
  ) }
);

export type BillingTaskSidebarTaskUpdatedSubscriptionVariables = Exact<{
  taskId: Scalars['Int'];
}>;


export type BillingTaskSidebarTaskUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { tasksUpdated: Array<(
    { __typename?: 'Task' }
    & BillingTaskSidebarTaskFragment
  )> }
);

export type ContactPersonFragmentFragment = (
  { __typename?: 'ContactPerson' }
  & Pick<ContactPerson, 'id' | 'name' | 'surname' | 'title' | 'email' | 'companyId'>
);

export type GetContactPersonsQueryVariables = Exact<{
  companyId: Scalars['Int'];
  searchString: Scalars['String'];
}>;


export type GetContactPersonsQuery = (
  { __typename?: 'Query' }
  & { contactPersons: Array<(
    { __typename?: 'ContactPerson' }
    & ContactPersonFragmentFragment
  )> }
);

export type AddContactPersonMutationVariables = Exact<{
  name: Scalars['String'];
  surname: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  companyId: Scalars['Int'];
  picURL?: Maybe<Scalars['String']>;
  email: Scalars['String'];
}>;


export type AddContactPersonMutation = (
  { __typename?: 'Mutation' }
  & { addContactPerson: (
    { __typename?: 'ContactPerson' }
    & ContactPersonFragmentFragment
  ) }
);

export type ContactPersonAddedSubscriptionVariables = Exact<{
  companyId: Scalars['Int'];
}>;


export type ContactPersonAddedSubscription = (
  { __typename?: 'Subscription' }
  & { contactPersonAdded: (
    { __typename?: 'ContactPerson' }
    & ContactPersonFragmentFragment
  ) }
);

export type AddCustomerMutationVariables = Exact<{
  name: Scalars['String'];
  shortName: Scalars['String'];
  picURL?: Maybe<Scalars['String']>;
  isFreelancer?: Maybe<Scalars['Int']>;
  priceCategory?: Maybe<Scalars['Int']>;
  legalName: Scalars['String'];
  street: Scalars['String'];
  houseNumber: Scalars['String'];
  postCode: Scalars['Int'];
  place: Scalars['String'];
  country: Scalars['String'];
}>;


export type AddCustomerMutation = (
  { __typename?: 'Mutation' }
  & { addCustomer: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'short' | 'freelancer' | 'imageUrl' | 'priceCategory'>
  ) }
);

export type AddCustomerCheckQueryVariables = Exact<{
  name: Scalars['String'];
  shortName: Scalars['String'];
  companyId?: Maybe<Scalars['Int']>;
}>;


export type AddCustomerCheckQuery = (
  { __typename?: 'Query' }
  & { addCustomerCheck: (
    { __typename?: 'AddCustomerCheckResponse' }
    & Pick<AddCustomerCheckResponse, 'name' | 'short'>
  ) }
);

export type UpdateCompanyMutationVariables = Exact<{
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  legalName?: Maybe<Scalars['String']>;
  picURL?: Maybe<Scalars['String']>;
  isFreelancer?: Maybe<Scalars['Int']>;
  priceCategory?: Maybe<Scalars['Int']>;
  street?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['Int']>;
  place?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
}>;


export type UpdateCompanyMutation = (
  { __typename?: 'Mutation' }
  & { updateCompany: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'short' | 'freelancer' | 'imageUrl' | 'priceCategory'>
    & { address: (
      { __typename?: 'Address' }
      & Pick<Address, 'street' | 'houseNumber' | 'postCode' | 'place' | 'country' | 'legalName'>
    ) }
  ) }
);

export type GetCustomerDialogCompanyQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetCustomerDialogCompanyQuery = (
  { __typename?: 'Query' }
  & { company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'short' | 'freelancer' | 'imageUrl' | 'priceCategory'>
    & { address: (
      { __typename?: 'Address' }
      & Pick<Address, 'street' | 'houseNumber' | 'postCode' | 'place' | 'country' | 'legalName'>
    ) }
  ) }
);

export type CustomerDialogCompanyUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type CustomerDialogCompanyUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { companyUpdated: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'short' | 'freelancer' | 'imageUrl' | 'priceCategory'>
    & { address: (
      { __typename?: 'Address' }
      & Pick<Address, 'street' | 'houseNumber' | 'postCode' | 'place' | 'country' | 'legalName'>
    ) }
  ) }
);

export type CustomerSidebarActivityQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;


export type CustomerSidebarActivityQuery = (
  { __typename?: 'Query' }
  & { activity: Array<(
    { __typename?: 'Activity' }
    & Pick<Activity, 'all' | 'userRelated'>
    & { prio: (
      { __typename?: 'Prio' }
      & Pick<Prio, 'id' | 'color'>
    ) }
  )> }
);

export type GetPriosQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPriosQuery = (
  { __typename?: 'Query' }
  & { prios: Array<(
    { __typename?: 'Prio' }
    & Pick<Prio, 'id' | 'description' | 'color' | 'sort'>
  )> }
);

export type CompanyListItemFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'short' | 'sort' | 'favorite' | 'hasActivity' | 'hasUserRelatedActivity' | 'nextDeadline'>
  & { activity: Array<(
    { __typename?: 'Activity' }
    & Pick<Activity, 'all'>
    & { prio: (
      { __typename?: 'Prio' }
      & Pick<Prio, 'sort' | 'description' | 'color'>
    ) }
  )> }
);

export type GetCompaniesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompaniesQuery = (
  { __typename?: 'Query' }
  & { companies: Array<(
    { __typename?: 'Company' }
    & CompanyListItemFragment
  )> }
);

export type CompanyAddedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type CompanyAddedSubscription = (
  { __typename?: 'Subscription' }
  & { companyAdded: (
    { __typename?: 'Company' }
    & CompanyListItemFragment
  ) }
);

export type CompanyUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type CompanyUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { companyUpdated: (
    { __typename?: 'Company' }
    & CompanyListItemFragment
  ) }
);

export type HeaderMeFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'name' | 'surname' | 'profilePic' | 'settings'>
);

export type TimeTrackingTaskFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'id' | 'parentId' | 'name' | 'deadline'>
  & { prio: (
    { __typename?: 'Prio' }
    & Pick<Prio, 'id' | 'description' | 'color' | 'sort' | 'icon'>
  ) }
);

export type TimeTrackingSuperTaskFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'id' | 'name' | 'deadline'>
  & { prio: (
    { __typename?: 'Prio' }
    & TimeTrackingPrioFragment
  ) }
);

export type TimeTrackingCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'imageUrl' | 'short'>
);

export type TimeTrackingWorkloadFragment = (
  { __typename?: 'Workload' }
  & Pick<Workload, 'id' | 'start' | 'end'>
);

export type TimeTrackingPrioFragment = (
  { __typename?: 'Prio' }
  & Pick<Prio, 'id' | 'description' | 'color' | 'sort' | 'icon'>
);

export type TimeTrackingItemFragment = (
  { __typename?: 'TimeTrackingInfo' }
  & { task: (
    { __typename?: 'Task' }
    & TimeTrackingTaskFragment
  ), company: (
    { __typename?: 'Company' }
    & TimeTrackingCompanyFragment
  ), superTask?: Maybe<(
    { __typename?: 'Task' }
    & TimeTrackingSuperTaskFragment
  )>, workload?: Maybe<(
    { __typename?: 'Workload' }
    & TimeTrackingWorkloadFragment
  )>, shownPrio: (
    { __typename?: 'Prio' }
    & TimeTrackingPrioFragment
  ) }
);

export type GetHeaderDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHeaderDataQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & HeaderMeFragment
  ), companies: Array<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'hasActivity' | 'hasUserRelatedActivity'>
  )>, timeTracking: (
    { __typename?: 'TimeTrackingResponse' }
    & { quickSelection: Array<(
      { __typename?: 'TimeTrackingInfo' }
      & TimeTrackingItemFragment
    )>, urgent: Array<(
      { __typename?: 'TimeTrackingInfo' }
      & TimeTrackingItemFragment
    )> }
  ) }
);

export type HeaderTimeTrackingUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type HeaderTimeTrackingUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { timeTrackingUpdated: (
    { __typename?: 'TimeTrackingResponse' }
    & { quickSelection: Array<(
      { __typename?: 'TimeTrackingInfo' }
      & TimeTrackingItemFragment
    )>, urgent: Array<(
      { __typename?: 'TimeTrackingInfo' }
      & TimeTrackingItemFragment
    )> }
  ) }
);

export type GetInvoiceEditorPdfInfosQueryVariables = Exact<{
  invoiceNumber?: Maybe<Scalars['Int']>;
  taskIds: Array<Scalars['Int']>;
  rateIds: Array<Scalars['Int']>;
  companyId: Scalars['Int'];
  contactPersonId?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  preText?: Maybe<Scalars['String']>;
  creationDate?: Maybe<Scalars['Date']>;
  deliveryDate?: Maybe<Scalars['Date']>;
}>;


export type GetInvoiceEditorPdfInfosQuery = (
  { __typename?: 'Query' }
  & { invoiceEditor: (
    { __typename?: 'InvoiceEditorResponse' }
    & Pick<InvoiceEditorResponse, 'nextInvoiceNumber'>
    & { pdfPreview: (
      { __typename?: 'Base64File' }
      & Pick<Base64File, 'id' | 'filename' | 'base64'>
    ) }
  ) }
);

export type CreateInvoiceMutationVariables = Exact<{
  invoiceNumber?: Maybe<Scalars['Int']>;
  taskIds: Array<Scalars['Int']>;
  rateIds: Array<Scalars['Int']>;
  companyId: Scalars['Int'];
  contactPersonId?: Maybe<Scalars['Int']>;
  creationDate: Scalars['Date'];
  deliveryDate: Scalars['Date'];
  preText: Scalars['String'];
  afterText: Scalars['String'];
  emailText: Scalars['String'];
  preTextTemplate: Scalars['Int'];
  afterTextTemplate: Scalars['Int'];
  emailTextTemplate: Scalars['Int'];
  customContactEmail?: Maybe<Scalars['String']>;
  sendMail: Scalars['Boolean'];
  attachPDF: Scalars['Boolean'];
}>;


export type CreateInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { createInvoice: (
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id' | 'number'>
    & { tasks: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'name'>
    )>, pdf: (
      { __typename?: 'Base64File' }
      & Pick<Base64File, 'id' | 'filename' | 'base64'>
    ) }
  ) }
);

export type InvoiceListItemFragment = (
  { __typename?: 'Invoice' }
  & Pick<Invoice, 'id' | 'number' | 'creationDate' | 'deliveryDate' | 'state'>
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'name'>
  )>, price: (
    { __typename?: 'Price' }
    & Pick<Price, 'original' | 'adjusted'>
  ) }
);

export type GetInvoiceListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetInvoiceListQuery = (
  { __typename?: 'Query' }
  & { invoices: Array<(
    { __typename?: 'Invoice' }
    & InvoiceListItemFragment
  )> }
);

export type UpdateInvoiceStateMutationVariables = Exact<{
  id: Scalars['Int'];
  state: InvoiceState;
}>;


export type UpdateInvoiceStateMutation = (
  { __typename?: 'Mutation' }
  & { updateInvoiceState: (
    { __typename?: 'Invoice' }
    & InvoiceListItemFragment
  ) }
);

export type InvoiceUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type InvoiceUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { invoiceUpdated: (
    { __typename?: 'Invoice' }
    & InvoiceListItemFragment
  ) }
);

export type InvoiceAddedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type InvoiceAddedSubscription = (
  { __typename?: 'Subscription' }
  & { invoiceAdded: (
    { __typename?: 'Invoice' }
    & InvoiceListItemFragment
  ) }
);

export type GetInvoicePdfQueryVariables = Exact<{
  invoiceNumber: Scalars['Int'];
}>;


export type GetInvoicePdfQuery = (
  { __typename?: 'Query' }
  & { invoice: (
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id' | 'number' | 'state' | 'creationDate' | 'deliveryDate' | 'customContactEmail' | 'emailText' | 'emailTextTemplate' | 'sendMail' | 'mailAttachPDF'>
    & { contactPerson?: Maybe<(
      { __typename?: 'ContactPerson' }
      & Pick<ContactPerson, 'id' | 'name' | 'email'>
    )>, pdf: (
      { __typename?: 'Base64File' }
      & Pick<Base64File, 'id' | 'filename' | 'base64'>
    ) }
  ) }
);

export type AddInvoiceToSendQueueMutationVariables = Exact<{
  id: Scalars['Int'];
  attachPDF: Scalars['Boolean'];
}>;


export type AddInvoiceToSendQueueMutation = (
  { __typename?: 'Mutation' }
  & { addInvoiceToSendQueue: (
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id' | 'state'>
  ) }
);

export type UpdateInvoiceMailSettingsMutationVariables = Exact<{
  invoiceId: Scalars['Int'];
  attachPDF?: Maybe<Scalars['Boolean']>;
  sendMail?: Maybe<Scalars['Boolean']>;
  emailText?: Maybe<Scalars['String']>;
  emailTextTemplate?: Maybe<Scalars['Int']>;
  customContactEmail?: Maybe<Scalars['String']>;
}>;


export type UpdateInvoiceMailSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateInvoiceMailSettings: (
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id' | 'sendMail' | 'mailAttachPDF' | 'emailText' | 'emailTextTemplate' | 'customContactEmail'>
  ) }
);

export type InvoiceViewerInvoiceUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type InvoiceViewerInvoiceUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { invoiceUpdated: (
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id' | 'sendMail' | 'mailAttachPDF' | 'emailText' | 'emailTextTemplate' | 'customContactEmail'>
  ) }
);

export type OfferEditorRateFragment = (
  { __typename?: 'Rate' }
  & Pick<Rate, 'id' | 'at' | 'description' | 'offerId'>
);

export type OfferEditorContactPersonFragment = (
  { __typename?: 'ContactPerson' }
  & Pick<ContactPerson, 'id' | 'name' | 'surname' | 'title' | 'email' | 'companyId'>
);

export type OfferEditorDiscountFragment = (
  { __typename?: 'Discount' }
  & Pick<Discount, 'id' | 'value' | 'unit'>
);

export type OfferEditorOfferFragment = (
  { __typename?: 'Offer' }
  & Pick<Offer, 'id' | 'companyId' | 'name' | 'validity' | 'userDefinedTextId' | 'status' | 'asAPackage' | 'preText' | 'preTextTemplate' | 'afterText' | 'afterTextTemplate' | 'sendMail' | 'mailAttachPDF' | 'emailText' | 'emailTextTemplate' | 'customContactEmail' | 'sent' | 'pin' | 'link' | 'addedToSendQueue' | 'aggregatedMinPrice' | 'aggregatedFullPrice' | 'aggregatedPrice' | 'aggregatedPriceWithoutDiscount' | 'costEstimate'>
  & { rates: Array<(
    { __typename?: 'Rate' }
    & OfferEditorRateFragment
  )>, contactPerson?: Maybe<(
    { __typename?: 'ContactPerson' }
    & OfferEditorContactPersonFragment
  )>, discount: Array<(
    { __typename?: 'Discount' }
    & OfferEditorDiscountFragment
  )>, customer?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'short'>
  )>, views: Array<(
    { __typename?: 'OfferViewAction' }
    & Pick<OfferViewAction, 'timestamp' | 'type'>
  )> }
);

export type OfferEditorPositionFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'id' | 'isTopTask' | 'index' | 'parentId' | 'name' | 'path' | 'companyId' | 'description' | 'deadline' | 'timeBudget' | 'taskCategory' | 'timeBudgetViewUnit' | 'hourlyWorkRate' | 'priceCalcMethod' | 'flatRatePrice' | 'preselected' | 'optional' | 'selected'>
  & { discount: Array<(
    { __typename?: 'Discount' }
    & OfferEditorDiscountFragment
  )>, price: (
    { __typename?: 'Price' }
    & Pick<Price, 'original' | 'adjusted'>
  ) }
);

export type GetOfferEditorOfferQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetOfferEditorOfferQuery = (
  { __typename?: 'Query' }
  & { offer: (
    { __typename?: 'Offer' }
    & OfferEditorOfferFragment
  ), offerPositions: Array<(
    { __typename?: 'Task' }
    & OfferEditorPositionFragment
  )> }
);

export type MoveOfferEditorPositionMutationVariables = Exact<{
  id: Scalars['Int'];
  newIndex: Scalars['Int'];
  newParentId?: Maybe<Scalars['Int']>;
}>;


export type MoveOfferEditorPositionMutation = (
  { __typename?: 'Mutation' }
  & { moveTask: Array<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'parentId' | 'index'>
  )> }
);

export type AddOfferEditorPositionMutationVariables = Exact<{
  offerId: Scalars['Int'];
  name: Scalars['String'];
  companyId: Scalars['Int'];
  parentId?: Maybe<Scalars['Int']>;
}>;


export type AddOfferEditorPositionMutation = (
  { __typename?: 'Mutation' }
  & { addTask: (
    { __typename?: 'AddTaskOutput' }
    & { addedTask: (
      { __typename?: 'Task' }
      & OfferEditorPositionFragment
    ) }
  ) }
);

export type DuplicateOfferEditorOfferMutationVariables = Exact<{
  offerId: Scalars['Int'];
}>;


export type DuplicateOfferEditorOfferMutation = (
  { __typename?: 'Mutation' }
  & { duplicateOffer: (
    { __typename?: 'Offer' }
    & OfferEditorOfferFragment
  ) }
);

export type UpdateOfferEditorOfferMutationVariables = Exact<{
  id: Scalars['Int'];
  status?: Maybe<OfferStatus>;
  name?: Maybe<Scalars['String']>;
  preText?: Maybe<Scalars['String']>;
  preTextTemplate?: Maybe<Scalars['Int']>;
  afterText?: Maybe<Scalars['String']>;
  afterTextTemplate?: Maybe<Scalars['Int']>;
  sendMail?: Maybe<Scalars['Boolean']>;
  mailAttachPDF?: Maybe<Scalars['Boolean']>;
  emailText?: Maybe<Scalars['String']>;
  emailTextTemplate?: Maybe<Scalars['Int']>;
  asAPackage?: Maybe<Scalars['Boolean']>;
  companyId?: Maybe<Scalars['Int']>;
  contactPersonId?: Maybe<Scalars['Int']>;
  customContactEmail?: Maybe<Scalars['String']>;
  validity?: Maybe<Scalars['Date']>;
  costEstimate?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateOfferEditorOfferMutation = (
  { __typename?: 'Mutation' }
  & { updateOffer: (
    { __typename?: 'Offer' }
    & OfferEditorOfferFragment
  ) }
);

export type OfferEditorUpdateTaskMutationVariables = Exact<{
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  prioId?: Maybe<Scalars['Int']>;
  deadline?: Maybe<Scalars['Int']>;
  timeBudget?: Maybe<Scalars['Int']>;
  taskCategory?: Maybe<Scalars['Int']>;
  priceCalcMethod?: Maybe<PriceCalcMethodType>;
  customHourlyWorkRate?: Maybe<Scalars['Float']>;
  timeBudgetViewUnit?: Maybe<Scalars['String']>;
  flatRatePrice?: Maybe<Scalars['Float']>;
}>;


export type OfferEditorUpdateTaskMutation = (
  { __typename?: 'Mutation' }
  & { updateTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'name' | 'description' | 'deadline' | 'timeBudget' | 'priceCalcMethod' | 'timeBudgetViewUnit' | 'hourlyWorkRate' | 'taskCategory' | 'flatRatePrice'>
    & { prio: (
      { __typename?: 'Prio' }
      & Pick<Prio, 'id'>
    ), price: (
      { __typename?: 'Price' }
      & Pick<Price, 'original' | 'adjusted'>
    ) }
  )> }
);

export type OfferEditorAddOfferDiscountMutationVariables = Exact<{
  value: Scalars['Float'];
  unit: Charge;
  productID: Scalars['Int'];
}>;


export type OfferEditorAddOfferDiscountMutation = (
  { __typename?: 'Mutation' }
  & { addOfferDiscount: (
    { __typename?: 'Offer' }
    & Pick<Offer, 'id'>
    & { discount: Array<(
      { __typename?: 'Discount' }
      & Pick<Discount, 'id' | 'value' | 'unit'>
    )> }
  ) }
);

export type OfferEditorUpdateOfferDiscountMutationVariables = Exact<{
  id: Scalars['Int'];
  value: Scalars['Float'];
  unit: Charge;
  productID: Scalars['Int'];
}>;


export type OfferEditorUpdateOfferDiscountMutation = (
  { __typename?: 'Mutation' }
  & { updateOfferDiscount: (
    { __typename?: 'Offer' }
    & Pick<Offer, 'id'>
    & { discount: Array<(
      { __typename?: 'Discount' }
      & Pick<Discount, 'id' | 'value' | 'unit'>
    )> }
  ) }
);

export type OfferEditorDeleteOfferDiscountMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type OfferEditorDeleteOfferDiscountMutation = (
  { __typename?: 'Mutation' }
  & { deleteOfferDiscount: (
    { __typename?: 'Offer' }
    & Pick<Offer, 'id'>
    & { discount: Array<(
      { __typename?: 'Discount' }
      & Pick<Discount, 'id' | 'value' | 'unit'>
    )> }
  ) }
);

export type OfferEditorOfferUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OfferEditorOfferUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { offerUpdated: (
    { __typename?: 'Offer' }
    & OfferEditorOfferFragment
  ) }
);

export type OfferEditorPositionAddedSubscriptionVariables = Exact<{
  offerId: Scalars['Int'];
}>;


export type OfferEditorPositionAddedSubscription = (
  { __typename?: 'Subscription' }
  & { taskAdded: (
    { __typename?: 'AddTaskOutput' }
    & { addedTask: (
      { __typename?: 'Task' }
      & OfferEditorPositionFragment
    ) }
  ) }
);

export type OfferEditorPositionUpdatedSubscriptionVariables = Exact<{
  offerId?: Maybe<Scalars['Int']>;
}>;


export type OfferEditorPositionUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { tasksUpdated: Array<(
    { __typename?: 'Task' }
    & OfferEditorPositionFragment
  )> }
);

export type OfferEditorPositionDeletedSubscriptionVariables = Exact<{
  offerId?: Maybe<Scalars['Int']>;
}>;


export type OfferEditorPositionDeletedSubscription = (
  { __typename?: 'Subscription' }
  & Pick<Subscription, 'tasksDeleted'>
);

export type OfferEditorPdfUpdatedSubscriptionVariables = Exact<{
  id: Scalars['Int'];
  interactive: Scalars['Boolean'];
}>;


export type OfferEditorPdfUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & Pick<Subscription, 'offerPDFUpdated'>
);

export type OfferListOfferRateFragment = (
  { __typename?: 'Rate' }
  & Pick<Rate, 'id' | 'at' | 'description' | 'offerId'>
);

export type OfferListOfferContactPersonFragment = (
  { __typename?: 'ContactPerson' }
  & Pick<ContactPerson, 'id' | 'name' | 'surname' | 'title' | 'email' | 'companyId'>
);

export type OfferListOfferDiscountFragment = (
  { __typename?: 'Discount' }
  & Pick<Discount, 'id' | 'value' | 'unit'>
);

export type OfferListOfferFragment = (
  { __typename?: 'Offer' }
  & Pick<Offer, 'id' | 'companyId' | 'name' | 'validity' | 'status' | 'asAPackage' | 'sent' | 'addedToSendQueue' | 'aggregatedPrice' | 'costEstimate'>
  & { customer?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
  )>, views: Array<(
    { __typename?: 'OfferViewAction' }
    & Pick<OfferViewAction, 'timestamp' | 'type'>
  )> }
);

export type GetNextOfferIdQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNextOfferIdQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'nextOfferId'>
);

export type GetOfferlistQueryVariables = Exact<{
  loadOffers: Scalars['Boolean'];
  loadCostEstimates: Scalars['Boolean'];
}>;


export type GetOfferlistQuery = (
  { __typename?: 'Query' }
  & { offers: Array<(
    { __typename?: 'Offer' }
    & OfferListOfferFragment
  )> }
);

export type OfferListOfferUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OfferListOfferUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { offerUpdated: (
    { __typename?: 'Offer' }
    & OfferListOfferFragment
  ) }
);

export type OfferListOfferAddedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OfferListOfferAddedSubscription = (
  { __typename?: 'Subscription' }
  & { offerAdded: (
    { __typename?: 'Offer' }
    & OfferListOfferFragment
  ) }
);

export type OfferListOfferDeletedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OfferListOfferDeletedSubscription = (
  { __typename?: 'Subscription' }
  & { offerDeleted: (
    { __typename?: 'OfferDeletedResponse' }
    & Pick<OfferDeletedResponse, 'id'>
  ) }
);

export type OfferListAddOfferMutationVariables = Exact<{
  name: Scalars['String'];
  isCostEstimate: Scalars['Boolean'];
}>;


export type OfferListAddOfferMutation = (
  { __typename?: 'Mutation' }
  & { addOffer: (
    { __typename?: 'Offer' }
    & OfferListOfferFragment
  ) }
);

export type OfferWebviewOfferRateFragment = (
  { __typename?: 'Rate' }
  & Pick<Rate, 'id' | 'at' | 'description' | 'offerId'>
);

export type OfferWebviewContactPersonFragment = (
  { __typename?: 'ContactPerson' }
  & Pick<ContactPerson, 'id' | 'name' | 'surname' | 'title'>
);

export type OfferWebviewDiscountFragment = (
  { __typename?: 'Discount' }
  & Pick<Discount, 'id' | 'value' | 'unit'>
);

export type OfferWebviewOfferFragment = (
  { __typename?: 'Offer' }
  & Pick<Offer, 'id' | 'name' | 'validity' | 'status' | 'asAPackage' | 'preText' | 'afterText' | 'emailText' | 'aggregatedMinPrice' | 'aggregatedFullPrice' | 'aggregatedPrice' | 'aggregatedPriceWithoutDiscount' | 'pdf' | 'costEstimate'>
  & { rates: Array<(
    { __typename?: 'Rate' }
    & OfferWebviewOfferRateFragment
  )>, contactPerson?: Maybe<(
    { __typename?: 'ContactPerson' }
    & OfferWebviewContactPersonFragment
  )>, discount: Array<(
    { __typename?: 'Discount' }
    & OfferWebviewDiscountFragment
  )>, customer?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'short' | 'legalName'>
  )> }
);

export type OfferWebviewOfferPositionFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'id' | 'isTopTask' | 'index' | 'parentId' | 'name' | 'path' | 'companyId' | 'description' | 'deadline' | 'timeBudget' | 'taskCategory' | 'timeBudgetViewUnit' | 'hourlyWorkRate' | 'priceCalcMethod' | 'flatRatePrice' | 'preselected' | 'optional' | 'selected'>
  & { discount: Array<(
    { __typename?: 'Discount' }
    & OfferWebviewDiscountFragment
  )>, price: (
    { __typename?: 'Price' }
    & Pick<Price, 'original' | 'adjusted'>
  ) }
);

export type GetOfferByLinkQueryVariables = Exact<{
  link: Scalars['String'];
  companyShort: Scalars['String'];
}>;


export type GetOfferByLinkQuery = (
  { __typename?: 'Query' }
  & { offerByLink: (
    { __typename?: 'OfferInfoByLink' }
    & { offer: (
      { __typename?: 'Offer' }
      & OfferWebviewOfferFragment
    ), positions: Array<(
      { __typename?: 'Task' }
      & OfferWebviewOfferPositionFragment
    )> }
  ) }
);

export type OfferWebviewDownloadOfferMutationVariables = Exact<{
  link: Scalars['String'];
  companyShort: Scalars['String'];
}>;


export type OfferWebviewDownloadOfferMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'downloadOffer'>
);

export type OfferWebviewAnswerOfferMutationVariables = Exact<{
  link: Scalars['String'];
  companyShort: Scalars['String'];
  status: Scalars['Boolean'];
}>;


export type OfferWebviewAnswerOfferMutation = (
  { __typename?: 'Mutation' }
  & { answerOffer: (
    { __typename?: 'Offer' }
    & OfferWebviewOfferFragment
  ) }
);

export type OfferWebviewUpdateOfferPositionSelectionMutationVariables = Exact<{
  taskId: Scalars['Int'];
  offerId: Scalars['Int'];
  selected?: Maybe<Scalars['Boolean']>;
}>;


export type OfferWebviewUpdateOfferPositionSelectionMutation = (
  { __typename?: 'Mutation' }
  & { updateOfferPosition: (
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'selected'>
  ) }
);

export type OfferWebviewOfferUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OfferWebviewOfferUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { offerUpdated: (
    { __typename?: 'Offer' }
    & OfferWebviewOfferFragment
  ) }
);

export type OfferWebviewTasksUpdatedSubscriptionVariables = Exact<{
  offerId?: Maybe<Scalars['Int']>;
}>;


export type OfferWebviewTasksUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { tasksUpdated: Array<(
    { __typename?: 'Task' }
    & OfferWebviewOfferPositionFragment
  )> }
);

export type RateEditorRateFragment = (
  { __typename?: 'Rate' }
  & Pick<Rate, 'id' | 'at' | 'description' | 'offerId'>
);

export type AddOfferRateMutationVariables = Exact<{
  offerId: Scalars['Int'];
  at: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
}>;


export type AddOfferRateMutation = (
  { __typename?: 'Mutation' }
  & { addOfferRate: (
    { __typename?: 'Offer' }
    & Pick<Offer, 'id' | 'name'>
    & { rates: Array<(
      { __typename?: 'Rate' }
      & RateEditorRateFragment
    )> }
  ) }
);

export type UpdateOfferRateMutationVariables = Exact<{
  id: Scalars['Int'];
  at?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
}>;


export type UpdateOfferRateMutation = (
  { __typename?: 'Mutation' }
  & { updateOfferRate: (
    { __typename?: 'Offer' }
    & Pick<Offer, 'id'>
    & { rates: Array<(
      { __typename?: 'Rate' }
      & RateEditorRateFragment
    )> }
  ) }
);

export type DeleteOfferRateMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteOfferRateMutation = (
  { __typename?: 'Mutation' }
  & { deleteOfferRate: (
    { __typename?: 'Offer' }
    & Pick<Offer, 'id' | 'name'>
    & { rates: Array<(
      { __typename?: 'Rate' }
      & RateEditorRateFragment
    )> }
  ) }
);

export type DistibuteOfferRatesEqualMutationVariables = Exact<{
  offerId: Scalars['Int'];
}>;


export type DistibuteOfferRatesEqualMutation = (
  { __typename?: 'Mutation' }
  & { distributeOfferRatesEqual: (
    { __typename?: 'Offer' }
    & Pick<Offer, 'id' | 'name'>
    & { rates: Array<(
      { __typename?: 'Rate' }
      & RateEditorRateFragment
    )> }
  ) }
);

export type RecurringInvoiceEditorDiscountFragment = (
  { __typename?: 'Discount' }
  & Pick<Discount, 'id' | 'value' | 'unit'>
);

export type RecurringInvoiceEditorPositionFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'id' | 'isTopTask' | 'index' | 'parentId' | 'name' | 'path' | 'companyId' | 'description' | 'deadline' | 'timeBudget' | 'taskCategory' | 'timeBudgetViewUnit' | 'hourlyWorkRate' | 'priceCalcMethod' | 'flatRatePrice' | 'preselected' | 'optional' | 'selected'>
  & { discount: Array<(
    { __typename?: 'Discount' }
    & RecurringInvoiceEditorDiscountFragment
  )>, price: (
    { __typename?: 'Price' }
    & Pick<Price, 'original' | 'adjusted'>
  ) }
);

export type RecurrentOfferEditorInvoiceFragment = (
  { __typename?: 'Invoice' }
  & Pick<Invoice, 'id' | 'name' | 'preText' | 'preTextTemplate' | 'afterText' | 'afterTextTemplate' | 'emailText' | 'emailTextTemplate' | 'sendMail' | 'mailAttachPDF' | 'customContactEmail'>
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
  )>, contactPerson?: Maybe<(
    { __typename?: 'ContactPerson' }
    & ContactPersonFragmentFragment
  )>, tasks: Array<(
    { __typename?: 'Task' }
    & RecurringInvoiceEditorPositionFragment
  )>, pdf: (
    { __typename?: 'Base64File' }
    & Pick<Base64File, 'id' | 'base64'>
  ), generatedChildren: Array<(
    { __typename?: 'GeneratedInvoice' }
    & Pick<GeneratedInvoice, 'id' | 'number'>
  )> }
);

export type RecurrentOfferEditorRepetitionRuleFragment = (
  { __typename?: 'RepetitionRule' }
  & Pick<RepetitionRule, 'id' | 'start' | 'end' | 'finalExecution' | 'interval' | 'intervalType' | 'status' | 'executionPreview'>
  & { executionHistory: Array<(
    { __typename?: 'ExecutionHistoryItem' }
    & Pick<ExecutionHistoryItem, 'id' | 'date' | 'email'>
  )> }
);

export type RecurringInvoiceEditorRecurringInvoiceFragment = (
  { __typename?: 'RecurringInvoice' }
  & Pick<RecurringInvoice, 'id'>
  & { invoice: (
    { __typename?: 'Invoice' }
    & RecurrentOfferEditorInvoiceFragment
  ), repetitionRule: (
    { __typename?: 'RepetitionRule' }
    & RecurrentOfferEditorRepetitionRuleFragment
  ) }
);

export type GetRecurrentInvoiceEditorDataQueryVariables = Exact<{
  recurringInvoiceId: Scalars['Int'];
}>;


export type GetRecurrentInvoiceEditorDataQuery = (
  { __typename?: 'Query' }
  & { recurringInvoice: (
    { __typename?: 'RecurringInvoice' }
    & RecurringInvoiceEditorRecurringInvoiceFragment
  ) }
);

export type DeleteRecurringInvoiceMutationVariables = Exact<{
  recurringInvoiceId: Scalars['Int'];
}>;


export type DeleteRecurringInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteRecurringInvoice'>
);

export type UpdateRecurringInvoiceInvoiceMutationVariables = Exact<{
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  contactPersonId?: Maybe<Scalars['Int']>;
  customContactEmail?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['Int']>;
  preText?: Maybe<Scalars['String']>;
  preTextTemplate?: Maybe<Scalars['Int']>;
  afterText?: Maybe<Scalars['String']>;
  afterTextTemplate?: Maybe<Scalars['Int']>;
  emailText?: Maybe<Scalars['String']>;
  emailTextTemplate?: Maybe<Scalars['Int']>;
  sendMail?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateRecurringInvoiceInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { updateInvoice: (
    { __typename?: 'Invoice' }
    & RecurrentOfferEditorInvoiceFragment
  ) }
);

export type AddRecurringInvoiceEditorPositionMutationVariables = Exact<{
  invoiceId: Scalars['Int'];
  name: Scalars['String'];
  companyId: Scalars['Int'];
  parentId?: Maybe<Scalars['Int']>;
}>;


export type AddRecurringInvoiceEditorPositionMutation = (
  { __typename?: 'Mutation' }
  & { addTask: (
    { __typename?: 'AddTaskOutput' }
    & { addedTask: (
      { __typename?: 'Task' }
      & RecurringInvoiceEditorPositionFragment
    ) }
  ) }
);

export type UpdateRecurringInvoiceEditorRepetitionRuleMutationVariables = Exact<{
  repetitionRuleId: Scalars['Int'];
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
  intervalType?: Maybe<IntervalType>;
  intervalFrequency?: Maybe<Scalars['Int']>;
  status?: Maybe<RepetitionRuleStatus>;
}>;


export type UpdateRecurringInvoiceEditorRepetitionRuleMutation = (
  { __typename?: 'Mutation' }
  & { updateRepetitionRule: (
    { __typename?: 'RepetitionRule' }
    & RecurrentOfferEditorRepetitionRuleFragment
  ) }
);

export type RecurringInvoiceEditorDeletedSubscriptionVariables = Exact<{
  recurringInvoiceIds: Array<Scalars['Int']>;
}>;


export type RecurringInvoiceEditorDeletedSubscription = (
  { __typename?: 'Subscription' }
  & Pick<Subscription, 'recurringInvoiceDeleted'>
);

export type RecurringInvoiceEditorInvoiceUpdatedSubscriptionVariables = Exact<{
  ids: Array<Scalars['Int']>;
}>;


export type RecurringInvoiceEditorInvoiceUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { invoiceUpdated: (
    { __typename?: 'Invoice' }
    & RecurrentOfferEditorInvoiceFragment
  ) }
);

export type RecurringInvoiceEditorRepetitionRuleUpdatedSubscriptionVariables = Exact<{
  ids: Array<Scalars['Int']>;
}>;


export type RecurringInvoiceEditorRepetitionRuleUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { repetitionRuleUpdated: (
    { __typename?: 'RepetitionRule' }
    & RecurrentOfferEditorRepetitionRuleFragment
  ) }
);

export type RecurringInvoiceListItemRepetitionRuleFragment = (
  { __typename?: 'RepetitionRule' }
  & Pick<RepetitionRule, 'id' | 'status' | 'start' | 'end' | 'interval' | 'intervalType' | 'executionPreview' | 'finalExecution'>
  & { executionHistory: Array<(
    { __typename?: 'ExecutionHistoryItem' }
    & Pick<ExecutionHistoryItem, 'id' | 'date' | 'email'>
  )> }
);

export type RecurringInvoiceListItemFragment = (
  { __typename?: 'RecurringInvoice' }
  & Pick<RecurringInvoice, 'id'>
  & { invoice: (
    { __typename?: 'Invoice' }
    & RecurringInvoiceListRecurringInvoiceInvoiceFragment
  ), repetitionRule: (
    { __typename?: 'RepetitionRule' }
    & RecurringInvoiceListItemRepetitionRuleFragment
  ) }
);

export type RecurringInvoiceListRecurringInvoiceInvoiceFragment = (
  { __typename?: 'Invoice' }
  & Pick<Invoice, 'id' | 'name' | 'creationDate' | 'deliveryDate' | 'companyId' | 'state'>
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
  )>, tasks: Array<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'name'>
  )>, price: (
    { __typename?: 'Price' }
    & Pick<Price, 'original' | 'adjusted'>
  ), generatedChildren: Array<(
    { __typename?: 'GeneratedInvoice' }
    & Pick<GeneratedInvoice, 'id' | 'number'>
  )> }
);

export type GetRecurringsInvoiceListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRecurringsInvoiceListQuery = (
  { __typename?: 'Query' }
  & { recurringInvoices: (
    { __typename?: 'RecurringInvoiceList' }
    & Pick<RecurringInvoiceList, 'nextRecurringInvoiceId'>
    & { recurringInvoices: Array<(
      { __typename?: 'RecurringInvoice' }
      & RecurringInvoiceListItemFragment
    )> }
  ) }
);

export type AddRecurringInvoiceListItemMutationVariables = Exact<{ [key: string]: never; }>;


export type AddRecurringInvoiceListItemMutation = (
  { __typename?: 'Mutation' }
  & { addRecurringInvoice: (
    { __typename?: 'RecurringInvoice' }
    & RecurringInvoiceListItemFragment
  ) }
);

export type UpdateRepetitionRuleMutationVariables = Exact<{
  repetitionRuleId: Scalars['Int'];
  status?: Maybe<RepetitionRuleStatus>;
}>;


export type UpdateRepetitionRuleMutation = (
  { __typename?: 'Mutation' }
  & { updateRepetitionRule: (
    { __typename?: 'RepetitionRule' }
    & RecurringInvoiceListItemRepetitionRuleFragment
  ) }
);

export type RecurringInvoiceAddedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type RecurringInvoiceAddedSubscription = (
  { __typename?: 'Subscription' }
  & { recurringInvoiceAdded: (
    { __typename?: 'RecurringInvoice' }
    & RecurringInvoiceListItemFragment
  ) }
);

export type RecurringInvoiceDeletedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type RecurringInvoiceDeletedSubscription = (
  { __typename?: 'Subscription' }
  & Pick<Subscription, 'recurringInvoiceDeleted'>
);

export type RecurringInvoiceListInvoiceUpdatedSubscriptionVariables = Exact<{
  ids: Array<Scalars['Int']>;
}>;


export type RecurringInvoiceListInvoiceUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { invoiceUpdated: (
    { __typename?: 'Invoice' }
    & RecurringInvoiceListRecurringInvoiceInvoiceFragment
  ) }
);

export type RecurringInvoiceListRepetitionRuleUpdatedSubscriptionVariables = Exact<{
  ids: Array<Scalars['Int']>;
}>;


export type RecurringInvoiceListRepetitionRuleUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { repetitionRuleUpdated: (
    { __typename?: 'RepetitionRule' }
    & RecurringInvoiceListItemRepetitionRuleFragment
  ) }
);

export type SidebarAddTimeTrackingMutationVariables = Exact<{
  taskId: Scalars['Int'];
  start: Scalars['Date'];
  end: Scalars['Date'];
}>;


export type SidebarAddTimeTrackingMutation = (
  { __typename?: 'Mutation' }
  & { addTimeTracking: (
    { __typename?: 'TimeTrackingChangeResponse' }
    & { workloads: Array<(
      { __typename?: 'Workload' }
      & Pick<Workload, 'id' | 'start' | 'end' | 'taskId'>
    )>, tasks: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'workedTime' | 'effectiveHourlyWorkRate'>
      & { price: (
        { __typename?: 'Price' }
        & Pick<Price, 'original' | 'adjusted'>
      ), currentWork: Array<(
        { __typename?: 'Workload' }
        & Pick<Workload, 'id' | 'start' | 'end' | 'userId'>
      )> }
    )> }
  ) }
);

export type UserSelectUserFragmentFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'name' | 'surname' | 'profilePic' | 'settings' | 'role'>
);

export type GetUserSelectUsersQueryVariables = Exact<{
  companyId?: Maybe<Scalars['Int']>;
}>;


export type GetUserSelectUsersQuery = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'User' }
    & UserSelectUserFragmentFragment
  )> }
);

export type UserSelectTasksUpdatedSubscriptionVariables = Exact<{
  companyId?: Maybe<Scalars['Int']>;
}>;


export type UserSelectTasksUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { tasksUpdated: Array<(
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
  )> }
);

export type UserSelectTaskAddedSubscriptionVariables = Exact<{
  companyId?: Maybe<Scalars['Int']>;
}>;


export type UserSelectTaskAddedSubscription = (
  { __typename?: 'Subscription' }
  & { taskAdded: (
    { __typename?: 'AddTaskOutput' }
    & { addedTask: (
      { __typename?: 'Task' }
      & Pick<Task, 'id'>
    ) }
  ) }
);

export type UserSelectTasksDeletedSubscriptionVariables = Exact<{
  companyId?: Maybe<Scalars['Int']>;
}>;


export type UserSelectTasksDeletedSubscription = (
  { __typename?: 'Subscription' }
  & Pick<Subscription, 'tasksDeleted'>
);

export type TaskListTaskUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'name' | 'surname' | 'profilePic' | 'settings'>
);

export type TaskListTaskChildrenWorkedTimeCurrentWorkFragment = (
  { __typename?: 'Workload' }
  & Pick<Workload, 'id' | 'start' | 'end' | 'userId'>
);

export type TaskListTaskChildrenWorkedTimeFragment = (
  { __typename?: 'ChildrenWorkedTime' }
  & Pick<ChildrenWorkedTime, 'completed' | 'notCompleted'>
  & { currentWork: Array<(
    { __typename?: 'Workload' }
    & TaskListTaskChildrenWorkedTimeCurrentWorkFragment
  )> }
);

export type TaskListTaskDiscountFragment = (
  { __typename?: 'Discount' }
  & Pick<Discount, 'id' | 'value' | 'unit'>
);

export type TasklistTaskPrioFragment = (
  { __typename?: 'Prio' }
  & Pick<Prio, 'id' | 'sort' | 'color'>
);

export type TaskListTaskFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'id' | 'companyId' | 'offerId' | 'index' | 'path' | 'isTopTask' | 'parentId' | 'name' | 'description' | 'deadline' | 'timeBudget' | 'completed' | 'taskCategory' | 'workedTime' | 'timeBudgetViewUnit'>
  & { offer?: Maybe<(
    { __typename?: 'Offer' }
    & Pick<Offer, 'id' | 'name' | 'status'>
  )>, prio: (
    { __typename?: 'Prio' }
    & TasklistTaskPrioFragment
  ), shownPrio: (
    { __typename?: 'Prio' }
    & TasklistTaskPrioFragment
  ), assignedUsers: Array<(
    { __typename?: 'User' }
    & TaskListTaskUserFragment
  )>, currentTrackingUsers: Array<(
    { __typename?: 'User' }
    & TaskListTaskUserFragment
  )>, finishedWork: Array<(
    { __typename?: 'Workload' }
    & Pick<Workload, 'id' | 'start' | 'end' | 'userId'>
  )>, childrenWorkedTime: (
    { __typename?: 'ChildrenWorkedTime' }
    & TaskListTaskChildrenWorkedTimeFragment
  ), discount: Array<(
    { __typename?: 'Discount' }
    & TaskListTaskDiscountFragment
  )>, currentWork: Array<(
    { __typename?: 'Workload' }
    & Pick<Workload, 'id' | 'start' | 'end' | 'userId'>
  )> }
);

export type GetTasklistQueryVariables = Exact<{
  companyId: Scalars['Int'];
  showNotAssigned?: Maybe<Scalars['Boolean']>;
  showCompleted?: Maybe<Scalars['Boolean']>;
  showOfferPending?: Maybe<Scalars['Boolean']>;
  uncollapsedTaskIds: Array<Scalars['Int']>;
}>;


export type GetTasklistQuery = (
  { __typename?: 'Query' }
  & { tasks: (
    { __typename?: 'TasksResponse' }
    & Pick<TasksResponse, 'uncollapsableTaskIds'>
    & { tasks: Array<(
      { __typename?: 'Task' }
      & TaskListTaskFragment
    )> }
  ) }
);

export type TasklistMoveTaskMutationVariables = Exact<{
  id: Scalars['Int'];
  newIndex: Scalars['Int'];
  newParentId?: Maybe<Scalars['Int']>;
}>;


export type TasklistMoveTaskMutation = (
  { __typename?: 'Mutation' }
  & { moveTask: Array<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'parentId' | 'index'>
  )> }
);

export type TasklistTaskUpdatedSubscriptionVariables = Exact<{
  companyId?: Maybe<Scalars['Int']>;
}>;


export type TasklistTaskUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { tasksUpdated: Array<(
    { __typename?: 'Task' }
    & TaskListTaskFragment
  )> }
);

export type TasklistTaskAddedSubscriptionVariables = Exact<{
  companyId?: Maybe<Scalars['Int']>;
}>;


export type TasklistTaskAddedSubscription = (
  { __typename?: 'Subscription' }
  & { taskAdded: (
    { __typename?: 'AddTaskOutput' }
    & { addedTask: (
      { __typename?: 'Task' }
      & TaskListTaskFragment
    ), affectedTasks: Array<(
      { __typename?: 'Task' }
      & TaskListTaskFragment
    )> }
  ) }
);

export type TasklistTaskDeletedSubscriptionVariables = Exact<{
  companyId?: Maybe<Scalars['Int']>;
}>;


export type TasklistTaskDeletedSubscription = (
  { __typename?: 'Subscription' }
  & Pick<Subscription, 'tasksDeleted'>
);

export type TaskListSidebarDiscountRowAddDiscountMutationVariables = Exact<{
  value: Scalars['Float'];
  unit: Charge;
  productID: Scalars['Int'];
}>;


export type TaskListSidebarDiscountRowAddDiscountMutation = (
  { __typename?: 'Mutation' }
  & { addDiscount: (
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'name' | 'description' | 'deadline' | 'timeBudget' | 'priceCalcMethod' | 'timeBudgetViewUnit' | 'hourlyWorkRate' | 'effectiveHourlyWorkRate' | 'taskCategory'>
    & { price: (
      { __typename?: 'Price' }
      & Pick<Price, 'original' | 'adjusted'>
    ), prio: (
      { __typename?: 'Prio' }
      & Pick<Prio, 'id'>
    ), discount: Array<(
      { __typename?: 'Discount' }
      & Pick<Discount, 'id' | 'value' | 'unit'>
    )> }
  ) }
);

export type TaskListSidebarDiscountRowUpdateDiscountMutationVariables = Exact<{
  id: Scalars['Int'];
  value: Scalars['Float'];
  unit: Charge;
  productID: Scalars['Int'];
}>;


export type TaskListSidebarDiscountRowUpdateDiscountMutation = (
  { __typename?: 'Mutation' }
  & { updateDiscount: (
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'name' | 'description' | 'deadline' | 'timeBudget' | 'priceCalcMethod' | 'timeBudgetViewUnit' | 'hourlyWorkRate' | 'effectiveHourlyWorkRate' | 'taskCategory'>
    & { price: (
      { __typename?: 'Price' }
      & Pick<Price, 'original' | 'adjusted'>
    ), prio: (
      { __typename?: 'Prio' }
      & Pick<Prio, 'id'>
    ), discount: Array<(
      { __typename?: 'Discount' }
      & Pick<Discount, 'id' | 'value' | 'unit'>
    )> }
  ) }
);

export type TaskListSidebarDiscountRowDeleteDiscountMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type TaskListSidebarDiscountRowDeleteDiscountMutation = (
  { __typename?: 'Mutation' }
  & { deleteDiscount: (
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'name' | 'description' | 'deadline' | 'timeBudget' | 'priceCalcMethod' | 'timeBudgetViewUnit' | 'hourlyWorkRate' | 'effectiveHourlyWorkRate' | 'taskCategory'>
    & { price: (
      { __typename?: 'Price' }
      & Pick<Price, 'original' | 'adjusted'>
    ), prio: (
      { __typename?: 'Prio' }
      & Pick<Prio, 'id'>
    ), discount: Array<(
      { __typename?: 'Discount' }
      & Pick<Discount, 'id' | 'value' | 'unit'>
    )> }
  ) }
);

export type TaskSidebarTaskFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'id' | 'companyId' | 'offerId' | 'index' | 'path' | 'childrenTaskIds' | 'isTopTask' | 'isBillable' | 'deletionOption' | 'isMovable' | 'parentId' | 'name' | 'description' | 'deadline' | 'timeBudget' | 'completed' | 'archived' | 'taskCategory' | 'workedTime' | 'timeBudgetViewUnit' | 'hourlyWorkRate' | 'priceCalcMethod' | 'flatRatePrice' | 'effectiveHourlyWorkRate' | 'isNotCharged'>
  & { offer?: Maybe<(
    { __typename?: 'Offer' }
    & Pick<Offer, 'id' | 'name' | 'status' | 'asAPackage'>
  )>, prio: (
    { __typename?: 'Prio' }
    & Pick<Prio, 'id' | 'sort' | 'color'>
  ), shownPrio: (
    { __typename?: 'Prio' }
    & Pick<Prio, 'id' | 'sort' | 'color'>
  ), assignedUsers: Array<(
    { __typename?: 'User' }
    & TaskSidebarTaskUserFragment
  )>, currentTrackingUsers: Array<(
    { __typename?: 'User' }
    & TaskSidebarTaskUserFragment
  )>, finishedWork: Array<(
    { __typename?: 'Workload' }
    & Pick<Workload, 'id' | 'start' | 'end' | 'userId'>
  )>, childrenWorkedTime: (
    { __typename?: 'ChildrenWorkedTime' }
    & TaskSidebarTaskChildrenWorkedTimeFragment
  ), discount: Array<(
    { __typename?: 'Discount' }
    & TaskSidebarTaskDiscountFragment
  )>, currentWork: Array<(
    { __typename?: 'Workload' }
    & TaskSidebarTaskWorkloadFragment
  )>, price: (
    { __typename?: 'Price' }
    & Pick<Price, 'original' | 'adjusted'>
  ) }
);

export type TaskSidebarTaskDiscountFragment = (
  { __typename?: 'Discount' }
  & Pick<Discount, 'id' | 'value' | 'unit'>
);

export type TaskSidebarTaskWorkloadFragment = (
  { __typename?: 'Workload' }
  & Pick<Workload, 'id' | 'start' | 'end' | 'userId'>
);

export type TaskSidebarTaskUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'name' | 'surname' | 'profilePic' | 'settings'>
);

export type TaskSidebarTaskChildrenWorkedTimeFragment = (
  { __typename?: 'ChildrenWorkedTime' }
  & Pick<ChildrenWorkedTime, 'completed' | 'notCompleted'>
  & { currentWork: Array<(
    { __typename?: 'Workload' }
    & Pick<Workload, 'id' | 'start' | 'end' | 'userId'>
  )> }
);

export type GetTaskSidebarQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetTaskSidebarQuery = (
  { __typename?: 'Query' }
  & { task: (
    { __typename?: 'Task' }
    & TaskSidebarTaskFragment
  ) }
);

export type DeleteTaskMutationVariables = Exact<{
  taskId: Scalars['Int'];
}>;


export type DeleteTaskMutation = (
  { __typename?: 'Mutation' }
  & { deleteTask: (
    { __typename?: 'DeleteTaskResponse' }
    & { deletedTasks: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'id'>
    )>, blockedTasks: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'id'>
      & { currentTrackingUsers: Array<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name' | 'surname' | 'profilePic'>
      )> }
    )> }
  ) }
);

export type TasklistSidebarMoveTaskMutationVariables = Exact<{
  id: Scalars['Int'];
  newCompanyId: Scalars['Int'];
  newIndex: Scalars['Int'];
}>;


export type TasklistSidebarMoveTaskMutation = (
  { __typename?: 'Mutation' }
  & { moveTask: Array<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'parentId' | 'index' | 'companyId'>
  )> }
);

export type TaskSidebarUpdateTaskCompletedMutationVariables = Exact<{
  id: Scalars['Int'];
  completed: Scalars['Boolean'];
}>;


export type TaskSidebarUpdateTaskCompletedMutation = (
  { __typename?: 'Mutation' }
  & { updateTaskCompleted: (
    { __typename?: 'UpdateTaskCompletedResponse' }
    & { updatedTasks: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'completed'>
    )>, blockedTasks: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'id'>
      & { currentTrackingUsers: Array<(
        { __typename?: 'User' }
        & TaskSidebarTaskUserFragment
      )> }
    )> }
  ) }
);

export type TaskSidebarUpdateTaskMutationVariables = Exact<{
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  prioId?: Maybe<Scalars['Int']>;
  deadline?: Maybe<Scalars['Int']>;
  timeBudget?: Maybe<Scalars['Int']>;
  taskCategory?: Maybe<Scalars['Int']>;
  priceCalcMethod?: Maybe<PriceCalcMethodType>;
  customHourlyWorkRate?: Maybe<Scalars['Float']>;
  timeBudgetViewUnit?: Maybe<Scalars['String']>;
  flatRatePrice?: Maybe<Scalars['Float']>;
  archived?: Maybe<Scalars['Boolean']>;
  isNotCharged?: Maybe<Scalars['Boolean']>;
}>;


export type TaskSidebarUpdateTaskMutation = (
  { __typename?: 'Mutation' }
  & { updateTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'name' | 'description' | 'deadline' | 'timeBudget' | 'priceCalcMethod' | 'timeBudgetViewUnit' | 'hourlyWorkRate' | 'taskCategory' | 'flatRatePrice' | 'effectiveHourlyWorkRate' | 'archived' | 'isNotCharged'>
    & { prio: (
      { __typename?: 'Prio' }
      & Pick<Prio, 'id'>
    ), price: (
      { __typename?: 'Price' }
      & Pick<Price, 'adjusted' | 'original'>
    ) }
  )> }
);

export type TaskSidebarArchiveOfferPositionsMutationVariables = Exact<{
  offerId: Scalars['Int'];
  taskId?: Maybe<Scalars['Int']>;
  rateId?: Maybe<Scalars['Int']>;
}>;


export type TaskSidebarArchiveOfferPositionsMutation = (
  { __typename?: 'Mutation' }
  & { archiveOfferPositions: (
    { __typename?: 'Offer' }
    & Pick<Offer, 'id'>
  ) }
);

export type TaskListSidebarAssignUserMutationVariables = Exact<{
  userId: Scalars['Int'];
  taskId: Scalars['Int'];
}>;


export type TaskListSidebarAssignUserMutation = (
  { __typename?: 'Mutation' }
  & { assignUserToTask: (
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
    & { assignedUsers: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'surname' | 'profilePic'>
    )> }
  ) }
);

export type TaskListSidebarUnassignUserMutationVariables = Exact<{
  userId: Scalars['Int'];
  taskId: Scalars['Int'];
}>;


export type TaskListSidebarUnassignUserMutation = (
  { __typename?: 'Mutation' }
  & { unassignUserFromTask: (
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
    & { assignedUsers: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )> }
  ) }
);

export type TasklistSidebarTaskUpdatedSubscriptionVariables = Exact<{
  taskId: Scalars['Int'];
}>;


export type TasklistSidebarTaskUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { tasksUpdated: Array<(
    { __typename?: 'Task' }
    & TaskSidebarTaskFragment
  )> }
);

export type BackendUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type BackendUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & Pick<Subscription, 'backendUpdated'>
);

export type GetParentTasksQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetParentTasksQuery = (
  { __typename?: 'Query' }
  & { parentTasks: Array<(
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
  )> }
);

export type BillingTaskListTaskUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'name' | 'surname' | 'profilePic' | 'settings'>
);

export type BillingTaskListTaskChildrenWorkedTimeCurrentWorkFragment = (
  { __typename?: 'Workload' }
  & Pick<Workload, 'id' | 'start' | 'end' | 'userId'>
);

export type BillingTaskListTaskChildrenWorkedTimeFragment = (
  { __typename?: 'ChildrenWorkedTime' }
  & Pick<ChildrenWorkedTime, 'completed' | 'notCompleted'>
  & { currentWork: Array<(
    { __typename?: 'Workload' }
    & TaskListTaskChildrenWorkedTimeCurrentWorkFragment
  )> }
);

export type BillingTaskListTaskDiscountFragment = (
  { __typename?: 'Discount' }
  & Pick<Discount, 'id' | 'value' | 'unit'>
);

export type BillingTasklistTaskPrioFragment = (
  { __typename?: 'Prio' }
  & Pick<Prio, 'id' | 'sort' | 'color'>
);

export type BillingTaskListTaskFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'id' | 'name' | 'companyId' | 'isBillable' | 'offerId' | 'index' | 'path' | 'childrenTaskIds' | 'isTopTask' | 'parentId' | 'timeBudget'>
  & { offer?: Maybe<(
    { __typename?: 'Offer' }
    & Pick<Offer, 'id' | 'name' | 'asAPackage'>
    & { rates: Array<(
      { __typename?: 'Rate' }
      & BillingTaskListTaskRateFragment
    )> }
  )>, price: (
    { __typename?: 'Price' }
    & Pick<Price, 'adjusted'>
  ), priceWithoutChildren: (
    { __typename?: 'Price' }
    & Pick<Price, 'adjusted'>
  ), billingRelevantTime?: Maybe<(
    { __typename?: 'TaskBillingRelevantTime' }
    & Pick<TaskBillingRelevantTime, 'aggregated' | 'withoutChildren'>
  )> }
);

export type BillingTaskListTaskRateFragment = (
  { __typename?: 'Rate' }
  & Pick<Rate, 'id' | 'at' | 'description' | 'offerId' | 'amount'>
);

export type BillingListPositionFragment = (
  { __typename?: 'BillingPosition' }
  & { task: (
    { __typename?: 'Task' }
    & BillingTaskListTaskFragment
  ), rates: Array<(
    { __typename?: 'Rate' }
    & BillingTaskListTaskRateFragment
  )> }
);

export type GetBillingTasklistQueryVariables = Exact<{
  companyId: Scalars['Int'];
  uncollapsedTaskIds: Array<Scalars['Int']>;
  selectedTaskIds: Array<Scalars['Int']>;
}>;


export type GetBillingTasklistQuery = (
  { __typename?: 'Query' }
  & { billingTasks: (
    { __typename?: 'BillingTasksResponse' }
    & Pick<BillingTasksResponse, 'uncollapsableTaskIds' | 'selectedTaskIds'>
    & { tasks: Array<(
      { __typename?: 'BillingPosition' }
      & BillingListPositionFragment
    )> }
  ) }
);

export type BillingTasklistOfferUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type BillingTasklistOfferUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { offerUpdated: (
    { __typename?: 'Offer' }
    & Pick<Offer, 'id'>
  ) }
);

export type BillingTasklistOfferDeletedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type BillingTasklistOfferDeletedSubscription = (
  { __typename?: 'Subscription' }
  & { offerDeleted: (
    { __typename?: 'OfferDeletedResponse' }
    & Pick<OfferDeletedResponse, 'id'>
  ) }
);

export type BillingTasklistInvoiceAddedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type BillingTasklistInvoiceAddedSubscription = (
  { __typename?: 'Subscription' }
  & { invoiceAdded: (
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id'>
  ) }
);

export type BillingTasklistTasksDeletedSubscriptionVariables = Exact<{
  companyId?: Maybe<Scalars['Int']>;
}>;


export type BillingTasklistTasksDeletedSubscription = (
  { __typename?: 'Subscription' }
  & Pick<Subscription, 'tasksDeleted'>
);

export type GetCompanyHeaderQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetCompanyHeaderQuery = (
  { __typename?: 'Query' }
  & { company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'short' | 'imageUrl' | 'favorite'>
  ) }
);

export type UpdateCompanyFavoriteMutationVariables = Exact<{
  id: Scalars['Int'];
  favorite: Scalars['Boolean'];
}>;


export type UpdateCompanyFavoriteMutation = (
  { __typename?: 'Mutation' }
  & { updateCompanyFavorite: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'favorite'>
  ) }
);

export type CompanyNamesFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'short' | 'freelancer' | 'imageUrl' | 'priceCategory'>
);

export type GetCompanyNamesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompanyNamesQuery = (
  { __typename?: 'Query' }
  & { companies: Array<(
    { __typename?: 'Company' }
    & CompanyNamesFragment
  )> }
);

export type CompanyNameAddedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type CompanyNameAddedSubscription = (
  { __typename?: 'Subscription' }
  & { companyAdded: (
    { __typename?: 'Company' }
    & CompanyNamesFragment
  ) }
);

export type MeFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'name' | 'surname' | 'profilePic' | 'settings'>
);

export type GetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & MeFragment
  ) }
);

export type MeUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type MeUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { meUpdated: (
    { __typename?: 'User' }
    & MeFragment
  ) }
);

export type GetUserSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserSettingsQuery = (
  { __typename?: 'Query' }
  & { userSettings: Array<(
    { __typename?: 'UserSettings' }
    & Pick<UserSettings, 'id' | 'value'>
  )> }
);

export type UpdateUserSettingsMutationVariables = Exact<{
  id: UserSettingsKey;
  value?: Maybe<Scalars['String']>;
}>;


export type UpdateUserSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateUserSettings: (
    { __typename?: 'UserSettings' }
    & Pick<UserSettings, 'id' | 'value'>
  ) }
);

export type AddUserSettingsMutationVariables = Exact<{
  id: UserSettingsKey;
  value?: Maybe<Scalars['String']>;
}>;


export type AddUserSettingsMutation = (
  { __typename?: 'Mutation' }
  & { addUserSettings: (
    { __typename?: 'UserSettings' }
    & Pick<UserSettings, 'id' | 'value'>
  ) }
);

export type TemplateFragmentFragment = (
  { __typename?: 'Template' }
  & Pick<Template, 'id' | 'title' | 'text'>
);

export type GetTemplatesQueryVariables = Exact<{
  templateType: TemplateType;
}>;


export type GetTemplatesQuery = (
  { __typename?: 'Query' }
  & { templates: Array<(
    { __typename?: 'Template' }
    & TemplateFragmentFragment
  )> }
);

export type UpdateOrAddTemplateMutationVariables = Exact<{
  templateType: TemplateType;
  title: Scalars['String'];
  text: Scalars['String'];
}>;


export type UpdateOrAddTemplateMutation = (
  { __typename?: 'Mutation' }
  & { updateOrAddTemplateType: (
    { __typename?: 'Template' }
    & TemplateFragmentFragment
  ) }
);

export type TemplateUpdatedSubscriptionVariables = Exact<{
  templateType: TemplateType;
}>;


export type TemplateUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { templateUpdated: (
    { __typename?: 'Template' }
    & TemplateFragmentFragment
  ) }
);

export type TemplateAddedSubscriptionVariables = Exact<{
  templateType: TemplateType;
}>;


export type TemplateAddedSubscription = (
  { __typename?: 'Subscription' }
  & { templateAdded: (
    { __typename?: 'Template' }
    & TemplateFragmentFragment
  ) }
);

export type TemplateDeletedSubscriptionVariables = Exact<{
  templateType: TemplateType;
}>;


export type TemplateDeletedSubscription = (
  { __typename?: 'Subscription' }
  & { templateDeleted: (
    { __typename?: 'Template' }
    & TemplateFragmentFragment
  ) }
);

export const BillingCompanyListItemFragmentDoc = gql`
    fragment BillingCompanyListItem on Company {
  id
  name
  short
  sort
  favorite
  hasBillingActivity
  hasUserRelatedBillingActivity
  nextBillingDeadline
}
    `;
export const BillingTaskListRateFragmentDoc = gql`
    fragment BillingTaskListRate on Rate {
  id
  at
  amount
  description
  offerId
  invoiceId
  archivedDate
}
    `;
export const BillingTaskSidebarTaskUserFragmentDoc = gql`
    fragment BillingTaskSidebarTaskUser on User {
  id
  name
  surname
  profilePic
  settings
}
    `;
export const BillingTaskSidebarTaskChildrenWorkedTimeFragmentDoc = gql`
    fragment BillingTaskSidebarTaskChildrenWorkedTime on ChildrenWorkedTime {
  completed
  notCompleted
  currentWork {
    id
    start
    end
    userId
  }
}
    `;
export const BillingTaskSidebarTaskDiscountFragmentDoc = gql`
    fragment BillingTaskSidebarTaskDiscount on Discount {
  id
  value
  unit
}
    `;
export const BillingTaskSidebarTaskWorkloadFragmentDoc = gql`
    fragment BillingTaskSidebarTaskWorkload on Workload {
  id
  start
  end
  userId
}
    `;
export const BillingTaskSidebarTaskFragmentDoc = gql`
    fragment BillingTaskSidebarTask on Task {
  id
  companyId
  offerId
  offer {
    id
    name
    status
    asAPackage
    rates {
      ...BillingTaskListRate
    }
    aggregatedPrice
  }
  index
  path
  childrenTaskIds
  isTopTask
  isBillable
  deletionOption
  isMovable
  parentId
  name
  description
  deadline
  timeBudget
  completed
  archived
  taskCategory
  prio {
    id
    sort
    color
  }
  shownPrio {
    id
    sort
    color
  }
  assignedUsers {
    ...BillingTaskSidebarTaskUser
  }
  currentTrackingUsers {
    ...BillingTaskSidebarTaskUser
  }
  workedTime
  finishedWork {
    id
    start
    end
    userId
  }
  childrenWorkedTime {
    ...BillingTaskSidebarTaskChildrenWorkedTime
  }
  discount {
    ...BillingTaskSidebarTaskDiscount
  }
  currentWork {
    ...BillingTaskSidebarTaskWorkload
  }
  timeBudgetViewUnit
  hourlyWorkRate
  priceCalcMethod
  flatRatePrice
  price {
    original
    adjusted
  }
  priceWithoutChildren {
    original
    adjusted
  }
  effectiveHourlyWorkRate
  isNotCharged
}
    ${BillingTaskListRateFragmentDoc}
${BillingTaskSidebarTaskUserFragmentDoc}
${BillingTaskSidebarTaskChildrenWorkedTimeFragmentDoc}
${BillingTaskSidebarTaskDiscountFragmentDoc}
${BillingTaskSidebarTaskWorkloadFragmentDoc}`;
export const CompanyListItemFragmentDoc = gql`
    fragment CompanyListItem on Company {
  id
  name
  short
  sort
  favorite
  hasActivity
  hasUserRelatedActivity
  activity {
    all
    prio {
      sort
      description
      color
    }
  }
  nextDeadline
}
    `;
export const HeaderMeFragmentDoc = gql`
    fragment HeaderMe on User {
  id
  name
  surname
  profilePic
  settings
}
    `;
export const TimeTrackingTaskFragmentDoc = gql`
    fragment TimeTrackingTask on Task {
  id
  parentId
  name
  deadline
  prio {
    id
    description
    color
    sort
    icon
  }
}
    `;
export const TimeTrackingCompanyFragmentDoc = gql`
    fragment TimeTrackingCompany on Company {
  id
  name
  imageUrl
  short
}
    `;
export const TimeTrackingPrioFragmentDoc = gql`
    fragment TimeTrackingPrio on Prio {
  id
  description
  color
  sort
  icon
}
    `;
export const TimeTrackingSuperTaskFragmentDoc = gql`
    fragment TimeTrackingSuperTask on Task {
  id
  name
  deadline
  prio {
    ...TimeTrackingPrio
  }
}
    ${TimeTrackingPrioFragmentDoc}`;
export const TimeTrackingWorkloadFragmentDoc = gql`
    fragment TimeTrackingWorkload on Workload {
  id
  start
  end
}
    `;
export const TimeTrackingItemFragmentDoc = gql`
    fragment TimeTrackingItem on TimeTrackingInfo {
  task {
    ...TimeTrackingTask
  }
  company {
    ...TimeTrackingCompany
  }
  superTask {
    ...TimeTrackingSuperTask
  }
  workload {
    ...TimeTrackingWorkload
  }
  shownPrio {
    ...TimeTrackingPrio
  }
}
    ${TimeTrackingTaskFragmentDoc}
${TimeTrackingCompanyFragmentDoc}
${TimeTrackingSuperTaskFragmentDoc}
${TimeTrackingWorkloadFragmentDoc}
${TimeTrackingPrioFragmentDoc}`;
export const InvoiceListItemFragmentDoc = gql`
    fragment InvoiceListItem on Invoice {
  id
  number
  creationDate
  deliveryDate
  state
  company {
    name
  }
  price {
    original
    adjusted
  }
}
    `;
export const OfferEditorRateFragmentDoc = gql`
    fragment OfferEditorRate on Rate {
  id
  at
  description
  offerId
}
    `;
export const OfferEditorContactPersonFragmentDoc = gql`
    fragment OfferEditorContactPerson on ContactPerson {
  id
  name
  surname
  title
  email
  companyId
}
    `;
export const OfferEditorDiscountFragmentDoc = gql`
    fragment OfferEditorDiscount on Discount {
  id
  value
  unit
}
    `;
export const OfferEditorOfferFragmentDoc = gql`
    fragment OfferEditorOffer on Offer {
  id
  companyId
  name
  validity
  userDefinedTextId
  status
  asAPackage
  rates {
    ...OfferEditorRate
  }
  preText
  preTextTemplate
  afterText
  afterTextTemplate
  sendMail
  mailAttachPDF
  emailText
  emailTextTemplate
  contactPerson {
    ...OfferEditorContactPerson
  }
  customContactEmail
  discount {
    ...OfferEditorDiscount
  }
  sent
  customer {
    id
    name
    short
  }
  pin
  link
  addedToSendQueue
  aggregatedMinPrice
  aggregatedFullPrice
  aggregatedPrice
  aggregatedPriceWithoutDiscount
  views {
    timestamp
    type
  }
  costEstimate
}
    ${OfferEditorRateFragmentDoc}
${OfferEditorContactPersonFragmentDoc}
${OfferEditorDiscountFragmentDoc}`;
export const OfferEditorPositionFragmentDoc = gql`
    fragment OfferEditorPosition on Task {
  id
  isTopTask
  index
  parentId
  name
  path
  companyId
  description
  deadline
  timeBudget
  taskCategory
  timeBudgetViewUnit
  hourlyWorkRate
  priceCalcMethod
  discount {
    ...OfferEditorDiscount
  }
  flatRatePrice
  preselected
  optional
  selected
  price {
    original
    adjusted
  }
}
    ${OfferEditorDiscountFragmentDoc}`;
export const OfferListOfferRateFragmentDoc = gql`
    fragment OfferListOfferRate on Rate {
  id
  at
  description
  offerId
}
    `;
export const OfferListOfferContactPersonFragmentDoc = gql`
    fragment OfferListOfferContactPerson on ContactPerson {
  id
  name
  surname
  title
  email
  companyId
}
    `;
export const OfferListOfferDiscountFragmentDoc = gql`
    fragment OfferListOfferDiscount on Discount {
  id
  value
  unit
}
    `;
export const OfferListOfferFragmentDoc = gql`
    fragment OfferListOffer on Offer {
  id
  companyId
  name
  validity
  status
  asAPackage
  sent
  customer {
    id
    name
  }
  addedToSendQueue
  aggregatedPrice
  views {
    timestamp
    type
  }
  costEstimate
}
    `;
export const OfferWebviewOfferRateFragmentDoc = gql`
    fragment OfferWebviewOfferRate on Rate {
  id
  at
  description
  offerId
}
    `;
export const OfferWebviewContactPersonFragmentDoc = gql`
    fragment OfferWebviewContactPerson on ContactPerson {
  id
  name
  surname
  title
}
    `;
export const OfferWebviewDiscountFragmentDoc = gql`
    fragment OfferWebviewDiscount on Discount {
  id
  value
  unit
}
    `;
export const OfferWebviewOfferFragmentDoc = gql`
    fragment OfferWebviewOffer on Offer {
  id
  name
  validity
  status
  asAPackage
  rates {
    ...OfferWebviewOfferRate
  }
  preText
  afterText
  emailText
  contactPerson {
    ...OfferWebviewContactPerson
  }
  discount {
    ...OfferWebviewDiscount
  }
  customer {
    id
    name
    short
    legalName
  }
  aggregatedMinPrice
  aggregatedFullPrice
  aggregatedPrice
  aggregatedPriceWithoutDiscount
  pdf
  costEstimate
}
    ${OfferWebviewOfferRateFragmentDoc}
${OfferWebviewContactPersonFragmentDoc}
${OfferWebviewDiscountFragmentDoc}`;
export const OfferWebviewOfferPositionFragmentDoc = gql`
    fragment OfferWebviewOfferPosition on Task {
  id
  isTopTask
  index
  parentId
  name
  path
  companyId
  description
  deadline
  timeBudget
  taskCategory
  timeBudgetViewUnit
  hourlyWorkRate
  priceCalcMethod
  discount {
    ...OfferWebviewDiscount
  }
  flatRatePrice
  preselected
  optional
  selected
  price {
    original
    adjusted
  }
}
    ${OfferWebviewDiscountFragmentDoc}`;
export const RateEditorRateFragmentDoc = gql`
    fragment RateEditorRate on Rate {
  id
  at
  description
  offerId
}
    `;
export const ContactPersonFragmentFragmentDoc = gql`
    fragment ContactPersonFragment on ContactPerson {
  id
  name
  surname
  title
  email
  companyId
}
    `;
export const RecurringInvoiceEditorDiscountFragmentDoc = gql`
    fragment RecurringInvoiceEditorDiscount on Discount {
  id
  value
  unit
}
    `;
export const RecurringInvoiceEditorPositionFragmentDoc = gql`
    fragment RecurringInvoiceEditorPosition on Task {
  id
  isTopTask
  index
  parentId
  name
  path
  companyId
  description
  deadline
  timeBudget
  taskCategory
  timeBudgetViewUnit
  hourlyWorkRate
  priceCalcMethod
  discount {
    ...RecurringInvoiceEditorDiscount
  }
  flatRatePrice
  preselected
  optional
  selected
  price {
    original
    adjusted
  }
}
    ${RecurringInvoiceEditorDiscountFragmentDoc}`;
export const RecurrentOfferEditorInvoiceFragmentDoc = gql`
    fragment RecurrentOfferEditorInvoice on Invoice {
  id
  name
  preText
  preTextTemplate
  afterText
  afterTextTemplate
  emailText
  emailTextTemplate
  sendMail
  mailAttachPDF
  company {
    id
    name
  }
  contactPerson {
    ...ContactPersonFragment
  }
  customContactEmail
  tasks {
    ...RecurringInvoiceEditorPosition
  }
  pdf {
    id
    base64
  }
  generatedChildren {
    id
    number
  }
}
    ${ContactPersonFragmentFragmentDoc}
${RecurringInvoiceEditorPositionFragmentDoc}`;
export const RecurrentOfferEditorRepetitionRuleFragmentDoc = gql`
    fragment RecurrentOfferEditorRepetitionRule on RepetitionRule {
  id
  start
  end
  finalExecution
  interval
  intervalType
  status
  executionPreview
  executionHistory {
    id
    date
    email
  }
}
    `;
export const RecurringInvoiceEditorRecurringInvoiceFragmentDoc = gql`
    fragment RecurringInvoiceEditorRecurringInvoice on RecurringInvoice {
  id
  invoice {
    ...RecurrentOfferEditorInvoice
  }
  repetitionRule {
    ...RecurrentOfferEditorRepetitionRule
  }
}
    ${RecurrentOfferEditorInvoiceFragmentDoc}
${RecurrentOfferEditorRepetitionRuleFragmentDoc}`;
export const RecurringInvoiceListRecurringInvoiceInvoiceFragmentDoc = gql`
    fragment RecurringInvoiceListRecurringInvoiceInvoice on Invoice {
  id
  name
  creationDate
  deliveryDate
  companyId
  company {
    id
    name
  }
  tasks {
    id
    name
  }
  state
  price {
    original
    adjusted
  }
  generatedChildren {
    id
    number
  }
}
    `;
export const RecurringInvoiceListItemRepetitionRuleFragmentDoc = gql`
    fragment RecurringInvoiceListItemRepetitionRule on RepetitionRule {
  id
  status
  start
  end
  interval
  intervalType
  executionPreview
  executionHistory {
    id
    date
    email
  }
  finalExecution
}
    `;
export const RecurringInvoiceListItemFragmentDoc = gql`
    fragment RecurringInvoiceListItem on RecurringInvoice {
  id
  invoice {
    ...RecurringInvoiceListRecurringInvoiceInvoice
  }
  repetitionRule {
    ...RecurringInvoiceListItemRepetitionRule
  }
}
    ${RecurringInvoiceListRecurringInvoiceInvoiceFragmentDoc}
${RecurringInvoiceListItemRepetitionRuleFragmentDoc}`;
export const UserSelectUserFragmentFragmentDoc = gql`
    fragment UserSelectUserFragment on User {
  id
  name
  surname
  profilePic
  settings
  role
}
    `;
export const TasklistTaskPrioFragmentDoc = gql`
    fragment TasklistTaskPrio on Prio {
  id
  sort
  color
}
    `;
export const TaskListTaskUserFragmentDoc = gql`
    fragment TaskListTaskUser on User {
  id
  name
  surname
  profilePic
  settings
}
    `;
export const TaskListTaskChildrenWorkedTimeCurrentWorkFragmentDoc = gql`
    fragment TaskListTaskChildrenWorkedTimeCurrentWork on Workload {
  id
  start
  end
  userId
}
    `;
export const TaskListTaskChildrenWorkedTimeFragmentDoc = gql`
    fragment TaskListTaskChildrenWorkedTime on ChildrenWorkedTime {
  completed
  notCompleted
  currentWork {
    ...TaskListTaskChildrenWorkedTimeCurrentWork
  }
}
    ${TaskListTaskChildrenWorkedTimeCurrentWorkFragmentDoc}`;
export const TaskListTaskDiscountFragmentDoc = gql`
    fragment TaskListTaskDiscount on Discount {
  id
  value
  unit
}
    `;
export const TaskListTaskFragmentDoc = gql`
    fragment TaskListTask on Task {
  id
  companyId
  offerId
  offer {
    id
    name
    status
  }
  index
  path
  isTopTask
  parentId
  name
  description
  deadline
  timeBudget
  completed
  taskCategory
  prio {
    ...TasklistTaskPrio
  }
  shownPrio {
    ...TasklistTaskPrio
  }
  assignedUsers {
    ...TaskListTaskUser
  }
  currentTrackingUsers {
    ...TaskListTaskUser
  }
  workedTime
  finishedWork {
    id
    start
    end
    userId
  }
  childrenWorkedTime {
    ...TaskListTaskChildrenWorkedTime
  }
  discount {
    ...TaskListTaskDiscount
  }
  currentWork {
    id
    start
    end
    userId
  }
  timeBudgetViewUnit
}
    ${TasklistTaskPrioFragmentDoc}
${TaskListTaskUserFragmentDoc}
${TaskListTaskChildrenWorkedTimeFragmentDoc}
${TaskListTaskDiscountFragmentDoc}`;
export const TaskSidebarTaskUserFragmentDoc = gql`
    fragment TaskSidebarTaskUser on User {
  id
  name
  surname
  profilePic
  settings
}
    `;
export const TaskSidebarTaskChildrenWorkedTimeFragmentDoc = gql`
    fragment TaskSidebarTaskChildrenWorkedTime on ChildrenWorkedTime {
  completed
  notCompleted
  currentWork {
    id
    start
    end
    userId
  }
}
    `;
export const TaskSidebarTaskDiscountFragmentDoc = gql`
    fragment TaskSidebarTaskDiscount on Discount {
  id
  value
  unit
}
    `;
export const TaskSidebarTaskWorkloadFragmentDoc = gql`
    fragment TaskSidebarTaskWorkload on Workload {
  id
  start
  end
  userId
}
    `;
export const TaskSidebarTaskFragmentDoc = gql`
    fragment TaskSidebarTask on Task {
  id
  companyId
  offerId
  offer {
    id
    name
    status
    asAPackage
  }
  index
  path
  childrenTaskIds
  isTopTask
  isBillable
  deletionOption
  isMovable
  parentId
  name
  description
  deadline
  timeBudget
  completed
  archived
  taskCategory
  prio {
    id
    sort
    color
  }
  shownPrio {
    id
    sort
    color
  }
  assignedUsers {
    ...TaskSidebarTaskUser
  }
  currentTrackingUsers {
    ...TaskSidebarTaskUser
  }
  workedTime
  finishedWork {
    id
    start
    end
    userId
  }
  childrenWorkedTime {
    ...TaskSidebarTaskChildrenWorkedTime
  }
  discount {
    ...TaskSidebarTaskDiscount
  }
  currentWork {
    ...TaskSidebarTaskWorkload
  }
  timeBudgetViewUnit
  hourlyWorkRate
  priceCalcMethod
  flatRatePrice
  price {
    original
    adjusted
  }
  effectiveHourlyWorkRate
  isNotCharged
}
    ${TaskSidebarTaskUserFragmentDoc}
${TaskSidebarTaskChildrenWorkedTimeFragmentDoc}
${TaskSidebarTaskDiscountFragmentDoc}
${TaskSidebarTaskWorkloadFragmentDoc}`;
export const BillingTaskListTaskUserFragmentDoc = gql`
    fragment BillingTaskListTaskUser on User {
  id
  name
  surname
  profilePic
  settings
}
    `;
export const BillingTaskListTaskChildrenWorkedTimeCurrentWorkFragmentDoc = gql`
    fragment BillingTaskListTaskChildrenWorkedTimeCurrentWork on Workload {
  id
  start
  end
  userId
}
    `;
export const BillingTaskListTaskChildrenWorkedTimeFragmentDoc = gql`
    fragment BillingTaskListTaskChildrenWorkedTime on ChildrenWorkedTime {
  completed
  notCompleted
  currentWork {
    ...TaskListTaskChildrenWorkedTimeCurrentWork
  }
}
    ${TaskListTaskChildrenWorkedTimeCurrentWorkFragmentDoc}`;
export const BillingTaskListTaskDiscountFragmentDoc = gql`
    fragment BillingTaskListTaskDiscount on Discount {
  id
  value
  unit
}
    `;
export const BillingTasklistTaskPrioFragmentDoc = gql`
    fragment BillingTasklistTaskPrio on Prio {
  id
  sort
  color
}
    `;
export const BillingTaskListTaskRateFragmentDoc = gql`
    fragment BillingTaskListTaskRate on Rate {
  id
  at
  description
  offerId
  amount
}
    `;
export const BillingTaskListTaskFragmentDoc = gql`
    fragment BillingTaskListTask on Task {
  id
  name
  offer {
    id
    name
    asAPackage
    rates {
      ...BillingTaskListTaskRate
    }
  }
  price {
    adjusted
  }
  priceWithoutChildren {
    adjusted
  }
  companyId
  isBillable
  billingRelevantTime {
    aggregated
    withoutChildren
  }
  offerId
  index
  path
  childrenTaskIds
  isTopTask
  parentId
  timeBudget
}
    ${BillingTaskListTaskRateFragmentDoc}`;
export const BillingListPositionFragmentDoc = gql`
    fragment BillingListPosition on BillingPosition {
  task {
    ...BillingTaskListTask
  }
  rates {
    ...BillingTaskListTaskRate
  }
}
    ${BillingTaskListTaskFragmentDoc}
${BillingTaskListTaskRateFragmentDoc}`;
export const CompanyNamesFragmentDoc = gql`
    fragment CompanyNames on Company {
  id
  name
  short
  freelancer
  imageUrl
  priceCategory
}
    `;
export const MeFragmentDoc = gql`
    fragment Me on User {
  id
  name
  surname
  profilePic
  settings
}
    `;
export const TemplateFragmentFragmentDoc = gql`
    fragment TemplateFragment on Template {
  id
  title
  text
}
    `;
export const GetBillingCompaniesDocument = gql`
    query GetBillingCompanies {
  companies {
    ...BillingCompanyListItem
  }
}
    ${BillingCompanyListItemFragmentDoc}`;
export type GetBillingCompaniesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBillingCompaniesQuery, GetBillingCompaniesQueryVariables>, 'query'>;

    export const GetBillingCompaniesComponent = (props: GetBillingCompaniesComponentProps) => (
      <ApolloReactComponents.Query<GetBillingCompaniesQuery, GetBillingCompaniesQueryVariables> query={GetBillingCompaniesDocument} {...props} />
    );
    
export type GetBillingCompaniesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetBillingCompaniesQuery, GetBillingCompaniesQueryVariables>
    } & TChildProps;
export function withGetBillingCompanies<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBillingCompaniesQuery,
  GetBillingCompaniesQueryVariables,
  GetBillingCompaniesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetBillingCompaniesQuery, GetBillingCompaniesQueryVariables, GetBillingCompaniesProps<TChildProps, TDataName>>(GetBillingCompaniesDocument, {
      alias: 'getBillingCompanies',
      ...operationOptions
    });
};

/**
 * __useGetBillingCompaniesQuery__
 *
 * To run a query within a React component, call `useGetBillingCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillingCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillingCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBillingCompaniesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBillingCompaniesQuery, GetBillingCompaniesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBillingCompaniesQuery, GetBillingCompaniesQueryVariables>(GetBillingCompaniesDocument, baseOptions);
      }
export function useGetBillingCompaniesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBillingCompaniesQuery, GetBillingCompaniesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBillingCompaniesQuery, GetBillingCompaniesQueryVariables>(GetBillingCompaniesDocument, baseOptions);
        }
export type GetBillingCompaniesQueryHookResult = ReturnType<typeof useGetBillingCompaniesQuery>;
export type GetBillingCompaniesLazyQueryHookResult = ReturnType<typeof useGetBillingCompaniesLazyQuery>;
export type GetBillingCompaniesQueryResult = ApolloReactCommon.QueryResult<GetBillingCompaniesQuery, GetBillingCompaniesQueryVariables>;
export const BillingCompanyAddedDocument = gql`
    subscription BillingCompanyAdded {
  companyAdded {
    ...BillingCompanyListItem
  }
}
    ${BillingCompanyListItemFragmentDoc}`;
export type BillingCompanyAddedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<BillingCompanyAddedSubscription, BillingCompanyAddedSubscriptionVariables>, 'subscription'>;

    export const BillingCompanyAddedComponent = (props: BillingCompanyAddedComponentProps) => (
      <ApolloReactComponents.Subscription<BillingCompanyAddedSubscription, BillingCompanyAddedSubscriptionVariables> subscription={BillingCompanyAddedDocument} {...props} />
    );
    
export type BillingCompanyAddedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<BillingCompanyAddedSubscription, BillingCompanyAddedSubscriptionVariables>
    } & TChildProps;
export function withBillingCompanyAdded<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BillingCompanyAddedSubscription,
  BillingCompanyAddedSubscriptionVariables,
  BillingCompanyAddedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, BillingCompanyAddedSubscription, BillingCompanyAddedSubscriptionVariables, BillingCompanyAddedProps<TChildProps, TDataName>>(BillingCompanyAddedDocument, {
      alias: 'billingCompanyAdded',
      ...operationOptions
    });
};

/**
 * __useBillingCompanyAddedSubscription__
 *
 * To run a query within a React component, call `useBillingCompanyAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useBillingCompanyAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingCompanyAddedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useBillingCompanyAddedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<BillingCompanyAddedSubscription, BillingCompanyAddedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<BillingCompanyAddedSubscription, BillingCompanyAddedSubscriptionVariables>(BillingCompanyAddedDocument, baseOptions);
      }
export type BillingCompanyAddedSubscriptionHookResult = ReturnType<typeof useBillingCompanyAddedSubscription>;
export type BillingCompanyAddedSubscriptionResult = ApolloReactCommon.SubscriptionResult<BillingCompanyAddedSubscription>;
export const BillingCompanyUpdatedDocument = gql`
    subscription BillingCompanyUpdated {
  companyUpdated {
    ...BillingCompanyListItem
  }
}
    ${BillingCompanyListItemFragmentDoc}`;
export type BillingCompanyUpdatedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<BillingCompanyUpdatedSubscription, BillingCompanyUpdatedSubscriptionVariables>, 'subscription'>;

    export const BillingCompanyUpdatedComponent = (props: BillingCompanyUpdatedComponentProps) => (
      <ApolloReactComponents.Subscription<BillingCompanyUpdatedSubscription, BillingCompanyUpdatedSubscriptionVariables> subscription={BillingCompanyUpdatedDocument} {...props} />
    );
    
export type BillingCompanyUpdatedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<BillingCompanyUpdatedSubscription, BillingCompanyUpdatedSubscriptionVariables>
    } & TChildProps;
export function withBillingCompanyUpdated<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BillingCompanyUpdatedSubscription,
  BillingCompanyUpdatedSubscriptionVariables,
  BillingCompanyUpdatedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, BillingCompanyUpdatedSubscription, BillingCompanyUpdatedSubscriptionVariables, BillingCompanyUpdatedProps<TChildProps, TDataName>>(BillingCompanyUpdatedDocument, {
      alias: 'billingCompanyUpdated',
      ...operationOptions
    });
};

/**
 * __useBillingCompanyUpdatedSubscription__
 *
 * To run a query within a React component, call `useBillingCompanyUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useBillingCompanyUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingCompanyUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useBillingCompanyUpdatedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<BillingCompanyUpdatedSubscription, BillingCompanyUpdatedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<BillingCompanyUpdatedSubscription, BillingCompanyUpdatedSubscriptionVariables>(BillingCompanyUpdatedDocument, baseOptions);
      }
export type BillingCompanyUpdatedSubscriptionHookResult = ReturnType<typeof useBillingCompanyUpdatedSubscription>;
export type BillingCompanyUpdatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<BillingCompanyUpdatedSubscription>;
export const GetBillingTaskSidebarDocument = gql`
    query GetBillingTaskSidebar($id: Int!) {
  task(where: {id: $id}) {
    ...BillingTaskSidebarTask
  }
}
    ${BillingTaskSidebarTaskFragmentDoc}`;
export type GetBillingTaskSidebarComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBillingTaskSidebarQuery, GetBillingTaskSidebarQueryVariables>, 'query'> & ({ variables: GetBillingTaskSidebarQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBillingTaskSidebarComponent = (props: GetBillingTaskSidebarComponentProps) => (
      <ApolloReactComponents.Query<GetBillingTaskSidebarQuery, GetBillingTaskSidebarQueryVariables> query={GetBillingTaskSidebarDocument} {...props} />
    );
    
export type GetBillingTaskSidebarProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetBillingTaskSidebarQuery, GetBillingTaskSidebarQueryVariables>
    } & TChildProps;
export function withGetBillingTaskSidebar<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBillingTaskSidebarQuery,
  GetBillingTaskSidebarQueryVariables,
  GetBillingTaskSidebarProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetBillingTaskSidebarQuery, GetBillingTaskSidebarQueryVariables, GetBillingTaskSidebarProps<TChildProps, TDataName>>(GetBillingTaskSidebarDocument, {
      alias: 'getBillingTaskSidebar',
      ...operationOptions
    });
};

/**
 * __useGetBillingTaskSidebarQuery__
 *
 * To run a query within a React component, call `useGetBillingTaskSidebarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillingTaskSidebarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillingTaskSidebarQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBillingTaskSidebarQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBillingTaskSidebarQuery, GetBillingTaskSidebarQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBillingTaskSidebarQuery, GetBillingTaskSidebarQueryVariables>(GetBillingTaskSidebarDocument, baseOptions);
      }
export function useGetBillingTaskSidebarLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBillingTaskSidebarQuery, GetBillingTaskSidebarQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBillingTaskSidebarQuery, GetBillingTaskSidebarQueryVariables>(GetBillingTaskSidebarDocument, baseOptions);
        }
export type GetBillingTaskSidebarQueryHookResult = ReturnType<typeof useGetBillingTaskSidebarQuery>;
export type GetBillingTaskSidebarLazyQueryHookResult = ReturnType<typeof useGetBillingTaskSidebarLazyQuery>;
export type GetBillingTaskSidebarQueryResult = ApolloReactCommon.QueryResult<GetBillingTaskSidebarQuery, GetBillingTaskSidebarQueryVariables>;
export const BillingDeleteTaskDocument = gql`
    mutation BillingDeleteTask($taskId: Int!) {
  deleteTask(where: {taskId: $taskId}) {
    deletedTasks {
      id
    }
    blockedTasks {
      id
      currentTrackingUsers {
        id
        name
        surname
        profilePic
      }
    }
  }
}
    `;
export type BillingDeleteTaskMutationFn = ApolloReactCommon.MutationFunction<BillingDeleteTaskMutation, BillingDeleteTaskMutationVariables>;
export type BillingDeleteTaskComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<BillingDeleteTaskMutation, BillingDeleteTaskMutationVariables>, 'mutation'>;

    export const BillingDeleteTaskComponent = (props: BillingDeleteTaskComponentProps) => (
      <ApolloReactComponents.Mutation<BillingDeleteTaskMutation, BillingDeleteTaskMutationVariables> mutation={BillingDeleteTaskDocument} {...props} />
    );
    
export type BillingDeleteTaskProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<BillingDeleteTaskMutation, BillingDeleteTaskMutationVariables>
    } & TChildProps;
export function withBillingDeleteTask<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BillingDeleteTaskMutation,
  BillingDeleteTaskMutationVariables,
  BillingDeleteTaskProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, BillingDeleteTaskMutation, BillingDeleteTaskMutationVariables, BillingDeleteTaskProps<TChildProps, TDataName>>(BillingDeleteTaskDocument, {
      alias: 'billingDeleteTask',
      ...operationOptions
    });
};

/**
 * __useBillingDeleteTaskMutation__
 *
 * To run a mutation, you first call `useBillingDeleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillingDeleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billingDeleteTaskMutation, { data, loading, error }] = useBillingDeleteTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useBillingDeleteTaskMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BillingDeleteTaskMutation, BillingDeleteTaskMutationVariables>) {
        return ApolloReactHooks.useMutation<BillingDeleteTaskMutation, BillingDeleteTaskMutationVariables>(BillingDeleteTaskDocument, baseOptions);
      }
export type BillingDeleteTaskMutationHookResult = ReturnType<typeof useBillingDeleteTaskMutation>;
export type BillingDeleteTaskMutationResult = ApolloReactCommon.MutationResult<BillingDeleteTaskMutation>;
export type BillingDeleteTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<BillingDeleteTaskMutation, BillingDeleteTaskMutationVariables>;
export const BillingTaskSidebarUpdateTaskCompletedDocument = gql`
    mutation BillingTaskSidebarUpdateTaskCompleted($id: Int!, $completed: Boolean!) {
  updateTaskCompleted(where: {id: $id, completed: $completed}) {
    updatedTasks {
      id
      completed
    }
    blockedTasks {
      id
      currentTrackingUsers {
        ...BillingTaskSidebarTaskUser
      }
    }
  }
}
    ${BillingTaskSidebarTaskUserFragmentDoc}`;
export type BillingTaskSidebarUpdateTaskCompletedMutationFn = ApolloReactCommon.MutationFunction<BillingTaskSidebarUpdateTaskCompletedMutation, BillingTaskSidebarUpdateTaskCompletedMutationVariables>;
export type BillingTaskSidebarUpdateTaskCompletedComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<BillingTaskSidebarUpdateTaskCompletedMutation, BillingTaskSidebarUpdateTaskCompletedMutationVariables>, 'mutation'>;

    export const BillingTaskSidebarUpdateTaskCompletedComponent = (props: BillingTaskSidebarUpdateTaskCompletedComponentProps) => (
      <ApolloReactComponents.Mutation<BillingTaskSidebarUpdateTaskCompletedMutation, BillingTaskSidebarUpdateTaskCompletedMutationVariables> mutation={BillingTaskSidebarUpdateTaskCompletedDocument} {...props} />
    );
    
export type BillingTaskSidebarUpdateTaskCompletedProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<BillingTaskSidebarUpdateTaskCompletedMutation, BillingTaskSidebarUpdateTaskCompletedMutationVariables>
    } & TChildProps;
export function withBillingTaskSidebarUpdateTaskCompleted<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BillingTaskSidebarUpdateTaskCompletedMutation,
  BillingTaskSidebarUpdateTaskCompletedMutationVariables,
  BillingTaskSidebarUpdateTaskCompletedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, BillingTaskSidebarUpdateTaskCompletedMutation, BillingTaskSidebarUpdateTaskCompletedMutationVariables, BillingTaskSidebarUpdateTaskCompletedProps<TChildProps, TDataName>>(BillingTaskSidebarUpdateTaskCompletedDocument, {
      alias: 'billingTaskSidebarUpdateTaskCompleted',
      ...operationOptions
    });
};

/**
 * __useBillingTaskSidebarUpdateTaskCompletedMutation__
 *
 * To run a mutation, you first call `useBillingTaskSidebarUpdateTaskCompletedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillingTaskSidebarUpdateTaskCompletedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billingTaskSidebarUpdateTaskCompletedMutation, { data, loading, error }] = useBillingTaskSidebarUpdateTaskCompletedMutation({
 *   variables: {
 *      id: // value for 'id'
 *      completed: // value for 'completed'
 *   },
 * });
 */
export function useBillingTaskSidebarUpdateTaskCompletedMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BillingTaskSidebarUpdateTaskCompletedMutation, BillingTaskSidebarUpdateTaskCompletedMutationVariables>) {
        return ApolloReactHooks.useMutation<BillingTaskSidebarUpdateTaskCompletedMutation, BillingTaskSidebarUpdateTaskCompletedMutationVariables>(BillingTaskSidebarUpdateTaskCompletedDocument, baseOptions);
      }
export type BillingTaskSidebarUpdateTaskCompletedMutationHookResult = ReturnType<typeof useBillingTaskSidebarUpdateTaskCompletedMutation>;
export type BillingTaskSidebarUpdateTaskCompletedMutationResult = ApolloReactCommon.MutationResult<BillingTaskSidebarUpdateTaskCompletedMutation>;
export type BillingTaskSidebarUpdateTaskCompletedMutationOptions = ApolloReactCommon.BaseMutationOptions<BillingTaskSidebarUpdateTaskCompletedMutation, BillingTaskSidebarUpdateTaskCompletedMutationVariables>;
export const BillingTaskSidebarUpdateTaskDocument = gql`
    mutation BillingTaskSidebarUpdateTask($id: Int!, $name: String, $description: String, $prioId: Int, $deadline: Int, $timeBudget: Int, $taskCategory: Int, $priceCalcMethod: PriceCalcMethodType, $customHourlyWorkRate: Float, $timeBudgetViewUnit: String, $flatRatePrice: Float, $archived: Boolean, $isNotCharged: Boolean) {
  updateTask(where: {id: $id, name: $name, description: $description, prioId: $prioId, deadline: $deadline, timeBudget: $timeBudget, taskCategory: $taskCategory, priceCalcMethod: $priceCalcMethod, customHourlyWorkRate: $customHourlyWorkRate, timeBudgetViewUnit: $timeBudgetViewUnit, flatRatePrice: $flatRatePrice, archived: $archived, isNotCharged: $isNotCharged}) {
    id
    name
    description
    deadline
    timeBudget
    priceCalcMethod
    timeBudgetViewUnit
    hourlyWorkRate
    prio {
      id
    }
    taskCategory
    flatRatePrice
    effectiveHourlyWorkRate
    price {
      adjusted
      original
    }
    archived
    isNotCharged
  }
}
    `;
export type BillingTaskSidebarUpdateTaskMutationFn = ApolloReactCommon.MutationFunction<BillingTaskSidebarUpdateTaskMutation, BillingTaskSidebarUpdateTaskMutationVariables>;
export type BillingTaskSidebarUpdateTaskComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<BillingTaskSidebarUpdateTaskMutation, BillingTaskSidebarUpdateTaskMutationVariables>, 'mutation'>;

    export const BillingTaskSidebarUpdateTaskComponent = (props: BillingTaskSidebarUpdateTaskComponentProps) => (
      <ApolloReactComponents.Mutation<BillingTaskSidebarUpdateTaskMutation, BillingTaskSidebarUpdateTaskMutationVariables> mutation={BillingTaskSidebarUpdateTaskDocument} {...props} />
    );
    
export type BillingTaskSidebarUpdateTaskProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<BillingTaskSidebarUpdateTaskMutation, BillingTaskSidebarUpdateTaskMutationVariables>
    } & TChildProps;
export function withBillingTaskSidebarUpdateTask<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BillingTaskSidebarUpdateTaskMutation,
  BillingTaskSidebarUpdateTaskMutationVariables,
  BillingTaskSidebarUpdateTaskProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, BillingTaskSidebarUpdateTaskMutation, BillingTaskSidebarUpdateTaskMutationVariables, BillingTaskSidebarUpdateTaskProps<TChildProps, TDataName>>(BillingTaskSidebarUpdateTaskDocument, {
      alias: 'billingTaskSidebarUpdateTask',
      ...operationOptions
    });
};

/**
 * __useBillingTaskSidebarUpdateTaskMutation__
 *
 * To run a mutation, you first call `useBillingTaskSidebarUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillingTaskSidebarUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billingTaskSidebarUpdateTaskMutation, { data, loading, error }] = useBillingTaskSidebarUpdateTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      prioId: // value for 'prioId'
 *      deadline: // value for 'deadline'
 *      timeBudget: // value for 'timeBudget'
 *      taskCategory: // value for 'taskCategory'
 *      priceCalcMethod: // value for 'priceCalcMethod'
 *      customHourlyWorkRate: // value for 'customHourlyWorkRate'
 *      timeBudgetViewUnit: // value for 'timeBudgetViewUnit'
 *      flatRatePrice: // value for 'flatRatePrice'
 *      archived: // value for 'archived'
 *      isNotCharged: // value for 'isNotCharged'
 *   },
 * });
 */
export function useBillingTaskSidebarUpdateTaskMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BillingTaskSidebarUpdateTaskMutation, BillingTaskSidebarUpdateTaskMutationVariables>) {
        return ApolloReactHooks.useMutation<BillingTaskSidebarUpdateTaskMutation, BillingTaskSidebarUpdateTaskMutationVariables>(BillingTaskSidebarUpdateTaskDocument, baseOptions);
      }
export type BillingTaskSidebarUpdateTaskMutationHookResult = ReturnType<typeof useBillingTaskSidebarUpdateTaskMutation>;
export type BillingTaskSidebarUpdateTaskMutationResult = ApolloReactCommon.MutationResult<BillingTaskSidebarUpdateTaskMutation>;
export type BillingTaskSidebarUpdateTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<BillingTaskSidebarUpdateTaskMutation, BillingTaskSidebarUpdateTaskMutationVariables>;
export const BillingTaskSidebarArchiveOfferPositionsDocument = gql`
    mutation BillingTaskSidebarArchiveOfferPositions($offerId: Int!, $taskId: Int, $rateId: Int) {
  archiveOfferPositions(where: {offerId: $offerId, taskId: $taskId, rateId: $rateId}) {
    id
  }
}
    `;
export type BillingTaskSidebarArchiveOfferPositionsMutationFn = ApolloReactCommon.MutationFunction<BillingTaskSidebarArchiveOfferPositionsMutation, BillingTaskSidebarArchiveOfferPositionsMutationVariables>;
export type BillingTaskSidebarArchiveOfferPositionsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<BillingTaskSidebarArchiveOfferPositionsMutation, BillingTaskSidebarArchiveOfferPositionsMutationVariables>, 'mutation'>;

    export const BillingTaskSidebarArchiveOfferPositionsComponent = (props: BillingTaskSidebarArchiveOfferPositionsComponentProps) => (
      <ApolloReactComponents.Mutation<BillingTaskSidebarArchiveOfferPositionsMutation, BillingTaskSidebarArchiveOfferPositionsMutationVariables> mutation={BillingTaskSidebarArchiveOfferPositionsDocument} {...props} />
    );
    
export type BillingTaskSidebarArchiveOfferPositionsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<BillingTaskSidebarArchiveOfferPositionsMutation, BillingTaskSidebarArchiveOfferPositionsMutationVariables>
    } & TChildProps;
export function withBillingTaskSidebarArchiveOfferPositions<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BillingTaskSidebarArchiveOfferPositionsMutation,
  BillingTaskSidebarArchiveOfferPositionsMutationVariables,
  BillingTaskSidebarArchiveOfferPositionsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, BillingTaskSidebarArchiveOfferPositionsMutation, BillingTaskSidebarArchiveOfferPositionsMutationVariables, BillingTaskSidebarArchiveOfferPositionsProps<TChildProps, TDataName>>(BillingTaskSidebarArchiveOfferPositionsDocument, {
      alias: 'billingTaskSidebarArchiveOfferPositions',
      ...operationOptions
    });
};

/**
 * __useBillingTaskSidebarArchiveOfferPositionsMutation__
 *
 * To run a mutation, you first call `useBillingTaskSidebarArchiveOfferPositionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillingTaskSidebarArchiveOfferPositionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billingTaskSidebarArchiveOfferPositionsMutation, { data, loading, error }] = useBillingTaskSidebarArchiveOfferPositionsMutation({
 *   variables: {
 *      offerId: // value for 'offerId'
 *      taskId: // value for 'taskId'
 *      rateId: // value for 'rateId'
 *   },
 * });
 */
export function useBillingTaskSidebarArchiveOfferPositionsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BillingTaskSidebarArchiveOfferPositionsMutation, BillingTaskSidebarArchiveOfferPositionsMutationVariables>) {
        return ApolloReactHooks.useMutation<BillingTaskSidebarArchiveOfferPositionsMutation, BillingTaskSidebarArchiveOfferPositionsMutationVariables>(BillingTaskSidebarArchiveOfferPositionsDocument, baseOptions);
      }
export type BillingTaskSidebarArchiveOfferPositionsMutationHookResult = ReturnType<typeof useBillingTaskSidebarArchiveOfferPositionsMutation>;
export type BillingTaskSidebarArchiveOfferPositionsMutationResult = ApolloReactCommon.MutationResult<BillingTaskSidebarArchiveOfferPositionsMutation>;
export type BillingTaskSidebarArchiveOfferPositionsMutationOptions = ApolloReactCommon.BaseMutationOptions<BillingTaskSidebarArchiveOfferPositionsMutation, BillingTaskSidebarArchiveOfferPositionsMutationVariables>;
export const BillingTasksidebarEditTimeTrackingDocument = gql`
    mutation BillingTasksidebarEditTimeTracking($workloadId: Int!, $start: Date!, $end: Date!) {
  updateTimeTracking(where: {workloadId: $workloadId, start: $start, end: $end}) {
    workloads {
      id
      start
      end
      taskId
    }
    tasks {
      id
      workedTime
      effectiveHourlyWorkRate
      price {
        original
        adjusted
      }
      currentWork {
        id
        start
        end
        userId
      }
    }
  }
}
    `;
export type BillingTasksidebarEditTimeTrackingMutationFn = ApolloReactCommon.MutationFunction<BillingTasksidebarEditTimeTrackingMutation, BillingTasksidebarEditTimeTrackingMutationVariables>;
export type BillingTasksidebarEditTimeTrackingComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<BillingTasksidebarEditTimeTrackingMutation, BillingTasksidebarEditTimeTrackingMutationVariables>, 'mutation'>;

    export const BillingTasksidebarEditTimeTrackingComponent = (props: BillingTasksidebarEditTimeTrackingComponentProps) => (
      <ApolloReactComponents.Mutation<BillingTasksidebarEditTimeTrackingMutation, BillingTasksidebarEditTimeTrackingMutationVariables> mutation={BillingTasksidebarEditTimeTrackingDocument} {...props} />
    );
    
export type BillingTasksidebarEditTimeTrackingProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<BillingTasksidebarEditTimeTrackingMutation, BillingTasksidebarEditTimeTrackingMutationVariables>
    } & TChildProps;
export function withBillingTasksidebarEditTimeTracking<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BillingTasksidebarEditTimeTrackingMutation,
  BillingTasksidebarEditTimeTrackingMutationVariables,
  BillingTasksidebarEditTimeTrackingProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, BillingTasksidebarEditTimeTrackingMutation, BillingTasksidebarEditTimeTrackingMutationVariables, BillingTasksidebarEditTimeTrackingProps<TChildProps, TDataName>>(BillingTasksidebarEditTimeTrackingDocument, {
      alias: 'billingTasksidebarEditTimeTracking',
      ...operationOptions
    });
};

/**
 * __useBillingTasksidebarEditTimeTrackingMutation__
 *
 * To run a mutation, you first call `useBillingTasksidebarEditTimeTrackingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillingTasksidebarEditTimeTrackingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billingTasksidebarEditTimeTrackingMutation, { data, loading, error }] = useBillingTasksidebarEditTimeTrackingMutation({
 *   variables: {
 *      workloadId: // value for 'workloadId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useBillingTasksidebarEditTimeTrackingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BillingTasksidebarEditTimeTrackingMutation, BillingTasksidebarEditTimeTrackingMutationVariables>) {
        return ApolloReactHooks.useMutation<BillingTasksidebarEditTimeTrackingMutation, BillingTasksidebarEditTimeTrackingMutationVariables>(BillingTasksidebarEditTimeTrackingDocument, baseOptions);
      }
export type BillingTasksidebarEditTimeTrackingMutationHookResult = ReturnType<typeof useBillingTasksidebarEditTimeTrackingMutation>;
export type BillingTasksidebarEditTimeTrackingMutationResult = ApolloReactCommon.MutationResult<BillingTasksidebarEditTimeTrackingMutation>;
export type BillingTasksidebarEditTimeTrackingMutationOptions = ApolloReactCommon.BaseMutationOptions<BillingTasksidebarEditTimeTrackingMutation, BillingTasksidebarEditTimeTrackingMutationVariables>;
export const BillingTasksidebarAddDiscountDocument = gql`
    mutation BillingTasksidebarAddDiscount($value: Float!, $unit: Charge!, $productID: Int!) {
  addDiscount(where: {value: $value, unit: $unit, productID: $productID}) {
    id
    name
    description
    deadline
    timeBudget
    priceCalcMethod
    timeBudgetViewUnit
    hourlyWorkRate
    effectiveHourlyWorkRate
    price {
      original
      adjusted
    }
    prio {
      id
    }
    taskCategory
    discount {
      id
      value
      unit
    }
  }
}
    `;
export type BillingTasksidebarAddDiscountMutationFn = ApolloReactCommon.MutationFunction<BillingTasksidebarAddDiscountMutation, BillingTasksidebarAddDiscountMutationVariables>;
export type BillingTasksidebarAddDiscountComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<BillingTasksidebarAddDiscountMutation, BillingTasksidebarAddDiscountMutationVariables>, 'mutation'>;

    export const BillingTasksidebarAddDiscountComponent = (props: BillingTasksidebarAddDiscountComponentProps) => (
      <ApolloReactComponents.Mutation<BillingTasksidebarAddDiscountMutation, BillingTasksidebarAddDiscountMutationVariables> mutation={BillingTasksidebarAddDiscountDocument} {...props} />
    );
    
export type BillingTasksidebarAddDiscountProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<BillingTasksidebarAddDiscountMutation, BillingTasksidebarAddDiscountMutationVariables>
    } & TChildProps;
export function withBillingTasksidebarAddDiscount<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BillingTasksidebarAddDiscountMutation,
  BillingTasksidebarAddDiscountMutationVariables,
  BillingTasksidebarAddDiscountProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, BillingTasksidebarAddDiscountMutation, BillingTasksidebarAddDiscountMutationVariables, BillingTasksidebarAddDiscountProps<TChildProps, TDataName>>(BillingTasksidebarAddDiscountDocument, {
      alias: 'billingTasksidebarAddDiscount',
      ...operationOptions
    });
};

/**
 * __useBillingTasksidebarAddDiscountMutation__
 *
 * To run a mutation, you first call `useBillingTasksidebarAddDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillingTasksidebarAddDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billingTasksidebarAddDiscountMutation, { data, loading, error }] = useBillingTasksidebarAddDiscountMutation({
 *   variables: {
 *      value: // value for 'value'
 *      unit: // value for 'unit'
 *      productID: // value for 'productID'
 *   },
 * });
 */
export function useBillingTasksidebarAddDiscountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BillingTasksidebarAddDiscountMutation, BillingTasksidebarAddDiscountMutationVariables>) {
        return ApolloReactHooks.useMutation<BillingTasksidebarAddDiscountMutation, BillingTasksidebarAddDiscountMutationVariables>(BillingTasksidebarAddDiscountDocument, baseOptions);
      }
export type BillingTasksidebarAddDiscountMutationHookResult = ReturnType<typeof useBillingTasksidebarAddDiscountMutation>;
export type BillingTasksidebarAddDiscountMutationResult = ApolloReactCommon.MutationResult<BillingTasksidebarAddDiscountMutation>;
export type BillingTasksidebarAddDiscountMutationOptions = ApolloReactCommon.BaseMutationOptions<BillingTasksidebarAddDiscountMutation, BillingTasksidebarAddDiscountMutationVariables>;
export const BillingTasksidebarUpdateDiscountDocument = gql`
    mutation BillingTasksidebarUpdateDiscount($id: Int!, $value: Float!, $unit: Charge!, $productID: Int!) {
  updateDiscount(where: {id: $id, value: $value, unit: $unit, productID: $productID}) {
    id
    name
    description
    deadline
    timeBudget
    priceCalcMethod
    timeBudgetViewUnit
    hourlyWorkRate
    effectiveHourlyWorkRate
    price {
      original
      adjusted
    }
    prio {
      id
    }
    taskCategory
    discount {
      id
      value
      unit
    }
  }
}
    `;
export type BillingTasksidebarUpdateDiscountMutationFn = ApolloReactCommon.MutationFunction<BillingTasksidebarUpdateDiscountMutation, BillingTasksidebarUpdateDiscountMutationVariables>;
export type BillingTasksidebarUpdateDiscountComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<BillingTasksidebarUpdateDiscountMutation, BillingTasksidebarUpdateDiscountMutationVariables>, 'mutation'>;

    export const BillingTasksidebarUpdateDiscountComponent = (props: BillingTasksidebarUpdateDiscountComponentProps) => (
      <ApolloReactComponents.Mutation<BillingTasksidebarUpdateDiscountMutation, BillingTasksidebarUpdateDiscountMutationVariables> mutation={BillingTasksidebarUpdateDiscountDocument} {...props} />
    );
    
export type BillingTasksidebarUpdateDiscountProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<BillingTasksidebarUpdateDiscountMutation, BillingTasksidebarUpdateDiscountMutationVariables>
    } & TChildProps;
export function withBillingTasksidebarUpdateDiscount<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BillingTasksidebarUpdateDiscountMutation,
  BillingTasksidebarUpdateDiscountMutationVariables,
  BillingTasksidebarUpdateDiscountProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, BillingTasksidebarUpdateDiscountMutation, BillingTasksidebarUpdateDiscountMutationVariables, BillingTasksidebarUpdateDiscountProps<TChildProps, TDataName>>(BillingTasksidebarUpdateDiscountDocument, {
      alias: 'billingTasksidebarUpdateDiscount',
      ...operationOptions
    });
};

/**
 * __useBillingTasksidebarUpdateDiscountMutation__
 *
 * To run a mutation, you first call `useBillingTasksidebarUpdateDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillingTasksidebarUpdateDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billingTasksidebarUpdateDiscountMutation, { data, loading, error }] = useBillingTasksidebarUpdateDiscountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      value: // value for 'value'
 *      unit: // value for 'unit'
 *      productID: // value for 'productID'
 *   },
 * });
 */
export function useBillingTasksidebarUpdateDiscountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BillingTasksidebarUpdateDiscountMutation, BillingTasksidebarUpdateDiscountMutationVariables>) {
        return ApolloReactHooks.useMutation<BillingTasksidebarUpdateDiscountMutation, BillingTasksidebarUpdateDiscountMutationVariables>(BillingTasksidebarUpdateDiscountDocument, baseOptions);
      }
export type BillingTasksidebarUpdateDiscountMutationHookResult = ReturnType<typeof useBillingTasksidebarUpdateDiscountMutation>;
export type BillingTasksidebarUpdateDiscountMutationResult = ApolloReactCommon.MutationResult<BillingTasksidebarUpdateDiscountMutation>;
export type BillingTasksidebarUpdateDiscountMutationOptions = ApolloReactCommon.BaseMutationOptions<BillingTasksidebarUpdateDiscountMutation, BillingTasksidebarUpdateDiscountMutationVariables>;
export const BillingTasksidebarDeleteDiscountDocument = gql`
    mutation BillingTasksidebarDeleteDiscount($id: Int!) {
  deleteDiscount(where: {id: $id}) {
    id
    name
    description
    deadline
    timeBudget
    priceCalcMethod
    timeBudgetViewUnit
    hourlyWorkRate
    effectiveHourlyWorkRate
    price {
      original
      adjusted
    }
    prio {
      id
    }
    taskCategory
    discount {
      id
      value
      unit
    }
  }
}
    `;
export type BillingTasksidebarDeleteDiscountMutationFn = ApolloReactCommon.MutationFunction<BillingTasksidebarDeleteDiscountMutation, BillingTasksidebarDeleteDiscountMutationVariables>;
export type BillingTasksidebarDeleteDiscountComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<BillingTasksidebarDeleteDiscountMutation, BillingTasksidebarDeleteDiscountMutationVariables>, 'mutation'>;

    export const BillingTasksidebarDeleteDiscountComponent = (props: BillingTasksidebarDeleteDiscountComponentProps) => (
      <ApolloReactComponents.Mutation<BillingTasksidebarDeleteDiscountMutation, BillingTasksidebarDeleteDiscountMutationVariables> mutation={BillingTasksidebarDeleteDiscountDocument} {...props} />
    );
    
export type BillingTasksidebarDeleteDiscountProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<BillingTasksidebarDeleteDiscountMutation, BillingTasksidebarDeleteDiscountMutationVariables>
    } & TChildProps;
export function withBillingTasksidebarDeleteDiscount<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BillingTasksidebarDeleteDiscountMutation,
  BillingTasksidebarDeleteDiscountMutationVariables,
  BillingTasksidebarDeleteDiscountProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, BillingTasksidebarDeleteDiscountMutation, BillingTasksidebarDeleteDiscountMutationVariables, BillingTasksidebarDeleteDiscountProps<TChildProps, TDataName>>(BillingTasksidebarDeleteDiscountDocument, {
      alias: 'billingTasksidebarDeleteDiscount',
      ...operationOptions
    });
};

/**
 * __useBillingTasksidebarDeleteDiscountMutation__
 *
 * To run a mutation, you first call `useBillingTasksidebarDeleteDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillingTasksidebarDeleteDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billingTasksidebarDeleteDiscountMutation, { data, loading, error }] = useBillingTasksidebarDeleteDiscountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBillingTasksidebarDeleteDiscountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BillingTasksidebarDeleteDiscountMutation, BillingTasksidebarDeleteDiscountMutationVariables>) {
        return ApolloReactHooks.useMutation<BillingTasksidebarDeleteDiscountMutation, BillingTasksidebarDeleteDiscountMutationVariables>(BillingTasksidebarDeleteDiscountDocument, baseOptions);
      }
export type BillingTasksidebarDeleteDiscountMutationHookResult = ReturnType<typeof useBillingTasksidebarDeleteDiscountMutation>;
export type BillingTasksidebarDeleteDiscountMutationResult = ApolloReactCommon.MutationResult<BillingTasksidebarDeleteDiscountMutation>;
export type BillingTasksidebarDeleteDiscountMutationOptions = ApolloReactCommon.BaseMutationOptions<BillingTasksidebarDeleteDiscountMutation, BillingTasksidebarDeleteDiscountMutationVariables>;
export const BillingTasksideAssignUserDocument = gql`
    mutation BillingTasksideAssignUser($userId: Int!, $taskId: Int!) {
  assignUserToTask(where: {userId: $userId, taskId: $taskId}) {
    id
    assignedUsers {
      id
      name
      surname
      profilePic
    }
  }
}
    `;
export type BillingTasksideAssignUserMutationFn = ApolloReactCommon.MutationFunction<BillingTasksideAssignUserMutation, BillingTasksideAssignUserMutationVariables>;
export type BillingTasksideAssignUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<BillingTasksideAssignUserMutation, BillingTasksideAssignUserMutationVariables>, 'mutation'>;

    export const BillingTasksideAssignUserComponent = (props: BillingTasksideAssignUserComponentProps) => (
      <ApolloReactComponents.Mutation<BillingTasksideAssignUserMutation, BillingTasksideAssignUserMutationVariables> mutation={BillingTasksideAssignUserDocument} {...props} />
    );
    
export type BillingTasksideAssignUserProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<BillingTasksideAssignUserMutation, BillingTasksideAssignUserMutationVariables>
    } & TChildProps;
export function withBillingTasksideAssignUser<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BillingTasksideAssignUserMutation,
  BillingTasksideAssignUserMutationVariables,
  BillingTasksideAssignUserProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, BillingTasksideAssignUserMutation, BillingTasksideAssignUserMutationVariables, BillingTasksideAssignUserProps<TChildProps, TDataName>>(BillingTasksideAssignUserDocument, {
      alias: 'billingTasksideAssignUser',
      ...operationOptions
    });
};

/**
 * __useBillingTasksideAssignUserMutation__
 *
 * To run a mutation, you first call `useBillingTasksideAssignUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillingTasksideAssignUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billingTasksideAssignUserMutation, { data, loading, error }] = useBillingTasksideAssignUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useBillingTasksideAssignUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BillingTasksideAssignUserMutation, BillingTasksideAssignUserMutationVariables>) {
        return ApolloReactHooks.useMutation<BillingTasksideAssignUserMutation, BillingTasksideAssignUserMutationVariables>(BillingTasksideAssignUserDocument, baseOptions);
      }
export type BillingTasksideAssignUserMutationHookResult = ReturnType<typeof useBillingTasksideAssignUserMutation>;
export type BillingTasksideAssignUserMutationResult = ApolloReactCommon.MutationResult<BillingTasksideAssignUserMutation>;
export type BillingTasksideAssignUserMutationOptions = ApolloReactCommon.BaseMutationOptions<BillingTasksideAssignUserMutation, BillingTasksideAssignUserMutationVariables>;
export const BillingTasksideUnassignUserDocument = gql`
    mutation BillingTasksideUnassignUser($userId: Int!, $taskId: Int!) {
  unassignUserFromTask(where: {userId: $userId, taskId: $taskId}) {
    id
    assignedUsers {
      id
    }
  }
}
    `;
export type BillingTasksideUnassignUserMutationFn = ApolloReactCommon.MutationFunction<BillingTasksideUnassignUserMutation, BillingTasksideUnassignUserMutationVariables>;
export type BillingTasksideUnassignUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<BillingTasksideUnassignUserMutation, BillingTasksideUnassignUserMutationVariables>, 'mutation'>;

    export const BillingTasksideUnassignUserComponent = (props: BillingTasksideUnassignUserComponentProps) => (
      <ApolloReactComponents.Mutation<BillingTasksideUnassignUserMutation, BillingTasksideUnassignUserMutationVariables> mutation={BillingTasksideUnassignUserDocument} {...props} />
    );
    
export type BillingTasksideUnassignUserProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<BillingTasksideUnassignUserMutation, BillingTasksideUnassignUserMutationVariables>
    } & TChildProps;
export function withBillingTasksideUnassignUser<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BillingTasksideUnassignUserMutation,
  BillingTasksideUnassignUserMutationVariables,
  BillingTasksideUnassignUserProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, BillingTasksideUnassignUserMutation, BillingTasksideUnassignUserMutationVariables, BillingTasksideUnassignUserProps<TChildProps, TDataName>>(BillingTasksideUnassignUserDocument, {
      alias: 'billingTasksideUnassignUser',
      ...operationOptions
    });
};

/**
 * __useBillingTasksideUnassignUserMutation__
 *
 * To run a mutation, you first call `useBillingTasksideUnassignUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillingTasksideUnassignUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billingTasksideUnassignUserMutation, { data, loading, error }] = useBillingTasksideUnassignUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useBillingTasksideUnassignUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BillingTasksideUnassignUserMutation, BillingTasksideUnassignUserMutationVariables>) {
        return ApolloReactHooks.useMutation<BillingTasksideUnassignUserMutation, BillingTasksideUnassignUserMutationVariables>(BillingTasksideUnassignUserDocument, baseOptions);
      }
export type BillingTasksideUnassignUserMutationHookResult = ReturnType<typeof useBillingTasksideUnassignUserMutation>;
export type BillingTasksideUnassignUserMutationResult = ApolloReactCommon.MutationResult<BillingTasksideUnassignUserMutation>;
export type BillingTasksideUnassignUserMutationOptions = ApolloReactCommon.BaseMutationOptions<BillingTasksideUnassignUserMutation, BillingTasksideUnassignUserMutationVariables>;
export const BillingTasksideDeleteTimeTrackingDocument = gql`
    mutation BillingTasksideDeleteTimeTracking($workloadId: Int!) {
  deleteTimeTracking(where: {workloadId: $workloadId}) {
    workloads {
      id
      start
      end
      taskId
    }
    tasks {
      id
      workedTime
      effectiveHourlyWorkRate
      price {
        original
        adjusted
      }
      currentWork {
        id
        start
        end
        userId
      }
    }
  }
}
    `;
export type BillingTasksideDeleteTimeTrackingMutationFn = ApolloReactCommon.MutationFunction<BillingTasksideDeleteTimeTrackingMutation, BillingTasksideDeleteTimeTrackingMutationVariables>;
export type BillingTasksideDeleteTimeTrackingComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<BillingTasksideDeleteTimeTrackingMutation, BillingTasksideDeleteTimeTrackingMutationVariables>, 'mutation'>;

    export const BillingTasksideDeleteTimeTrackingComponent = (props: BillingTasksideDeleteTimeTrackingComponentProps) => (
      <ApolloReactComponents.Mutation<BillingTasksideDeleteTimeTrackingMutation, BillingTasksideDeleteTimeTrackingMutationVariables> mutation={BillingTasksideDeleteTimeTrackingDocument} {...props} />
    );
    
export type BillingTasksideDeleteTimeTrackingProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<BillingTasksideDeleteTimeTrackingMutation, BillingTasksideDeleteTimeTrackingMutationVariables>
    } & TChildProps;
export function withBillingTasksideDeleteTimeTracking<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BillingTasksideDeleteTimeTrackingMutation,
  BillingTasksideDeleteTimeTrackingMutationVariables,
  BillingTasksideDeleteTimeTrackingProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, BillingTasksideDeleteTimeTrackingMutation, BillingTasksideDeleteTimeTrackingMutationVariables, BillingTasksideDeleteTimeTrackingProps<TChildProps, TDataName>>(BillingTasksideDeleteTimeTrackingDocument, {
      alias: 'billingTasksideDeleteTimeTracking',
      ...operationOptions
    });
};

/**
 * __useBillingTasksideDeleteTimeTrackingMutation__
 *
 * To run a mutation, you first call `useBillingTasksideDeleteTimeTrackingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillingTasksideDeleteTimeTrackingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billingTasksideDeleteTimeTrackingMutation, { data, loading, error }] = useBillingTasksideDeleteTimeTrackingMutation({
 *   variables: {
 *      workloadId: // value for 'workloadId'
 *   },
 * });
 */
export function useBillingTasksideDeleteTimeTrackingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BillingTasksideDeleteTimeTrackingMutation, BillingTasksideDeleteTimeTrackingMutationVariables>) {
        return ApolloReactHooks.useMutation<BillingTasksideDeleteTimeTrackingMutation, BillingTasksideDeleteTimeTrackingMutationVariables>(BillingTasksideDeleteTimeTrackingDocument, baseOptions);
      }
export type BillingTasksideDeleteTimeTrackingMutationHookResult = ReturnType<typeof useBillingTasksideDeleteTimeTrackingMutation>;
export type BillingTasksideDeleteTimeTrackingMutationResult = ApolloReactCommon.MutationResult<BillingTasksideDeleteTimeTrackingMutation>;
export type BillingTasksideDeleteTimeTrackingMutationOptions = ApolloReactCommon.BaseMutationOptions<BillingTasksideDeleteTimeTrackingMutation, BillingTasksideDeleteTimeTrackingMutationVariables>;
export const BillingTasksideDeleteTaskDocument = gql`
    mutation BillingTasksideDeleteTask($taskId: Int!) {
  deleteTask(where: {taskId: $taskId}) {
    deletedTasks {
      id
    }
    blockedTasks {
      id
      currentTrackingUsers {
        id
        name
        surname
        profilePic
      }
    }
  }
}
    `;
export type BillingTasksideDeleteTaskMutationFn = ApolloReactCommon.MutationFunction<BillingTasksideDeleteTaskMutation, BillingTasksideDeleteTaskMutationVariables>;
export type BillingTasksideDeleteTaskComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<BillingTasksideDeleteTaskMutation, BillingTasksideDeleteTaskMutationVariables>, 'mutation'>;

    export const BillingTasksideDeleteTaskComponent = (props: BillingTasksideDeleteTaskComponentProps) => (
      <ApolloReactComponents.Mutation<BillingTasksideDeleteTaskMutation, BillingTasksideDeleteTaskMutationVariables> mutation={BillingTasksideDeleteTaskDocument} {...props} />
    );
    
export type BillingTasksideDeleteTaskProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<BillingTasksideDeleteTaskMutation, BillingTasksideDeleteTaskMutationVariables>
    } & TChildProps;
export function withBillingTasksideDeleteTask<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BillingTasksideDeleteTaskMutation,
  BillingTasksideDeleteTaskMutationVariables,
  BillingTasksideDeleteTaskProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, BillingTasksideDeleteTaskMutation, BillingTasksideDeleteTaskMutationVariables, BillingTasksideDeleteTaskProps<TChildProps, TDataName>>(BillingTasksideDeleteTaskDocument, {
      alias: 'billingTasksideDeleteTask',
      ...operationOptions
    });
};

/**
 * __useBillingTasksideDeleteTaskMutation__
 *
 * To run a mutation, you first call `useBillingTasksideDeleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillingTasksideDeleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billingTasksideDeleteTaskMutation, { data, loading, error }] = useBillingTasksideDeleteTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useBillingTasksideDeleteTaskMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BillingTasksideDeleteTaskMutation, BillingTasksideDeleteTaskMutationVariables>) {
        return ApolloReactHooks.useMutation<BillingTasksideDeleteTaskMutation, BillingTasksideDeleteTaskMutationVariables>(BillingTasksideDeleteTaskDocument, baseOptions);
      }
export type BillingTasksideDeleteTaskMutationHookResult = ReturnType<typeof useBillingTasksideDeleteTaskMutation>;
export type BillingTasksideDeleteTaskMutationResult = ApolloReactCommon.MutationResult<BillingTasksideDeleteTaskMutation>;
export type BillingTasksideDeleteTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<BillingTasksideDeleteTaskMutation, BillingTasksideDeleteTaskMutationVariables>;
export const BillingTaskSidebarTaskUpdatedDocument = gql`
    subscription BillingTaskSidebarTaskUpdated($taskId: Int!) {
  tasksUpdated(where: {taskIds: [$taskId]}) {
    ...BillingTaskSidebarTask
  }
}
    ${BillingTaskSidebarTaskFragmentDoc}`;
export type BillingTaskSidebarTaskUpdatedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<BillingTaskSidebarTaskUpdatedSubscription, BillingTaskSidebarTaskUpdatedSubscriptionVariables>, 'subscription'>;

    export const BillingTaskSidebarTaskUpdatedComponent = (props: BillingTaskSidebarTaskUpdatedComponentProps) => (
      <ApolloReactComponents.Subscription<BillingTaskSidebarTaskUpdatedSubscription, BillingTaskSidebarTaskUpdatedSubscriptionVariables> subscription={BillingTaskSidebarTaskUpdatedDocument} {...props} />
    );
    
export type BillingTaskSidebarTaskUpdatedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<BillingTaskSidebarTaskUpdatedSubscription, BillingTaskSidebarTaskUpdatedSubscriptionVariables>
    } & TChildProps;
export function withBillingTaskSidebarTaskUpdated<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BillingTaskSidebarTaskUpdatedSubscription,
  BillingTaskSidebarTaskUpdatedSubscriptionVariables,
  BillingTaskSidebarTaskUpdatedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, BillingTaskSidebarTaskUpdatedSubscription, BillingTaskSidebarTaskUpdatedSubscriptionVariables, BillingTaskSidebarTaskUpdatedProps<TChildProps, TDataName>>(BillingTaskSidebarTaskUpdatedDocument, {
      alias: 'billingTaskSidebarTaskUpdated',
      ...operationOptions
    });
};

/**
 * __useBillingTaskSidebarTaskUpdatedSubscription__
 *
 * To run a query within a React component, call `useBillingTaskSidebarTaskUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useBillingTaskSidebarTaskUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingTaskSidebarTaskUpdatedSubscription({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useBillingTaskSidebarTaskUpdatedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<BillingTaskSidebarTaskUpdatedSubscription, BillingTaskSidebarTaskUpdatedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<BillingTaskSidebarTaskUpdatedSubscription, BillingTaskSidebarTaskUpdatedSubscriptionVariables>(BillingTaskSidebarTaskUpdatedDocument, baseOptions);
      }
export type BillingTaskSidebarTaskUpdatedSubscriptionHookResult = ReturnType<typeof useBillingTaskSidebarTaskUpdatedSubscription>;
export type BillingTaskSidebarTaskUpdatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<BillingTaskSidebarTaskUpdatedSubscription>;
export const GetContactPersonsDocument = gql`
    query GetContactPersons($companyId: Int!, $searchString: String!) {
  contactPersons(where: {companyId: $companyId, searchString: $searchString}) {
    ...ContactPersonFragment
  }
}
    ${ContactPersonFragmentFragmentDoc}`;
export type GetContactPersonsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetContactPersonsQuery, GetContactPersonsQueryVariables>, 'query'> & ({ variables: GetContactPersonsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetContactPersonsComponent = (props: GetContactPersonsComponentProps) => (
      <ApolloReactComponents.Query<GetContactPersonsQuery, GetContactPersonsQueryVariables> query={GetContactPersonsDocument} {...props} />
    );
    
export type GetContactPersonsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetContactPersonsQuery, GetContactPersonsQueryVariables>
    } & TChildProps;
export function withGetContactPersons<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetContactPersonsQuery,
  GetContactPersonsQueryVariables,
  GetContactPersonsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetContactPersonsQuery, GetContactPersonsQueryVariables, GetContactPersonsProps<TChildProps, TDataName>>(GetContactPersonsDocument, {
      alias: 'getContactPersons',
      ...operationOptions
    });
};

/**
 * __useGetContactPersonsQuery__
 *
 * To run a query within a React component, call `useGetContactPersonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactPersonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactPersonsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      searchString: // value for 'searchString'
 *   },
 * });
 */
export function useGetContactPersonsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContactPersonsQuery, GetContactPersonsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContactPersonsQuery, GetContactPersonsQueryVariables>(GetContactPersonsDocument, baseOptions);
      }
export function useGetContactPersonsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContactPersonsQuery, GetContactPersonsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContactPersonsQuery, GetContactPersonsQueryVariables>(GetContactPersonsDocument, baseOptions);
        }
export type GetContactPersonsQueryHookResult = ReturnType<typeof useGetContactPersonsQuery>;
export type GetContactPersonsLazyQueryHookResult = ReturnType<typeof useGetContactPersonsLazyQuery>;
export type GetContactPersonsQueryResult = ApolloReactCommon.QueryResult<GetContactPersonsQuery, GetContactPersonsQueryVariables>;
export const AddContactPersonDocument = gql`
    mutation AddContactPerson($name: String!, $surname: String!, $title: String, $companyId: Int!, $picURL: String, $email: String!) {
  addContactPerson(where: {name: $name, surname: $surname, title: $title, companyId: $companyId, picURL: $picURL, email: $email}) {
    ...ContactPersonFragment
  }
}
    ${ContactPersonFragmentFragmentDoc}`;
export type AddContactPersonMutationFn = ApolloReactCommon.MutationFunction<AddContactPersonMutation, AddContactPersonMutationVariables>;
export type AddContactPersonComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddContactPersonMutation, AddContactPersonMutationVariables>, 'mutation'>;

    export const AddContactPersonComponent = (props: AddContactPersonComponentProps) => (
      <ApolloReactComponents.Mutation<AddContactPersonMutation, AddContactPersonMutationVariables> mutation={AddContactPersonDocument} {...props} />
    );
    
export type AddContactPersonProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AddContactPersonMutation, AddContactPersonMutationVariables>
    } & TChildProps;
export function withAddContactPerson<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddContactPersonMutation,
  AddContactPersonMutationVariables,
  AddContactPersonProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AddContactPersonMutation, AddContactPersonMutationVariables, AddContactPersonProps<TChildProps, TDataName>>(AddContactPersonDocument, {
      alias: 'addContactPerson',
      ...operationOptions
    });
};

/**
 * __useAddContactPersonMutation__
 *
 * To run a mutation, you first call `useAddContactPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddContactPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addContactPersonMutation, { data, loading, error }] = useAddContactPersonMutation({
 *   variables: {
 *      name: // value for 'name'
 *      surname: // value for 'surname'
 *      title: // value for 'title'
 *      companyId: // value for 'companyId'
 *      picURL: // value for 'picURL'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAddContactPersonMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddContactPersonMutation, AddContactPersonMutationVariables>) {
        return ApolloReactHooks.useMutation<AddContactPersonMutation, AddContactPersonMutationVariables>(AddContactPersonDocument, baseOptions);
      }
export type AddContactPersonMutationHookResult = ReturnType<typeof useAddContactPersonMutation>;
export type AddContactPersonMutationResult = ApolloReactCommon.MutationResult<AddContactPersonMutation>;
export type AddContactPersonMutationOptions = ApolloReactCommon.BaseMutationOptions<AddContactPersonMutation, AddContactPersonMutationVariables>;
export const ContactPersonAddedDocument = gql`
    subscription ContactPersonAdded($companyId: Int!) {
  contactPersonAdded(where: {companyId: $companyId}) {
    ...ContactPersonFragment
  }
}
    ${ContactPersonFragmentFragmentDoc}`;
export type ContactPersonAddedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<ContactPersonAddedSubscription, ContactPersonAddedSubscriptionVariables>, 'subscription'>;

    export const ContactPersonAddedComponent = (props: ContactPersonAddedComponentProps) => (
      <ApolloReactComponents.Subscription<ContactPersonAddedSubscription, ContactPersonAddedSubscriptionVariables> subscription={ContactPersonAddedDocument} {...props} />
    );
    
export type ContactPersonAddedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ContactPersonAddedSubscription, ContactPersonAddedSubscriptionVariables>
    } & TChildProps;
export function withContactPersonAdded<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ContactPersonAddedSubscription,
  ContactPersonAddedSubscriptionVariables,
  ContactPersonAddedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, ContactPersonAddedSubscription, ContactPersonAddedSubscriptionVariables, ContactPersonAddedProps<TChildProps, TDataName>>(ContactPersonAddedDocument, {
      alias: 'contactPersonAdded',
      ...operationOptions
    });
};

/**
 * __useContactPersonAddedSubscription__
 *
 * To run a query within a React component, call `useContactPersonAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useContactPersonAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactPersonAddedSubscription({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useContactPersonAddedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<ContactPersonAddedSubscription, ContactPersonAddedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<ContactPersonAddedSubscription, ContactPersonAddedSubscriptionVariables>(ContactPersonAddedDocument, baseOptions);
      }
export type ContactPersonAddedSubscriptionHookResult = ReturnType<typeof useContactPersonAddedSubscription>;
export type ContactPersonAddedSubscriptionResult = ApolloReactCommon.SubscriptionResult<ContactPersonAddedSubscription>;
export const AddCustomerDocument = gql`
    mutation AddCustomer($name: String!, $shortName: String!, $picURL: String, $isFreelancer: Int, $priceCategory: Int, $legalName: String!, $street: String!, $houseNumber: String!, $postCode: Int!, $place: String!, $country: String!) {
  addCustomer(where: {name: $name, shortName: $shortName, picURL: $picURL, isFreelancer: $isFreelancer, priceCategory: $priceCategory, legalName: $legalName, street: $street, houseNumber: $houseNumber, postCode: $postCode, place: $place, country: $country}) {
    id
    name
    short
    freelancer
    imageUrl
    priceCategory
  }
}
    `;
export type AddCustomerMutationFn = ApolloReactCommon.MutationFunction<AddCustomerMutation, AddCustomerMutationVariables>;
export type AddCustomerComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddCustomerMutation, AddCustomerMutationVariables>, 'mutation'>;

    export const AddCustomerComponent = (props: AddCustomerComponentProps) => (
      <ApolloReactComponents.Mutation<AddCustomerMutation, AddCustomerMutationVariables> mutation={AddCustomerDocument} {...props} />
    );
    
export type AddCustomerProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AddCustomerMutation, AddCustomerMutationVariables>
    } & TChildProps;
export function withAddCustomer<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddCustomerMutation,
  AddCustomerMutationVariables,
  AddCustomerProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AddCustomerMutation, AddCustomerMutationVariables, AddCustomerProps<TChildProps, TDataName>>(AddCustomerDocument, {
      alias: 'addCustomer',
      ...operationOptions
    });
};

/**
 * __useAddCustomerMutation__
 *
 * To run a mutation, you first call `useAddCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCustomerMutation, { data, loading, error }] = useAddCustomerMutation({
 *   variables: {
 *      name: // value for 'name'
 *      shortName: // value for 'shortName'
 *      picURL: // value for 'picURL'
 *      isFreelancer: // value for 'isFreelancer'
 *      priceCategory: // value for 'priceCategory'
 *      legalName: // value for 'legalName'
 *      street: // value for 'street'
 *      houseNumber: // value for 'houseNumber'
 *      postCode: // value for 'postCode'
 *      place: // value for 'place'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useAddCustomerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddCustomerMutation, AddCustomerMutationVariables>) {
        return ApolloReactHooks.useMutation<AddCustomerMutation, AddCustomerMutationVariables>(AddCustomerDocument, baseOptions);
      }
export type AddCustomerMutationHookResult = ReturnType<typeof useAddCustomerMutation>;
export type AddCustomerMutationResult = ApolloReactCommon.MutationResult<AddCustomerMutation>;
export type AddCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<AddCustomerMutation, AddCustomerMutationVariables>;
export const AddCustomerCheckDocument = gql`
    query AddCustomerCheck($name: String!, $shortName: String!, $companyId: Int) {
  addCustomerCheck(where: {name: $name, shortName: $shortName, companyId: $companyId}) {
    name
    short
  }
}
    `;
export type AddCustomerCheckComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AddCustomerCheckQuery, AddCustomerCheckQueryVariables>, 'query'> & ({ variables: AddCustomerCheckQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AddCustomerCheckComponent = (props: AddCustomerCheckComponentProps) => (
      <ApolloReactComponents.Query<AddCustomerCheckQuery, AddCustomerCheckQueryVariables> query={AddCustomerCheckDocument} {...props} />
    );
    
export type AddCustomerCheckProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AddCustomerCheckQuery, AddCustomerCheckQueryVariables>
    } & TChildProps;
export function withAddCustomerCheck<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddCustomerCheckQuery,
  AddCustomerCheckQueryVariables,
  AddCustomerCheckProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AddCustomerCheckQuery, AddCustomerCheckQueryVariables, AddCustomerCheckProps<TChildProps, TDataName>>(AddCustomerCheckDocument, {
      alias: 'addCustomerCheck',
      ...operationOptions
    });
};

/**
 * __useAddCustomerCheckQuery__
 *
 * To run a query within a React component, call `useAddCustomerCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddCustomerCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddCustomerCheckQuery({
 *   variables: {
 *      name: // value for 'name'
 *      shortName: // value for 'shortName'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAddCustomerCheckQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AddCustomerCheckQuery, AddCustomerCheckQueryVariables>) {
        return ApolloReactHooks.useQuery<AddCustomerCheckQuery, AddCustomerCheckQueryVariables>(AddCustomerCheckDocument, baseOptions);
      }
export function useAddCustomerCheckLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AddCustomerCheckQuery, AddCustomerCheckQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AddCustomerCheckQuery, AddCustomerCheckQueryVariables>(AddCustomerCheckDocument, baseOptions);
        }
export type AddCustomerCheckQueryHookResult = ReturnType<typeof useAddCustomerCheckQuery>;
export type AddCustomerCheckLazyQueryHookResult = ReturnType<typeof useAddCustomerCheckLazyQuery>;
export type AddCustomerCheckQueryResult = ApolloReactCommon.QueryResult<AddCustomerCheckQuery, AddCustomerCheckQueryVariables>;
export const UpdateCompanyDocument = gql`
    mutation updateCompany($id: Int!, $name: String, $shortName: String, $legalName: String, $picURL: String, $isFreelancer: Int, $priceCategory: Int, $street: String, $houseNumber: String, $postCode: Int, $place: String, $country: String) {
  updateCompany(where: {id: $id, name: $name, shortName: $shortName, legalName: $legalName, picURL: $picURL, isFreelancer: $isFreelancer, priceCategory: $priceCategory, street: $street, houseNumber: $houseNumber, postCode: $postCode, place: $place, country: $country}) {
    id
    name
    short
    freelancer
    imageUrl
    priceCategory
    address {
      street
      houseNumber
      postCode
      place
      country
      legalName
    }
  }
}
    `;
export type UpdateCompanyMutationFn = ApolloReactCommon.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export type UpdateCompanyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>, 'mutation'>;

    export const UpdateCompanyComponent = (props: UpdateCompanyComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateCompanyMutation, UpdateCompanyMutationVariables> mutation={UpdateCompanyDocument} {...props} />
    );
    
export type UpdateCompanyProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>
    } & TChildProps;
export function withUpdateCompany<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables,
  UpdateCompanyProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateCompanyMutation, UpdateCompanyMutationVariables, UpdateCompanyProps<TChildProps, TDataName>>(UpdateCompanyDocument, {
      alias: 'updateCompany',
      ...operationOptions
    });
};

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      shortName: // value for 'shortName'
 *      legalName: // value for 'legalName'
 *      picURL: // value for 'picURL'
 *      isFreelancer: // value for 'isFreelancer'
 *      priceCategory: // value for 'priceCategory'
 *      street: // value for 'street'
 *      houseNumber: // value for 'houseNumber'
 *      postCode: // value for 'postCode'
 *      place: // value for 'place'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useUpdateCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, baseOptions);
      }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = ApolloReactCommon.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const GetCustomerDialogCompanyDocument = gql`
    query GetCustomerDialogCompany($id: Int!) {
  company(where: {id: $id}) {
    id
    name
    short
    freelancer
    imageUrl
    priceCategory
    address {
      street
      houseNumber
      postCode
      place
      country
      legalName
    }
  }
}
    `;
export type GetCustomerDialogCompanyComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCustomerDialogCompanyQuery, GetCustomerDialogCompanyQueryVariables>, 'query'> & ({ variables: GetCustomerDialogCompanyQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCustomerDialogCompanyComponent = (props: GetCustomerDialogCompanyComponentProps) => (
      <ApolloReactComponents.Query<GetCustomerDialogCompanyQuery, GetCustomerDialogCompanyQueryVariables> query={GetCustomerDialogCompanyDocument} {...props} />
    );
    
export type GetCustomerDialogCompanyProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetCustomerDialogCompanyQuery, GetCustomerDialogCompanyQueryVariables>
    } & TChildProps;
export function withGetCustomerDialogCompany<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCustomerDialogCompanyQuery,
  GetCustomerDialogCompanyQueryVariables,
  GetCustomerDialogCompanyProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetCustomerDialogCompanyQuery, GetCustomerDialogCompanyQueryVariables, GetCustomerDialogCompanyProps<TChildProps, TDataName>>(GetCustomerDialogCompanyDocument, {
      alias: 'getCustomerDialogCompany',
      ...operationOptions
    });
};

/**
 * __useGetCustomerDialogCompanyQuery__
 *
 * To run a query within a React component, call `useGetCustomerDialogCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerDialogCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerDialogCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCustomerDialogCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCustomerDialogCompanyQuery, GetCustomerDialogCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCustomerDialogCompanyQuery, GetCustomerDialogCompanyQueryVariables>(GetCustomerDialogCompanyDocument, baseOptions);
      }
export function useGetCustomerDialogCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomerDialogCompanyQuery, GetCustomerDialogCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCustomerDialogCompanyQuery, GetCustomerDialogCompanyQueryVariables>(GetCustomerDialogCompanyDocument, baseOptions);
        }
export type GetCustomerDialogCompanyQueryHookResult = ReturnType<typeof useGetCustomerDialogCompanyQuery>;
export type GetCustomerDialogCompanyLazyQueryHookResult = ReturnType<typeof useGetCustomerDialogCompanyLazyQuery>;
export type GetCustomerDialogCompanyQueryResult = ApolloReactCommon.QueryResult<GetCustomerDialogCompanyQuery, GetCustomerDialogCompanyQueryVariables>;
export const CustomerDialogCompanyUpdatedDocument = gql`
    subscription CustomerDialogCompanyUpdated {
  companyUpdated {
    id
    name
    short
    freelancer
    imageUrl
    priceCategory
    address {
      street
      houseNumber
      postCode
      place
      country
      legalName
    }
  }
}
    `;
export type CustomerDialogCompanyUpdatedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<CustomerDialogCompanyUpdatedSubscription, CustomerDialogCompanyUpdatedSubscriptionVariables>, 'subscription'>;

    export const CustomerDialogCompanyUpdatedComponent = (props: CustomerDialogCompanyUpdatedComponentProps) => (
      <ApolloReactComponents.Subscription<CustomerDialogCompanyUpdatedSubscription, CustomerDialogCompanyUpdatedSubscriptionVariables> subscription={CustomerDialogCompanyUpdatedDocument} {...props} />
    );
    
export type CustomerDialogCompanyUpdatedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CustomerDialogCompanyUpdatedSubscription, CustomerDialogCompanyUpdatedSubscriptionVariables>
    } & TChildProps;
export function withCustomerDialogCompanyUpdated<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CustomerDialogCompanyUpdatedSubscription,
  CustomerDialogCompanyUpdatedSubscriptionVariables,
  CustomerDialogCompanyUpdatedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, CustomerDialogCompanyUpdatedSubscription, CustomerDialogCompanyUpdatedSubscriptionVariables, CustomerDialogCompanyUpdatedProps<TChildProps, TDataName>>(CustomerDialogCompanyUpdatedDocument, {
      alias: 'customerDialogCompanyUpdated',
      ...operationOptions
    });
};

/**
 * __useCustomerDialogCompanyUpdatedSubscription__
 *
 * To run a query within a React component, call `useCustomerDialogCompanyUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCustomerDialogCompanyUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerDialogCompanyUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useCustomerDialogCompanyUpdatedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<CustomerDialogCompanyUpdatedSubscription, CustomerDialogCompanyUpdatedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<CustomerDialogCompanyUpdatedSubscription, CustomerDialogCompanyUpdatedSubscriptionVariables>(CustomerDialogCompanyUpdatedDocument, baseOptions);
      }
export type CustomerDialogCompanyUpdatedSubscriptionHookResult = ReturnType<typeof useCustomerDialogCompanyUpdatedSubscription>;
export type CustomerDialogCompanyUpdatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<CustomerDialogCompanyUpdatedSubscription>;
export const CustomerSidebarActivityDocument = gql`
    query CustomerSidebarActivity($companyId: Int!) {
  activity(where: {companyId: $companyId}) {
    prio {
      id
      color
    }
    all
    userRelated
  }
}
    `;
export type CustomerSidebarActivityComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CustomerSidebarActivityQuery, CustomerSidebarActivityQueryVariables>, 'query'> & ({ variables: CustomerSidebarActivityQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CustomerSidebarActivityComponent = (props: CustomerSidebarActivityComponentProps) => (
      <ApolloReactComponents.Query<CustomerSidebarActivityQuery, CustomerSidebarActivityQueryVariables> query={CustomerSidebarActivityDocument} {...props} />
    );
    
export type CustomerSidebarActivityProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CustomerSidebarActivityQuery, CustomerSidebarActivityQueryVariables>
    } & TChildProps;
export function withCustomerSidebarActivity<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CustomerSidebarActivityQuery,
  CustomerSidebarActivityQueryVariables,
  CustomerSidebarActivityProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, CustomerSidebarActivityQuery, CustomerSidebarActivityQueryVariables, CustomerSidebarActivityProps<TChildProps, TDataName>>(CustomerSidebarActivityDocument, {
      alias: 'customerSidebarActivity',
      ...operationOptions
    });
};

/**
 * __useCustomerSidebarActivityQuery__
 *
 * To run a query within a React component, call `useCustomerSidebarActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerSidebarActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerSidebarActivityQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCustomerSidebarActivityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CustomerSidebarActivityQuery, CustomerSidebarActivityQueryVariables>) {
        return ApolloReactHooks.useQuery<CustomerSidebarActivityQuery, CustomerSidebarActivityQueryVariables>(CustomerSidebarActivityDocument, baseOptions);
      }
export function useCustomerSidebarActivityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CustomerSidebarActivityQuery, CustomerSidebarActivityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CustomerSidebarActivityQuery, CustomerSidebarActivityQueryVariables>(CustomerSidebarActivityDocument, baseOptions);
        }
export type CustomerSidebarActivityQueryHookResult = ReturnType<typeof useCustomerSidebarActivityQuery>;
export type CustomerSidebarActivityLazyQueryHookResult = ReturnType<typeof useCustomerSidebarActivityLazyQuery>;
export type CustomerSidebarActivityQueryResult = ApolloReactCommon.QueryResult<CustomerSidebarActivityQuery, CustomerSidebarActivityQueryVariables>;
export const GetPriosDocument = gql`
    query GetPrios {
  prios {
    id
    description
    color
    sort
  }
}
    `;
export type GetPriosComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetPriosQuery, GetPriosQueryVariables>, 'query'>;

    export const GetPriosComponent = (props: GetPriosComponentProps) => (
      <ApolloReactComponents.Query<GetPriosQuery, GetPriosQueryVariables> query={GetPriosDocument} {...props} />
    );
    
export type GetPriosProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetPriosQuery, GetPriosQueryVariables>
    } & TChildProps;
export function withGetPrios<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetPriosQuery,
  GetPriosQueryVariables,
  GetPriosProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetPriosQuery, GetPriosQueryVariables, GetPriosProps<TChildProps, TDataName>>(GetPriosDocument, {
      alias: 'getPrios',
      ...operationOptions
    });
};

/**
 * __useGetPriosQuery__
 *
 * To run a query within a React component, call `useGetPriosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPriosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPriosQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPriosQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPriosQuery, GetPriosQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPriosQuery, GetPriosQueryVariables>(GetPriosDocument, baseOptions);
      }
export function useGetPriosLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPriosQuery, GetPriosQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPriosQuery, GetPriosQueryVariables>(GetPriosDocument, baseOptions);
        }
export type GetPriosQueryHookResult = ReturnType<typeof useGetPriosQuery>;
export type GetPriosLazyQueryHookResult = ReturnType<typeof useGetPriosLazyQuery>;
export type GetPriosQueryResult = ApolloReactCommon.QueryResult<GetPriosQuery, GetPriosQueryVariables>;
export const GetCompaniesDocument = gql`
    query GetCompanies {
  companies {
    ...CompanyListItem
  }
}
    ${CompanyListItemFragmentDoc}`;
export type GetCompaniesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCompaniesQuery, GetCompaniesQueryVariables>, 'query'>;

    export const GetCompaniesComponent = (props: GetCompaniesComponentProps) => (
      <ApolloReactComponents.Query<GetCompaniesQuery, GetCompaniesQueryVariables> query={GetCompaniesDocument} {...props} />
    );
    
export type GetCompaniesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetCompaniesQuery, GetCompaniesQueryVariables>
    } & TChildProps;
export function withGetCompanies<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCompaniesQuery,
  GetCompaniesQueryVariables,
  GetCompaniesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetCompaniesQuery, GetCompaniesQueryVariables, GetCompaniesProps<TChildProps, TDataName>>(GetCompaniesDocument, {
      alias: 'getCompanies',
      ...operationOptions
    });
};

/**
 * __useGetCompaniesQuery__
 *
 * To run a query within a React component, call `useGetCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompaniesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompaniesQuery, GetCompaniesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(GetCompaniesDocument, baseOptions);
      }
export function useGetCompaniesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompaniesQuery, GetCompaniesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(GetCompaniesDocument, baseOptions);
        }
export type GetCompaniesQueryHookResult = ReturnType<typeof useGetCompaniesQuery>;
export type GetCompaniesLazyQueryHookResult = ReturnType<typeof useGetCompaniesLazyQuery>;
export type GetCompaniesQueryResult = ApolloReactCommon.QueryResult<GetCompaniesQuery, GetCompaniesQueryVariables>;
export const CompanyAddedDocument = gql`
    subscription CompanyAdded {
  companyAdded {
    ...CompanyListItem
  }
}
    ${CompanyListItemFragmentDoc}`;
export type CompanyAddedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<CompanyAddedSubscription, CompanyAddedSubscriptionVariables>, 'subscription'>;

    export const CompanyAddedComponent = (props: CompanyAddedComponentProps) => (
      <ApolloReactComponents.Subscription<CompanyAddedSubscription, CompanyAddedSubscriptionVariables> subscription={CompanyAddedDocument} {...props} />
    );
    
export type CompanyAddedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CompanyAddedSubscription, CompanyAddedSubscriptionVariables>
    } & TChildProps;
export function withCompanyAdded<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CompanyAddedSubscription,
  CompanyAddedSubscriptionVariables,
  CompanyAddedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, CompanyAddedSubscription, CompanyAddedSubscriptionVariables, CompanyAddedProps<TChildProps, TDataName>>(CompanyAddedDocument, {
      alias: 'companyAdded',
      ...operationOptions
    });
};

/**
 * __useCompanyAddedSubscription__
 *
 * To run a query within a React component, call `useCompanyAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCompanyAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyAddedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useCompanyAddedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<CompanyAddedSubscription, CompanyAddedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<CompanyAddedSubscription, CompanyAddedSubscriptionVariables>(CompanyAddedDocument, baseOptions);
      }
export type CompanyAddedSubscriptionHookResult = ReturnType<typeof useCompanyAddedSubscription>;
export type CompanyAddedSubscriptionResult = ApolloReactCommon.SubscriptionResult<CompanyAddedSubscription>;
export const CompanyUpdatedDocument = gql`
    subscription CompanyUpdated {
  companyUpdated {
    ...CompanyListItem
  }
}
    ${CompanyListItemFragmentDoc}`;
export type CompanyUpdatedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<CompanyUpdatedSubscription, CompanyUpdatedSubscriptionVariables>, 'subscription'>;

    export const CompanyUpdatedComponent = (props: CompanyUpdatedComponentProps) => (
      <ApolloReactComponents.Subscription<CompanyUpdatedSubscription, CompanyUpdatedSubscriptionVariables> subscription={CompanyUpdatedDocument} {...props} />
    );
    
export type CompanyUpdatedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CompanyUpdatedSubscription, CompanyUpdatedSubscriptionVariables>
    } & TChildProps;
export function withCompanyUpdated<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CompanyUpdatedSubscription,
  CompanyUpdatedSubscriptionVariables,
  CompanyUpdatedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, CompanyUpdatedSubscription, CompanyUpdatedSubscriptionVariables, CompanyUpdatedProps<TChildProps, TDataName>>(CompanyUpdatedDocument, {
      alias: 'companyUpdated',
      ...operationOptions
    });
};

/**
 * __useCompanyUpdatedSubscription__
 *
 * To run a query within a React component, call `useCompanyUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCompanyUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useCompanyUpdatedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<CompanyUpdatedSubscription, CompanyUpdatedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<CompanyUpdatedSubscription, CompanyUpdatedSubscriptionVariables>(CompanyUpdatedDocument, baseOptions);
      }
export type CompanyUpdatedSubscriptionHookResult = ReturnType<typeof useCompanyUpdatedSubscription>;
export type CompanyUpdatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<CompanyUpdatedSubscription>;
export const GetHeaderDataDocument = gql`
    query GetHeaderData {
  me {
    ...HeaderMe
  }
  companies {
    id
    hasActivity
    hasUserRelatedActivity
  }
  timeTracking {
    quickSelection {
      ...TimeTrackingItem
    }
    urgent {
      ...TimeTrackingItem
    }
  }
}
    ${HeaderMeFragmentDoc}
${TimeTrackingItemFragmentDoc}`;
export type GetHeaderDataComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetHeaderDataQuery, GetHeaderDataQueryVariables>, 'query'>;

    export const GetHeaderDataComponent = (props: GetHeaderDataComponentProps) => (
      <ApolloReactComponents.Query<GetHeaderDataQuery, GetHeaderDataQueryVariables> query={GetHeaderDataDocument} {...props} />
    );
    
export type GetHeaderDataProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetHeaderDataQuery, GetHeaderDataQueryVariables>
    } & TChildProps;
export function withGetHeaderData<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetHeaderDataQuery,
  GetHeaderDataQueryVariables,
  GetHeaderDataProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetHeaderDataQuery, GetHeaderDataQueryVariables, GetHeaderDataProps<TChildProps, TDataName>>(GetHeaderDataDocument, {
      alias: 'getHeaderData',
      ...operationOptions
    });
};

/**
 * __useGetHeaderDataQuery__
 *
 * To run a query within a React component, call `useGetHeaderDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHeaderDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHeaderDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHeaderDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetHeaderDataQuery, GetHeaderDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetHeaderDataQuery, GetHeaderDataQueryVariables>(GetHeaderDataDocument, baseOptions);
      }
export function useGetHeaderDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetHeaderDataQuery, GetHeaderDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetHeaderDataQuery, GetHeaderDataQueryVariables>(GetHeaderDataDocument, baseOptions);
        }
export type GetHeaderDataQueryHookResult = ReturnType<typeof useGetHeaderDataQuery>;
export type GetHeaderDataLazyQueryHookResult = ReturnType<typeof useGetHeaderDataLazyQuery>;
export type GetHeaderDataQueryResult = ApolloReactCommon.QueryResult<GetHeaderDataQuery, GetHeaderDataQueryVariables>;
export const HeaderTimeTrackingUpdatedDocument = gql`
    subscription HeaderTimeTrackingUpdated {
  timeTrackingUpdated {
    quickSelection {
      ...TimeTrackingItem
    }
    urgent {
      ...TimeTrackingItem
    }
  }
}
    ${TimeTrackingItemFragmentDoc}`;
export type HeaderTimeTrackingUpdatedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<HeaderTimeTrackingUpdatedSubscription, HeaderTimeTrackingUpdatedSubscriptionVariables>, 'subscription'>;

    export const HeaderTimeTrackingUpdatedComponent = (props: HeaderTimeTrackingUpdatedComponentProps) => (
      <ApolloReactComponents.Subscription<HeaderTimeTrackingUpdatedSubscription, HeaderTimeTrackingUpdatedSubscriptionVariables> subscription={HeaderTimeTrackingUpdatedDocument} {...props} />
    );
    
export type HeaderTimeTrackingUpdatedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<HeaderTimeTrackingUpdatedSubscription, HeaderTimeTrackingUpdatedSubscriptionVariables>
    } & TChildProps;
export function withHeaderTimeTrackingUpdated<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  HeaderTimeTrackingUpdatedSubscription,
  HeaderTimeTrackingUpdatedSubscriptionVariables,
  HeaderTimeTrackingUpdatedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, HeaderTimeTrackingUpdatedSubscription, HeaderTimeTrackingUpdatedSubscriptionVariables, HeaderTimeTrackingUpdatedProps<TChildProps, TDataName>>(HeaderTimeTrackingUpdatedDocument, {
      alias: 'headerTimeTrackingUpdated',
      ...operationOptions
    });
};

/**
 * __useHeaderTimeTrackingUpdatedSubscription__
 *
 * To run a query within a React component, call `useHeaderTimeTrackingUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useHeaderTimeTrackingUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHeaderTimeTrackingUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useHeaderTimeTrackingUpdatedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<HeaderTimeTrackingUpdatedSubscription, HeaderTimeTrackingUpdatedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<HeaderTimeTrackingUpdatedSubscription, HeaderTimeTrackingUpdatedSubscriptionVariables>(HeaderTimeTrackingUpdatedDocument, baseOptions);
      }
export type HeaderTimeTrackingUpdatedSubscriptionHookResult = ReturnType<typeof useHeaderTimeTrackingUpdatedSubscription>;
export type HeaderTimeTrackingUpdatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<HeaderTimeTrackingUpdatedSubscription>;
export const GetInvoiceEditorPdfInfosDocument = gql`
    query GetInvoiceEditorPDFInfos($invoiceNumber: Int, $taskIds: [Int!]!, $rateIds: [Int!]!, $companyId: Int!, $contactPersonId: Int, $notes: String, $preText: String, $creationDate: Date, $deliveryDate: Date) {
  invoiceEditor(where: {invoiceNumber: $invoiceNumber, taskIds: $taskIds, rateIds: $rateIds, companyId: $companyId, contactPersonId: $contactPersonId, notes: $notes, preText: $preText, creationDate: $creationDate, deliveryDate: $deliveryDate}) {
    nextInvoiceNumber
    pdfPreview {
      id
      filename
      base64
    }
  }
}
    `;
export type GetInvoiceEditorPdfInfosComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetInvoiceEditorPdfInfosQuery, GetInvoiceEditorPdfInfosQueryVariables>, 'query'> & ({ variables: GetInvoiceEditorPdfInfosQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetInvoiceEditorPdfInfosComponent = (props: GetInvoiceEditorPdfInfosComponentProps) => (
      <ApolloReactComponents.Query<GetInvoiceEditorPdfInfosQuery, GetInvoiceEditorPdfInfosQueryVariables> query={GetInvoiceEditorPdfInfosDocument} {...props} />
    );
    
export type GetInvoiceEditorPdfInfosProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetInvoiceEditorPdfInfosQuery, GetInvoiceEditorPdfInfosQueryVariables>
    } & TChildProps;
export function withGetInvoiceEditorPdfInfos<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetInvoiceEditorPdfInfosQuery,
  GetInvoiceEditorPdfInfosQueryVariables,
  GetInvoiceEditorPdfInfosProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetInvoiceEditorPdfInfosQuery, GetInvoiceEditorPdfInfosQueryVariables, GetInvoiceEditorPdfInfosProps<TChildProps, TDataName>>(GetInvoiceEditorPdfInfosDocument, {
      alias: 'getInvoiceEditorPdfInfos',
      ...operationOptions
    });
};

/**
 * __useGetInvoiceEditorPdfInfosQuery__
 *
 * To run a query within a React component, call `useGetInvoiceEditorPdfInfosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceEditorPdfInfosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceEditorPdfInfosQuery({
 *   variables: {
 *      invoiceNumber: // value for 'invoiceNumber'
 *      taskIds: // value for 'taskIds'
 *      rateIds: // value for 'rateIds'
 *      companyId: // value for 'companyId'
 *      contactPersonId: // value for 'contactPersonId'
 *      notes: // value for 'notes'
 *      preText: // value for 'preText'
 *      creationDate: // value for 'creationDate'
 *      deliveryDate: // value for 'deliveryDate'
 *   },
 * });
 */
export function useGetInvoiceEditorPdfInfosQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInvoiceEditorPdfInfosQuery, GetInvoiceEditorPdfInfosQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInvoiceEditorPdfInfosQuery, GetInvoiceEditorPdfInfosQueryVariables>(GetInvoiceEditorPdfInfosDocument, baseOptions);
      }
export function useGetInvoiceEditorPdfInfosLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInvoiceEditorPdfInfosQuery, GetInvoiceEditorPdfInfosQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInvoiceEditorPdfInfosQuery, GetInvoiceEditorPdfInfosQueryVariables>(GetInvoiceEditorPdfInfosDocument, baseOptions);
        }
export type GetInvoiceEditorPdfInfosQueryHookResult = ReturnType<typeof useGetInvoiceEditorPdfInfosQuery>;
export type GetInvoiceEditorPdfInfosLazyQueryHookResult = ReturnType<typeof useGetInvoiceEditorPdfInfosLazyQuery>;
export type GetInvoiceEditorPdfInfosQueryResult = ApolloReactCommon.QueryResult<GetInvoiceEditorPdfInfosQuery, GetInvoiceEditorPdfInfosQueryVariables>;
export const CreateInvoiceDocument = gql`
    mutation CreateInvoice($invoiceNumber: Int, $taskIds: [Int!]!, $rateIds: [Int!]!, $companyId: Int!, $contactPersonId: Int, $creationDate: Date!, $deliveryDate: Date!, $preText: String!, $afterText: String!, $emailText: String!, $preTextTemplate: Int!, $afterTextTemplate: Int!, $emailTextTemplate: Int!, $customContactEmail: String, $sendMail: Boolean!, $attachPDF: Boolean!) {
  createInvoice(where: {invoiceNumber: $invoiceNumber, taskIds: $taskIds, rateIds: $rateIds, companyId: $companyId, contactPersonId: $contactPersonId, creationDate: $creationDate, deliveryDate: $deliveryDate, preText: $preText, afterText: $afterText, emailText: $emailText, preTextTemplate: $preTextTemplate, afterTextTemplate: $afterTextTemplate, emailTextTemplate: $emailTextTemplate, customContactEmail: $customContactEmail, sendMail: $sendMail, attachPDF: $attachPDF}) {
    id
    number
    tasks {
      id
      name
    }
    pdf {
      id
      filename
      base64
    }
  }
}
    `;
export type CreateInvoiceMutationFn = ApolloReactCommon.MutationFunction<CreateInvoiceMutation, CreateInvoiceMutationVariables>;
export type CreateInvoiceComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateInvoiceMutation, CreateInvoiceMutationVariables>, 'mutation'>;

    export const CreateInvoiceComponent = (props: CreateInvoiceComponentProps) => (
      <ApolloReactComponents.Mutation<CreateInvoiceMutation, CreateInvoiceMutationVariables> mutation={CreateInvoiceDocument} {...props} />
    );
    
export type CreateInvoiceProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateInvoiceMutation, CreateInvoiceMutationVariables>
    } & TChildProps;
export function withCreateInvoice<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateInvoiceMutation,
  CreateInvoiceMutationVariables,
  CreateInvoiceProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateInvoiceMutation, CreateInvoiceMutationVariables, CreateInvoiceProps<TChildProps, TDataName>>(CreateInvoiceDocument, {
      alias: 'createInvoice',
      ...operationOptions
    });
};

/**
 * __useCreateInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceMutation, { data, loading, error }] = useCreateInvoiceMutation({
 *   variables: {
 *      invoiceNumber: // value for 'invoiceNumber'
 *      taskIds: // value for 'taskIds'
 *      rateIds: // value for 'rateIds'
 *      companyId: // value for 'companyId'
 *      contactPersonId: // value for 'contactPersonId'
 *      creationDate: // value for 'creationDate'
 *      deliveryDate: // value for 'deliveryDate'
 *      preText: // value for 'preText'
 *      afterText: // value for 'afterText'
 *      emailText: // value for 'emailText'
 *      preTextTemplate: // value for 'preTextTemplate'
 *      afterTextTemplate: // value for 'afterTextTemplate'
 *      emailTextTemplate: // value for 'emailTextTemplate'
 *      customContactEmail: // value for 'customContactEmail'
 *      sendMail: // value for 'sendMail'
 *      attachPDF: // value for 'attachPDF'
 *   },
 * });
 */
export function useCreateInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateInvoiceMutation, CreateInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateInvoiceMutation, CreateInvoiceMutationVariables>(CreateInvoiceDocument, baseOptions);
      }
export type CreateInvoiceMutationHookResult = ReturnType<typeof useCreateInvoiceMutation>;
export type CreateInvoiceMutationResult = ApolloReactCommon.MutationResult<CreateInvoiceMutation>;
export type CreateInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateInvoiceMutation, CreateInvoiceMutationVariables>;
export const GetInvoiceListDocument = gql`
    query GetInvoiceList {
  invoices {
    ...InvoiceListItem
  }
}
    ${InvoiceListItemFragmentDoc}`;
export type GetInvoiceListComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetInvoiceListQuery, GetInvoiceListQueryVariables>, 'query'>;

    export const GetInvoiceListComponent = (props: GetInvoiceListComponentProps) => (
      <ApolloReactComponents.Query<GetInvoiceListQuery, GetInvoiceListQueryVariables> query={GetInvoiceListDocument} {...props} />
    );
    
export type GetInvoiceListProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetInvoiceListQuery, GetInvoiceListQueryVariables>
    } & TChildProps;
export function withGetInvoiceList<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetInvoiceListQuery,
  GetInvoiceListQueryVariables,
  GetInvoiceListProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetInvoiceListQuery, GetInvoiceListQueryVariables, GetInvoiceListProps<TChildProps, TDataName>>(GetInvoiceListDocument, {
      alias: 'getInvoiceList',
      ...operationOptions
    });
};

/**
 * __useGetInvoiceListQuery__
 *
 * To run a query within a React component, call `useGetInvoiceListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInvoiceListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInvoiceListQuery, GetInvoiceListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInvoiceListQuery, GetInvoiceListQueryVariables>(GetInvoiceListDocument, baseOptions);
      }
export function useGetInvoiceListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInvoiceListQuery, GetInvoiceListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInvoiceListQuery, GetInvoiceListQueryVariables>(GetInvoiceListDocument, baseOptions);
        }
export type GetInvoiceListQueryHookResult = ReturnType<typeof useGetInvoiceListQuery>;
export type GetInvoiceListLazyQueryHookResult = ReturnType<typeof useGetInvoiceListLazyQuery>;
export type GetInvoiceListQueryResult = ApolloReactCommon.QueryResult<GetInvoiceListQuery, GetInvoiceListQueryVariables>;
export const UpdateInvoiceStateDocument = gql`
    mutation UpdateInvoiceState($id: Int!, $state: InvoiceState!) {
  updateInvoiceState(where: {id: $id, state: $state}) {
    ...InvoiceListItem
  }
}
    ${InvoiceListItemFragmentDoc}`;
export type UpdateInvoiceStateMutationFn = ApolloReactCommon.MutationFunction<UpdateInvoiceStateMutation, UpdateInvoiceStateMutationVariables>;
export type UpdateInvoiceStateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateInvoiceStateMutation, UpdateInvoiceStateMutationVariables>, 'mutation'>;

    export const UpdateInvoiceStateComponent = (props: UpdateInvoiceStateComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateInvoiceStateMutation, UpdateInvoiceStateMutationVariables> mutation={UpdateInvoiceStateDocument} {...props} />
    );
    
export type UpdateInvoiceStateProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateInvoiceStateMutation, UpdateInvoiceStateMutationVariables>
    } & TChildProps;
export function withUpdateInvoiceState<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateInvoiceStateMutation,
  UpdateInvoiceStateMutationVariables,
  UpdateInvoiceStateProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateInvoiceStateMutation, UpdateInvoiceStateMutationVariables, UpdateInvoiceStateProps<TChildProps, TDataName>>(UpdateInvoiceStateDocument, {
      alias: 'updateInvoiceState',
      ...operationOptions
    });
};

/**
 * __useUpdateInvoiceStateMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceStateMutation, { data, loading, error }] = useUpdateInvoiceStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useUpdateInvoiceStateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateInvoiceStateMutation, UpdateInvoiceStateMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateInvoiceStateMutation, UpdateInvoiceStateMutationVariables>(UpdateInvoiceStateDocument, baseOptions);
      }
export type UpdateInvoiceStateMutationHookResult = ReturnType<typeof useUpdateInvoiceStateMutation>;
export type UpdateInvoiceStateMutationResult = ApolloReactCommon.MutationResult<UpdateInvoiceStateMutation>;
export type UpdateInvoiceStateMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateInvoiceStateMutation, UpdateInvoiceStateMutationVariables>;
export const InvoiceUpdatedDocument = gql`
    subscription InvoiceUpdated {
  invoiceUpdated {
    ...InvoiceListItem
  }
}
    ${InvoiceListItemFragmentDoc}`;
export type InvoiceUpdatedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<InvoiceUpdatedSubscription, InvoiceUpdatedSubscriptionVariables>, 'subscription'>;

    export const InvoiceUpdatedComponent = (props: InvoiceUpdatedComponentProps) => (
      <ApolloReactComponents.Subscription<InvoiceUpdatedSubscription, InvoiceUpdatedSubscriptionVariables> subscription={InvoiceUpdatedDocument} {...props} />
    );
    
export type InvoiceUpdatedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<InvoiceUpdatedSubscription, InvoiceUpdatedSubscriptionVariables>
    } & TChildProps;
export function withInvoiceUpdated<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  InvoiceUpdatedSubscription,
  InvoiceUpdatedSubscriptionVariables,
  InvoiceUpdatedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, InvoiceUpdatedSubscription, InvoiceUpdatedSubscriptionVariables, InvoiceUpdatedProps<TChildProps, TDataName>>(InvoiceUpdatedDocument, {
      alias: 'invoiceUpdated',
      ...operationOptions
    });
};

/**
 * __useInvoiceUpdatedSubscription__
 *
 * To run a query within a React component, call `useInvoiceUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useInvoiceUpdatedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<InvoiceUpdatedSubscription, InvoiceUpdatedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<InvoiceUpdatedSubscription, InvoiceUpdatedSubscriptionVariables>(InvoiceUpdatedDocument, baseOptions);
      }
export type InvoiceUpdatedSubscriptionHookResult = ReturnType<typeof useInvoiceUpdatedSubscription>;
export type InvoiceUpdatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<InvoiceUpdatedSubscription>;
export const InvoiceAddedDocument = gql`
    subscription InvoiceAdded {
  invoiceAdded {
    ...InvoiceListItem
  }
}
    ${InvoiceListItemFragmentDoc}`;
export type InvoiceAddedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<InvoiceAddedSubscription, InvoiceAddedSubscriptionVariables>, 'subscription'>;

    export const InvoiceAddedComponent = (props: InvoiceAddedComponentProps) => (
      <ApolloReactComponents.Subscription<InvoiceAddedSubscription, InvoiceAddedSubscriptionVariables> subscription={InvoiceAddedDocument} {...props} />
    );
    
export type InvoiceAddedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<InvoiceAddedSubscription, InvoiceAddedSubscriptionVariables>
    } & TChildProps;
export function withInvoiceAdded<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  InvoiceAddedSubscription,
  InvoiceAddedSubscriptionVariables,
  InvoiceAddedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, InvoiceAddedSubscription, InvoiceAddedSubscriptionVariables, InvoiceAddedProps<TChildProps, TDataName>>(InvoiceAddedDocument, {
      alias: 'invoiceAdded',
      ...operationOptions
    });
};

/**
 * __useInvoiceAddedSubscription__
 *
 * To run a query within a React component, call `useInvoiceAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceAddedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useInvoiceAddedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<InvoiceAddedSubscription, InvoiceAddedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<InvoiceAddedSubscription, InvoiceAddedSubscriptionVariables>(InvoiceAddedDocument, baseOptions);
      }
export type InvoiceAddedSubscriptionHookResult = ReturnType<typeof useInvoiceAddedSubscription>;
export type InvoiceAddedSubscriptionResult = ApolloReactCommon.SubscriptionResult<InvoiceAddedSubscription>;
export const GetInvoicePdfDocument = gql`
    query GetInvoicePdf($invoiceNumber: Int!) {
  invoice(where: {invoiceNumber: $invoiceNumber}) {
    id
    number
    state
    creationDate
    deliveryDate
    state
    contactPerson {
      id
      name
      email
    }
    customContactEmail
    pdf {
      id
      filename
      base64
    }
    emailText
    emailTextTemplate
    sendMail
    mailAttachPDF
  }
}
    `;
export type GetInvoicePdfComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetInvoicePdfQuery, GetInvoicePdfQueryVariables>, 'query'> & ({ variables: GetInvoicePdfQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetInvoicePdfComponent = (props: GetInvoicePdfComponentProps) => (
      <ApolloReactComponents.Query<GetInvoicePdfQuery, GetInvoicePdfQueryVariables> query={GetInvoicePdfDocument} {...props} />
    );
    
export type GetInvoicePdfProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetInvoicePdfQuery, GetInvoicePdfQueryVariables>
    } & TChildProps;
export function withGetInvoicePdf<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetInvoicePdfQuery,
  GetInvoicePdfQueryVariables,
  GetInvoicePdfProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetInvoicePdfQuery, GetInvoicePdfQueryVariables, GetInvoicePdfProps<TChildProps, TDataName>>(GetInvoicePdfDocument, {
      alias: 'getInvoicePdf',
      ...operationOptions
    });
};

/**
 * __useGetInvoicePdfQuery__
 *
 * To run a query within a React component, call `useGetInvoicePdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicePdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicePdfQuery({
 *   variables: {
 *      invoiceNumber: // value for 'invoiceNumber'
 *   },
 * });
 */
export function useGetInvoicePdfQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInvoicePdfQuery, GetInvoicePdfQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInvoicePdfQuery, GetInvoicePdfQueryVariables>(GetInvoicePdfDocument, baseOptions);
      }
export function useGetInvoicePdfLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInvoicePdfQuery, GetInvoicePdfQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInvoicePdfQuery, GetInvoicePdfQueryVariables>(GetInvoicePdfDocument, baseOptions);
        }
export type GetInvoicePdfQueryHookResult = ReturnType<typeof useGetInvoicePdfQuery>;
export type GetInvoicePdfLazyQueryHookResult = ReturnType<typeof useGetInvoicePdfLazyQuery>;
export type GetInvoicePdfQueryResult = ApolloReactCommon.QueryResult<GetInvoicePdfQuery, GetInvoicePdfQueryVariables>;
export const AddInvoiceToSendQueueDocument = gql`
    mutation AddInvoiceToSendQueue($id: Int!, $attachPDF: Boolean!) {
  addInvoiceToSendQueue(where: {id: $id, attachPDF: $attachPDF}) {
    id
    state
  }
}
    `;
export type AddInvoiceToSendQueueMutationFn = ApolloReactCommon.MutationFunction<AddInvoiceToSendQueueMutation, AddInvoiceToSendQueueMutationVariables>;
export type AddInvoiceToSendQueueComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddInvoiceToSendQueueMutation, AddInvoiceToSendQueueMutationVariables>, 'mutation'>;

    export const AddInvoiceToSendQueueComponent = (props: AddInvoiceToSendQueueComponentProps) => (
      <ApolloReactComponents.Mutation<AddInvoiceToSendQueueMutation, AddInvoiceToSendQueueMutationVariables> mutation={AddInvoiceToSendQueueDocument} {...props} />
    );
    
export type AddInvoiceToSendQueueProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AddInvoiceToSendQueueMutation, AddInvoiceToSendQueueMutationVariables>
    } & TChildProps;
export function withAddInvoiceToSendQueue<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddInvoiceToSendQueueMutation,
  AddInvoiceToSendQueueMutationVariables,
  AddInvoiceToSendQueueProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AddInvoiceToSendQueueMutation, AddInvoiceToSendQueueMutationVariables, AddInvoiceToSendQueueProps<TChildProps, TDataName>>(AddInvoiceToSendQueueDocument, {
      alias: 'addInvoiceToSendQueue',
      ...operationOptions
    });
};

/**
 * __useAddInvoiceToSendQueueMutation__
 *
 * To run a mutation, you first call `useAddInvoiceToSendQueueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInvoiceToSendQueueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInvoiceToSendQueueMutation, { data, loading, error }] = useAddInvoiceToSendQueueMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attachPDF: // value for 'attachPDF'
 *   },
 * });
 */
export function useAddInvoiceToSendQueueMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddInvoiceToSendQueueMutation, AddInvoiceToSendQueueMutationVariables>) {
        return ApolloReactHooks.useMutation<AddInvoiceToSendQueueMutation, AddInvoiceToSendQueueMutationVariables>(AddInvoiceToSendQueueDocument, baseOptions);
      }
export type AddInvoiceToSendQueueMutationHookResult = ReturnType<typeof useAddInvoiceToSendQueueMutation>;
export type AddInvoiceToSendQueueMutationResult = ApolloReactCommon.MutationResult<AddInvoiceToSendQueueMutation>;
export type AddInvoiceToSendQueueMutationOptions = ApolloReactCommon.BaseMutationOptions<AddInvoiceToSendQueueMutation, AddInvoiceToSendQueueMutationVariables>;
export const UpdateInvoiceMailSettingsDocument = gql`
    mutation UpdateInvoiceMailSettings($invoiceId: Int!, $attachPDF: Boolean, $sendMail: Boolean, $emailText: String, $emailTextTemplate: Int, $customContactEmail: String) {
  updateInvoiceMailSettings(where: {invoiceId: $invoiceId, attachPDF: $attachPDF, sendMail: $sendMail, emailText: $emailText, emailTextTemplate: $emailTextTemplate, customContactEmail: $customContactEmail}) {
    id
    sendMail
    mailAttachPDF
    emailText
    emailTextTemplate
    customContactEmail
  }
}
    `;
export type UpdateInvoiceMailSettingsMutationFn = ApolloReactCommon.MutationFunction<UpdateInvoiceMailSettingsMutation, UpdateInvoiceMailSettingsMutationVariables>;
export type UpdateInvoiceMailSettingsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateInvoiceMailSettingsMutation, UpdateInvoiceMailSettingsMutationVariables>, 'mutation'>;

    export const UpdateInvoiceMailSettingsComponent = (props: UpdateInvoiceMailSettingsComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateInvoiceMailSettingsMutation, UpdateInvoiceMailSettingsMutationVariables> mutation={UpdateInvoiceMailSettingsDocument} {...props} />
    );
    
export type UpdateInvoiceMailSettingsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateInvoiceMailSettingsMutation, UpdateInvoiceMailSettingsMutationVariables>
    } & TChildProps;
export function withUpdateInvoiceMailSettings<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateInvoiceMailSettingsMutation,
  UpdateInvoiceMailSettingsMutationVariables,
  UpdateInvoiceMailSettingsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateInvoiceMailSettingsMutation, UpdateInvoiceMailSettingsMutationVariables, UpdateInvoiceMailSettingsProps<TChildProps, TDataName>>(UpdateInvoiceMailSettingsDocument, {
      alias: 'updateInvoiceMailSettings',
      ...operationOptions
    });
};

/**
 * __useUpdateInvoiceMailSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceMailSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceMailSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceMailSettingsMutation, { data, loading, error }] = useUpdateInvoiceMailSettingsMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      attachPDF: // value for 'attachPDF'
 *      sendMail: // value for 'sendMail'
 *      emailText: // value for 'emailText'
 *      emailTextTemplate: // value for 'emailTextTemplate'
 *      customContactEmail: // value for 'customContactEmail'
 *   },
 * });
 */
export function useUpdateInvoiceMailSettingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateInvoiceMailSettingsMutation, UpdateInvoiceMailSettingsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateInvoiceMailSettingsMutation, UpdateInvoiceMailSettingsMutationVariables>(UpdateInvoiceMailSettingsDocument, baseOptions);
      }
export type UpdateInvoiceMailSettingsMutationHookResult = ReturnType<typeof useUpdateInvoiceMailSettingsMutation>;
export type UpdateInvoiceMailSettingsMutationResult = ApolloReactCommon.MutationResult<UpdateInvoiceMailSettingsMutation>;
export type UpdateInvoiceMailSettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateInvoiceMailSettingsMutation, UpdateInvoiceMailSettingsMutationVariables>;
export const InvoiceViewerInvoiceUpdatedDocument = gql`
    subscription InvoiceViewerInvoiceUpdated {
  invoiceUpdated {
    id
    sendMail
    mailAttachPDF
    emailText
    emailTextTemplate
    customContactEmail
  }
}
    `;
export type InvoiceViewerInvoiceUpdatedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<InvoiceViewerInvoiceUpdatedSubscription, InvoiceViewerInvoiceUpdatedSubscriptionVariables>, 'subscription'>;

    export const InvoiceViewerInvoiceUpdatedComponent = (props: InvoiceViewerInvoiceUpdatedComponentProps) => (
      <ApolloReactComponents.Subscription<InvoiceViewerInvoiceUpdatedSubscription, InvoiceViewerInvoiceUpdatedSubscriptionVariables> subscription={InvoiceViewerInvoiceUpdatedDocument} {...props} />
    );
    
export type InvoiceViewerInvoiceUpdatedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<InvoiceViewerInvoiceUpdatedSubscription, InvoiceViewerInvoiceUpdatedSubscriptionVariables>
    } & TChildProps;
export function withInvoiceViewerInvoiceUpdated<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  InvoiceViewerInvoiceUpdatedSubscription,
  InvoiceViewerInvoiceUpdatedSubscriptionVariables,
  InvoiceViewerInvoiceUpdatedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, InvoiceViewerInvoiceUpdatedSubscription, InvoiceViewerInvoiceUpdatedSubscriptionVariables, InvoiceViewerInvoiceUpdatedProps<TChildProps, TDataName>>(InvoiceViewerInvoiceUpdatedDocument, {
      alias: 'invoiceViewerInvoiceUpdated',
      ...operationOptions
    });
};

/**
 * __useInvoiceViewerInvoiceUpdatedSubscription__
 *
 * To run a query within a React component, call `useInvoiceViewerInvoiceUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceViewerInvoiceUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceViewerInvoiceUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useInvoiceViewerInvoiceUpdatedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<InvoiceViewerInvoiceUpdatedSubscription, InvoiceViewerInvoiceUpdatedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<InvoiceViewerInvoiceUpdatedSubscription, InvoiceViewerInvoiceUpdatedSubscriptionVariables>(InvoiceViewerInvoiceUpdatedDocument, baseOptions);
      }
export type InvoiceViewerInvoiceUpdatedSubscriptionHookResult = ReturnType<typeof useInvoiceViewerInvoiceUpdatedSubscription>;
export type InvoiceViewerInvoiceUpdatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<InvoiceViewerInvoiceUpdatedSubscription>;
export const GetOfferEditorOfferDocument = gql`
    query GetOfferEditorOffer($id: Int!) {
  offer(where: {id: $id}) {
    ...OfferEditorOffer
  }
  offerPositions(where: {offerId: $id}) {
    ...OfferEditorPosition
  }
}
    ${OfferEditorOfferFragmentDoc}
${OfferEditorPositionFragmentDoc}`;
export type GetOfferEditorOfferComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetOfferEditorOfferQuery, GetOfferEditorOfferQueryVariables>, 'query'> & ({ variables: GetOfferEditorOfferQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetOfferEditorOfferComponent = (props: GetOfferEditorOfferComponentProps) => (
      <ApolloReactComponents.Query<GetOfferEditorOfferQuery, GetOfferEditorOfferQueryVariables> query={GetOfferEditorOfferDocument} {...props} />
    );
    
export type GetOfferEditorOfferProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetOfferEditorOfferQuery, GetOfferEditorOfferQueryVariables>
    } & TChildProps;
export function withGetOfferEditorOffer<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetOfferEditorOfferQuery,
  GetOfferEditorOfferQueryVariables,
  GetOfferEditorOfferProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetOfferEditorOfferQuery, GetOfferEditorOfferQueryVariables, GetOfferEditorOfferProps<TChildProps, TDataName>>(GetOfferEditorOfferDocument, {
      alias: 'getOfferEditorOffer',
      ...operationOptions
    });
};

/**
 * __useGetOfferEditorOfferQuery__
 *
 * To run a query within a React component, call `useGetOfferEditorOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfferEditorOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfferEditorOfferQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOfferEditorOfferQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOfferEditorOfferQuery, GetOfferEditorOfferQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOfferEditorOfferQuery, GetOfferEditorOfferQueryVariables>(GetOfferEditorOfferDocument, baseOptions);
      }
export function useGetOfferEditorOfferLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOfferEditorOfferQuery, GetOfferEditorOfferQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOfferEditorOfferQuery, GetOfferEditorOfferQueryVariables>(GetOfferEditorOfferDocument, baseOptions);
        }
export type GetOfferEditorOfferQueryHookResult = ReturnType<typeof useGetOfferEditorOfferQuery>;
export type GetOfferEditorOfferLazyQueryHookResult = ReturnType<typeof useGetOfferEditorOfferLazyQuery>;
export type GetOfferEditorOfferQueryResult = ApolloReactCommon.QueryResult<GetOfferEditorOfferQuery, GetOfferEditorOfferQueryVariables>;
export const MoveOfferEditorPositionDocument = gql`
    mutation MoveOfferEditorPosition($id: Int!, $newIndex: Int!, $newParentId: Int) {
  moveTask(where: {id: $id, newIndex: $newIndex, newParentId: $newParentId}) {
    id
    parentId
    index
  }
}
    `;
export type MoveOfferEditorPositionMutationFn = ApolloReactCommon.MutationFunction<MoveOfferEditorPositionMutation, MoveOfferEditorPositionMutationVariables>;
export type MoveOfferEditorPositionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<MoveOfferEditorPositionMutation, MoveOfferEditorPositionMutationVariables>, 'mutation'>;

    export const MoveOfferEditorPositionComponent = (props: MoveOfferEditorPositionComponentProps) => (
      <ApolloReactComponents.Mutation<MoveOfferEditorPositionMutation, MoveOfferEditorPositionMutationVariables> mutation={MoveOfferEditorPositionDocument} {...props} />
    );
    
export type MoveOfferEditorPositionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<MoveOfferEditorPositionMutation, MoveOfferEditorPositionMutationVariables>
    } & TChildProps;
export function withMoveOfferEditorPosition<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  MoveOfferEditorPositionMutation,
  MoveOfferEditorPositionMutationVariables,
  MoveOfferEditorPositionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, MoveOfferEditorPositionMutation, MoveOfferEditorPositionMutationVariables, MoveOfferEditorPositionProps<TChildProps, TDataName>>(MoveOfferEditorPositionDocument, {
      alias: 'moveOfferEditorPosition',
      ...operationOptions
    });
};

/**
 * __useMoveOfferEditorPositionMutation__
 *
 * To run a mutation, you first call `useMoveOfferEditorPositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveOfferEditorPositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveOfferEditorPositionMutation, { data, loading, error }] = useMoveOfferEditorPositionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      newIndex: // value for 'newIndex'
 *      newParentId: // value for 'newParentId'
 *   },
 * });
 */
export function useMoveOfferEditorPositionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MoveOfferEditorPositionMutation, MoveOfferEditorPositionMutationVariables>) {
        return ApolloReactHooks.useMutation<MoveOfferEditorPositionMutation, MoveOfferEditorPositionMutationVariables>(MoveOfferEditorPositionDocument, baseOptions);
      }
export type MoveOfferEditorPositionMutationHookResult = ReturnType<typeof useMoveOfferEditorPositionMutation>;
export type MoveOfferEditorPositionMutationResult = ApolloReactCommon.MutationResult<MoveOfferEditorPositionMutation>;
export type MoveOfferEditorPositionMutationOptions = ApolloReactCommon.BaseMutationOptions<MoveOfferEditorPositionMutation, MoveOfferEditorPositionMutationVariables>;
export const AddOfferEditorPositionDocument = gql`
    mutation AddOfferEditorPosition($offerId: Int!, $name: String!, $companyId: Int!, $parentId: Int) {
  addTask(where: {name: $name, companyId: $companyId, offerId: $offerId, parentId: $parentId}) {
    addedTask {
      ...OfferEditorPosition
    }
  }
}
    ${OfferEditorPositionFragmentDoc}`;
export type AddOfferEditorPositionMutationFn = ApolloReactCommon.MutationFunction<AddOfferEditorPositionMutation, AddOfferEditorPositionMutationVariables>;
export type AddOfferEditorPositionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddOfferEditorPositionMutation, AddOfferEditorPositionMutationVariables>, 'mutation'>;

    export const AddOfferEditorPositionComponent = (props: AddOfferEditorPositionComponentProps) => (
      <ApolloReactComponents.Mutation<AddOfferEditorPositionMutation, AddOfferEditorPositionMutationVariables> mutation={AddOfferEditorPositionDocument} {...props} />
    );
    
export type AddOfferEditorPositionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AddOfferEditorPositionMutation, AddOfferEditorPositionMutationVariables>
    } & TChildProps;
export function withAddOfferEditorPosition<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddOfferEditorPositionMutation,
  AddOfferEditorPositionMutationVariables,
  AddOfferEditorPositionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AddOfferEditorPositionMutation, AddOfferEditorPositionMutationVariables, AddOfferEditorPositionProps<TChildProps, TDataName>>(AddOfferEditorPositionDocument, {
      alias: 'addOfferEditorPosition',
      ...operationOptions
    });
};

/**
 * __useAddOfferEditorPositionMutation__
 *
 * To run a mutation, you first call `useAddOfferEditorPositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOfferEditorPositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOfferEditorPositionMutation, { data, loading, error }] = useAddOfferEditorPositionMutation({
 *   variables: {
 *      offerId: // value for 'offerId'
 *      name: // value for 'name'
 *      companyId: // value for 'companyId'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useAddOfferEditorPositionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddOfferEditorPositionMutation, AddOfferEditorPositionMutationVariables>) {
        return ApolloReactHooks.useMutation<AddOfferEditorPositionMutation, AddOfferEditorPositionMutationVariables>(AddOfferEditorPositionDocument, baseOptions);
      }
export type AddOfferEditorPositionMutationHookResult = ReturnType<typeof useAddOfferEditorPositionMutation>;
export type AddOfferEditorPositionMutationResult = ApolloReactCommon.MutationResult<AddOfferEditorPositionMutation>;
export type AddOfferEditorPositionMutationOptions = ApolloReactCommon.BaseMutationOptions<AddOfferEditorPositionMutation, AddOfferEditorPositionMutationVariables>;
export const DuplicateOfferEditorOfferDocument = gql`
    mutation DuplicateOfferEditorOffer($offerId: Int!) {
  duplicateOffer(where: {offerId: $offerId}) {
    ...OfferEditorOffer
  }
}
    ${OfferEditorOfferFragmentDoc}`;
export type DuplicateOfferEditorOfferMutationFn = ApolloReactCommon.MutationFunction<DuplicateOfferEditorOfferMutation, DuplicateOfferEditorOfferMutationVariables>;
export type DuplicateOfferEditorOfferComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DuplicateOfferEditorOfferMutation, DuplicateOfferEditorOfferMutationVariables>, 'mutation'>;

    export const DuplicateOfferEditorOfferComponent = (props: DuplicateOfferEditorOfferComponentProps) => (
      <ApolloReactComponents.Mutation<DuplicateOfferEditorOfferMutation, DuplicateOfferEditorOfferMutationVariables> mutation={DuplicateOfferEditorOfferDocument} {...props} />
    );
    
export type DuplicateOfferEditorOfferProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DuplicateOfferEditorOfferMutation, DuplicateOfferEditorOfferMutationVariables>
    } & TChildProps;
export function withDuplicateOfferEditorOffer<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DuplicateOfferEditorOfferMutation,
  DuplicateOfferEditorOfferMutationVariables,
  DuplicateOfferEditorOfferProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DuplicateOfferEditorOfferMutation, DuplicateOfferEditorOfferMutationVariables, DuplicateOfferEditorOfferProps<TChildProps, TDataName>>(DuplicateOfferEditorOfferDocument, {
      alias: 'duplicateOfferEditorOffer',
      ...operationOptions
    });
};

/**
 * __useDuplicateOfferEditorOfferMutation__
 *
 * To run a mutation, you first call `useDuplicateOfferEditorOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateOfferEditorOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateOfferEditorOfferMutation, { data, loading, error }] = useDuplicateOfferEditorOfferMutation({
 *   variables: {
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useDuplicateOfferEditorOfferMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DuplicateOfferEditorOfferMutation, DuplicateOfferEditorOfferMutationVariables>) {
        return ApolloReactHooks.useMutation<DuplicateOfferEditorOfferMutation, DuplicateOfferEditorOfferMutationVariables>(DuplicateOfferEditorOfferDocument, baseOptions);
      }
export type DuplicateOfferEditorOfferMutationHookResult = ReturnType<typeof useDuplicateOfferEditorOfferMutation>;
export type DuplicateOfferEditorOfferMutationResult = ApolloReactCommon.MutationResult<DuplicateOfferEditorOfferMutation>;
export type DuplicateOfferEditorOfferMutationOptions = ApolloReactCommon.BaseMutationOptions<DuplicateOfferEditorOfferMutation, DuplicateOfferEditorOfferMutationVariables>;
export const UpdateOfferEditorOfferDocument = gql`
    mutation UpdateOfferEditorOffer($id: Int!, $status: OfferStatus, $name: String, $preText: String, $preTextTemplate: Int, $afterText: String, $afterTextTemplate: Int, $sendMail: Boolean, $mailAttachPDF: Boolean, $emailText: String, $emailTextTemplate: Int, $asAPackage: Boolean, $companyId: Int, $contactPersonId: Int, $customContactEmail: String, $validity: Date, $costEstimate: Boolean) {
  updateOffer(where: {id: $id, status: $status, name: $name, preText: $preText, preTextTemplate: $preTextTemplate, afterText: $afterText, afterTextTemplate: $afterTextTemplate, sendMail: $sendMail, mailAttachPDF: $mailAttachPDF, emailText: $emailText, emailTextTemplate: $emailTextTemplate, asAPackage: $asAPackage, companyId: $companyId, contactPersonId: $contactPersonId, customContactEmail: $customContactEmail, validity: $validity, costEstimate: $costEstimate}) {
    ...OfferEditorOffer
  }
}
    ${OfferEditorOfferFragmentDoc}`;
export type UpdateOfferEditorOfferMutationFn = ApolloReactCommon.MutationFunction<UpdateOfferEditorOfferMutation, UpdateOfferEditorOfferMutationVariables>;
export type UpdateOfferEditorOfferComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateOfferEditorOfferMutation, UpdateOfferEditorOfferMutationVariables>, 'mutation'>;

    export const UpdateOfferEditorOfferComponent = (props: UpdateOfferEditorOfferComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateOfferEditorOfferMutation, UpdateOfferEditorOfferMutationVariables> mutation={UpdateOfferEditorOfferDocument} {...props} />
    );
    
export type UpdateOfferEditorOfferProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateOfferEditorOfferMutation, UpdateOfferEditorOfferMutationVariables>
    } & TChildProps;
export function withUpdateOfferEditorOffer<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateOfferEditorOfferMutation,
  UpdateOfferEditorOfferMutationVariables,
  UpdateOfferEditorOfferProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateOfferEditorOfferMutation, UpdateOfferEditorOfferMutationVariables, UpdateOfferEditorOfferProps<TChildProps, TDataName>>(UpdateOfferEditorOfferDocument, {
      alias: 'updateOfferEditorOffer',
      ...operationOptions
    });
};

/**
 * __useUpdateOfferEditorOfferMutation__
 *
 * To run a mutation, you first call `useUpdateOfferEditorOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOfferEditorOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOfferEditorOfferMutation, { data, loading, error }] = useUpdateOfferEditorOfferMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *      name: // value for 'name'
 *      preText: // value for 'preText'
 *      preTextTemplate: // value for 'preTextTemplate'
 *      afterText: // value for 'afterText'
 *      afterTextTemplate: // value for 'afterTextTemplate'
 *      sendMail: // value for 'sendMail'
 *      mailAttachPDF: // value for 'mailAttachPDF'
 *      emailText: // value for 'emailText'
 *      emailTextTemplate: // value for 'emailTextTemplate'
 *      asAPackage: // value for 'asAPackage'
 *      companyId: // value for 'companyId'
 *      contactPersonId: // value for 'contactPersonId'
 *      customContactEmail: // value for 'customContactEmail'
 *      validity: // value for 'validity'
 *      costEstimate: // value for 'costEstimate'
 *   },
 * });
 */
export function useUpdateOfferEditorOfferMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOfferEditorOfferMutation, UpdateOfferEditorOfferMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOfferEditorOfferMutation, UpdateOfferEditorOfferMutationVariables>(UpdateOfferEditorOfferDocument, baseOptions);
      }
export type UpdateOfferEditorOfferMutationHookResult = ReturnType<typeof useUpdateOfferEditorOfferMutation>;
export type UpdateOfferEditorOfferMutationResult = ApolloReactCommon.MutationResult<UpdateOfferEditorOfferMutation>;
export type UpdateOfferEditorOfferMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOfferEditorOfferMutation, UpdateOfferEditorOfferMutationVariables>;
export const OfferEditorUpdateTaskDocument = gql`
    mutation OfferEditorUpdateTask($id: Int!, $name: String, $description: String, $prioId: Int, $deadline: Int, $timeBudget: Int, $taskCategory: Int, $priceCalcMethod: PriceCalcMethodType, $customHourlyWorkRate: Float, $timeBudgetViewUnit: String, $flatRatePrice: Float) {
  updateTask(where: {id: $id, name: $name, description: $description, prioId: $prioId, deadline: $deadline, timeBudget: $timeBudget, taskCategory: $taskCategory, priceCalcMethod: $priceCalcMethod, customHourlyWorkRate: $customHourlyWorkRate, timeBudgetViewUnit: $timeBudgetViewUnit, flatRatePrice: $flatRatePrice}) {
    id
    name
    description
    deadline
    timeBudget
    priceCalcMethod
    timeBudgetViewUnit
    hourlyWorkRate
    prio {
      id
    }
    taskCategory
    flatRatePrice
    price {
      original
      adjusted
    }
  }
}
    `;
export type OfferEditorUpdateTaskMutationFn = ApolloReactCommon.MutationFunction<OfferEditorUpdateTaskMutation, OfferEditorUpdateTaskMutationVariables>;
export type OfferEditorUpdateTaskComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<OfferEditorUpdateTaskMutation, OfferEditorUpdateTaskMutationVariables>, 'mutation'>;

    export const OfferEditorUpdateTaskComponent = (props: OfferEditorUpdateTaskComponentProps) => (
      <ApolloReactComponents.Mutation<OfferEditorUpdateTaskMutation, OfferEditorUpdateTaskMutationVariables> mutation={OfferEditorUpdateTaskDocument} {...props} />
    );
    
export type OfferEditorUpdateTaskProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<OfferEditorUpdateTaskMutation, OfferEditorUpdateTaskMutationVariables>
    } & TChildProps;
export function withOfferEditorUpdateTask<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OfferEditorUpdateTaskMutation,
  OfferEditorUpdateTaskMutationVariables,
  OfferEditorUpdateTaskProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, OfferEditorUpdateTaskMutation, OfferEditorUpdateTaskMutationVariables, OfferEditorUpdateTaskProps<TChildProps, TDataName>>(OfferEditorUpdateTaskDocument, {
      alias: 'offerEditorUpdateTask',
      ...operationOptions
    });
};

/**
 * __useOfferEditorUpdateTaskMutation__
 *
 * To run a mutation, you first call `useOfferEditorUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferEditorUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offerEditorUpdateTaskMutation, { data, loading, error }] = useOfferEditorUpdateTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      prioId: // value for 'prioId'
 *      deadline: // value for 'deadline'
 *      timeBudget: // value for 'timeBudget'
 *      taskCategory: // value for 'taskCategory'
 *      priceCalcMethod: // value for 'priceCalcMethod'
 *      customHourlyWorkRate: // value for 'customHourlyWorkRate'
 *      timeBudgetViewUnit: // value for 'timeBudgetViewUnit'
 *      flatRatePrice: // value for 'flatRatePrice'
 *   },
 * });
 */
export function useOfferEditorUpdateTaskMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OfferEditorUpdateTaskMutation, OfferEditorUpdateTaskMutationVariables>) {
        return ApolloReactHooks.useMutation<OfferEditorUpdateTaskMutation, OfferEditorUpdateTaskMutationVariables>(OfferEditorUpdateTaskDocument, baseOptions);
      }
export type OfferEditorUpdateTaskMutationHookResult = ReturnType<typeof useOfferEditorUpdateTaskMutation>;
export type OfferEditorUpdateTaskMutationResult = ApolloReactCommon.MutationResult<OfferEditorUpdateTaskMutation>;
export type OfferEditorUpdateTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<OfferEditorUpdateTaskMutation, OfferEditorUpdateTaskMutationVariables>;
export const OfferEditorAddOfferDiscountDocument = gql`
    mutation OfferEditorAddOfferDiscount($value: Float!, $unit: Charge!, $productID: Int!) {
  addOfferDiscount(where: {value: $value, unit: $unit, productID: $productID}) {
    id
    discount {
      id
      value
      unit
    }
  }
}
    `;
export type OfferEditorAddOfferDiscountMutationFn = ApolloReactCommon.MutationFunction<OfferEditorAddOfferDiscountMutation, OfferEditorAddOfferDiscountMutationVariables>;
export type OfferEditorAddOfferDiscountComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<OfferEditorAddOfferDiscountMutation, OfferEditorAddOfferDiscountMutationVariables>, 'mutation'>;

    export const OfferEditorAddOfferDiscountComponent = (props: OfferEditorAddOfferDiscountComponentProps) => (
      <ApolloReactComponents.Mutation<OfferEditorAddOfferDiscountMutation, OfferEditorAddOfferDiscountMutationVariables> mutation={OfferEditorAddOfferDiscountDocument} {...props} />
    );
    
export type OfferEditorAddOfferDiscountProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<OfferEditorAddOfferDiscountMutation, OfferEditorAddOfferDiscountMutationVariables>
    } & TChildProps;
export function withOfferEditorAddOfferDiscount<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OfferEditorAddOfferDiscountMutation,
  OfferEditorAddOfferDiscountMutationVariables,
  OfferEditorAddOfferDiscountProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, OfferEditorAddOfferDiscountMutation, OfferEditorAddOfferDiscountMutationVariables, OfferEditorAddOfferDiscountProps<TChildProps, TDataName>>(OfferEditorAddOfferDiscountDocument, {
      alias: 'offerEditorAddOfferDiscount',
      ...operationOptions
    });
};

/**
 * __useOfferEditorAddOfferDiscountMutation__
 *
 * To run a mutation, you first call `useOfferEditorAddOfferDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferEditorAddOfferDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offerEditorAddOfferDiscountMutation, { data, loading, error }] = useOfferEditorAddOfferDiscountMutation({
 *   variables: {
 *      value: // value for 'value'
 *      unit: // value for 'unit'
 *      productID: // value for 'productID'
 *   },
 * });
 */
export function useOfferEditorAddOfferDiscountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OfferEditorAddOfferDiscountMutation, OfferEditorAddOfferDiscountMutationVariables>) {
        return ApolloReactHooks.useMutation<OfferEditorAddOfferDiscountMutation, OfferEditorAddOfferDiscountMutationVariables>(OfferEditorAddOfferDiscountDocument, baseOptions);
      }
export type OfferEditorAddOfferDiscountMutationHookResult = ReturnType<typeof useOfferEditorAddOfferDiscountMutation>;
export type OfferEditorAddOfferDiscountMutationResult = ApolloReactCommon.MutationResult<OfferEditorAddOfferDiscountMutation>;
export type OfferEditorAddOfferDiscountMutationOptions = ApolloReactCommon.BaseMutationOptions<OfferEditorAddOfferDiscountMutation, OfferEditorAddOfferDiscountMutationVariables>;
export const OfferEditorUpdateOfferDiscountDocument = gql`
    mutation OfferEditorUpdateOfferDiscount($id: Int!, $value: Float!, $unit: Charge!, $productID: Int!) {
  updateOfferDiscount(where: {id: $id, value: $value, unit: $unit, productID: $productID}) {
    id
    discount {
      id
      value
      unit
    }
  }
}
    `;
export type OfferEditorUpdateOfferDiscountMutationFn = ApolloReactCommon.MutationFunction<OfferEditorUpdateOfferDiscountMutation, OfferEditorUpdateOfferDiscountMutationVariables>;
export type OfferEditorUpdateOfferDiscountComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<OfferEditorUpdateOfferDiscountMutation, OfferEditorUpdateOfferDiscountMutationVariables>, 'mutation'>;

    export const OfferEditorUpdateOfferDiscountComponent = (props: OfferEditorUpdateOfferDiscountComponentProps) => (
      <ApolloReactComponents.Mutation<OfferEditorUpdateOfferDiscountMutation, OfferEditorUpdateOfferDiscountMutationVariables> mutation={OfferEditorUpdateOfferDiscountDocument} {...props} />
    );
    
export type OfferEditorUpdateOfferDiscountProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<OfferEditorUpdateOfferDiscountMutation, OfferEditorUpdateOfferDiscountMutationVariables>
    } & TChildProps;
export function withOfferEditorUpdateOfferDiscount<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OfferEditorUpdateOfferDiscountMutation,
  OfferEditorUpdateOfferDiscountMutationVariables,
  OfferEditorUpdateOfferDiscountProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, OfferEditorUpdateOfferDiscountMutation, OfferEditorUpdateOfferDiscountMutationVariables, OfferEditorUpdateOfferDiscountProps<TChildProps, TDataName>>(OfferEditorUpdateOfferDiscountDocument, {
      alias: 'offerEditorUpdateOfferDiscount',
      ...operationOptions
    });
};

/**
 * __useOfferEditorUpdateOfferDiscountMutation__
 *
 * To run a mutation, you first call `useOfferEditorUpdateOfferDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferEditorUpdateOfferDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offerEditorUpdateOfferDiscountMutation, { data, loading, error }] = useOfferEditorUpdateOfferDiscountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      value: // value for 'value'
 *      unit: // value for 'unit'
 *      productID: // value for 'productID'
 *   },
 * });
 */
export function useOfferEditorUpdateOfferDiscountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OfferEditorUpdateOfferDiscountMutation, OfferEditorUpdateOfferDiscountMutationVariables>) {
        return ApolloReactHooks.useMutation<OfferEditorUpdateOfferDiscountMutation, OfferEditorUpdateOfferDiscountMutationVariables>(OfferEditorUpdateOfferDiscountDocument, baseOptions);
      }
export type OfferEditorUpdateOfferDiscountMutationHookResult = ReturnType<typeof useOfferEditorUpdateOfferDiscountMutation>;
export type OfferEditorUpdateOfferDiscountMutationResult = ApolloReactCommon.MutationResult<OfferEditorUpdateOfferDiscountMutation>;
export type OfferEditorUpdateOfferDiscountMutationOptions = ApolloReactCommon.BaseMutationOptions<OfferEditorUpdateOfferDiscountMutation, OfferEditorUpdateOfferDiscountMutationVariables>;
export const OfferEditorDeleteOfferDiscountDocument = gql`
    mutation OfferEditorDeleteOfferDiscount($id: Int!) {
  deleteOfferDiscount(where: {id: $id}) {
    id
    discount {
      id
      value
      unit
    }
  }
}
    `;
export type OfferEditorDeleteOfferDiscountMutationFn = ApolloReactCommon.MutationFunction<OfferEditorDeleteOfferDiscountMutation, OfferEditorDeleteOfferDiscountMutationVariables>;
export type OfferEditorDeleteOfferDiscountComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<OfferEditorDeleteOfferDiscountMutation, OfferEditorDeleteOfferDiscountMutationVariables>, 'mutation'>;

    export const OfferEditorDeleteOfferDiscountComponent = (props: OfferEditorDeleteOfferDiscountComponentProps) => (
      <ApolloReactComponents.Mutation<OfferEditorDeleteOfferDiscountMutation, OfferEditorDeleteOfferDiscountMutationVariables> mutation={OfferEditorDeleteOfferDiscountDocument} {...props} />
    );
    
export type OfferEditorDeleteOfferDiscountProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<OfferEditorDeleteOfferDiscountMutation, OfferEditorDeleteOfferDiscountMutationVariables>
    } & TChildProps;
export function withOfferEditorDeleteOfferDiscount<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OfferEditorDeleteOfferDiscountMutation,
  OfferEditorDeleteOfferDiscountMutationVariables,
  OfferEditorDeleteOfferDiscountProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, OfferEditorDeleteOfferDiscountMutation, OfferEditorDeleteOfferDiscountMutationVariables, OfferEditorDeleteOfferDiscountProps<TChildProps, TDataName>>(OfferEditorDeleteOfferDiscountDocument, {
      alias: 'offerEditorDeleteOfferDiscount',
      ...operationOptions
    });
};

/**
 * __useOfferEditorDeleteOfferDiscountMutation__
 *
 * To run a mutation, you first call `useOfferEditorDeleteOfferDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferEditorDeleteOfferDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offerEditorDeleteOfferDiscountMutation, { data, loading, error }] = useOfferEditorDeleteOfferDiscountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOfferEditorDeleteOfferDiscountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OfferEditorDeleteOfferDiscountMutation, OfferEditorDeleteOfferDiscountMutationVariables>) {
        return ApolloReactHooks.useMutation<OfferEditorDeleteOfferDiscountMutation, OfferEditorDeleteOfferDiscountMutationVariables>(OfferEditorDeleteOfferDiscountDocument, baseOptions);
      }
export type OfferEditorDeleteOfferDiscountMutationHookResult = ReturnType<typeof useOfferEditorDeleteOfferDiscountMutation>;
export type OfferEditorDeleteOfferDiscountMutationResult = ApolloReactCommon.MutationResult<OfferEditorDeleteOfferDiscountMutation>;
export type OfferEditorDeleteOfferDiscountMutationOptions = ApolloReactCommon.BaseMutationOptions<OfferEditorDeleteOfferDiscountMutation, OfferEditorDeleteOfferDiscountMutationVariables>;
export const OfferEditorOfferUpdatedDocument = gql`
    subscription OfferEditorOfferUpdated {
  offerUpdated {
    ...OfferEditorOffer
  }
}
    ${OfferEditorOfferFragmentDoc}`;
export type OfferEditorOfferUpdatedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<OfferEditorOfferUpdatedSubscription, OfferEditorOfferUpdatedSubscriptionVariables>, 'subscription'>;

    export const OfferEditorOfferUpdatedComponent = (props: OfferEditorOfferUpdatedComponentProps) => (
      <ApolloReactComponents.Subscription<OfferEditorOfferUpdatedSubscription, OfferEditorOfferUpdatedSubscriptionVariables> subscription={OfferEditorOfferUpdatedDocument} {...props} />
    );
    
export type OfferEditorOfferUpdatedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OfferEditorOfferUpdatedSubscription, OfferEditorOfferUpdatedSubscriptionVariables>
    } & TChildProps;
export function withOfferEditorOfferUpdated<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OfferEditorOfferUpdatedSubscription,
  OfferEditorOfferUpdatedSubscriptionVariables,
  OfferEditorOfferUpdatedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, OfferEditorOfferUpdatedSubscription, OfferEditorOfferUpdatedSubscriptionVariables, OfferEditorOfferUpdatedProps<TChildProps, TDataName>>(OfferEditorOfferUpdatedDocument, {
      alias: 'offerEditorOfferUpdated',
      ...operationOptions
    });
};

/**
 * __useOfferEditorOfferUpdatedSubscription__
 *
 * To run a query within a React component, call `useOfferEditorOfferUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOfferEditorOfferUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferEditorOfferUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOfferEditorOfferUpdatedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<OfferEditorOfferUpdatedSubscription, OfferEditorOfferUpdatedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<OfferEditorOfferUpdatedSubscription, OfferEditorOfferUpdatedSubscriptionVariables>(OfferEditorOfferUpdatedDocument, baseOptions);
      }
export type OfferEditorOfferUpdatedSubscriptionHookResult = ReturnType<typeof useOfferEditorOfferUpdatedSubscription>;
export type OfferEditorOfferUpdatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<OfferEditorOfferUpdatedSubscription>;
export const OfferEditorPositionAddedDocument = gql`
    subscription OfferEditorPositionAdded($offerId: Int!) {
  taskAdded(where: {offerId: $offerId}) {
    addedTask {
      ...OfferEditorPosition
    }
  }
}
    ${OfferEditorPositionFragmentDoc}`;
export type OfferEditorPositionAddedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<OfferEditorPositionAddedSubscription, OfferEditorPositionAddedSubscriptionVariables>, 'subscription'>;

    export const OfferEditorPositionAddedComponent = (props: OfferEditorPositionAddedComponentProps) => (
      <ApolloReactComponents.Subscription<OfferEditorPositionAddedSubscription, OfferEditorPositionAddedSubscriptionVariables> subscription={OfferEditorPositionAddedDocument} {...props} />
    );
    
export type OfferEditorPositionAddedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OfferEditorPositionAddedSubscription, OfferEditorPositionAddedSubscriptionVariables>
    } & TChildProps;
export function withOfferEditorPositionAdded<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OfferEditorPositionAddedSubscription,
  OfferEditorPositionAddedSubscriptionVariables,
  OfferEditorPositionAddedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, OfferEditorPositionAddedSubscription, OfferEditorPositionAddedSubscriptionVariables, OfferEditorPositionAddedProps<TChildProps, TDataName>>(OfferEditorPositionAddedDocument, {
      alias: 'offerEditorPositionAdded',
      ...operationOptions
    });
};

/**
 * __useOfferEditorPositionAddedSubscription__
 *
 * To run a query within a React component, call `useOfferEditorPositionAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOfferEditorPositionAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferEditorPositionAddedSubscription({
 *   variables: {
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useOfferEditorPositionAddedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<OfferEditorPositionAddedSubscription, OfferEditorPositionAddedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<OfferEditorPositionAddedSubscription, OfferEditorPositionAddedSubscriptionVariables>(OfferEditorPositionAddedDocument, baseOptions);
      }
export type OfferEditorPositionAddedSubscriptionHookResult = ReturnType<typeof useOfferEditorPositionAddedSubscription>;
export type OfferEditorPositionAddedSubscriptionResult = ApolloReactCommon.SubscriptionResult<OfferEditorPositionAddedSubscription>;
export const OfferEditorPositionUpdatedDocument = gql`
    subscription OfferEditorPositionUpdated($offerId: Int) {
  tasksUpdated(where: {offerId: $offerId}) {
    ...OfferEditorPosition
  }
}
    ${OfferEditorPositionFragmentDoc}`;
export type OfferEditorPositionUpdatedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<OfferEditorPositionUpdatedSubscription, OfferEditorPositionUpdatedSubscriptionVariables>, 'subscription'>;

    export const OfferEditorPositionUpdatedComponent = (props: OfferEditorPositionUpdatedComponentProps) => (
      <ApolloReactComponents.Subscription<OfferEditorPositionUpdatedSubscription, OfferEditorPositionUpdatedSubscriptionVariables> subscription={OfferEditorPositionUpdatedDocument} {...props} />
    );
    
export type OfferEditorPositionUpdatedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OfferEditorPositionUpdatedSubscription, OfferEditorPositionUpdatedSubscriptionVariables>
    } & TChildProps;
export function withOfferEditorPositionUpdated<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OfferEditorPositionUpdatedSubscription,
  OfferEditorPositionUpdatedSubscriptionVariables,
  OfferEditorPositionUpdatedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, OfferEditorPositionUpdatedSubscription, OfferEditorPositionUpdatedSubscriptionVariables, OfferEditorPositionUpdatedProps<TChildProps, TDataName>>(OfferEditorPositionUpdatedDocument, {
      alias: 'offerEditorPositionUpdated',
      ...operationOptions
    });
};

/**
 * __useOfferEditorPositionUpdatedSubscription__
 *
 * To run a query within a React component, call `useOfferEditorPositionUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOfferEditorPositionUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferEditorPositionUpdatedSubscription({
 *   variables: {
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useOfferEditorPositionUpdatedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<OfferEditorPositionUpdatedSubscription, OfferEditorPositionUpdatedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<OfferEditorPositionUpdatedSubscription, OfferEditorPositionUpdatedSubscriptionVariables>(OfferEditorPositionUpdatedDocument, baseOptions);
      }
export type OfferEditorPositionUpdatedSubscriptionHookResult = ReturnType<typeof useOfferEditorPositionUpdatedSubscription>;
export type OfferEditorPositionUpdatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<OfferEditorPositionUpdatedSubscription>;
export const OfferEditorPositionDeletedDocument = gql`
    subscription OfferEditorPositionDeleted($offerId: Int) {
  tasksDeleted(where: {offerId: $offerId})
}
    `;
export type OfferEditorPositionDeletedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<OfferEditorPositionDeletedSubscription, OfferEditorPositionDeletedSubscriptionVariables>, 'subscription'>;

    export const OfferEditorPositionDeletedComponent = (props: OfferEditorPositionDeletedComponentProps) => (
      <ApolloReactComponents.Subscription<OfferEditorPositionDeletedSubscription, OfferEditorPositionDeletedSubscriptionVariables> subscription={OfferEditorPositionDeletedDocument} {...props} />
    );
    
export type OfferEditorPositionDeletedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OfferEditorPositionDeletedSubscription, OfferEditorPositionDeletedSubscriptionVariables>
    } & TChildProps;
export function withOfferEditorPositionDeleted<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OfferEditorPositionDeletedSubscription,
  OfferEditorPositionDeletedSubscriptionVariables,
  OfferEditorPositionDeletedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, OfferEditorPositionDeletedSubscription, OfferEditorPositionDeletedSubscriptionVariables, OfferEditorPositionDeletedProps<TChildProps, TDataName>>(OfferEditorPositionDeletedDocument, {
      alias: 'offerEditorPositionDeleted',
      ...operationOptions
    });
};

/**
 * __useOfferEditorPositionDeletedSubscription__
 *
 * To run a query within a React component, call `useOfferEditorPositionDeletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOfferEditorPositionDeletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferEditorPositionDeletedSubscription({
 *   variables: {
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useOfferEditorPositionDeletedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<OfferEditorPositionDeletedSubscription, OfferEditorPositionDeletedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<OfferEditorPositionDeletedSubscription, OfferEditorPositionDeletedSubscriptionVariables>(OfferEditorPositionDeletedDocument, baseOptions);
      }
export type OfferEditorPositionDeletedSubscriptionHookResult = ReturnType<typeof useOfferEditorPositionDeletedSubscription>;
export type OfferEditorPositionDeletedSubscriptionResult = ApolloReactCommon.SubscriptionResult<OfferEditorPositionDeletedSubscription>;
export const OfferEditorPdfUpdatedDocument = gql`
    subscription OfferEditorPDFUpdated($id: Int!, $interactive: Boolean!) {
  offerPDFUpdated(where: {id: $id, interactive: $interactive})
}
    `;
export type OfferEditorPdfUpdatedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<OfferEditorPdfUpdatedSubscription, OfferEditorPdfUpdatedSubscriptionVariables>, 'subscription'>;

    export const OfferEditorPdfUpdatedComponent = (props: OfferEditorPdfUpdatedComponentProps) => (
      <ApolloReactComponents.Subscription<OfferEditorPdfUpdatedSubscription, OfferEditorPdfUpdatedSubscriptionVariables> subscription={OfferEditorPdfUpdatedDocument} {...props} />
    );
    
export type OfferEditorPdfUpdatedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OfferEditorPdfUpdatedSubscription, OfferEditorPdfUpdatedSubscriptionVariables>
    } & TChildProps;
export function withOfferEditorPdfUpdated<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OfferEditorPdfUpdatedSubscription,
  OfferEditorPdfUpdatedSubscriptionVariables,
  OfferEditorPdfUpdatedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, OfferEditorPdfUpdatedSubscription, OfferEditorPdfUpdatedSubscriptionVariables, OfferEditorPdfUpdatedProps<TChildProps, TDataName>>(OfferEditorPdfUpdatedDocument, {
      alias: 'offerEditorPdfUpdated',
      ...operationOptions
    });
};

/**
 * __useOfferEditorPdfUpdatedSubscription__
 *
 * To run a query within a React component, call `useOfferEditorPdfUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOfferEditorPdfUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferEditorPdfUpdatedSubscription({
 *   variables: {
 *      id: // value for 'id'
 *      interactive: // value for 'interactive'
 *   },
 * });
 */
export function useOfferEditorPdfUpdatedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<OfferEditorPdfUpdatedSubscription, OfferEditorPdfUpdatedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<OfferEditorPdfUpdatedSubscription, OfferEditorPdfUpdatedSubscriptionVariables>(OfferEditorPdfUpdatedDocument, baseOptions);
      }
export type OfferEditorPdfUpdatedSubscriptionHookResult = ReturnType<typeof useOfferEditorPdfUpdatedSubscription>;
export type OfferEditorPdfUpdatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<OfferEditorPdfUpdatedSubscription>;
export const GetNextOfferIdDocument = gql`
    query GetNextOfferId {
  nextOfferId
}
    `;
export type GetNextOfferIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetNextOfferIdQuery, GetNextOfferIdQueryVariables>, 'query'>;

    export const GetNextOfferIdComponent = (props: GetNextOfferIdComponentProps) => (
      <ApolloReactComponents.Query<GetNextOfferIdQuery, GetNextOfferIdQueryVariables> query={GetNextOfferIdDocument} {...props} />
    );
    
export type GetNextOfferIdProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetNextOfferIdQuery, GetNextOfferIdQueryVariables>
    } & TChildProps;
export function withGetNextOfferId<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetNextOfferIdQuery,
  GetNextOfferIdQueryVariables,
  GetNextOfferIdProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetNextOfferIdQuery, GetNextOfferIdQueryVariables, GetNextOfferIdProps<TChildProps, TDataName>>(GetNextOfferIdDocument, {
      alias: 'getNextOfferId',
      ...operationOptions
    });
};

/**
 * __useGetNextOfferIdQuery__
 *
 * To run a query within a React component, call `useGetNextOfferIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNextOfferIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextOfferIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNextOfferIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNextOfferIdQuery, GetNextOfferIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNextOfferIdQuery, GetNextOfferIdQueryVariables>(GetNextOfferIdDocument, baseOptions);
      }
export function useGetNextOfferIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNextOfferIdQuery, GetNextOfferIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNextOfferIdQuery, GetNextOfferIdQueryVariables>(GetNextOfferIdDocument, baseOptions);
        }
export type GetNextOfferIdQueryHookResult = ReturnType<typeof useGetNextOfferIdQuery>;
export type GetNextOfferIdLazyQueryHookResult = ReturnType<typeof useGetNextOfferIdLazyQuery>;
export type GetNextOfferIdQueryResult = ApolloReactCommon.QueryResult<GetNextOfferIdQuery, GetNextOfferIdQueryVariables>;
export const GetOfferlistDocument = gql`
    query GetOfferlist($loadOffers: Boolean!, $loadCostEstimates: Boolean!) {
  offers(where: {loadOffers: $loadOffers, loadCostEstimates: $loadCostEstimates}) {
    ...OfferListOffer
  }
}
    ${OfferListOfferFragmentDoc}`;
export type GetOfferlistComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetOfferlistQuery, GetOfferlistQueryVariables>, 'query'> & ({ variables: GetOfferlistQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetOfferlistComponent = (props: GetOfferlistComponentProps) => (
      <ApolloReactComponents.Query<GetOfferlistQuery, GetOfferlistQueryVariables> query={GetOfferlistDocument} {...props} />
    );
    
export type GetOfferlistProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetOfferlistQuery, GetOfferlistQueryVariables>
    } & TChildProps;
export function withGetOfferlist<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetOfferlistQuery,
  GetOfferlistQueryVariables,
  GetOfferlistProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetOfferlistQuery, GetOfferlistQueryVariables, GetOfferlistProps<TChildProps, TDataName>>(GetOfferlistDocument, {
      alias: 'getOfferlist',
      ...operationOptions
    });
};

/**
 * __useGetOfferlistQuery__
 *
 * To run a query within a React component, call `useGetOfferlistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfferlistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfferlistQuery({
 *   variables: {
 *      loadOffers: // value for 'loadOffers'
 *      loadCostEstimates: // value for 'loadCostEstimates'
 *   },
 * });
 */
export function useGetOfferlistQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOfferlistQuery, GetOfferlistQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOfferlistQuery, GetOfferlistQueryVariables>(GetOfferlistDocument, baseOptions);
      }
export function useGetOfferlistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOfferlistQuery, GetOfferlistQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOfferlistQuery, GetOfferlistQueryVariables>(GetOfferlistDocument, baseOptions);
        }
export type GetOfferlistQueryHookResult = ReturnType<typeof useGetOfferlistQuery>;
export type GetOfferlistLazyQueryHookResult = ReturnType<typeof useGetOfferlistLazyQuery>;
export type GetOfferlistQueryResult = ApolloReactCommon.QueryResult<GetOfferlistQuery, GetOfferlistQueryVariables>;
export const OfferListOfferUpdatedDocument = gql`
    subscription OfferListOfferUpdated {
  offerUpdated {
    ...OfferListOffer
  }
}
    ${OfferListOfferFragmentDoc}`;
export type OfferListOfferUpdatedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<OfferListOfferUpdatedSubscription, OfferListOfferUpdatedSubscriptionVariables>, 'subscription'>;

    export const OfferListOfferUpdatedComponent = (props: OfferListOfferUpdatedComponentProps) => (
      <ApolloReactComponents.Subscription<OfferListOfferUpdatedSubscription, OfferListOfferUpdatedSubscriptionVariables> subscription={OfferListOfferUpdatedDocument} {...props} />
    );
    
export type OfferListOfferUpdatedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OfferListOfferUpdatedSubscription, OfferListOfferUpdatedSubscriptionVariables>
    } & TChildProps;
export function withOfferListOfferUpdated<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OfferListOfferUpdatedSubscription,
  OfferListOfferUpdatedSubscriptionVariables,
  OfferListOfferUpdatedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, OfferListOfferUpdatedSubscription, OfferListOfferUpdatedSubscriptionVariables, OfferListOfferUpdatedProps<TChildProps, TDataName>>(OfferListOfferUpdatedDocument, {
      alias: 'offerListOfferUpdated',
      ...operationOptions
    });
};

/**
 * __useOfferListOfferUpdatedSubscription__
 *
 * To run a query within a React component, call `useOfferListOfferUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOfferListOfferUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferListOfferUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOfferListOfferUpdatedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<OfferListOfferUpdatedSubscription, OfferListOfferUpdatedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<OfferListOfferUpdatedSubscription, OfferListOfferUpdatedSubscriptionVariables>(OfferListOfferUpdatedDocument, baseOptions);
      }
export type OfferListOfferUpdatedSubscriptionHookResult = ReturnType<typeof useOfferListOfferUpdatedSubscription>;
export type OfferListOfferUpdatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<OfferListOfferUpdatedSubscription>;
export const OfferListOfferAddedDocument = gql`
    subscription OfferListOfferAdded {
  offerAdded {
    ...OfferListOffer
  }
}
    ${OfferListOfferFragmentDoc}`;
export type OfferListOfferAddedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<OfferListOfferAddedSubscription, OfferListOfferAddedSubscriptionVariables>, 'subscription'>;

    export const OfferListOfferAddedComponent = (props: OfferListOfferAddedComponentProps) => (
      <ApolloReactComponents.Subscription<OfferListOfferAddedSubscription, OfferListOfferAddedSubscriptionVariables> subscription={OfferListOfferAddedDocument} {...props} />
    );
    
export type OfferListOfferAddedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OfferListOfferAddedSubscription, OfferListOfferAddedSubscriptionVariables>
    } & TChildProps;
export function withOfferListOfferAdded<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OfferListOfferAddedSubscription,
  OfferListOfferAddedSubscriptionVariables,
  OfferListOfferAddedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, OfferListOfferAddedSubscription, OfferListOfferAddedSubscriptionVariables, OfferListOfferAddedProps<TChildProps, TDataName>>(OfferListOfferAddedDocument, {
      alias: 'offerListOfferAdded',
      ...operationOptions
    });
};

/**
 * __useOfferListOfferAddedSubscription__
 *
 * To run a query within a React component, call `useOfferListOfferAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOfferListOfferAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferListOfferAddedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOfferListOfferAddedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<OfferListOfferAddedSubscription, OfferListOfferAddedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<OfferListOfferAddedSubscription, OfferListOfferAddedSubscriptionVariables>(OfferListOfferAddedDocument, baseOptions);
      }
export type OfferListOfferAddedSubscriptionHookResult = ReturnType<typeof useOfferListOfferAddedSubscription>;
export type OfferListOfferAddedSubscriptionResult = ApolloReactCommon.SubscriptionResult<OfferListOfferAddedSubscription>;
export const OfferListOfferDeletedDocument = gql`
    subscription OfferListOfferDeleted {
  offerDeleted {
    id
  }
}
    `;
export type OfferListOfferDeletedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<OfferListOfferDeletedSubscription, OfferListOfferDeletedSubscriptionVariables>, 'subscription'>;

    export const OfferListOfferDeletedComponent = (props: OfferListOfferDeletedComponentProps) => (
      <ApolloReactComponents.Subscription<OfferListOfferDeletedSubscription, OfferListOfferDeletedSubscriptionVariables> subscription={OfferListOfferDeletedDocument} {...props} />
    );
    
export type OfferListOfferDeletedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OfferListOfferDeletedSubscription, OfferListOfferDeletedSubscriptionVariables>
    } & TChildProps;
export function withOfferListOfferDeleted<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OfferListOfferDeletedSubscription,
  OfferListOfferDeletedSubscriptionVariables,
  OfferListOfferDeletedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, OfferListOfferDeletedSubscription, OfferListOfferDeletedSubscriptionVariables, OfferListOfferDeletedProps<TChildProps, TDataName>>(OfferListOfferDeletedDocument, {
      alias: 'offerListOfferDeleted',
      ...operationOptions
    });
};

/**
 * __useOfferListOfferDeletedSubscription__
 *
 * To run a query within a React component, call `useOfferListOfferDeletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOfferListOfferDeletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferListOfferDeletedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOfferListOfferDeletedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<OfferListOfferDeletedSubscription, OfferListOfferDeletedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<OfferListOfferDeletedSubscription, OfferListOfferDeletedSubscriptionVariables>(OfferListOfferDeletedDocument, baseOptions);
      }
export type OfferListOfferDeletedSubscriptionHookResult = ReturnType<typeof useOfferListOfferDeletedSubscription>;
export type OfferListOfferDeletedSubscriptionResult = ApolloReactCommon.SubscriptionResult<OfferListOfferDeletedSubscription>;
export const OfferListAddOfferDocument = gql`
    mutation OfferListAddOffer($name: String!, $isCostEstimate: Boolean!) {
  addOffer(where: {name: $name, isCostEstimate: $isCostEstimate}) {
    ...OfferListOffer
  }
}
    ${OfferListOfferFragmentDoc}`;
export type OfferListAddOfferMutationFn = ApolloReactCommon.MutationFunction<OfferListAddOfferMutation, OfferListAddOfferMutationVariables>;
export type OfferListAddOfferComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<OfferListAddOfferMutation, OfferListAddOfferMutationVariables>, 'mutation'>;

    export const OfferListAddOfferComponent = (props: OfferListAddOfferComponentProps) => (
      <ApolloReactComponents.Mutation<OfferListAddOfferMutation, OfferListAddOfferMutationVariables> mutation={OfferListAddOfferDocument} {...props} />
    );
    
export type OfferListAddOfferProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<OfferListAddOfferMutation, OfferListAddOfferMutationVariables>
    } & TChildProps;
export function withOfferListAddOffer<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OfferListAddOfferMutation,
  OfferListAddOfferMutationVariables,
  OfferListAddOfferProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, OfferListAddOfferMutation, OfferListAddOfferMutationVariables, OfferListAddOfferProps<TChildProps, TDataName>>(OfferListAddOfferDocument, {
      alias: 'offerListAddOffer',
      ...operationOptions
    });
};

/**
 * __useOfferListAddOfferMutation__
 *
 * To run a mutation, you first call `useOfferListAddOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferListAddOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offerListAddOfferMutation, { data, loading, error }] = useOfferListAddOfferMutation({
 *   variables: {
 *      name: // value for 'name'
 *      isCostEstimate: // value for 'isCostEstimate'
 *   },
 * });
 */
export function useOfferListAddOfferMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OfferListAddOfferMutation, OfferListAddOfferMutationVariables>) {
        return ApolloReactHooks.useMutation<OfferListAddOfferMutation, OfferListAddOfferMutationVariables>(OfferListAddOfferDocument, baseOptions);
      }
export type OfferListAddOfferMutationHookResult = ReturnType<typeof useOfferListAddOfferMutation>;
export type OfferListAddOfferMutationResult = ApolloReactCommon.MutationResult<OfferListAddOfferMutation>;
export type OfferListAddOfferMutationOptions = ApolloReactCommon.BaseMutationOptions<OfferListAddOfferMutation, OfferListAddOfferMutationVariables>;
export const GetOfferByLinkDocument = gql`
    query GetOfferByLink($link: String!, $companyShort: String!) {
  offerByLink(where: {link: $link, companyShort: $companyShort}) {
    offer {
      ...OfferWebviewOffer
    }
    positions {
      ...OfferWebviewOfferPosition
    }
  }
}
    ${OfferWebviewOfferFragmentDoc}
${OfferWebviewOfferPositionFragmentDoc}`;
export type GetOfferByLinkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetOfferByLinkQuery, GetOfferByLinkQueryVariables>, 'query'> & ({ variables: GetOfferByLinkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetOfferByLinkComponent = (props: GetOfferByLinkComponentProps) => (
      <ApolloReactComponents.Query<GetOfferByLinkQuery, GetOfferByLinkQueryVariables> query={GetOfferByLinkDocument} {...props} />
    );
    
export type GetOfferByLinkProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetOfferByLinkQuery, GetOfferByLinkQueryVariables>
    } & TChildProps;
export function withGetOfferByLink<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetOfferByLinkQuery,
  GetOfferByLinkQueryVariables,
  GetOfferByLinkProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetOfferByLinkQuery, GetOfferByLinkQueryVariables, GetOfferByLinkProps<TChildProps, TDataName>>(GetOfferByLinkDocument, {
      alias: 'getOfferByLink',
      ...operationOptions
    });
};

/**
 * __useGetOfferByLinkQuery__
 *
 * To run a query within a React component, call `useGetOfferByLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfferByLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfferByLinkQuery({
 *   variables: {
 *      link: // value for 'link'
 *      companyShort: // value for 'companyShort'
 *   },
 * });
 */
export function useGetOfferByLinkQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOfferByLinkQuery, GetOfferByLinkQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOfferByLinkQuery, GetOfferByLinkQueryVariables>(GetOfferByLinkDocument, baseOptions);
      }
export function useGetOfferByLinkLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOfferByLinkQuery, GetOfferByLinkQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOfferByLinkQuery, GetOfferByLinkQueryVariables>(GetOfferByLinkDocument, baseOptions);
        }
export type GetOfferByLinkQueryHookResult = ReturnType<typeof useGetOfferByLinkQuery>;
export type GetOfferByLinkLazyQueryHookResult = ReturnType<typeof useGetOfferByLinkLazyQuery>;
export type GetOfferByLinkQueryResult = ApolloReactCommon.QueryResult<GetOfferByLinkQuery, GetOfferByLinkQueryVariables>;
export const OfferWebviewDownloadOfferDocument = gql`
    mutation OfferWebviewDownloadOffer($link: String!, $companyShort: String!) {
  downloadOffer(where: {link: $link, companyShort: $companyShort})
}
    `;
export type OfferWebviewDownloadOfferMutationFn = ApolloReactCommon.MutationFunction<OfferWebviewDownloadOfferMutation, OfferWebviewDownloadOfferMutationVariables>;
export type OfferWebviewDownloadOfferComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<OfferWebviewDownloadOfferMutation, OfferWebviewDownloadOfferMutationVariables>, 'mutation'>;

    export const OfferWebviewDownloadOfferComponent = (props: OfferWebviewDownloadOfferComponentProps) => (
      <ApolloReactComponents.Mutation<OfferWebviewDownloadOfferMutation, OfferWebviewDownloadOfferMutationVariables> mutation={OfferWebviewDownloadOfferDocument} {...props} />
    );
    
export type OfferWebviewDownloadOfferProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<OfferWebviewDownloadOfferMutation, OfferWebviewDownloadOfferMutationVariables>
    } & TChildProps;
export function withOfferWebviewDownloadOffer<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OfferWebviewDownloadOfferMutation,
  OfferWebviewDownloadOfferMutationVariables,
  OfferWebviewDownloadOfferProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, OfferWebviewDownloadOfferMutation, OfferWebviewDownloadOfferMutationVariables, OfferWebviewDownloadOfferProps<TChildProps, TDataName>>(OfferWebviewDownloadOfferDocument, {
      alias: 'offerWebviewDownloadOffer',
      ...operationOptions
    });
};

/**
 * __useOfferWebviewDownloadOfferMutation__
 *
 * To run a mutation, you first call `useOfferWebviewDownloadOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferWebviewDownloadOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offerWebviewDownloadOfferMutation, { data, loading, error }] = useOfferWebviewDownloadOfferMutation({
 *   variables: {
 *      link: // value for 'link'
 *      companyShort: // value for 'companyShort'
 *   },
 * });
 */
export function useOfferWebviewDownloadOfferMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OfferWebviewDownloadOfferMutation, OfferWebviewDownloadOfferMutationVariables>) {
        return ApolloReactHooks.useMutation<OfferWebviewDownloadOfferMutation, OfferWebviewDownloadOfferMutationVariables>(OfferWebviewDownloadOfferDocument, baseOptions);
      }
export type OfferWebviewDownloadOfferMutationHookResult = ReturnType<typeof useOfferWebviewDownloadOfferMutation>;
export type OfferWebviewDownloadOfferMutationResult = ApolloReactCommon.MutationResult<OfferWebviewDownloadOfferMutation>;
export type OfferWebviewDownloadOfferMutationOptions = ApolloReactCommon.BaseMutationOptions<OfferWebviewDownloadOfferMutation, OfferWebviewDownloadOfferMutationVariables>;
export const OfferWebviewAnswerOfferDocument = gql`
    mutation OfferWebviewAnswerOffer($link: String!, $companyShort: String!, $status: Boolean!) {
  answerOffer(where: {link: $link, companyShort: $companyShort, status: $status}) {
    ...OfferWebviewOffer
  }
}
    ${OfferWebviewOfferFragmentDoc}`;
export type OfferWebviewAnswerOfferMutationFn = ApolloReactCommon.MutationFunction<OfferWebviewAnswerOfferMutation, OfferWebviewAnswerOfferMutationVariables>;
export type OfferWebviewAnswerOfferComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<OfferWebviewAnswerOfferMutation, OfferWebviewAnswerOfferMutationVariables>, 'mutation'>;

    export const OfferWebviewAnswerOfferComponent = (props: OfferWebviewAnswerOfferComponentProps) => (
      <ApolloReactComponents.Mutation<OfferWebviewAnswerOfferMutation, OfferWebviewAnswerOfferMutationVariables> mutation={OfferWebviewAnswerOfferDocument} {...props} />
    );
    
export type OfferWebviewAnswerOfferProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<OfferWebviewAnswerOfferMutation, OfferWebviewAnswerOfferMutationVariables>
    } & TChildProps;
export function withOfferWebviewAnswerOffer<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OfferWebviewAnswerOfferMutation,
  OfferWebviewAnswerOfferMutationVariables,
  OfferWebviewAnswerOfferProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, OfferWebviewAnswerOfferMutation, OfferWebviewAnswerOfferMutationVariables, OfferWebviewAnswerOfferProps<TChildProps, TDataName>>(OfferWebviewAnswerOfferDocument, {
      alias: 'offerWebviewAnswerOffer',
      ...operationOptions
    });
};

/**
 * __useOfferWebviewAnswerOfferMutation__
 *
 * To run a mutation, you first call `useOfferWebviewAnswerOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferWebviewAnswerOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offerWebviewAnswerOfferMutation, { data, loading, error }] = useOfferWebviewAnswerOfferMutation({
 *   variables: {
 *      link: // value for 'link'
 *      companyShort: // value for 'companyShort'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useOfferWebviewAnswerOfferMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OfferWebviewAnswerOfferMutation, OfferWebviewAnswerOfferMutationVariables>) {
        return ApolloReactHooks.useMutation<OfferWebviewAnswerOfferMutation, OfferWebviewAnswerOfferMutationVariables>(OfferWebviewAnswerOfferDocument, baseOptions);
      }
export type OfferWebviewAnswerOfferMutationHookResult = ReturnType<typeof useOfferWebviewAnswerOfferMutation>;
export type OfferWebviewAnswerOfferMutationResult = ApolloReactCommon.MutationResult<OfferWebviewAnswerOfferMutation>;
export type OfferWebviewAnswerOfferMutationOptions = ApolloReactCommon.BaseMutationOptions<OfferWebviewAnswerOfferMutation, OfferWebviewAnswerOfferMutationVariables>;
export const OfferWebviewUpdateOfferPositionSelectionDocument = gql`
    mutation OfferWebviewUpdateOfferPositionSelection($taskId: Int!, $offerId: Int!, $selected: Boolean) {
  updateOfferPosition(where: {taskId: $taskId, offerId: $offerId, selected: $selected}) {
    id
    selected
  }
}
    `;
export type OfferWebviewUpdateOfferPositionSelectionMutationFn = ApolloReactCommon.MutationFunction<OfferWebviewUpdateOfferPositionSelectionMutation, OfferWebviewUpdateOfferPositionSelectionMutationVariables>;
export type OfferWebviewUpdateOfferPositionSelectionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<OfferWebviewUpdateOfferPositionSelectionMutation, OfferWebviewUpdateOfferPositionSelectionMutationVariables>, 'mutation'>;

    export const OfferWebviewUpdateOfferPositionSelectionComponent = (props: OfferWebviewUpdateOfferPositionSelectionComponentProps) => (
      <ApolloReactComponents.Mutation<OfferWebviewUpdateOfferPositionSelectionMutation, OfferWebviewUpdateOfferPositionSelectionMutationVariables> mutation={OfferWebviewUpdateOfferPositionSelectionDocument} {...props} />
    );
    
export type OfferWebviewUpdateOfferPositionSelectionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<OfferWebviewUpdateOfferPositionSelectionMutation, OfferWebviewUpdateOfferPositionSelectionMutationVariables>
    } & TChildProps;
export function withOfferWebviewUpdateOfferPositionSelection<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OfferWebviewUpdateOfferPositionSelectionMutation,
  OfferWebviewUpdateOfferPositionSelectionMutationVariables,
  OfferWebviewUpdateOfferPositionSelectionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, OfferWebviewUpdateOfferPositionSelectionMutation, OfferWebviewUpdateOfferPositionSelectionMutationVariables, OfferWebviewUpdateOfferPositionSelectionProps<TChildProps, TDataName>>(OfferWebviewUpdateOfferPositionSelectionDocument, {
      alias: 'offerWebviewUpdateOfferPositionSelection',
      ...operationOptions
    });
};

/**
 * __useOfferWebviewUpdateOfferPositionSelectionMutation__
 *
 * To run a mutation, you first call `useOfferWebviewUpdateOfferPositionSelectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferWebviewUpdateOfferPositionSelectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offerWebviewUpdateOfferPositionSelectionMutation, { data, loading, error }] = useOfferWebviewUpdateOfferPositionSelectionMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      offerId: // value for 'offerId'
 *      selected: // value for 'selected'
 *   },
 * });
 */
export function useOfferWebviewUpdateOfferPositionSelectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OfferWebviewUpdateOfferPositionSelectionMutation, OfferWebviewUpdateOfferPositionSelectionMutationVariables>) {
        return ApolloReactHooks.useMutation<OfferWebviewUpdateOfferPositionSelectionMutation, OfferWebviewUpdateOfferPositionSelectionMutationVariables>(OfferWebviewUpdateOfferPositionSelectionDocument, baseOptions);
      }
export type OfferWebviewUpdateOfferPositionSelectionMutationHookResult = ReturnType<typeof useOfferWebviewUpdateOfferPositionSelectionMutation>;
export type OfferWebviewUpdateOfferPositionSelectionMutationResult = ApolloReactCommon.MutationResult<OfferWebviewUpdateOfferPositionSelectionMutation>;
export type OfferWebviewUpdateOfferPositionSelectionMutationOptions = ApolloReactCommon.BaseMutationOptions<OfferWebviewUpdateOfferPositionSelectionMutation, OfferWebviewUpdateOfferPositionSelectionMutationVariables>;
export const OfferWebviewOfferUpdatedDocument = gql`
    subscription OfferWebviewOfferUpdated {
  offerUpdated {
    ...OfferWebviewOffer
  }
}
    ${OfferWebviewOfferFragmentDoc}`;
export type OfferWebviewOfferUpdatedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<OfferWebviewOfferUpdatedSubscription, OfferWebviewOfferUpdatedSubscriptionVariables>, 'subscription'>;

    export const OfferWebviewOfferUpdatedComponent = (props: OfferWebviewOfferUpdatedComponentProps) => (
      <ApolloReactComponents.Subscription<OfferWebviewOfferUpdatedSubscription, OfferWebviewOfferUpdatedSubscriptionVariables> subscription={OfferWebviewOfferUpdatedDocument} {...props} />
    );
    
export type OfferWebviewOfferUpdatedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OfferWebviewOfferUpdatedSubscription, OfferWebviewOfferUpdatedSubscriptionVariables>
    } & TChildProps;
export function withOfferWebviewOfferUpdated<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OfferWebviewOfferUpdatedSubscription,
  OfferWebviewOfferUpdatedSubscriptionVariables,
  OfferWebviewOfferUpdatedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, OfferWebviewOfferUpdatedSubscription, OfferWebviewOfferUpdatedSubscriptionVariables, OfferWebviewOfferUpdatedProps<TChildProps, TDataName>>(OfferWebviewOfferUpdatedDocument, {
      alias: 'offerWebviewOfferUpdated',
      ...operationOptions
    });
};

/**
 * __useOfferWebviewOfferUpdatedSubscription__
 *
 * To run a query within a React component, call `useOfferWebviewOfferUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOfferWebviewOfferUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferWebviewOfferUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOfferWebviewOfferUpdatedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<OfferWebviewOfferUpdatedSubscription, OfferWebviewOfferUpdatedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<OfferWebviewOfferUpdatedSubscription, OfferWebviewOfferUpdatedSubscriptionVariables>(OfferWebviewOfferUpdatedDocument, baseOptions);
      }
export type OfferWebviewOfferUpdatedSubscriptionHookResult = ReturnType<typeof useOfferWebviewOfferUpdatedSubscription>;
export type OfferWebviewOfferUpdatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<OfferWebviewOfferUpdatedSubscription>;
export const OfferWebviewTasksUpdatedDocument = gql`
    subscription OfferWebviewTasksUpdated($offerId: Int) {
  tasksUpdated(where: {offerId: $offerId}) {
    ...OfferWebviewOfferPosition
  }
}
    ${OfferWebviewOfferPositionFragmentDoc}`;
export type OfferWebviewTasksUpdatedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<OfferWebviewTasksUpdatedSubscription, OfferWebviewTasksUpdatedSubscriptionVariables>, 'subscription'>;

    export const OfferWebviewTasksUpdatedComponent = (props: OfferWebviewTasksUpdatedComponentProps) => (
      <ApolloReactComponents.Subscription<OfferWebviewTasksUpdatedSubscription, OfferWebviewTasksUpdatedSubscriptionVariables> subscription={OfferWebviewTasksUpdatedDocument} {...props} />
    );
    
export type OfferWebviewTasksUpdatedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OfferWebviewTasksUpdatedSubscription, OfferWebviewTasksUpdatedSubscriptionVariables>
    } & TChildProps;
export function withOfferWebviewTasksUpdated<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OfferWebviewTasksUpdatedSubscription,
  OfferWebviewTasksUpdatedSubscriptionVariables,
  OfferWebviewTasksUpdatedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, OfferWebviewTasksUpdatedSubscription, OfferWebviewTasksUpdatedSubscriptionVariables, OfferWebviewTasksUpdatedProps<TChildProps, TDataName>>(OfferWebviewTasksUpdatedDocument, {
      alias: 'offerWebviewTasksUpdated',
      ...operationOptions
    });
};

/**
 * __useOfferWebviewTasksUpdatedSubscription__
 *
 * To run a query within a React component, call `useOfferWebviewTasksUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOfferWebviewTasksUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferWebviewTasksUpdatedSubscription({
 *   variables: {
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useOfferWebviewTasksUpdatedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<OfferWebviewTasksUpdatedSubscription, OfferWebviewTasksUpdatedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<OfferWebviewTasksUpdatedSubscription, OfferWebviewTasksUpdatedSubscriptionVariables>(OfferWebviewTasksUpdatedDocument, baseOptions);
      }
export type OfferWebviewTasksUpdatedSubscriptionHookResult = ReturnType<typeof useOfferWebviewTasksUpdatedSubscription>;
export type OfferWebviewTasksUpdatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<OfferWebviewTasksUpdatedSubscription>;
export const AddOfferRateDocument = gql`
    mutation AddOfferRate($offerId: Int!, $at: Int!, $description: String) {
  addOfferRate(where: {offerId: $offerId, at: $at, description: $description}) {
    id
    name
    rates {
      ...RateEditorRate
    }
  }
}
    ${RateEditorRateFragmentDoc}`;
export type AddOfferRateMutationFn = ApolloReactCommon.MutationFunction<AddOfferRateMutation, AddOfferRateMutationVariables>;
export type AddOfferRateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddOfferRateMutation, AddOfferRateMutationVariables>, 'mutation'>;

    export const AddOfferRateComponent = (props: AddOfferRateComponentProps) => (
      <ApolloReactComponents.Mutation<AddOfferRateMutation, AddOfferRateMutationVariables> mutation={AddOfferRateDocument} {...props} />
    );
    
export type AddOfferRateProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AddOfferRateMutation, AddOfferRateMutationVariables>
    } & TChildProps;
export function withAddOfferRate<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddOfferRateMutation,
  AddOfferRateMutationVariables,
  AddOfferRateProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AddOfferRateMutation, AddOfferRateMutationVariables, AddOfferRateProps<TChildProps, TDataName>>(AddOfferRateDocument, {
      alias: 'addOfferRate',
      ...operationOptions
    });
};

/**
 * __useAddOfferRateMutation__
 *
 * To run a mutation, you first call `useAddOfferRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOfferRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOfferRateMutation, { data, loading, error }] = useAddOfferRateMutation({
 *   variables: {
 *      offerId: // value for 'offerId'
 *      at: // value for 'at'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useAddOfferRateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddOfferRateMutation, AddOfferRateMutationVariables>) {
        return ApolloReactHooks.useMutation<AddOfferRateMutation, AddOfferRateMutationVariables>(AddOfferRateDocument, baseOptions);
      }
export type AddOfferRateMutationHookResult = ReturnType<typeof useAddOfferRateMutation>;
export type AddOfferRateMutationResult = ApolloReactCommon.MutationResult<AddOfferRateMutation>;
export type AddOfferRateMutationOptions = ApolloReactCommon.BaseMutationOptions<AddOfferRateMutation, AddOfferRateMutationVariables>;
export const UpdateOfferRateDocument = gql`
    mutation UpdateOfferRate($id: Int!, $at: Int, $description: String) {
  updateOfferRate(where: {id: $id, at: $at, description: $description}) {
    id
    rates {
      ...RateEditorRate
    }
  }
}
    ${RateEditorRateFragmentDoc}`;
export type UpdateOfferRateMutationFn = ApolloReactCommon.MutationFunction<UpdateOfferRateMutation, UpdateOfferRateMutationVariables>;
export type UpdateOfferRateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateOfferRateMutation, UpdateOfferRateMutationVariables>, 'mutation'>;

    export const UpdateOfferRateComponent = (props: UpdateOfferRateComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateOfferRateMutation, UpdateOfferRateMutationVariables> mutation={UpdateOfferRateDocument} {...props} />
    );
    
export type UpdateOfferRateProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateOfferRateMutation, UpdateOfferRateMutationVariables>
    } & TChildProps;
export function withUpdateOfferRate<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateOfferRateMutation,
  UpdateOfferRateMutationVariables,
  UpdateOfferRateProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateOfferRateMutation, UpdateOfferRateMutationVariables, UpdateOfferRateProps<TChildProps, TDataName>>(UpdateOfferRateDocument, {
      alias: 'updateOfferRate',
      ...operationOptions
    });
};

/**
 * __useUpdateOfferRateMutation__
 *
 * To run a mutation, you first call `useUpdateOfferRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOfferRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOfferRateMutation, { data, loading, error }] = useUpdateOfferRateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      at: // value for 'at'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateOfferRateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOfferRateMutation, UpdateOfferRateMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOfferRateMutation, UpdateOfferRateMutationVariables>(UpdateOfferRateDocument, baseOptions);
      }
export type UpdateOfferRateMutationHookResult = ReturnType<typeof useUpdateOfferRateMutation>;
export type UpdateOfferRateMutationResult = ApolloReactCommon.MutationResult<UpdateOfferRateMutation>;
export type UpdateOfferRateMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOfferRateMutation, UpdateOfferRateMutationVariables>;
export const DeleteOfferRateDocument = gql`
    mutation DeleteOfferRate($id: Int!) {
  deleteOfferRate(where: {id: $id}) {
    id
    name
    rates {
      ...RateEditorRate
    }
  }
}
    ${RateEditorRateFragmentDoc}`;
export type DeleteOfferRateMutationFn = ApolloReactCommon.MutationFunction<DeleteOfferRateMutation, DeleteOfferRateMutationVariables>;
export type DeleteOfferRateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteOfferRateMutation, DeleteOfferRateMutationVariables>, 'mutation'>;

    export const DeleteOfferRateComponent = (props: DeleteOfferRateComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteOfferRateMutation, DeleteOfferRateMutationVariables> mutation={DeleteOfferRateDocument} {...props} />
    );
    
export type DeleteOfferRateProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteOfferRateMutation, DeleteOfferRateMutationVariables>
    } & TChildProps;
export function withDeleteOfferRate<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteOfferRateMutation,
  DeleteOfferRateMutationVariables,
  DeleteOfferRateProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteOfferRateMutation, DeleteOfferRateMutationVariables, DeleteOfferRateProps<TChildProps, TDataName>>(DeleteOfferRateDocument, {
      alias: 'deleteOfferRate',
      ...operationOptions
    });
};

/**
 * __useDeleteOfferRateMutation__
 *
 * To run a mutation, you first call `useDeleteOfferRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOfferRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOfferRateMutation, { data, loading, error }] = useDeleteOfferRateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOfferRateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteOfferRateMutation, DeleteOfferRateMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteOfferRateMutation, DeleteOfferRateMutationVariables>(DeleteOfferRateDocument, baseOptions);
      }
export type DeleteOfferRateMutationHookResult = ReturnType<typeof useDeleteOfferRateMutation>;
export type DeleteOfferRateMutationResult = ApolloReactCommon.MutationResult<DeleteOfferRateMutation>;
export type DeleteOfferRateMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteOfferRateMutation, DeleteOfferRateMutationVariables>;
export const DistibuteOfferRatesEqualDocument = gql`
    mutation DistibuteOfferRatesEqual($offerId: Int!) {
  distributeOfferRatesEqual(where: {offerId: $offerId}) {
    id
    name
    rates {
      ...RateEditorRate
    }
  }
}
    ${RateEditorRateFragmentDoc}`;
export type DistibuteOfferRatesEqualMutationFn = ApolloReactCommon.MutationFunction<DistibuteOfferRatesEqualMutation, DistibuteOfferRatesEqualMutationVariables>;
export type DistibuteOfferRatesEqualComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DistibuteOfferRatesEqualMutation, DistibuteOfferRatesEqualMutationVariables>, 'mutation'>;

    export const DistibuteOfferRatesEqualComponent = (props: DistibuteOfferRatesEqualComponentProps) => (
      <ApolloReactComponents.Mutation<DistibuteOfferRatesEqualMutation, DistibuteOfferRatesEqualMutationVariables> mutation={DistibuteOfferRatesEqualDocument} {...props} />
    );
    
export type DistibuteOfferRatesEqualProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DistibuteOfferRatesEqualMutation, DistibuteOfferRatesEqualMutationVariables>
    } & TChildProps;
export function withDistibuteOfferRatesEqual<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DistibuteOfferRatesEqualMutation,
  DistibuteOfferRatesEqualMutationVariables,
  DistibuteOfferRatesEqualProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DistibuteOfferRatesEqualMutation, DistibuteOfferRatesEqualMutationVariables, DistibuteOfferRatesEqualProps<TChildProps, TDataName>>(DistibuteOfferRatesEqualDocument, {
      alias: 'distibuteOfferRatesEqual',
      ...operationOptions
    });
};

/**
 * __useDistibuteOfferRatesEqualMutation__
 *
 * To run a mutation, you first call `useDistibuteOfferRatesEqualMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDistibuteOfferRatesEqualMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [distibuteOfferRatesEqualMutation, { data, loading, error }] = useDistibuteOfferRatesEqualMutation({
 *   variables: {
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useDistibuteOfferRatesEqualMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DistibuteOfferRatesEqualMutation, DistibuteOfferRatesEqualMutationVariables>) {
        return ApolloReactHooks.useMutation<DistibuteOfferRatesEqualMutation, DistibuteOfferRatesEqualMutationVariables>(DistibuteOfferRatesEqualDocument, baseOptions);
      }
export type DistibuteOfferRatesEqualMutationHookResult = ReturnType<typeof useDistibuteOfferRatesEqualMutation>;
export type DistibuteOfferRatesEqualMutationResult = ApolloReactCommon.MutationResult<DistibuteOfferRatesEqualMutation>;
export type DistibuteOfferRatesEqualMutationOptions = ApolloReactCommon.BaseMutationOptions<DistibuteOfferRatesEqualMutation, DistibuteOfferRatesEqualMutationVariables>;
export const GetRecurrentInvoiceEditorDataDocument = gql`
    query GetRecurrentInvoiceEditorData($recurringInvoiceId: Int!) {
  recurringInvoice(where: {recurringInvoiceId: $recurringInvoiceId}) {
    ...RecurringInvoiceEditorRecurringInvoice
  }
}
    ${RecurringInvoiceEditorRecurringInvoiceFragmentDoc}`;
export type GetRecurrentInvoiceEditorDataComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetRecurrentInvoiceEditorDataQuery, GetRecurrentInvoiceEditorDataQueryVariables>, 'query'> & ({ variables: GetRecurrentInvoiceEditorDataQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetRecurrentInvoiceEditorDataComponent = (props: GetRecurrentInvoiceEditorDataComponentProps) => (
      <ApolloReactComponents.Query<GetRecurrentInvoiceEditorDataQuery, GetRecurrentInvoiceEditorDataQueryVariables> query={GetRecurrentInvoiceEditorDataDocument} {...props} />
    );
    
export type GetRecurrentInvoiceEditorDataProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetRecurrentInvoiceEditorDataQuery, GetRecurrentInvoiceEditorDataQueryVariables>
    } & TChildProps;
export function withGetRecurrentInvoiceEditorData<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetRecurrentInvoiceEditorDataQuery,
  GetRecurrentInvoiceEditorDataQueryVariables,
  GetRecurrentInvoiceEditorDataProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetRecurrentInvoiceEditorDataQuery, GetRecurrentInvoiceEditorDataQueryVariables, GetRecurrentInvoiceEditorDataProps<TChildProps, TDataName>>(GetRecurrentInvoiceEditorDataDocument, {
      alias: 'getRecurrentInvoiceEditorData',
      ...operationOptions
    });
};

/**
 * __useGetRecurrentInvoiceEditorDataQuery__
 *
 * To run a query within a React component, call `useGetRecurrentInvoiceEditorDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecurrentInvoiceEditorDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecurrentInvoiceEditorDataQuery({
 *   variables: {
 *      recurringInvoiceId: // value for 'recurringInvoiceId'
 *   },
 * });
 */
export function useGetRecurrentInvoiceEditorDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRecurrentInvoiceEditorDataQuery, GetRecurrentInvoiceEditorDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRecurrentInvoiceEditorDataQuery, GetRecurrentInvoiceEditorDataQueryVariables>(GetRecurrentInvoiceEditorDataDocument, baseOptions);
      }
export function useGetRecurrentInvoiceEditorDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRecurrentInvoiceEditorDataQuery, GetRecurrentInvoiceEditorDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRecurrentInvoiceEditorDataQuery, GetRecurrentInvoiceEditorDataQueryVariables>(GetRecurrentInvoiceEditorDataDocument, baseOptions);
        }
export type GetRecurrentInvoiceEditorDataQueryHookResult = ReturnType<typeof useGetRecurrentInvoiceEditorDataQuery>;
export type GetRecurrentInvoiceEditorDataLazyQueryHookResult = ReturnType<typeof useGetRecurrentInvoiceEditorDataLazyQuery>;
export type GetRecurrentInvoiceEditorDataQueryResult = ApolloReactCommon.QueryResult<GetRecurrentInvoiceEditorDataQuery, GetRecurrentInvoiceEditorDataQueryVariables>;
export const DeleteRecurringInvoiceDocument = gql`
    mutation DeleteRecurringInvoice($recurringInvoiceId: Int!) {
  deleteRecurringInvoice(where: {recurringInvoiceId: $recurringInvoiceId})
}
    `;
export type DeleteRecurringInvoiceMutationFn = ApolloReactCommon.MutationFunction<DeleteRecurringInvoiceMutation, DeleteRecurringInvoiceMutationVariables>;
export type DeleteRecurringInvoiceComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteRecurringInvoiceMutation, DeleteRecurringInvoiceMutationVariables>, 'mutation'>;

    export const DeleteRecurringInvoiceComponent = (props: DeleteRecurringInvoiceComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteRecurringInvoiceMutation, DeleteRecurringInvoiceMutationVariables> mutation={DeleteRecurringInvoiceDocument} {...props} />
    );
    
export type DeleteRecurringInvoiceProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteRecurringInvoiceMutation, DeleteRecurringInvoiceMutationVariables>
    } & TChildProps;
export function withDeleteRecurringInvoice<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteRecurringInvoiceMutation,
  DeleteRecurringInvoiceMutationVariables,
  DeleteRecurringInvoiceProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteRecurringInvoiceMutation, DeleteRecurringInvoiceMutationVariables, DeleteRecurringInvoiceProps<TChildProps, TDataName>>(DeleteRecurringInvoiceDocument, {
      alias: 'deleteRecurringInvoice',
      ...operationOptions
    });
};

/**
 * __useDeleteRecurringInvoiceMutation__
 *
 * To run a mutation, you first call `useDeleteRecurringInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRecurringInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRecurringInvoiceMutation, { data, loading, error }] = useDeleteRecurringInvoiceMutation({
 *   variables: {
 *      recurringInvoiceId: // value for 'recurringInvoiceId'
 *   },
 * });
 */
export function useDeleteRecurringInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteRecurringInvoiceMutation, DeleteRecurringInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteRecurringInvoiceMutation, DeleteRecurringInvoiceMutationVariables>(DeleteRecurringInvoiceDocument, baseOptions);
      }
export type DeleteRecurringInvoiceMutationHookResult = ReturnType<typeof useDeleteRecurringInvoiceMutation>;
export type DeleteRecurringInvoiceMutationResult = ApolloReactCommon.MutationResult<DeleteRecurringInvoiceMutation>;
export type DeleteRecurringInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteRecurringInvoiceMutation, DeleteRecurringInvoiceMutationVariables>;
export const UpdateRecurringInvoiceInvoiceDocument = gql`
    mutation UpdateRecurringInvoiceInvoice($id: Int!, $name: String, $contactPersonId: Int, $customContactEmail: String, $companyId: Int, $preText: String, $preTextTemplate: Int, $afterText: String, $afterTextTemplate: Int, $emailText: String, $emailTextTemplate: Int, $sendMail: Boolean) {
  updateInvoice(where: {id: $id, name: $name, contactPersonId: $contactPersonId, customContactEmail: $customContactEmail, companyId: $companyId, preText: $preText, preTextTemplate: $preTextTemplate, afterText: $afterText, afterTextTemplate: $afterTextTemplate, emailText: $emailText, emailTextTemplate: $emailTextTemplate, sendMail: $sendMail}) {
    ...RecurrentOfferEditorInvoice
  }
}
    ${RecurrentOfferEditorInvoiceFragmentDoc}`;
export type UpdateRecurringInvoiceInvoiceMutationFn = ApolloReactCommon.MutationFunction<UpdateRecurringInvoiceInvoiceMutation, UpdateRecurringInvoiceInvoiceMutationVariables>;
export type UpdateRecurringInvoiceInvoiceComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateRecurringInvoiceInvoiceMutation, UpdateRecurringInvoiceInvoiceMutationVariables>, 'mutation'>;

    export const UpdateRecurringInvoiceInvoiceComponent = (props: UpdateRecurringInvoiceInvoiceComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateRecurringInvoiceInvoiceMutation, UpdateRecurringInvoiceInvoiceMutationVariables> mutation={UpdateRecurringInvoiceInvoiceDocument} {...props} />
    );
    
export type UpdateRecurringInvoiceInvoiceProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateRecurringInvoiceInvoiceMutation, UpdateRecurringInvoiceInvoiceMutationVariables>
    } & TChildProps;
export function withUpdateRecurringInvoiceInvoice<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateRecurringInvoiceInvoiceMutation,
  UpdateRecurringInvoiceInvoiceMutationVariables,
  UpdateRecurringInvoiceInvoiceProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateRecurringInvoiceInvoiceMutation, UpdateRecurringInvoiceInvoiceMutationVariables, UpdateRecurringInvoiceInvoiceProps<TChildProps, TDataName>>(UpdateRecurringInvoiceInvoiceDocument, {
      alias: 'updateRecurringInvoiceInvoice',
      ...operationOptions
    });
};

/**
 * __useUpdateRecurringInvoiceInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateRecurringInvoiceInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecurringInvoiceInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecurringInvoiceInvoiceMutation, { data, loading, error }] = useUpdateRecurringInvoiceInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      contactPersonId: // value for 'contactPersonId'
 *      customContactEmail: // value for 'customContactEmail'
 *      companyId: // value for 'companyId'
 *      preText: // value for 'preText'
 *      preTextTemplate: // value for 'preTextTemplate'
 *      afterText: // value for 'afterText'
 *      afterTextTemplate: // value for 'afterTextTemplate'
 *      emailText: // value for 'emailText'
 *      emailTextTemplate: // value for 'emailTextTemplate'
 *      sendMail: // value for 'sendMail'
 *   },
 * });
 */
export function useUpdateRecurringInvoiceInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRecurringInvoiceInvoiceMutation, UpdateRecurringInvoiceInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRecurringInvoiceInvoiceMutation, UpdateRecurringInvoiceInvoiceMutationVariables>(UpdateRecurringInvoiceInvoiceDocument, baseOptions);
      }
export type UpdateRecurringInvoiceInvoiceMutationHookResult = ReturnType<typeof useUpdateRecurringInvoiceInvoiceMutation>;
export type UpdateRecurringInvoiceInvoiceMutationResult = ApolloReactCommon.MutationResult<UpdateRecurringInvoiceInvoiceMutation>;
export type UpdateRecurringInvoiceInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateRecurringInvoiceInvoiceMutation, UpdateRecurringInvoiceInvoiceMutationVariables>;
export const AddRecurringInvoiceEditorPositionDocument = gql`
    mutation AddRecurringInvoiceEditorPosition($invoiceId: Int!, $name: String!, $companyId: Int!, $parentId: Int) {
  addTask(where: {name: $name, companyId: $companyId, invoiceId: $invoiceId, parentId: $parentId}) {
    addedTask {
      ...RecurringInvoiceEditorPosition
    }
  }
}
    ${RecurringInvoiceEditorPositionFragmentDoc}`;
export type AddRecurringInvoiceEditorPositionMutationFn = ApolloReactCommon.MutationFunction<AddRecurringInvoiceEditorPositionMutation, AddRecurringInvoiceEditorPositionMutationVariables>;
export type AddRecurringInvoiceEditorPositionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddRecurringInvoiceEditorPositionMutation, AddRecurringInvoiceEditorPositionMutationVariables>, 'mutation'>;

    export const AddRecurringInvoiceEditorPositionComponent = (props: AddRecurringInvoiceEditorPositionComponentProps) => (
      <ApolloReactComponents.Mutation<AddRecurringInvoiceEditorPositionMutation, AddRecurringInvoiceEditorPositionMutationVariables> mutation={AddRecurringInvoiceEditorPositionDocument} {...props} />
    );
    
export type AddRecurringInvoiceEditorPositionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AddRecurringInvoiceEditorPositionMutation, AddRecurringInvoiceEditorPositionMutationVariables>
    } & TChildProps;
export function withAddRecurringInvoiceEditorPosition<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddRecurringInvoiceEditorPositionMutation,
  AddRecurringInvoiceEditorPositionMutationVariables,
  AddRecurringInvoiceEditorPositionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AddRecurringInvoiceEditorPositionMutation, AddRecurringInvoiceEditorPositionMutationVariables, AddRecurringInvoiceEditorPositionProps<TChildProps, TDataName>>(AddRecurringInvoiceEditorPositionDocument, {
      alias: 'addRecurringInvoiceEditorPosition',
      ...operationOptions
    });
};

/**
 * __useAddRecurringInvoiceEditorPositionMutation__
 *
 * To run a mutation, you first call `useAddRecurringInvoiceEditorPositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRecurringInvoiceEditorPositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRecurringInvoiceEditorPositionMutation, { data, loading, error }] = useAddRecurringInvoiceEditorPositionMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      name: // value for 'name'
 *      companyId: // value for 'companyId'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useAddRecurringInvoiceEditorPositionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddRecurringInvoiceEditorPositionMutation, AddRecurringInvoiceEditorPositionMutationVariables>) {
        return ApolloReactHooks.useMutation<AddRecurringInvoiceEditorPositionMutation, AddRecurringInvoiceEditorPositionMutationVariables>(AddRecurringInvoiceEditorPositionDocument, baseOptions);
      }
export type AddRecurringInvoiceEditorPositionMutationHookResult = ReturnType<typeof useAddRecurringInvoiceEditorPositionMutation>;
export type AddRecurringInvoiceEditorPositionMutationResult = ApolloReactCommon.MutationResult<AddRecurringInvoiceEditorPositionMutation>;
export type AddRecurringInvoiceEditorPositionMutationOptions = ApolloReactCommon.BaseMutationOptions<AddRecurringInvoiceEditorPositionMutation, AddRecurringInvoiceEditorPositionMutationVariables>;
export const UpdateRecurringInvoiceEditorRepetitionRuleDocument = gql`
    mutation UpdateRecurringInvoiceEditorRepetitionRule($repetitionRuleId: Int!, $start: Date, $end: Date, $intervalType: IntervalType, $intervalFrequency: Int, $status: RepetitionRuleStatus) {
  updateRepetitionRule(where: {repetitionRuleId: $repetitionRuleId, start: $start, end: $end, intervalType: $intervalType, intervalFrequency: $intervalFrequency, status: $status}) {
    ...RecurrentOfferEditorRepetitionRule
  }
}
    ${RecurrentOfferEditorRepetitionRuleFragmentDoc}`;
export type UpdateRecurringInvoiceEditorRepetitionRuleMutationFn = ApolloReactCommon.MutationFunction<UpdateRecurringInvoiceEditorRepetitionRuleMutation, UpdateRecurringInvoiceEditorRepetitionRuleMutationVariables>;
export type UpdateRecurringInvoiceEditorRepetitionRuleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateRecurringInvoiceEditorRepetitionRuleMutation, UpdateRecurringInvoiceEditorRepetitionRuleMutationVariables>, 'mutation'>;

    export const UpdateRecurringInvoiceEditorRepetitionRuleComponent = (props: UpdateRecurringInvoiceEditorRepetitionRuleComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateRecurringInvoiceEditorRepetitionRuleMutation, UpdateRecurringInvoiceEditorRepetitionRuleMutationVariables> mutation={UpdateRecurringInvoiceEditorRepetitionRuleDocument} {...props} />
    );
    
export type UpdateRecurringInvoiceEditorRepetitionRuleProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateRecurringInvoiceEditorRepetitionRuleMutation, UpdateRecurringInvoiceEditorRepetitionRuleMutationVariables>
    } & TChildProps;
export function withUpdateRecurringInvoiceEditorRepetitionRule<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateRecurringInvoiceEditorRepetitionRuleMutation,
  UpdateRecurringInvoiceEditorRepetitionRuleMutationVariables,
  UpdateRecurringInvoiceEditorRepetitionRuleProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateRecurringInvoiceEditorRepetitionRuleMutation, UpdateRecurringInvoiceEditorRepetitionRuleMutationVariables, UpdateRecurringInvoiceEditorRepetitionRuleProps<TChildProps, TDataName>>(UpdateRecurringInvoiceEditorRepetitionRuleDocument, {
      alias: 'updateRecurringInvoiceEditorRepetitionRule',
      ...operationOptions
    });
};

/**
 * __useUpdateRecurringInvoiceEditorRepetitionRuleMutation__
 *
 * To run a mutation, you first call `useUpdateRecurringInvoiceEditorRepetitionRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecurringInvoiceEditorRepetitionRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecurringInvoiceEditorRepetitionRuleMutation, { data, loading, error }] = useUpdateRecurringInvoiceEditorRepetitionRuleMutation({
 *   variables: {
 *      repetitionRuleId: // value for 'repetitionRuleId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      intervalType: // value for 'intervalType'
 *      intervalFrequency: // value for 'intervalFrequency'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateRecurringInvoiceEditorRepetitionRuleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRecurringInvoiceEditorRepetitionRuleMutation, UpdateRecurringInvoiceEditorRepetitionRuleMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRecurringInvoiceEditorRepetitionRuleMutation, UpdateRecurringInvoiceEditorRepetitionRuleMutationVariables>(UpdateRecurringInvoiceEditorRepetitionRuleDocument, baseOptions);
      }
export type UpdateRecurringInvoiceEditorRepetitionRuleMutationHookResult = ReturnType<typeof useUpdateRecurringInvoiceEditorRepetitionRuleMutation>;
export type UpdateRecurringInvoiceEditorRepetitionRuleMutationResult = ApolloReactCommon.MutationResult<UpdateRecurringInvoiceEditorRepetitionRuleMutation>;
export type UpdateRecurringInvoiceEditorRepetitionRuleMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateRecurringInvoiceEditorRepetitionRuleMutation, UpdateRecurringInvoiceEditorRepetitionRuleMutationVariables>;
export const RecurringInvoiceEditorDeletedDocument = gql`
    subscription RecurringInvoiceEditorDeleted($recurringInvoiceIds: [Int!]!) {
  recurringInvoiceDeleted(where: {recurringInvoiceIds: $recurringInvoiceIds})
}
    `;
export type RecurringInvoiceEditorDeletedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<RecurringInvoiceEditorDeletedSubscription, RecurringInvoiceEditorDeletedSubscriptionVariables>, 'subscription'>;

    export const RecurringInvoiceEditorDeletedComponent = (props: RecurringInvoiceEditorDeletedComponentProps) => (
      <ApolloReactComponents.Subscription<RecurringInvoiceEditorDeletedSubscription, RecurringInvoiceEditorDeletedSubscriptionVariables> subscription={RecurringInvoiceEditorDeletedDocument} {...props} />
    );
    
export type RecurringInvoiceEditorDeletedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RecurringInvoiceEditorDeletedSubscription, RecurringInvoiceEditorDeletedSubscriptionVariables>
    } & TChildProps;
export function withRecurringInvoiceEditorDeleted<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RecurringInvoiceEditorDeletedSubscription,
  RecurringInvoiceEditorDeletedSubscriptionVariables,
  RecurringInvoiceEditorDeletedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, RecurringInvoiceEditorDeletedSubscription, RecurringInvoiceEditorDeletedSubscriptionVariables, RecurringInvoiceEditorDeletedProps<TChildProps, TDataName>>(RecurringInvoiceEditorDeletedDocument, {
      alias: 'recurringInvoiceEditorDeleted',
      ...operationOptions
    });
};

/**
 * __useRecurringInvoiceEditorDeletedSubscription__
 *
 * To run a query within a React component, call `useRecurringInvoiceEditorDeletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRecurringInvoiceEditorDeletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecurringInvoiceEditorDeletedSubscription({
 *   variables: {
 *      recurringInvoiceIds: // value for 'recurringInvoiceIds'
 *   },
 * });
 */
export function useRecurringInvoiceEditorDeletedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<RecurringInvoiceEditorDeletedSubscription, RecurringInvoiceEditorDeletedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<RecurringInvoiceEditorDeletedSubscription, RecurringInvoiceEditorDeletedSubscriptionVariables>(RecurringInvoiceEditorDeletedDocument, baseOptions);
      }
export type RecurringInvoiceEditorDeletedSubscriptionHookResult = ReturnType<typeof useRecurringInvoiceEditorDeletedSubscription>;
export type RecurringInvoiceEditorDeletedSubscriptionResult = ApolloReactCommon.SubscriptionResult<RecurringInvoiceEditorDeletedSubscription>;
export const RecurringInvoiceEditorInvoiceUpdatedDocument = gql`
    subscription RecurringInvoiceEditorInvoiceUpdated($ids: [Int!]!) {
  invoiceUpdated(where: {ids: $ids}) {
    ...RecurrentOfferEditorInvoice
  }
}
    ${RecurrentOfferEditorInvoiceFragmentDoc}`;
export type RecurringInvoiceEditorInvoiceUpdatedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<RecurringInvoiceEditorInvoiceUpdatedSubscription, RecurringInvoiceEditorInvoiceUpdatedSubscriptionVariables>, 'subscription'>;

    export const RecurringInvoiceEditorInvoiceUpdatedComponent = (props: RecurringInvoiceEditorInvoiceUpdatedComponentProps) => (
      <ApolloReactComponents.Subscription<RecurringInvoiceEditorInvoiceUpdatedSubscription, RecurringInvoiceEditorInvoiceUpdatedSubscriptionVariables> subscription={RecurringInvoiceEditorInvoiceUpdatedDocument} {...props} />
    );
    
export type RecurringInvoiceEditorInvoiceUpdatedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RecurringInvoiceEditorInvoiceUpdatedSubscription, RecurringInvoiceEditorInvoiceUpdatedSubscriptionVariables>
    } & TChildProps;
export function withRecurringInvoiceEditorInvoiceUpdated<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RecurringInvoiceEditorInvoiceUpdatedSubscription,
  RecurringInvoiceEditorInvoiceUpdatedSubscriptionVariables,
  RecurringInvoiceEditorInvoiceUpdatedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, RecurringInvoiceEditorInvoiceUpdatedSubscription, RecurringInvoiceEditorInvoiceUpdatedSubscriptionVariables, RecurringInvoiceEditorInvoiceUpdatedProps<TChildProps, TDataName>>(RecurringInvoiceEditorInvoiceUpdatedDocument, {
      alias: 'recurringInvoiceEditorInvoiceUpdated',
      ...operationOptions
    });
};

/**
 * __useRecurringInvoiceEditorInvoiceUpdatedSubscription__
 *
 * To run a query within a React component, call `useRecurringInvoiceEditorInvoiceUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRecurringInvoiceEditorInvoiceUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecurringInvoiceEditorInvoiceUpdatedSubscription({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRecurringInvoiceEditorInvoiceUpdatedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<RecurringInvoiceEditorInvoiceUpdatedSubscription, RecurringInvoiceEditorInvoiceUpdatedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<RecurringInvoiceEditorInvoiceUpdatedSubscription, RecurringInvoiceEditorInvoiceUpdatedSubscriptionVariables>(RecurringInvoiceEditorInvoiceUpdatedDocument, baseOptions);
      }
export type RecurringInvoiceEditorInvoiceUpdatedSubscriptionHookResult = ReturnType<typeof useRecurringInvoiceEditorInvoiceUpdatedSubscription>;
export type RecurringInvoiceEditorInvoiceUpdatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<RecurringInvoiceEditorInvoiceUpdatedSubscription>;
export const RecurringInvoiceEditorRepetitionRuleUpdatedDocument = gql`
    subscription RecurringInvoiceEditorRepetitionRuleUpdated($ids: [Int!]!) {
  repetitionRuleUpdated(where: {ids: $ids}) {
    ...RecurrentOfferEditorRepetitionRule
  }
}
    ${RecurrentOfferEditorRepetitionRuleFragmentDoc}`;
export type RecurringInvoiceEditorRepetitionRuleUpdatedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<RecurringInvoiceEditorRepetitionRuleUpdatedSubscription, RecurringInvoiceEditorRepetitionRuleUpdatedSubscriptionVariables>, 'subscription'>;

    export const RecurringInvoiceEditorRepetitionRuleUpdatedComponent = (props: RecurringInvoiceEditorRepetitionRuleUpdatedComponentProps) => (
      <ApolloReactComponents.Subscription<RecurringInvoiceEditorRepetitionRuleUpdatedSubscription, RecurringInvoiceEditorRepetitionRuleUpdatedSubscriptionVariables> subscription={RecurringInvoiceEditorRepetitionRuleUpdatedDocument} {...props} />
    );
    
export type RecurringInvoiceEditorRepetitionRuleUpdatedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RecurringInvoiceEditorRepetitionRuleUpdatedSubscription, RecurringInvoiceEditorRepetitionRuleUpdatedSubscriptionVariables>
    } & TChildProps;
export function withRecurringInvoiceEditorRepetitionRuleUpdated<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RecurringInvoiceEditorRepetitionRuleUpdatedSubscription,
  RecurringInvoiceEditorRepetitionRuleUpdatedSubscriptionVariables,
  RecurringInvoiceEditorRepetitionRuleUpdatedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, RecurringInvoiceEditorRepetitionRuleUpdatedSubscription, RecurringInvoiceEditorRepetitionRuleUpdatedSubscriptionVariables, RecurringInvoiceEditorRepetitionRuleUpdatedProps<TChildProps, TDataName>>(RecurringInvoiceEditorRepetitionRuleUpdatedDocument, {
      alias: 'recurringInvoiceEditorRepetitionRuleUpdated',
      ...operationOptions
    });
};

/**
 * __useRecurringInvoiceEditorRepetitionRuleUpdatedSubscription__
 *
 * To run a query within a React component, call `useRecurringInvoiceEditorRepetitionRuleUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRecurringInvoiceEditorRepetitionRuleUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecurringInvoiceEditorRepetitionRuleUpdatedSubscription({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRecurringInvoiceEditorRepetitionRuleUpdatedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<RecurringInvoiceEditorRepetitionRuleUpdatedSubscription, RecurringInvoiceEditorRepetitionRuleUpdatedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<RecurringInvoiceEditorRepetitionRuleUpdatedSubscription, RecurringInvoiceEditorRepetitionRuleUpdatedSubscriptionVariables>(RecurringInvoiceEditorRepetitionRuleUpdatedDocument, baseOptions);
      }
export type RecurringInvoiceEditorRepetitionRuleUpdatedSubscriptionHookResult = ReturnType<typeof useRecurringInvoiceEditorRepetitionRuleUpdatedSubscription>;
export type RecurringInvoiceEditorRepetitionRuleUpdatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<RecurringInvoiceEditorRepetitionRuleUpdatedSubscription>;
export const GetRecurringsInvoiceListDocument = gql`
    query GetRecurringsInvoiceList {
  recurringInvoices {
    recurringInvoices {
      ...RecurringInvoiceListItem
    }
    nextRecurringInvoiceId
  }
}
    ${RecurringInvoiceListItemFragmentDoc}`;
export type GetRecurringsInvoiceListComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetRecurringsInvoiceListQuery, GetRecurringsInvoiceListQueryVariables>, 'query'>;

    export const GetRecurringsInvoiceListComponent = (props: GetRecurringsInvoiceListComponentProps) => (
      <ApolloReactComponents.Query<GetRecurringsInvoiceListQuery, GetRecurringsInvoiceListQueryVariables> query={GetRecurringsInvoiceListDocument} {...props} />
    );
    
export type GetRecurringsInvoiceListProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetRecurringsInvoiceListQuery, GetRecurringsInvoiceListQueryVariables>
    } & TChildProps;
export function withGetRecurringsInvoiceList<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetRecurringsInvoiceListQuery,
  GetRecurringsInvoiceListQueryVariables,
  GetRecurringsInvoiceListProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetRecurringsInvoiceListQuery, GetRecurringsInvoiceListQueryVariables, GetRecurringsInvoiceListProps<TChildProps, TDataName>>(GetRecurringsInvoiceListDocument, {
      alias: 'getRecurringsInvoiceList',
      ...operationOptions
    });
};

/**
 * __useGetRecurringsInvoiceListQuery__
 *
 * To run a query within a React component, call `useGetRecurringsInvoiceListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecurringsInvoiceListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecurringsInvoiceListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRecurringsInvoiceListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRecurringsInvoiceListQuery, GetRecurringsInvoiceListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRecurringsInvoiceListQuery, GetRecurringsInvoiceListQueryVariables>(GetRecurringsInvoiceListDocument, baseOptions);
      }
export function useGetRecurringsInvoiceListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRecurringsInvoiceListQuery, GetRecurringsInvoiceListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRecurringsInvoiceListQuery, GetRecurringsInvoiceListQueryVariables>(GetRecurringsInvoiceListDocument, baseOptions);
        }
export type GetRecurringsInvoiceListQueryHookResult = ReturnType<typeof useGetRecurringsInvoiceListQuery>;
export type GetRecurringsInvoiceListLazyQueryHookResult = ReturnType<typeof useGetRecurringsInvoiceListLazyQuery>;
export type GetRecurringsInvoiceListQueryResult = ApolloReactCommon.QueryResult<GetRecurringsInvoiceListQuery, GetRecurringsInvoiceListQueryVariables>;
export const AddRecurringInvoiceListItemDocument = gql`
    mutation AddRecurringInvoiceListItem {
  addRecurringInvoice {
    ...RecurringInvoiceListItem
  }
}
    ${RecurringInvoiceListItemFragmentDoc}`;
export type AddRecurringInvoiceListItemMutationFn = ApolloReactCommon.MutationFunction<AddRecurringInvoiceListItemMutation, AddRecurringInvoiceListItemMutationVariables>;
export type AddRecurringInvoiceListItemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddRecurringInvoiceListItemMutation, AddRecurringInvoiceListItemMutationVariables>, 'mutation'>;

    export const AddRecurringInvoiceListItemComponent = (props: AddRecurringInvoiceListItemComponentProps) => (
      <ApolloReactComponents.Mutation<AddRecurringInvoiceListItemMutation, AddRecurringInvoiceListItemMutationVariables> mutation={AddRecurringInvoiceListItemDocument} {...props} />
    );
    
export type AddRecurringInvoiceListItemProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AddRecurringInvoiceListItemMutation, AddRecurringInvoiceListItemMutationVariables>
    } & TChildProps;
export function withAddRecurringInvoiceListItem<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddRecurringInvoiceListItemMutation,
  AddRecurringInvoiceListItemMutationVariables,
  AddRecurringInvoiceListItemProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AddRecurringInvoiceListItemMutation, AddRecurringInvoiceListItemMutationVariables, AddRecurringInvoiceListItemProps<TChildProps, TDataName>>(AddRecurringInvoiceListItemDocument, {
      alias: 'addRecurringInvoiceListItem',
      ...operationOptions
    });
};

/**
 * __useAddRecurringInvoiceListItemMutation__
 *
 * To run a mutation, you first call `useAddRecurringInvoiceListItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRecurringInvoiceListItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRecurringInvoiceListItemMutation, { data, loading, error }] = useAddRecurringInvoiceListItemMutation({
 *   variables: {
 *   },
 * });
 */
export function useAddRecurringInvoiceListItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddRecurringInvoiceListItemMutation, AddRecurringInvoiceListItemMutationVariables>) {
        return ApolloReactHooks.useMutation<AddRecurringInvoiceListItemMutation, AddRecurringInvoiceListItemMutationVariables>(AddRecurringInvoiceListItemDocument, baseOptions);
      }
export type AddRecurringInvoiceListItemMutationHookResult = ReturnType<typeof useAddRecurringInvoiceListItemMutation>;
export type AddRecurringInvoiceListItemMutationResult = ApolloReactCommon.MutationResult<AddRecurringInvoiceListItemMutation>;
export type AddRecurringInvoiceListItemMutationOptions = ApolloReactCommon.BaseMutationOptions<AddRecurringInvoiceListItemMutation, AddRecurringInvoiceListItemMutationVariables>;
export const UpdateRepetitionRuleDocument = gql`
    mutation UpdateRepetitionRule($repetitionRuleId: Int!, $status: RepetitionRuleStatus) {
  updateRepetitionRule(where: {repetitionRuleId: $repetitionRuleId, status: $status}) {
    ...RecurringInvoiceListItemRepetitionRule
  }
}
    ${RecurringInvoiceListItemRepetitionRuleFragmentDoc}`;
export type UpdateRepetitionRuleMutationFn = ApolloReactCommon.MutationFunction<UpdateRepetitionRuleMutation, UpdateRepetitionRuleMutationVariables>;
export type UpdateRepetitionRuleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateRepetitionRuleMutation, UpdateRepetitionRuleMutationVariables>, 'mutation'>;

    export const UpdateRepetitionRuleComponent = (props: UpdateRepetitionRuleComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateRepetitionRuleMutation, UpdateRepetitionRuleMutationVariables> mutation={UpdateRepetitionRuleDocument} {...props} />
    );
    
export type UpdateRepetitionRuleProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateRepetitionRuleMutation, UpdateRepetitionRuleMutationVariables>
    } & TChildProps;
export function withUpdateRepetitionRule<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateRepetitionRuleMutation,
  UpdateRepetitionRuleMutationVariables,
  UpdateRepetitionRuleProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateRepetitionRuleMutation, UpdateRepetitionRuleMutationVariables, UpdateRepetitionRuleProps<TChildProps, TDataName>>(UpdateRepetitionRuleDocument, {
      alias: 'updateRepetitionRule',
      ...operationOptions
    });
};

/**
 * __useUpdateRepetitionRuleMutation__
 *
 * To run a mutation, you first call `useUpdateRepetitionRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRepetitionRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRepetitionRuleMutation, { data, loading, error }] = useUpdateRepetitionRuleMutation({
 *   variables: {
 *      repetitionRuleId: // value for 'repetitionRuleId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateRepetitionRuleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRepetitionRuleMutation, UpdateRepetitionRuleMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRepetitionRuleMutation, UpdateRepetitionRuleMutationVariables>(UpdateRepetitionRuleDocument, baseOptions);
      }
export type UpdateRepetitionRuleMutationHookResult = ReturnType<typeof useUpdateRepetitionRuleMutation>;
export type UpdateRepetitionRuleMutationResult = ApolloReactCommon.MutationResult<UpdateRepetitionRuleMutation>;
export type UpdateRepetitionRuleMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateRepetitionRuleMutation, UpdateRepetitionRuleMutationVariables>;
export const RecurringInvoiceAddedDocument = gql`
    subscription RecurringInvoiceAdded {
  recurringInvoiceAdded {
    ...RecurringInvoiceListItem
  }
}
    ${RecurringInvoiceListItemFragmentDoc}`;
export type RecurringInvoiceAddedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<RecurringInvoiceAddedSubscription, RecurringInvoiceAddedSubscriptionVariables>, 'subscription'>;

    export const RecurringInvoiceAddedComponent = (props: RecurringInvoiceAddedComponentProps) => (
      <ApolloReactComponents.Subscription<RecurringInvoiceAddedSubscription, RecurringInvoiceAddedSubscriptionVariables> subscription={RecurringInvoiceAddedDocument} {...props} />
    );
    
export type RecurringInvoiceAddedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RecurringInvoiceAddedSubscription, RecurringInvoiceAddedSubscriptionVariables>
    } & TChildProps;
export function withRecurringInvoiceAdded<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RecurringInvoiceAddedSubscription,
  RecurringInvoiceAddedSubscriptionVariables,
  RecurringInvoiceAddedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, RecurringInvoiceAddedSubscription, RecurringInvoiceAddedSubscriptionVariables, RecurringInvoiceAddedProps<TChildProps, TDataName>>(RecurringInvoiceAddedDocument, {
      alias: 'recurringInvoiceAdded',
      ...operationOptions
    });
};

/**
 * __useRecurringInvoiceAddedSubscription__
 *
 * To run a query within a React component, call `useRecurringInvoiceAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRecurringInvoiceAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecurringInvoiceAddedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useRecurringInvoiceAddedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<RecurringInvoiceAddedSubscription, RecurringInvoiceAddedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<RecurringInvoiceAddedSubscription, RecurringInvoiceAddedSubscriptionVariables>(RecurringInvoiceAddedDocument, baseOptions);
      }
export type RecurringInvoiceAddedSubscriptionHookResult = ReturnType<typeof useRecurringInvoiceAddedSubscription>;
export type RecurringInvoiceAddedSubscriptionResult = ApolloReactCommon.SubscriptionResult<RecurringInvoiceAddedSubscription>;
export const RecurringInvoiceDeletedDocument = gql`
    subscription RecurringInvoiceDeleted {
  recurringInvoiceDeleted
}
    `;
export type RecurringInvoiceDeletedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<RecurringInvoiceDeletedSubscription, RecurringInvoiceDeletedSubscriptionVariables>, 'subscription'>;

    export const RecurringInvoiceDeletedComponent = (props: RecurringInvoiceDeletedComponentProps) => (
      <ApolloReactComponents.Subscription<RecurringInvoiceDeletedSubscription, RecurringInvoiceDeletedSubscriptionVariables> subscription={RecurringInvoiceDeletedDocument} {...props} />
    );
    
export type RecurringInvoiceDeletedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RecurringInvoiceDeletedSubscription, RecurringInvoiceDeletedSubscriptionVariables>
    } & TChildProps;
export function withRecurringInvoiceDeleted<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RecurringInvoiceDeletedSubscription,
  RecurringInvoiceDeletedSubscriptionVariables,
  RecurringInvoiceDeletedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, RecurringInvoiceDeletedSubscription, RecurringInvoiceDeletedSubscriptionVariables, RecurringInvoiceDeletedProps<TChildProps, TDataName>>(RecurringInvoiceDeletedDocument, {
      alias: 'recurringInvoiceDeleted',
      ...operationOptions
    });
};

/**
 * __useRecurringInvoiceDeletedSubscription__
 *
 * To run a query within a React component, call `useRecurringInvoiceDeletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRecurringInvoiceDeletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecurringInvoiceDeletedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useRecurringInvoiceDeletedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<RecurringInvoiceDeletedSubscription, RecurringInvoiceDeletedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<RecurringInvoiceDeletedSubscription, RecurringInvoiceDeletedSubscriptionVariables>(RecurringInvoiceDeletedDocument, baseOptions);
      }
export type RecurringInvoiceDeletedSubscriptionHookResult = ReturnType<typeof useRecurringInvoiceDeletedSubscription>;
export type RecurringInvoiceDeletedSubscriptionResult = ApolloReactCommon.SubscriptionResult<RecurringInvoiceDeletedSubscription>;
export const RecurringInvoiceListInvoiceUpdatedDocument = gql`
    subscription RecurringInvoiceListInvoiceUpdated($ids: [Int!]!) {
  invoiceUpdated(where: {ids: $ids}) {
    ...RecurringInvoiceListRecurringInvoiceInvoice
  }
}
    ${RecurringInvoiceListRecurringInvoiceInvoiceFragmentDoc}`;
export type RecurringInvoiceListInvoiceUpdatedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<RecurringInvoiceListInvoiceUpdatedSubscription, RecurringInvoiceListInvoiceUpdatedSubscriptionVariables>, 'subscription'>;

    export const RecurringInvoiceListInvoiceUpdatedComponent = (props: RecurringInvoiceListInvoiceUpdatedComponentProps) => (
      <ApolloReactComponents.Subscription<RecurringInvoiceListInvoiceUpdatedSubscription, RecurringInvoiceListInvoiceUpdatedSubscriptionVariables> subscription={RecurringInvoiceListInvoiceUpdatedDocument} {...props} />
    );
    
export type RecurringInvoiceListInvoiceUpdatedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RecurringInvoiceListInvoiceUpdatedSubscription, RecurringInvoiceListInvoiceUpdatedSubscriptionVariables>
    } & TChildProps;
export function withRecurringInvoiceListInvoiceUpdated<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RecurringInvoiceListInvoiceUpdatedSubscription,
  RecurringInvoiceListInvoiceUpdatedSubscriptionVariables,
  RecurringInvoiceListInvoiceUpdatedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, RecurringInvoiceListInvoiceUpdatedSubscription, RecurringInvoiceListInvoiceUpdatedSubscriptionVariables, RecurringInvoiceListInvoiceUpdatedProps<TChildProps, TDataName>>(RecurringInvoiceListInvoiceUpdatedDocument, {
      alias: 'recurringInvoiceListInvoiceUpdated',
      ...operationOptions
    });
};

/**
 * __useRecurringInvoiceListInvoiceUpdatedSubscription__
 *
 * To run a query within a React component, call `useRecurringInvoiceListInvoiceUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRecurringInvoiceListInvoiceUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecurringInvoiceListInvoiceUpdatedSubscription({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRecurringInvoiceListInvoiceUpdatedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<RecurringInvoiceListInvoiceUpdatedSubscription, RecurringInvoiceListInvoiceUpdatedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<RecurringInvoiceListInvoiceUpdatedSubscription, RecurringInvoiceListInvoiceUpdatedSubscriptionVariables>(RecurringInvoiceListInvoiceUpdatedDocument, baseOptions);
      }
export type RecurringInvoiceListInvoiceUpdatedSubscriptionHookResult = ReturnType<typeof useRecurringInvoiceListInvoiceUpdatedSubscription>;
export type RecurringInvoiceListInvoiceUpdatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<RecurringInvoiceListInvoiceUpdatedSubscription>;
export const RecurringInvoiceListRepetitionRuleUpdatedDocument = gql`
    subscription RecurringInvoiceListRepetitionRuleUpdated($ids: [Int!]!) {
  repetitionRuleUpdated(where: {ids: $ids}) {
    ...RecurringInvoiceListItemRepetitionRule
  }
}
    ${RecurringInvoiceListItemRepetitionRuleFragmentDoc}`;
export type RecurringInvoiceListRepetitionRuleUpdatedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<RecurringInvoiceListRepetitionRuleUpdatedSubscription, RecurringInvoiceListRepetitionRuleUpdatedSubscriptionVariables>, 'subscription'>;

    export const RecurringInvoiceListRepetitionRuleUpdatedComponent = (props: RecurringInvoiceListRepetitionRuleUpdatedComponentProps) => (
      <ApolloReactComponents.Subscription<RecurringInvoiceListRepetitionRuleUpdatedSubscription, RecurringInvoiceListRepetitionRuleUpdatedSubscriptionVariables> subscription={RecurringInvoiceListRepetitionRuleUpdatedDocument} {...props} />
    );
    
export type RecurringInvoiceListRepetitionRuleUpdatedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RecurringInvoiceListRepetitionRuleUpdatedSubscription, RecurringInvoiceListRepetitionRuleUpdatedSubscriptionVariables>
    } & TChildProps;
export function withRecurringInvoiceListRepetitionRuleUpdated<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RecurringInvoiceListRepetitionRuleUpdatedSubscription,
  RecurringInvoiceListRepetitionRuleUpdatedSubscriptionVariables,
  RecurringInvoiceListRepetitionRuleUpdatedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, RecurringInvoiceListRepetitionRuleUpdatedSubscription, RecurringInvoiceListRepetitionRuleUpdatedSubscriptionVariables, RecurringInvoiceListRepetitionRuleUpdatedProps<TChildProps, TDataName>>(RecurringInvoiceListRepetitionRuleUpdatedDocument, {
      alias: 'recurringInvoiceListRepetitionRuleUpdated',
      ...operationOptions
    });
};

/**
 * __useRecurringInvoiceListRepetitionRuleUpdatedSubscription__
 *
 * To run a query within a React component, call `useRecurringInvoiceListRepetitionRuleUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRecurringInvoiceListRepetitionRuleUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecurringInvoiceListRepetitionRuleUpdatedSubscription({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRecurringInvoiceListRepetitionRuleUpdatedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<RecurringInvoiceListRepetitionRuleUpdatedSubscription, RecurringInvoiceListRepetitionRuleUpdatedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<RecurringInvoiceListRepetitionRuleUpdatedSubscription, RecurringInvoiceListRepetitionRuleUpdatedSubscriptionVariables>(RecurringInvoiceListRepetitionRuleUpdatedDocument, baseOptions);
      }
export type RecurringInvoiceListRepetitionRuleUpdatedSubscriptionHookResult = ReturnType<typeof useRecurringInvoiceListRepetitionRuleUpdatedSubscription>;
export type RecurringInvoiceListRepetitionRuleUpdatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<RecurringInvoiceListRepetitionRuleUpdatedSubscription>;
export const SidebarAddTimeTrackingDocument = gql`
    mutation SidebarAddTimeTracking($taskId: Int!, $start: Date!, $end: Date!) {
  addTimeTracking(where: {taskId: $taskId, start: $start, end: $end}) {
    workloads {
      id
      start
      end
      taskId
    }
    tasks {
      id
      workedTime
      effectiveHourlyWorkRate
      price {
        original
        adjusted
      }
      currentWork {
        id
        start
        end
        userId
      }
    }
  }
}
    `;
export type SidebarAddTimeTrackingMutationFn = ApolloReactCommon.MutationFunction<SidebarAddTimeTrackingMutation, SidebarAddTimeTrackingMutationVariables>;
export type SidebarAddTimeTrackingComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SidebarAddTimeTrackingMutation, SidebarAddTimeTrackingMutationVariables>, 'mutation'>;

    export const SidebarAddTimeTrackingComponent = (props: SidebarAddTimeTrackingComponentProps) => (
      <ApolloReactComponents.Mutation<SidebarAddTimeTrackingMutation, SidebarAddTimeTrackingMutationVariables> mutation={SidebarAddTimeTrackingDocument} {...props} />
    );
    
export type SidebarAddTimeTrackingProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<SidebarAddTimeTrackingMutation, SidebarAddTimeTrackingMutationVariables>
    } & TChildProps;
export function withSidebarAddTimeTracking<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SidebarAddTimeTrackingMutation,
  SidebarAddTimeTrackingMutationVariables,
  SidebarAddTimeTrackingProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, SidebarAddTimeTrackingMutation, SidebarAddTimeTrackingMutationVariables, SidebarAddTimeTrackingProps<TChildProps, TDataName>>(SidebarAddTimeTrackingDocument, {
      alias: 'sidebarAddTimeTracking',
      ...operationOptions
    });
};

/**
 * __useSidebarAddTimeTrackingMutation__
 *
 * To run a mutation, you first call `useSidebarAddTimeTrackingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSidebarAddTimeTrackingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sidebarAddTimeTrackingMutation, { data, loading, error }] = useSidebarAddTimeTrackingMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useSidebarAddTimeTrackingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SidebarAddTimeTrackingMutation, SidebarAddTimeTrackingMutationVariables>) {
        return ApolloReactHooks.useMutation<SidebarAddTimeTrackingMutation, SidebarAddTimeTrackingMutationVariables>(SidebarAddTimeTrackingDocument, baseOptions);
      }
export type SidebarAddTimeTrackingMutationHookResult = ReturnType<typeof useSidebarAddTimeTrackingMutation>;
export type SidebarAddTimeTrackingMutationResult = ApolloReactCommon.MutationResult<SidebarAddTimeTrackingMutation>;
export type SidebarAddTimeTrackingMutationOptions = ApolloReactCommon.BaseMutationOptions<SidebarAddTimeTrackingMutation, SidebarAddTimeTrackingMutationVariables>;
export const GetUserSelectUsersDocument = gql`
    query GetUserSelectUsers($companyId: Int) {
  users(where: {companyId: $companyId}) {
    ...UserSelectUserFragment
  }
}
    ${UserSelectUserFragmentFragmentDoc}`;
export type GetUserSelectUsersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUserSelectUsersQuery, GetUserSelectUsersQueryVariables>, 'query'>;

    export const GetUserSelectUsersComponent = (props: GetUserSelectUsersComponentProps) => (
      <ApolloReactComponents.Query<GetUserSelectUsersQuery, GetUserSelectUsersQueryVariables> query={GetUserSelectUsersDocument} {...props} />
    );
    
export type GetUserSelectUsersProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetUserSelectUsersQuery, GetUserSelectUsersQueryVariables>
    } & TChildProps;
export function withGetUserSelectUsers<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetUserSelectUsersQuery,
  GetUserSelectUsersQueryVariables,
  GetUserSelectUsersProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetUserSelectUsersQuery, GetUserSelectUsersQueryVariables, GetUserSelectUsersProps<TChildProps, TDataName>>(GetUserSelectUsersDocument, {
      alias: 'getUserSelectUsers',
      ...operationOptions
    });
};

/**
 * __useGetUserSelectUsersQuery__
 *
 * To run a query within a React component, call `useGetUserSelectUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSelectUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSelectUsersQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetUserSelectUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserSelectUsersQuery, GetUserSelectUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserSelectUsersQuery, GetUserSelectUsersQueryVariables>(GetUserSelectUsersDocument, baseOptions);
      }
export function useGetUserSelectUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserSelectUsersQuery, GetUserSelectUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserSelectUsersQuery, GetUserSelectUsersQueryVariables>(GetUserSelectUsersDocument, baseOptions);
        }
export type GetUserSelectUsersQueryHookResult = ReturnType<typeof useGetUserSelectUsersQuery>;
export type GetUserSelectUsersLazyQueryHookResult = ReturnType<typeof useGetUserSelectUsersLazyQuery>;
export type GetUserSelectUsersQueryResult = ApolloReactCommon.QueryResult<GetUserSelectUsersQuery, GetUserSelectUsersQueryVariables>;
export const UserSelectTasksUpdatedDocument = gql`
    subscription UserSelectTasksUpdated($companyId: Int) {
  tasksUpdated(where: {companyId: $companyId}) {
    id
  }
}
    `;
export type UserSelectTasksUpdatedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<UserSelectTasksUpdatedSubscription, UserSelectTasksUpdatedSubscriptionVariables>, 'subscription'>;

    export const UserSelectTasksUpdatedComponent = (props: UserSelectTasksUpdatedComponentProps) => (
      <ApolloReactComponents.Subscription<UserSelectTasksUpdatedSubscription, UserSelectTasksUpdatedSubscriptionVariables> subscription={UserSelectTasksUpdatedDocument} {...props} />
    );
    
export type UserSelectTasksUpdatedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<UserSelectTasksUpdatedSubscription, UserSelectTasksUpdatedSubscriptionVariables>
    } & TChildProps;
export function withUserSelectTasksUpdated<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UserSelectTasksUpdatedSubscription,
  UserSelectTasksUpdatedSubscriptionVariables,
  UserSelectTasksUpdatedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, UserSelectTasksUpdatedSubscription, UserSelectTasksUpdatedSubscriptionVariables, UserSelectTasksUpdatedProps<TChildProps, TDataName>>(UserSelectTasksUpdatedDocument, {
      alias: 'userSelectTasksUpdated',
      ...operationOptions
    });
};

/**
 * __useUserSelectTasksUpdatedSubscription__
 *
 * To run a query within a React component, call `useUserSelectTasksUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUserSelectTasksUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSelectTasksUpdatedSubscription({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useUserSelectTasksUpdatedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<UserSelectTasksUpdatedSubscription, UserSelectTasksUpdatedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<UserSelectTasksUpdatedSubscription, UserSelectTasksUpdatedSubscriptionVariables>(UserSelectTasksUpdatedDocument, baseOptions);
      }
export type UserSelectTasksUpdatedSubscriptionHookResult = ReturnType<typeof useUserSelectTasksUpdatedSubscription>;
export type UserSelectTasksUpdatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<UserSelectTasksUpdatedSubscription>;
export const UserSelectTaskAddedDocument = gql`
    subscription UserSelectTaskAdded($companyId: Int) {
  taskAdded(where: {companyId: $companyId}) {
    addedTask {
      id
    }
  }
}
    `;
export type UserSelectTaskAddedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<UserSelectTaskAddedSubscription, UserSelectTaskAddedSubscriptionVariables>, 'subscription'>;

    export const UserSelectTaskAddedComponent = (props: UserSelectTaskAddedComponentProps) => (
      <ApolloReactComponents.Subscription<UserSelectTaskAddedSubscription, UserSelectTaskAddedSubscriptionVariables> subscription={UserSelectTaskAddedDocument} {...props} />
    );
    
export type UserSelectTaskAddedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<UserSelectTaskAddedSubscription, UserSelectTaskAddedSubscriptionVariables>
    } & TChildProps;
export function withUserSelectTaskAdded<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UserSelectTaskAddedSubscription,
  UserSelectTaskAddedSubscriptionVariables,
  UserSelectTaskAddedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, UserSelectTaskAddedSubscription, UserSelectTaskAddedSubscriptionVariables, UserSelectTaskAddedProps<TChildProps, TDataName>>(UserSelectTaskAddedDocument, {
      alias: 'userSelectTaskAdded',
      ...operationOptions
    });
};

/**
 * __useUserSelectTaskAddedSubscription__
 *
 * To run a query within a React component, call `useUserSelectTaskAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUserSelectTaskAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSelectTaskAddedSubscription({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useUserSelectTaskAddedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<UserSelectTaskAddedSubscription, UserSelectTaskAddedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<UserSelectTaskAddedSubscription, UserSelectTaskAddedSubscriptionVariables>(UserSelectTaskAddedDocument, baseOptions);
      }
export type UserSelectTaskAddedSubscriptionHookResult = ReturnType<typeof useUserSelectTaskAddedSubscription>;
export type UserSelectTaskAddedSubscriptionResult = ApolloReactCommon.SubscriptionResult<UserSelectTaskAddedSubscription>;
export const UserSelectTasksDeletedDocument = gql`
    subscription UserSelectTasksDeleted($companyId: Int) {
  tasksDeleted(where: {companyId: $companyId})
}
    `;
export type UserSelectTasksDeletedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<UserSelectTasksDeletedSubscription, UserSelectTasksDeletedSubscriptionVariables>, 'subscription'>;

    export const UserSelectTasksDeletedComponent = (props: UserSelectTasksDeletedComponentProps) => (
      <ApolloReactComponents.Subscription<UserSelectTasksDeletedSubscription, UserSelectTasksDeletedSubscriptionVariables> subscription={UserSelectTasksDeletedDocument} {...props} />
    );
    
export type UserSelectTasksDeletedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<UserSelectTasksDeletedSubscription, UserSelectTasksDeletedSubscriptionVariables>
    } & TChildProps;
export function withUserSelectTasksDeleted<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UserSelectTasksDeletedSubscription,
  UserSelectTasksDeletedSubscriptionVariables,
  UserSelectTasksDeletedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, UserSelectTasksDeletedSubscription, UserSelectTasksDeletedSubscriptionVariables, UserSelectTasksDeletedProps<TChildProps, TDataName>>(UserSelectTasksDeletedDocument, {
      alias: 'userSelectTasksDeleted',
      ...operationOptions
    });
};

/**
 * __useUserSelectTasksDeletedSubscription__
 *
 * To run a query within a React component, call `useUserSelectTasksDeletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUserSelectTasksDeletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSelectTasksDeletedSubscription({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useUserSelectTasksDeletedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<UserSelectTasksDeletedSubscription, UserSelectTasksDeletedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<UserSelectTasksDeletedSubscription, UserSelectTasksDeletedSubscriptionVariables>(UserSelectTasksDeletedDocument, baseOptions);
      }
export type UserSelectTasksDeletedSubscriptionHookResult = ReturnType<typeof useUserSelectTasksDeletedSubscription>;
export type UserSelectTasksDeletedSubscriptionResult = ApolloReactCommon.SubscriptionResult<UserSelectTasksDeletedSubscription>;
export const GetTasklistDocument = gql`
    query GetTasklist($companyId: Int!, $showNotAssigned: Boolean, $showCompleted: Boolean, $showOfferPending: Boolean, $uncollapsedTaskIds: [Int!]!) {
  tasks(where: {companyId: $companyId, showNotAssigned: $showNotAssigned, showCompleted: $showCompleted, showOfferPending: $showOfferPending, uncollapsedTaskIds: $uncollapsedTaskIds}) {
    tasks {
      ...TaskListTask
    }
    uncollapsableTaskIds
  }
}
    ${TaskListTaskFragmentDoc}`;
export type GetTasklistComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetTasklistQuery, GetTasklistQueryVariables>, 'query'> & ({ variables: GetTasklistQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetTasklistComponent = (props: GetTasklistComponentProps) => (
      <ApolloReactComponents.Query<GetTasklistQuery, GetTasklistQueryVariables> query={GetTasklistDocument} {...props} />
    );
    
export type GetTasklistProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetTasklistQuery, GetTasklistQueryVariables>
    } & TChildProps;
export function withGetTasklist<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetTasklistQuery,
  GetTasklistQueryVariables,
  GetTasklistProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetTasklistQuery, GetTasklistQueryVariables, GetTasklistProps<TChildProps, TDataName>>(GetTasklistDocument, {
      alias: 'getTasklist',
      ...operationOptions
    });
};

/**
 * __useGetTasklistQuery__
 *
 * To run a query within a React component, call `useGetTasklistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTasklistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTasklistQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      showNotAssigned: // value for 'showNotAssigned'
 *      showCompleted: // value for 'showCompleted'
 *      showOfferPending: // value for 'showOfferPending'
 *      uncollapsedTaskIds: // value for 'uncollapsedTaskIds'
 *   },
 * });
 */
export function useGetTasklistQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTasklistQuery, GetTasklistQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTasklistQuery, GetTasklistQueryVariables>(GetTasklistDocument, baseOptions);
      }
export function useGetTasklistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTasklistQuery, GetTasklistQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTasklistQuery, GetTasklistQueryVariables>(GetTasklistDocument, baseOptions);
        }
export type GetTasklistQueryHookResult = ReturnType<typeof useGetTasklistQuery>;
export type GetTasklistLazyQueryHookResult = ReturnType<typeof useGetTasklistLazyQuery>;
export type GetTasklistQueryResult = ApolloReactCommon.QueryResult<GetTasklistQuery, GetTasklistQueryVariables>;
export const TasklistMoveTaskDocument = gql`
    mutation TasklistMoveTask($id: Int!, $newIndex: Int!, $newParentId: Int) {
  moveTask(where: {id: $id, newIndex: $newIndex, newParentId: $newParentId}) {
    id
    parentId
    index
  }
}
    `;
export type TasklistMoveTaskMutationFn = ApolloReactCommon.MutationFunction<TasklistMoveTaskMutation, TasklistMoveTaskMutationVariables>;
export type TasklistMoveTaskComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<TasklistMoveTaskMutation, TasklistMoveTaskMutationVariables>, 'mutation'>;

    export const TasklistMoveTaskComponent = (props: TasklistMoveTaskComponentProps) => (
      <ApolloReactComponents.Mutation<TasklistMoveTaskMutation, TasklistMoveTaskMutationVariables> mutation={TasklistMoveTaskDocument} {...props} />
    );
    
export type TasklistMoveTaskProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<TasklistMoveTaskMutation, TasklistMoveTaskMutationVariables>
    } & TChildProps;
export function withTasklistMoveTask<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TasklistMoveTaskMutation,
  TasklistMoveTaskMutationVariables,
  TasklistMoveTaskProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, TasklistMoveTaskMutation, TasklistMoveTaskMutationVariables, TasklistMoveTaskProps<TChildProps, TDataName>>(TasklistMoveTaskDocument, {
      alias: 'tasklistMoveTask',
      ...operationOptions
    });
};

/**
 * __useTasklistMoveTaskMutation__
 *
 * To run a mutation, you first call `useTasklistMoveTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTasklistMoveTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tasklistMoveTaskMutation, { data, loading, error }] = useTasklistMoveTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      newIndex: // value for 'newIndex'
 *      newParentId: // value for 'newParentId'
 *   },
 * });
 */
export function useTasklistMoveTaskMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TasklistMoveTaskMutation, TasklistMoveTaskMutationVariables>) {
        return ApolloReactHooks.useMutation<TasklistMoveTaskMutation, TasklistMoveTaskMutationVariables>(TasklistMoveTaskDocument, baseOptions);
      }
export type TasklistMoveTaskMutationHookResult = ReturnType<typeof useTasklistMoveTaskMutation>;
export type TasklistMoveTaskMutationResult = ApolloReactCommon.MutationResult<TasklistMoveTaskMutation>;
export type TasklistMoveTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<TasklistMoveTaskMutation, TasklistMoveTaskMutationVariables>;
export const TasklistTaskUpdatedDocument = gql`
    subscription TasklistTaskUpdated($companyId: Int) {
  tasksUpdated(where: {companyId: $companyId}) {
    ...TaskListTask
  }
}
    ${TaskListTaskFragmentDoc}`;
export type TasklistTaskUpdatedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<TasklistTaskUpdatedSubscription, TasklistTaskUpdatedSubscriptionVariables>, 'subscription'>;

    export const TasklistTaskUpdatedComponent = (props: TasklistTaskUpdatedComponentProps) => (
      <ApolloReactComponents.Subscription<TasklistTaskUpdatedSubscription, TasklistTaskUpdatedSubscriptionVariables> subscription={TasklistTaskUpdatedDocument} {...props} />
    );
    
export type TasklistTaskUpdatedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<TasklistTaskUpdatedSubscription, TasklistTaskUpdatedSubscriptionVariables>
    } & TChildProps;
export function withTasklistTaskUpdated<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TasklistTaskUpdatedSubscription,
  TasklistTaskUpdatedSubscriptionVariables,
  TasklistTaskUpdatedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, TasklistTaskUpdatedSubscription, TasklistTaskUpdatedSubscriptionVariables, TasklistTaskUpdatedProps<TChildProps, TDataName>>(TasklistTaskUpdatedDocument, {
      alias: 'tasklistTaskUpdated',
      ...operationOptions
    });
};

/**
 * __useTasklistTaskUpdatedSubscription__
 *
 * To run a query within a React component, call `useTasklistTaskUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTasklistTaskUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasklistTaskUpdatedSubscription({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useTasklistTaskUpdatedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<TasklistTaskUpdatedSubscription, TasklistTaskUpdatedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<TasklistTaskUpdatedSubscription, TasklistTaskUpdatedSubscriptionVariables>(TasklistTaskUpdatedDocument, baseOptions);
      }
export type TasklistTaskUpdatedSubscriptionHookResult = ReturnType<typeof useTasklistTaskUpdatedSubscription>;
export type TasklistTaskUpdatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<TasklistTaskUpdatedSubscription>;
export const TasklistTaskAddedDocument = gql`
    subscription TasklistTaskAdded($companyId: Int) {
  taskAdded(where: {companyId: $companyId}) {
    addedTask {
      ...TaskListTask
    }
    affectedTasks {
      ...TaskListTask
    }
  }
}
    ${TaskListTaskFragmentDoc}`;
export type TasklistTaskAddedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<TasklistTaskAddedSubscription, TasklistTaskAddedSubscriptionVariables>, 'subscription'>;

    export const TasklistTaskAddedComponent = (props: TasklistTaskAddedComponentProps) => (
      <ApolloReactComponents.Subscription<TasklistTaskAddedSubscription, TasklistTaskAddedSubscriptionVariables> subscription={TasklistTaskAddedDocument} {...props} />
    );
    
export type TasklistTaskAddedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<TasklistTaskAddedSubscription, TasklistTaskAddedSubscriptionVariables>
    } & TChildProps;
export function withTasklistTaskAdded<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TasklistTaskAddedSubscription,
  TasklistTaskAddedSubscriptionVariables,
  TasklistTaskAddedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, TasklistTaskAddedSubscription, TasklistTaskAddedSubscriptionVariables, TasklistTaskAddedProps<TChildProps, TDataName>>(TasklistTaskAddedDocument, {
      alias: 'tasklistTaskAdded',
      ...operationOptions
    });
};

/**
 * __useTasklistTaskAddedSubscription__
 *
 * To run a query within a React component, call `useTasklistTaskAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTasklistTaskAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasklistTaskAddedSubscription({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useTasklistTaskAddedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<TasklistTaskAddedSubscription, TasklistTaskAddedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<TasklistTaskAddedSubscription, TasklistTaskAddedSubscriptionVariables>(TasklistTaskAddedDocument, baseOptions);
      }
export type TasklistTaskAddedSubscriptionHookResult = ReturnType<typeof useTasklistTaskAddedSubscription>;
export type TasklistTaskAddedSubscriptionResult = ApolloReactCommon.SubscriptionResult<TasklistTaskAddedSubscription>;
export const TasklistTaskDeletedDocument = gql`
    subscription TasklistTaskDeleted($companyId: Int) {
  tasksDeleted(where: {companyId: $companyId})
}
    `;
export type TasklistTaskDeletedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<TasklistTaskDeletedSubscription, TasklistTaskDeletedSubscriptionVariables>, 'subscription'>;

    export const TasklistTaskDeletedComponent = (props: TasklistTaskDeletedComponentProps) => (
      <ApolloReactComponents.Subscription<TasklistTaskDeletedSubscription, TasklistTaskDeletedSubscriptionVariables> subscription={TasklistTaskDeletedDocument} {...props} />
    );
    
export type TasklistTaskDeletedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<TasklistTaskDeletedSubscription, TasklistTaskDeletedSubscriptionVariables>
    } & TChildProps;
export function withTasklistTaskDeleted<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TasklistTaskDeletedSubscription,
  TasklistTaskDeletedSubscriptionVariables,
  TasklistTaskDeletedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, TasklistTaskDeletedSubscription, TasklistTaskDeletedSubscriptionVariables, TasklistTaskDeletedProps<TChildProps, TDataName>>(TasklistTaskDeletedDocument, {
      alias: 'tasklistTaskDeleted',
      ...operationOptions
    });
};

/**
 * __useTasklistTaskDeletedSubscription__
 *
 * To run a query within a React component, call `useTasklistTaskDeletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTasklistTaskDeletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasklistTaskDeletedSubscription({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useTasklistTaskDeletedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<TasklistTaskDeletedSubscription, TasklistTaskDeletedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<TasklistTaskDeletedSubscription, TasklistTaskDeletedSubscriptionVariables>(TasklistTaskDeletedDocument, baseOptions);
      }
export type TasklistTaskDeletedSubscriptionHookResult = ReturnType<typeof useTasklistTaskDeletedSubscription>;
export type TasklistTaskDeletedSubscriptionResult = ApolloReactCommon.SubscriptionResult<TasklistTaskDeletedSubscription>;
export const TaskListSidebarDiscountRowAddDiscountDocument = gql`
    mutation TaskListSidebarDiscountRowAddDiscount($value: Float!, $unit: Charge!, $productID: Int!) {
  addDiscount(where: {value: $value, unit: $unit, productID: $productID}) {
    id
    name
    description
    deadline
    timeBudget
    priceCalcMethod
    timeBudgetViewUnit
    hourlyWorkRate
    effectiveHourlyWorkRate
    price {
      original
      adjusted
    }
    prio {
      id
    }
    taskCategory
    discount {
      id
      value
      unit
    }
  }
}
    `;
export type TaskListSidebarDiscountRowAddDiscountMutationFn = ApolloReactCommon.MutationFunction<TaskListSidebarDiscountRowAddDiscountMutation, TaskListSidebarDiscountRowAddDiscountMutationVariables>;
export type TaskListSidebarDiscountRowAddDiscountComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<TaskListSidebarDiscountRowAddDiscountMutation, TaskListSidebarDiscountRowAddDiscountMutationVariables>, 'mutation'>;

    export const TaskListSidebarDiscountRowAddDiscountComponent = (props: TaskListSidebarDiscountRowAddDiscountComponentProps) => (
      <ApolloReactComponents.Mutation<TaskListSidebarDiscountRowAddDiscountMutation, TaskListSidebarDiscountRowAddDiscountMutationVariables> mutation={TaskListSidebarDiscountRowAddDiscountDocument} {...props} />
    );
    
export type TaskListSidebarDiscountRowAddDiscountProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<TaskListSidebarDiscountRowAddDiscountMutation, TaskListSidebarDiscountRowAddDiscountMutationVariables>
    } & TChildProps;
export function withTaskListSidebarDiscountRowAddDiscount<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TaskListSidebarDiscountRowAddDiscountMutation,
  TaskListSidebarDiscountRowAddDiscountMutationVariables,
  TaskListSidebarDiscountRowAddDiscountProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, TaskListSidebarDiscountRowAddDiscountMutation, TaskListSidebarDiscountRowAddDiscountMutationVariables, TaskListSidebarDiscountRowAddDiscountProps<TChildProps, TDataName>>(TaskListSidebarDiscountRowAddDiscountDocument, {
      alias: 'taskListSidebarDiscountRowAddDiscount',
      ...operationOptions
    });
};

/**
 * __useTaskListSidebarDiscountRowAddDiscountMutation__
 *
 * To run a mutation, you first call `useTaskListSidebarDiscountRowAddDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskListSidebarDiscountRowAddDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskListSidebarDiscountRowAddDiscountMutation, { data, loading, error }] = useTaskListSidebarDiscountRowAddDiscountMutation({
 *   variables: {
 *      value: // value for 'value'
 *      unit: // value for 'unit'
 *      productID: // value for 'productID'
 *   },
 * });
 */
export function useTaskListSidebarDiscountRowAddDiscountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TaskListSidebarDiscountRowAddDiscountMutation, TaskListSidebarDiscountRowAddDiscountMutationVariables>) {
        return ApolloReactHooks.useMutation<TaskListSidebarDiscountRowAddDiscountMutation, TaskListSidebarDiscountRowAddDiscountMutationVariables>(TaskListSidebarDiscountRowAddDiscountDocument, baseOptions);
      }
export type TaskListSidebarDiscountRowAddDiscountMutationHookResult = ReturnType<typeof useTaskListSidebarDiscountRowAddDiscountMutation>;
export type TaskListSidebarDiscountRowAddDiscountMutationResult = ApolloReactCommon.MutationResult<TaskListSidebarDiscountRowAddDiscountMutation>;
export type TaskListSidebarDiscountRowAddDiscountMutationOptions = ApolloReactCommon.BaseMutationOptions<TaskListSidebarDiscountRowAddDiscountMutation, TaskListSidebarDiscountRowAddDiscountMutationVariables>;
export const TaskListSidebarDiscountRowUpdateDiscountDocument = gql`
    mutation TaskListSidebarDiscountRowUpdateDiscount($id: Int!, $value: Float!, $unit: Charge!, $productID: Int!) {
  updateDiscount(where: {id: $id, value: $value, unit: $unit, productID: $productID}) {
    id
    name
    description
    deadline
    timeBudget
    priceCalcMethod
    timeBudgetViewUnit
    hourlyWorkRate
    effectiveHourlyWorkRate
    price {
      original
      adjusted
    }
    prio {
      id
    }
    taskCategory
    discount {
      id
      value
      unit
    }
  }
}
    `;
export type TaskListSidebarDiscountRowUpdateDiscountMutationFn = ApolloReactCommon.MutationFunction<TaskListSidebarDiscountRowUpdateDiscountMutation, TaskListSidebarDiscountRowUpdateDiscountMutationVariables>;
export type TaskListSidebarDiscountRowUpdateDiscountComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<TaskListSidebarDiscountRowUpdateDiscountMutation, TaskListSidebarDiscountRowUpdateDiscountMutationVariables>, 'mutation'>;

    export const TaskListSidebarDiscountRowUpdateDiscountComponent = (props: TaskListSidebarDiscountRowUpdateDiscountComponentProps) => (
      <ApolloReactComponents.Mutation<TaskListSidebarDiscountRowUpdateDiscountMutation, TaskListSidebarDiscountRowUpdateDiscountMutationVariables> mutation={TaskListSidebarDiscountRowUpdateDiscountDocument} {...props} />
    );
    
export type TaskListSidebarDiscountRowUpdateDiscountProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<TaskListSidebarDiscountRowUpdateDiscountMutation, TaskListSidebarDiscountRowUpdateDiscountMutationVariables>
    } & TChildProps;
export function withTaskListSidebarDiscountRowUpdateDiscount<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TaskListSidebarDiscountRowUpdateDiscountMutation,
  TaskListSidebarDiscountRowUpdateDiscountMutationVariables,
  TaskListSidebarDiscountRowUpdateDiscountProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, TaskListSidebarDiscountRowUpdateDiscountMutation, TaskListSidebarDiscountRowUpdateDiscountMutationVariables, TaskListSidebarDiscountRowUpdateDiscountProps<TChildProps, TDataName>>(TaskListSidebarDiscountRowUpdateDiscountDocument, {
      alias: 'taskListSidebarDiscountRowUpdateDiscount',
      ...operationOptions
    });
};

/**
 * __useTaskListSidebarDiscountRowUpdateDiscountMutation__
 *
 * To run a mutation, you first call `useTaskListSidebarDiscountRowUpdateDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskListSidebarDiscountRowUpdateDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskListSidebarDiscountRowUpdateDiscountMutation, { data, loading, error }] = useTaskListSidebarDiscountRowUpdateDiscountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      value: // value for 'value'
 *      unit: // value for 'unit'
 *      productID: // value for 'productID'
 *   },
 * });
 */
export function useTaskListSidebarDiscountRowUpdateDiscountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TaskListSidebarDiscountRowUpdateDiscountMutation, TaskListSidebarDiscountRowUpdateDiscountMutationVariables>) {
        return ApolloReactHooks.useMutation<TaskListSidebarDiscountRowUpdateDiscountMutation, TaskListSidebarDiscountRowUpdateDiscountMutationVariables>(TaskListSidebarDiscountRowUpdateDiscountDocument, baseOptions);
      }
export type TaskListSidebarDiscountRowUpdateDiscountMutationHookResult = ReturnType<typeof useTaskListSidebarDiscountRowUpdateDiscountMutation>;
export type TaskListSidebarDiscountRowUpdateDiscountMutationResult = ApolloReactCommon.MutationResult<TaskListSidebarDiscountRowUpdateDiscountMutation>;
export type TaskListSidebarDiscountRowUpdateDiscountMutationOptions = ApolloReactCommon.BaseMutationOptions<TaskListSidebarDiscountRowUpdateDiscountMutation, TaskListSidebarDiscountRowUpdateDiscountMutationVariables>;
export const TaskListSidebarDiscountRowDeleteDiscountDocument = gql`
    mutation TaskListSidebarDiscountRowDeleteDiscount($id: Int!) {
  deleteDiscount(where: {id: $id}) {
    id
    name
    description
    deadline
    timeBudget
    priceCalcMethod
    timeBudgetViewUnit
    hourlyWorkRate
    effectiveHourlyWorkRate
    price {
      original
      adjusted
    }
    prio {
      id
    }
    taskCategory
    discount {
      id
      value
      unit
    }
  }
}
    `;
export type TaskListSidebarDiscountRowDeleteDiscountMutationFn = ApolloReactCommon.MutationFunction<TaskListSidebarDiscountRowDeleteDiscountMutation, TaskListSidebarDiscountRowDeleteDiscountMutationVariables>;
export type TaskListSidebarDiscountRowDeleteDiscountComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<TaskListSidebarDiscountRowDeleteDiscountMutation, TaskListSidebarDiscountRowDeleteDiscountMutationVariables>, 'mutation'>;

    export const TaskListSidebarDiscountRowDeleteDiscountComponent = (props: TaskListSidebarDiscountRowDeleteDiscountComponentProps) => (
      <ApolloReactComponents.Mutation<TaskListSidebarDiscountRowDeleteDiscountMutation, TaskListSidebarDiscountRowDeleteDiscountMutationVariables> mutation={TaskListSidebarDiscountRowDeleteDiscountDocument} {...props} />
    );
    
export type TaskListSidebarDiscountRowDeleteDiscountProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<TaskListSidebarDiscountRowDeleteDiscountMutation, TaskListSidebarDiscountRowDeleteDiscountMutationVariables>
    } & TChildProps;
export function withTaskListSidebarDiscountRowDeleteDiscount<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TaskListSidebarDiscountRowDeleteDiscountMutation,
  TaskListSidebarDiscountRowDeleteDiscountMutationVariables,
  TaskListSidebarDiscountRowDeleteDiscountProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, TaskListSidebarDiscountRowDeleteDiscountMutation, TaskListSidebarDiscountRowDeleteDiscountMutationVariables, TaskListSidebarDiscountRowDeleteDiscountProps<TChildProps, TDataName>>(TaskListSidebarDiscountRowDeleteDiscountDocument, {
      alias: 'taskListSidebarDiscountRowDeleteDiscount',
      ...operationOptions
    });
};

/**
 * __useTaskListSidebarDiscountRowDeleteDiscountMutation__
 *
 * To run a mutation, you first call `useTaskListSidebarDiscountRowDeleteDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskListSidebarDiscountRowDeleteDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskListSidebarDiscountRowDeleteDiscountMutation, { data, loading, error }] = useTaskListSidebarDiscountRowDeleteDiscountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskListSidebarDiscountRowDeleteDiscountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TaskListSidebarDiscountRowDeleteDiscountMutation, TaskListSidebarDiscountRowDeleteDiscountMutationVariables>) {
        return ApolloReactHooks.useMutation<TaskListSidebarDiscountRowDeleteDiscountMutation, TaskListSidebarDiscountRowDeleteDiscountMutationVariables>(TaskListSidebarDiscountRowDeleteDiscountDocument, baseOptions);
      }
export type TaskListSidebarDiscountRowDeleteDiscountMutationHookResult = ReturnType<typeof useTaskListSidebarDiscountRowDeleteDiscountMutation>;
export type TaskListSidebarDiscountRowDeleteDiscountMutationResult = ApolloReactCommon.MutationResult<TaskListSidebarDiscountRowDeleteDiscountMutation>;
export type TaskListSidebarDiscountRowDeleteDiscountMutationOptions = ApolloReactCommon.BaseMutationOptions<TaskListSidebarDiscountRowDeleteDiscountMutation, TaskListSidebarDiscountRowDeleteDiscountMutationVariables>;
export const GetTaskSidebarDocument = gql`
    query GetTaskSidebar($id: Int!) {
  task(where: {id: $id}) {
    ...TaskSidebarTask
  }
}
    ${TaskSidebarTaskFragmentDoc}`;
export type GetTaskSidebarComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetTaskSidebarQuery, GetTaskSidebarQueryVariables>, 'query'> & ({ variables: GetTaskSidebarQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetTaskSidebarComponent = (props: GetTaskSidebarComponentProps) => (
      <ApolloReactComponents.Query<GetTaskSidebarQuery, GetTaskSidebarQueryVariables> query={GetTaskSidebarDocument} {...props} />
    );
    
export type GetTaskSidebarProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetTaskSidebarQuery, GetTaskSidebarQueryVariables>
    } & TChildProps;
export function withGetTaskSidebar<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetTaskSidebarQuery,
  GetTaskSidebarQueryVariables,
  GetTaskSidebarProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetTaskSidebarQuery, GetTaskSidebarQueryVariables, GetTaskSidebarProps<TChildProps, TDataName>>(GetTaskSidebarDocument, {
      alias: 'getTaskSidebar',
      ...operationOptions
    });
};

/**
 * __useGetTaskSidebarQuery__
 *
 * To run a query within a React component, call `useGetTaskSidebarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskSidebarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskSidebarQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTaskSidebarQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTaskSidebarQuery, GetTaskSidebarQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTaskSidebarQuery, GetTaskSidebarQueryVariables>(GetTaskSidebarDocument, baseOptions);
      }
export function useGetTaskSidebarLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaskSidebarQuery, GetTaskSidebarQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTaskSidebarQuery, GetTaskSidebarQueryVariables>(GetTaskSidebarDocument, baseOptions);
        }
export type GetTaskSidebarQueryHookResult = ReturnType<typeof useGetTaskSidebarQuery>;
export type GetTaskSidebarLazyQueryHookResult = ReturnType<typeof useGetTaskSidebarLazyQuery>;
export type GetTaskSidebarQueryResult = ApolloReactCommon.QueryResult<GetTaskSidebarQuery, GetTaskSidebarQueryVariables>;
export const DeleteTaskDocument = gql`
    mutation DeleteTask($taskId: Int!) {
  deleteTask(where: {taskId: $taskId}) {
    deletedTasks {
      id
    }
    blockedTasks {
      id
      currentTrackingUsers {
        id
        name
        surname
        profilePic
      }
    }
  }
}
    `;
export type DeleteTaskMutationFn = ApolloReactCommon.MutationFunction<DeleteTaskMutation, DeleteTaskMutationVariables>;
export type DeleteTaskComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteTaskMutation, DeleteTaskMutationVariables>, 'mutation'>;

    export const DeleteTaskComponent = (props: DeleteTaskComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteTaskMutation, DeleteTaskMutationVariables> mutation={DeleteTaskDocument} {...props} />
    );
    
export type DeleteTaskProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteTaskMutation, DeleteTaskMutationVariables>
    } & TChildProps;
export function withDeleteTask<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteTaskMutation,
  DeleteTaskMutationVariables,
  DeleteTaskProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteTaskMutation, DeleteTaskMutationVariables, DeleteTaskProps<TChildProps, TDataName>>(DeleteTaskDocument, {
      alias: 'deleteTask',
      ...operationOptions
    });
};

/**
 * __useDeleteTaskMutation__
 *
 * To run a mutation, you first call `useDeleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskMutation, { data, loading, error }] = useDeleteTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useDeleteTaskMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteTaskMutation, DeleteTaskMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteTaskMutation, DeleteTaskMutationVariables>(DeleteTaskDocument, baseOptions);
      }
export type DeleteTaskMutationHookResult = ReturnType<typeof useDeleteTaskMutation>;
export type DeleteTaskMutationResult = ApolloReactCommon.MutationResult<DeleteTaskMutation>;
export type DeleteTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteTaskMutation, DeleteTaskMutationVariables>;
export const TasklistSidebarMoveTaskDocument = gql`
    mutation TasklistSidebarMoveTask($id: Int!, $newCompanyId: Int!, $newIndex: Int!) {
  moveTask(where: {id: $id, newCompanyId: $newCompanyId, newIndex: $newIndex}) {
    id
    parentId
    index
    companyId
  }
}
    `;
export type TasklistSidebarMoveTaskMutationFn = ApolloReactCommon.MutationFunction<TasklistSidebarMoveTaskMutation, TasklistSidebarMoveTaskMutationVariables>;
export type TasklistSidebarMoveTaskComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<TasklistSidebarMoveTaskMutation, TasklistSidebarMoveTaskMutationVariables>, 'mutation'>;

    export const TasklistSidebarMoveTaskComponent = (props: TasklistSidebarMoveTaskComponentProps) => (
      <ApolloReactComponents.Mutation<TasklistSidebarMoveTaskMutation, TasklistSidebarMoveTaskMutationVariables> mutation={TasklistSidebarMoveTaskDocument} {...props} />
    );
    
export type TasklistSidebarMoveTaskProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<TasklistSidebarMoveTaskMutation, TasklistSidebarMoveTaskMutationVariables>
    } & TChildProps;
export function withTasklistSidebarMoveTask<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TasklistSidebarMoveTaskMutation,
  TasklistSidebarMoveTaskMutationVariables,
  TasklistSidebarMoveTaskProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, TasklistSidebarMoveTaskMutation, TasklistSidebarMoveTaskMutationVariables, TasklistSidebarMoveTaskProps<TChildProps, TDataName>>(TasklistSidebarMoveTaskDocument, {
      alias: 'tasklistSidebarMoveTask',
      ...operationOptions
    });
};

/**
 * __useTasklistSidebarMoveTaskMutation__
 *
 * To run a mutation, you first call `useTasklistSidebarMoveTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTasklistSidebarMoveTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tasklistSidebarMoveTaskMutation, { data, loading, error }] = useTasklistSidebarMoveTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      newCompanyId: // value for 'newCompanyId'
 *      newIndex: // value for 'newIndex'
 *   },
 * });
 */
export function useTasklistSidebarMoveTaskMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TasklistSidebarMoveTaskMutation, TasklistSidebarMoveTaskMutationVariables>) {
        return ApolloReactHooks.useMutation<TasklistSidebarMoveTaskMutation, TasklistSidebarMoveTaskMutationVariables>(TasklistSidebarMoveTaskDocument, baseOptions);
      }
export type TasklistSidebarMoveTaskMutationHookResult = ReturnType<typeof useTasklistSidebarMoveTaskMutation>;
export type TasklistSidebarMoveTaskMutationResult = ApolloReactCommon.MutationResult<TasklistSidebarMoveTaskMutation>;
export type TasklistSidebarMoveTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<TasklistSidebarMoveTaskMutation, TasklistSidebarMoveTaskMutationVariables>;
export const TaskSidebarUpdateTaskCompletedDocument = gql`
    mutation TaskSidebarUpdateTaskCompleted($id: Int!, $completed: Boolean!) {
  updateTaskCompleted(where: {id: $id, completed: $completed}) {
    updatedTasks {
      id
      completed
    }
    blockedTasks {
      id
      currentTrackingUsers {
        ...TaskSidebarTaskUser
      }
    }
  }
}
    ${TaskSidebarTaskUserFragmentDoc}`;
export type TaskSidebarUpdateTaskCompletedMutationFn = ApolloReactCommon.MutationFunction<TaskSidebarUpdateTaskCompletedMutation, TaskSidebarUpdateTaskCompletedMutationVariables>;
export type TaskSidebarUpdateTaskCompletedComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<TaskSidebarUpdateTaskCompletedMutation, TaskSidebarUpdateTaskCompletedMutationVariables>, 'mutation'>;

    export const TaskSidebarUpdateTaskCompletedComponent = (props: TaskSidebarUpdateTaskCompletedComponentProps) => (
      <ApolloReactComponents.Mutation<TaskSidebarUpdateTaskCompletedMutation, TaskSidebarUpdateTaskCompletedMutationVariables> mutation={TaskSidebarUpdateTaskCompletedDocument} {...props} />
    );
    
export type TaskSidebarUpdateTaskCompletedProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<TaskSidebarUpdateTaskCompletedMutation, TaskSidebarUpdateTaskCompletedMutationVariables>
    } & TChildProps;
export function withTaskSidebarUpdateTaskCompleted<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TaskSidebarUpdateTaskCompletedMutation,
  TaskSidebarUpdateTaskCompletedMutationVariables,
  TaskSidebarUpdateTaskCompletedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, TaskSidebarUpdateTaskCompletedMutation, TaskSidebarUpdateTaskCompletedMutationVariables, TaskSidebarUpdateTaskCompletedProps<TChildProps, TDataName>>(TaskSidebarUpdateTaskCompletedDocument, {
      alias: 'taskSidebarUpdateTaskCompleted',
      ...operationOptions
    });
};

/**
 * __useTaskSidebarUpdateTaskCompletedMutation__
 *
 * To run a mutation, you first call `useTaskSidebarUpdateTaskCompletedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskSidebarUpdateTaskCompletedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskSidebarUpdateTaskCompletedMutation, { data, loading, error }] = useTaskSidebarUpdateTaskCompletedMutation({
 *   variables: {
 *      id: // value for 'id'
 *      completed: // value for 'completed'
 *   },
 * });
 */
export function useTaskSidebarUpdateTaskCompletedMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TaskSidebarUpdateTaskCompletedMutation, TaskSidebarUpdateTaskCompletedMutationVariables>) {
        return ApolloReactHooks.useMutation<TaskSidebarUpdateTaskCompletedMutation, TaskSidebarUpdateTaskCompletedMutationVariables>(TaskSidebarUpdateTaskCompletedDocument, baseOptions);
      }
export type TaskSidebarUpdateTaskCompletedMutationHookResult = ReturnType<typeof useTaskSidebarUpdateTaskCompletedMutation>;
export type TaskSidebarUpdateTaskCompletedMutationResult = ApolloReactCommon.MutationResult<TaskSidebarUpdateTaskCompletedMutation>;
export type TaskSidebarUpdateTaskCompletedMutationOptions = ApolloReactCommon.BaseMutationOptions<TaskSidebarUpdateTaskCompletedMutation, TaskSidebarUpdateTaskCompletedMutationVariables>;
export const TaskSidebarUpdateTaskDocument = gql`
    mutation TaskSidebarUpdateTask($id: Int!, $name: String, $description: String, $prioId: Int, $deadline: Int, $timeBudget: Int, $taskCategory: Int, $priceCalcMethod: PriceCalcMethodType, $customHourlyWorkRate: Float, $timeBudgetViewUnit: String, $flatRatePrice: Float, $archived: Boolean, $isNotCharged: Boolean) {
  updateTask(where: {id: $id, name: $name, description: $description, prioId: $prioId, deadline: $deadline, timeBudget: $timeBudget, taskCategory: $taskCategory, priceCalcMethod: $priceCalcMethod, customHourlyWorkRate: $customHourlyWorkRate, timeBudgetViewUnit: $timeBudgetViewUnit, flatRatePrice: $flatRatePrice, archived: $archived, isNotCharged: $isNotCharged}) {
    id
    name
    description
    deadline
    timeBudget
    priceCalcMethod
    timeBudgetViewUnit
    hourlyWorkRate
    prio {
      id
    }
    taskCategory
    flatRatePrice
    effectiveHourlyWorkRate
    price {
      adjusted
      original
    }
    archived
    isNotCharged
  }
}
    `;
export type TaskSidebarUpdateTaskMutationFn = ApolloReactCommon.MutationFunction<TaskSidebarUpdateTaskMutation, TaskSidebarUpdateTaskMutationVariables>;
export type TaskSidebarUpdateTaskComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<TaskSidebarUpdateTaskMutation, TaskSidebarUpdateTaskMutationVariables>, 'mutation'>;

    export const TaskSidebarUpdateTaskComponent = (props: TaskSidebarUpdateTaskComponentProps) => (
      <ApolloReactComponents.Mutation<TaskSidebarUpdateTaskMutation, TaskSidebarUpdateTaskMutationVariables> mutation={TaskSidebarUpdateTaskDocument} {...props} />
    );
    
export type TaskSidebarUpdateTaskProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<TaskSidebarUpdateTaskMutation, TaskSidebarUpdateTaskMutationVariables>
    } & TChildProps;
export function withTaskSidebarUpdateTask<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TaskSidebarUpdateTaskMutation,
  TaskSidebarUpdateTaskMutationVariables,
  TaskSidebarUpdateTaskProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, TaskSidebarUpdateTaskMutation, TaskSidebarUpdateTaskMutationVariables, TaskSidebarUpdateTaskProps<TChildProps, TDataName>>(TaskSidebarUpdateTaskDocument, {
      alias: 'taskSidebarUpdateTask',
      ...operationOptions
    });
};

/**
 * __useTaskSidebarUpdateTaskMutation__
 *
 * To run a mutation, you first call `useTaskSidebarUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskSidebarUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskSidebarUpdateTaskMutation, { data, loading, error }] = useTaskSidebarUpdateTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      prioId: // value for 'prioId'
 *      deadline: // value for 'deadline'
 *      timeBudget: // value for 'timeBudget'
 *      taskCategory: // value for 'taskCategory'
 *      priceCalcMethod: // value for 'priceCalcMethod'
 *      customHourlyWorkRate: // value for 'customHourlyWorkRate'
 *      timeBudgetViewUnit: // value for 'timeBudgetViewUnit'
 *      flatRatePrice: // value for 'flatRatePrice'
 *      archived: // value for 'archived'
 *      isNotCharged: // value for 'isNotCharged'
 *   },
 * });
 */
export function useTaskSidebarUpdateTaskMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TaskSidebarUpdateTaskMutation, TaskSidebarUpdateTaskMutationVariables>) {
        return ApolloReactHooks.useMutation<TaskSidebarUpdateTaskMutation, TaskSidebarUpdateTaskMutationVariables>(TaskSidebarUpdateTaskDocument, baseOptions);
      }
export type TaskSidebarUpdateTaskMutationHookResult = ReturnType<typeof useTaskSidebarUpdateTaskMutation>;
export type TaskSidebarUpdateTaskMutationResult = ApolloReactCommon.MutationResult<TaskSidebarUpdateTaskMutation>;
export type TaskSidebarUpdateTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<TaskSidebarUpdateTaskMutation, TaskSidebarUpdateTaskMutationVariables>;
export const TaskSidebarArchiveOfferPositionsDocument = gql`
    mutation TaskSidebarArchiveOfferPositions($offerId: Int!, $taskId: Int, $rateId: Int) {
  archiveOfferPositions(where: {offerId: $offerId, taskId: $taskId, rateId: $rateId}) {
    id
  }
}
    `;
export type TaskSidebarArchiveOfferPositionsMutationFn = ApolloReactCommon.MutationFunction<TaskSidebarArchiveOfferPositionsMutation, TaskSidebarArchiveOfferPositionsMutationVariables>;
export type TaskSidebarArchiveOfferPositionsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<TaskSidebarArchiveOfferPositionsMutation, TaskSidebarArchiveOfferPositionsMutationVariables>, 'mutation'>;

    export const TaskSidebarArchiveOfferPositionsComponent = (props: TaskSidebarArchiveOfferPositionsComponentProps) => (
      <ApolloReactComponents.Mutation<TaskSidebarArchiveOfferPositionsMutation, TaskSidebarArchiveOfferPositionsMutationVariables> mutation={TaskSidebarArchiveOfferPositionsDocument} {...props} />
    );
    
export type TaskSidebarArchiveOfferPositionsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<TaskSidebarArchiveOfferPositionsMutation, TaskSidebarArchiveOfferPositionsMutationVariables>
    } & TChildProps;
export function withTaskSidebarArchiveOfferPositions<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TaskSidebarArchiveOfferPositionsMutation,
  TaskSidebarArchiveOfferPositionsMutationVariables,
  TaskSidebarArchiveOfferPositionsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, TaskSidebarArchiveOfferPositionsMutation, TaskSidebarArchiveOfferPositionsMutationVariables, TaskSidebarArchiveOfferPositionsProps<TChildProps, TDataName>>(TaskSidebarArchiveOfferPositionsDocument, {
      alias: 'taskSidebarArchiveOfferPositions',
      ...operationOptions
    });
};

/**
 * __useTaskSidebarArchiveOfferPositionsMutation__
 *
 * To run a mutation, you first call `useTaskSidebarArchiveOfferPositionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskSidebarArchiveOfferPositionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskSidebarArchiveOfferPositionsMutation, { data, loading, error }] = useTaskSidebarArchiveOfferPositionsMutation({
 *   variables: {
 *      offerId: // value for 'offerId'
 *      taskId: // value for 'taskId'
 *      rateId: // value for 'rateId'
 *   },
 * });
 */
export function useTaskSidebarArchiveOfferPositionsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TaskSidebarArchiveOfferPositionsMutation, TaskSidebarArchiveOfferPositionsMutationVariables>) {
        return ApolloReactHooks.useMutation<TaskSidebarArchiveOfferPositionsMutation, TaskSidebarArchiveOfferPositionsMutationVariables>(TaskSidebarArchiveOfferPositionsDocument, baseOptions);
      }
export type TaskSidebarArchiveOfferPositionsMutationHookResult = ReturnType<typeof useTaskSidebarArchiveOfferPositionsMutation>;
export type TaskSidebarArchiveOfferPositionsMutationResult = ApolloReactCommon.MutationResult<TaskSidebarArchiveOfferPositionsMutation>;
export type TaskSidebarArchiveOfferPositionsMutationOptions = ApolloReactCommon.BaseMutationOptions<TaskSidebarArchiveOfferPositionsMutation, TaskSidebarArchiveOfferPositionsMutationVariables>;
export const TaskListSidebarAssignUserDocument = gql`
    mutation TaskListSidebarAssignUser($userId: Int!, $taskId: Int!) {
  assignUserToTask(where: {userId: $userId, taskId: $taskId}) {
    id
    assignedUsers {
      id
      name
      surname
      profilePic
    }
  }
}
    `;
export type TaskListSidebarAssignUserMutationFn = ApolloReactCommon.MutationFunction<TaskListSidebarAssignUserMutation, TaskListSidebarAssignUserMutationVariables>;
export type TaskListSidebarAssignUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<TaskListSidebarAssignUserMutation, TaskListSidebarAssignUserMutationVariables>, 'mutation'>;

    export const TaskListSidebarAssignUserComponent = (props: TaskListSidebarAssignUserComponentProps) => (
      <ApolloReactComponents.Mutation<TaskListSidebarAssignUserMutation, TaskListSidebarAssignUserMutationVariables> mutation={TaskListSidebarAssignUserDocument} {...props} />
    );
    
export type TaskListSidebarAssignUserProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<TaskListSidebarAssignUserMutation, TaskListSidebarAssignUserMutationVariables>
    } & TChildProps;
export function withTaskListSidebarAssignUser<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TaskListSidebarAssignUserMutation,
  TaskListSidebarAssignUserMutationVariables,
  TaskListSidebarAssignUserProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, TaskListSidebarAssignUserMutation, TaskListSidebarAssignUserMutationVariables, TaskListSidebarAssignUserProps<TChildProps, TDataName>>(TaskListSidebarAssignUserDocument, {
      alias: 'taskListSidebarAssignUser',
      ...operationOptions
    });
};

/**
 * __useTaskListSidebarAssignUserMutation__
 *
 * To run a mutation, you first call `useTaskListSidebarAssignUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskListSidebarAssignUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskListSidebarAssignUserMutation, { data, loading, error }] = useTaskListSidebarAssignUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskListSidebarAssignUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TaskListSidebarAssignUserMutation, TaskListSidebarAssignUserMutationVariables>) {
        return ApolloReactHooks.useMutation<TaskListSidebarAssignUserMutation, TaskListSidebarAssignUserMutationVariables>(TaskListSidebarAssignUserDocument, baseOptions);
      }
export type TaskListSidebarAssignUserMutationHookResult = ReturnType<typeof useTaskListSidebarAssignUserMutation>;
export type TaskListSidebarAssignUserMutationResult = ApolloReactCommon.MutationResult<TaskListSidebarAssignUserMutation>;
export type TaskListSidebarAssignUserMutationOptions = ApolloReactCommon.BaseMutationOptions<TaskListSidebarAssignUserMutation, TaskListSidebarAssignUserMutationVariables>;
export const TaskListSidebarUnassignUserDocument = gql`
    mutation TaskListSidebarUnassignUser($userId: Int!, $taskId: Int!) {
  unassignUserFromTask(where: {userId: $userId, taskId: $taskId}) {
    id
    assignedUsers {
      id
    }
  }
}
    `;
export type TaskListSidebarUnassignUserMutationFn = ApolloReactCommon.MutationFunction<TaskListSidebarUnassignUserMutation, TaskListSidebarUnassignUserMutationVariables>;
export type TaskListSidebarUnassignUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<TaskListSidebarUnassignUserMutation, TaskListSidebarUnassignUserMutationVariables>, 'mutation'>;

    export const TaskListSidebarUnassignUserComponent = (props: TaskListSidebarUnassignUserComponentProps) => (
      <ApolloReactComponents.Mutation<TaskListSidebarUnassignUserMutation, TaskListSidebarUnassignUserMutationVariables> mutation={TaskListSidebarUnassignUserDocument} {...props} />
    );
    
export type TaskListSidebarUnassignUserProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<TaskListSidebarUnassignUserMutation, TaskListSidebarUnassignUserMutationVariables>
    } & TChildProps;
export function withTaskListSidebarUnassignUser<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TaskListSidebarUnassignUserMutation,
  TaskListSidebarUnassignUserMutationVariables,
  TaskListSidebarUnassignUserProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, TaskListSidebarUnassignUserMutation, TaskListSidebarUnassignUserMutationVariables, TaskListSidebarUnassignUserProps<TChildProps, TDataName>>(TaskListSidebarUnassignUserDocument, {
      alias: 'taskListSidebarUnassignUser',
      ...operationOptions
    });
};

/**
 * __useTaskListSidebarUnassignUserMutation__
 *
 * To run a mutation, you first call `useTaskListSidebarUnassignUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskListSidebarUnassignUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskListSidebarUnassignUserMutation, { data, loading, error }] = useTaskListSidebarUnassignUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskListSidebarUnassignUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TaskListSidebarUnassignUserMutation, TaskListSidebarUnassignUserMutationVariables>) {
        return ApolloReactHooks.useMutation<TaskListSidebarUnassignUserMutation, TaskListSidebarUnassignUserMutationVariables>(TaskListSidebarUnassignUserDocument, baseOptions);
      }
export type TaskListSidebarUnassignUserMutationHookResult = ReturnType<typeof useTaskListSidebarUnassignUserMutation>;
export type TaskListSidebarUnassignUserMutationResult = ApolloReactCommon.MutationResult<TaskListSidebarUnassignUserMutation>;
export type TaskListSidebarUnassignUserMutationOptions = ApolloReactCommon.BaseMutationOptions<TaskListSidebarUnassignUserMutation, TaskListSidebarUnassignUserMutationVariables>;
export const TasklistSidebarTaskUpdatedDocument = gql`
    subscription TasklistSidebarTaskUpdated($taskId: Int!) {
  tasksUpdated(where: {taskIds: [$taskId]}) {
    ...TaskSidebarTask
  }
}
    ${TaskSidebarTaskFragmentDoc}`;
export type TasklistSidebarTaskUpdatedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<TasklistSidebarTaskUpdatedSubscription, TasklistSidebarTaskUpdatedSubscriptionVariables>, 'subscription'>;

    export const TasklistSidebarTaskUpdatedComponent = (props: TasklistSidebarTaskUpdatedComponentProps) => (
      <ApolloReactComponents.Subscription<TasklistSidebarTaskUpdatedSubscription, TasklistSidebarTaskUpdatedSubscriptionVariables> subscription={TasklistSidebarTaskUpdatedDocument} {...props} />
    );
    
export type TasklistSidebarTaskUpdatedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<TasklistSidebarTaskUpdatedSubscription, TasklistSidebarTaskUpdatedSubscriptionVariables>
    } & TChildProps;
export function withTasklistSidebarTaskUpdated<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TasklistSidebarTaskUpdatedSubscription,
  TasklistSidebarTaskUpdatedSubscriptionVariables,
  TasklistSidebarTaskUpdatedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, TasklistSidebarTaskUpdatedSubscription, TasklistSidebarTaskUpdatedSubscriptionVariables, TasklistSidebarTaskUpdatedProps<TChildProps, TDataName>>(TasklistSidebarTaskUpdatedDocument, {
      alias: 'tasklistSidebarTaskUpdated',
      ...operationOptions
    });
};

/**
 * __useTasklistSidebarTaskUpdatedSubscription__
 *
 * To run a query within a React component, call `useTasklistSidebarTaskUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTasklistSidebarTaskUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasklistSidebarTaskUpdatedSubscription({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTasklistSidebarTaskUpdatedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<TasklistSidebarTaskUpdatedSubscription, TasklistSidebarTaskUpdatedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<TasklistSidebarTaskUpdatedSubscription, TasklistSidebarTaskUpdatedSubscriptionVariables>(TasklistSidebarTaskUpdatedDocument, baseOptions);
      }
export type TasklistSidebarTaskUpdatedSubscriptionHookResult = ReturnType<typeof useTasklistSidebarTaskUpdatedSubscription>;
export type TasklistSidebarTaskUpdatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<TasklistSidebarTaskUpdatedSubscription>;
export const BackendUpdatedDocument = gql`
    subscription backendUpdated {
  backendUpdated
}
    `;
export type BackendUpdatedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<BackendUpdatedSubscription, BackendUpdatedSubscriptionVariables>, 'subscription'>;

    export const BackendUpdatedComponent = (props: BackendUpdatedComponentProps) => (
      <ApolloReactComponents.Subscription<BackendUpdatedSubscription, BackendUpdatedSubscriptionVariables> subscription={BackendUpdatedDocument} {...props} />
    );
    
export type BackendUpdatedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<BackendUpdatedSubscription, BackendUpdatedSubscriptionVariables>
    } & TChildProps;
export function withBackendUpdated<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BackendUpdatedSubscription,
  BackendUpdatedSubscriptionVariables,
  BackendUpdatedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, BackendUpdatedSubscription, BackendUpdatedSubscriptionVariables, BackendUpdatedProps<TChildProps, TDataName>>(BackendUpdatedDocument, {
      alias: 'backendUpdated',
      ...operationOptions
    });
};

/**
 * __useBackendUpdatedSubscription__
 *
 * To run a query within a React component, call `useBackendUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useBackendUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBackendUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useBackendUpdatedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<BackendUpdatedSubscription, BackendUpdatedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<BackendUpdatedSubscription, BackendUpdatedSubscriptionVariables>(BackendUpdatedDocument, baseOptions);
      }
export type BackendUpdatedSubscriptionHookResult = ReturnType<typeof useBackendUpdatedSubscription>;
export type BackendUpdatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<BackendUpdatedSubscription>;
export const GetParentTasksDocument = gql`
    query GetParentTasks($id: Int!) {
  parentTasks(where: {id: $id}) {
    id
  }
}
    `;
export type GetParentTasksComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetParentTasksQuery, GetParentTasksQueryVariables>, 'query'> & ({ variables: GetParentTasksQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetParentTasksComponent = (props: GetParentTasksComponentProps) => (
      <ApolloReactComponents.Query<GetParentTasksQuery, GetParentTasksQueryVariables> query={GetParentTasksDocument} {...props} />
    );
    
export type GetParentTasksProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetParentTasksQuery, GetParentTasksQueryVariables>
    } & TChildProps;
export function withGetParentTasks<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetParentTasksQuery,
  GetParentTasksQueryVariables,
  GetParentTasksProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetParentTasksQuery, GetParentTasksQueryVariables, GetParentTasksProps<TChildProps, TDataName>>(GetParentTasksDocument, {
      alias: 'getParentTasks',
      ...operationOptions
    });
};

/**
 * __useGetParentTasksQuery__
 *
 * To run a query within a React component, call `useGetParentTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParentTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParentTasksQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetParentTasksQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetParentTasksQuery, GetParentTasksQueryVariables>) {
        return ApolloReactHooks.useQuery<GetParentTasksQuery, GetParentTasksQueryVariables>(GetParentTasksDocument, baseOptions);
      }
export function useGetParentTasksLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetParentTasksQuery, GetParentTasksQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetParentTasksQuery, GetParentTasksQueryVariables>(GetParentTasksDocument, baseOptions);
        }
export type GetParentTasksQueryHookResult = ReturnType<typeof useGetParentTasksQuery>;
export type GetParentTasksLazyQueryHookResult = ReturnType<typeof useGetParentTasksLazyQuery>;
export type GetParentTasksQueryResult = ApolloReactCommon.QueryResult<GetParentTasksQuery, GetParentTasksQueryVariables>;
export const GetBillingTasklistDocument = gql`
    query GetBillingTasklist($companyId: Int!, $uncollapsedTaskIds: [Int!]!, $selectedTaskIds: [Int!]!) {
  billingTasks(where: {companyId: $companyId, uncollapsedTaskIds: $uncollapsedTaskIds, selectedTaskIds: $selectedTaskIds}) {
    tasks {
      ...BillingListPosition
    }
    uncollapsableTaskIds
    selectedTaskIds
  }
}
    ${BillingListPositionFragmentDoc}`;
export type GetBillingTasklistComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBillingTasklistQuery, GetBillingTasklistQueryVariables>, 'query'> & ({ variables: GetBillingTasklistQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBillingTasklistComponent = (props: GetBillingTasklistComponentProps) => (
      <ApolloReactComponents.Query<GetBillingTasklistQuery, GetBillingTasklistQueryVariables> query={GetBillingTasklistDocument} {...props} />
    );
    
export type GetBillingTasklistProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetBillingTasklistQuery, GetBillingTasklistQueryVariables>
    } & TChildProps;
export function withGetBillingTasklist<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBillingTasklistQuery,
  GetBillingTasklistQueryVariables,
  GetBillingTasklistProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetBillingTasklistQuery, GetBillingTasklistQueryVariables, GetBillingTasklistProps<TChildProps, TDataName>>(GetBillingTasklistDocument, {
      alias: 'getBillingTasklist',
      ...operationOptions
    });
};

/**
 * __useGetBillingTasklistQuery__
 *
 * To run a query within a React component, call `useGetBillingTasklistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillingTasklistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillingTasklistQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      uncollapsedTaskIds: // value for 'uncollapsedTaskIds'
 *      selectedTaskIds: // value for 'selectedTaskIds'
 *   },
 * });
 */
export function useGetBillingTasklistQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBillingTasklistQuery, GetBillingTasklistQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBillingTasklistQuery, GetBillingTasklistQueryVariables>(GetBillingTasklistDocument, baseOptions);
      }
export function useGetBillingTasklistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBillingTasklistQuery, GetBillingTasklistQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBillingTasklistQuery, GetBillingTasklistQueryVariables>(GetBillingTasklistDocument, baseOptions);
        }
export type GetBillingTasklistQueryHookResult = ReturnType<typeof useGetBillingTasklistQuery>;
export type GetBillingTasklistLazyQueryHookResult = ReturnType<typeof useGetBillingTasklistLazyQuery>;
export type GetBillingTasklistQueryResult = ApolloReactCommon.QueryResult<GetBillingTasklistQuery, GetBillingTasklistQueryVariables>;
export const BillingTasklistOfferUpdatedDocument = gql`
    subscription BillingTasklistOfferUpdated {
  offerUpdated {
    id
  }
}
    `;
export type BillingTasklistOfferUpdatedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<BillingTasklistOfferUpdatedSubscription, BillingTasklistOfferUpdatedSubscriptionVariables>, 'subscription'>;

    export const BillingTasklistOfferUpdatedComponent = (props: BillingTasklistOfferUpdatedComponentProps) => (
      <ApolloReactComponents.Subscription<BillingTasklistOfferUpdatedSubscription, BillingTasklistOfferUpdatedSubscriptionVariables> subscription={BillingTasklistOfferUpdatedDocument} {...props} />
    );
    
export type BillingTasklistOfferUpdatedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<BillingTasklistOfferUpdatedSubscription, BillingTasklistOfferUpdatedSubscriptionVariables>
    } & TChildProps;
export function withBillingTasklistOfferUpdated<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BillingTasklistOfferUpdatedSubscription,
  BillingTasklistOfferUpdatedSubscriptionVariables,
  BillingTasklistOfferUpdatedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, BillingTasklistOfferUpdatedSubscription, BillingTasklistOfferUpdatedSubscriptionVariables, BillingTasklistOfferUpdatedProps<TChildProps, TDataName>>(BillingTasklistOfferUpdatedDocument, {
      alias: 'billingTasklistOfferUpdated',
      ...operationOptions
    });
};

/**
 * __useBillingTasklistOfferUpdatedSubscription__
 *
 * To run a query within a React component, call `useBillingTasklistOfferUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useBillingTasklistOfferUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingTasklistOfferUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useBillingTasklistOfferUpdatedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<BillingTasklistOfferUpdatedSubscription, BillingTasklistOfferUpdatedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<BillingTasklistOfferUpdatedSubscription, BillingTasklistOfferUpdatedSubscriptionVariables>(BillingTasklistOfferUpdatedDocument, baseOptions);
      }
export type BillingTasklistOfferUpdatedSubscriptionHookResult = ReturnType<typeof useBillingTasklistOfferUpdatedSubscription>;
export type BillingTasklistOfferUpdatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<BillingTasklistOfferUpdatedSubscription>;
export const BillingTasklistOfferDeletedDocument = gql`
    subscription BillingTasklistOfferDeleted {
  offerDeleted {
    id
  }
}
    `;
export type BillingTasklistOfferDeletedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<BillingTasklistOfferDeletedSubscription, BillingTasklistOfferDeletedSubscriptionVariables>, 'subscription'>;

    export const BillingTasklistOfferDeletedComponent = (props: BillingTasklistOfferDeletedComponentProps) => (
      <ApolloReactComponents.Subscription<BillingTasklistOfferDeletedSubscription, BillingTasklistOfferDeletedSubscriptionVariables> subscription={BillingTasklistOfferDeletedDocument} {...props} />
    );
    
export type BillingTasklistOfferDeletedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<BillingTasklistOfferDeletedSubscription, BillingTasklistOfferDeletedSubscriptionVariables>
    } & TChildProps;
export function withBillingTasklistOfferDeleted<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BillingTasklistOfferDeletedSubscription,
  BillingTasklistOfferDeletedSubscriptionVariables,
  BillingTasklistOfferDeletedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, BillingTasklistOfferDeletedSubscription, BillingTasklistOfferDeletedSubscriptionVariables, BillingTasklistOfferDeletedProps<TChildProps, TDataName>>(BillingTasklistOfferDeletedDocument, {
      alias: 'billingTasklistOfferDeleted',
      ...operationOptions
    });
};

/**
 * __useBillingTasklistOfferDeletedSubscription__
 *
 * To run a query within a React component, call `useBillingTasklistOfferDeletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useBillingTasklistOfferDeletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingTasklistOfferDeletedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useBillingTasklistOfferDeletedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<BillingTasklistOfferDeletedSubscription, BillingTasklistOfferDeletedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<BillingTasklistOfferDeletedSubscription, BillingTasklistOfferDeletedSubscriptionVariables>(BillingTasklistOfferDeletedDocument, baseOptions);
      }
export type BillingTasklistOfferDeletedSubscriptionHookResult = ReturnType<typeof useBillingTasklistOfferDeletedSubscription>;
export type BillingTasklistOfferDeletedSubscriptionResult = ApolloReactCommon.SubscriptionResult<BillingTasklistOfferDeletedSubscription>;
export const BillingTasklistInvoiceAddedDocument = gql`
    subscription BillingTasklistInvoiceAdded {
  invoiceAdded {
    id
  }
}
    `;
export type BillingTasklistInvoiceAddedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<BillingTasklistInvoiceAddedSubscription, BillingTasklistInvoiceAddedSubscriptionVariables>, 'subscription'>;

    export const BillingTasklistInvoiceAddedComponent = (props: BillingTasklistInvoiceAddedComponentProps) => (
      <ApolloReactComponents.Subscription<BillingTasklistInvoiceAddedSubscription, BillingTasklistInvoiceAddedSubscriptionVariables> subscription={BillingTasklistInvoiceAddedDocument} {...props} />
    );
    
export type BillingTasklistInvoiceAddedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<BillingTasklistInvoiceAddedSubscription, BillingTasklistInvoiceAddedSubscriptionVariables>
    } & TChildProps;
export function withBillingTasklistInvoiceAdded<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BillingTasklistInvoiceAddedSubscription,
  BillingTasklistInvoiceAddedSubscriptionVariables,
  BillingTasklistInvoiceAddedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, BillingTasklistInvoiceAddedSubscription, BillingTasklistInvoiceAddedSubscriptionVariables, BillingTasklistInvoiceAddedProps<TChildProps, TDataName>>(BillingTasklistInvoiceAddedDocument, {
      alias: 'billingTasklistInvoiceAdded',
      ...operationOptions
    });
};

/**
 * __useBillingTasklistInvoiceAddedSubscription__
 *
 * To run a query within a React component, call `useBillingTasklistInvoiceAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useBillingTasklistInvoiceAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingTasklistInvoiceAddedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useBillingTasklistInvoiceAddedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<BillingTasklistInvoiceAddedSubscription, BillingTasklistInvoiceAddedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<BillingTasklistInvoiceAddedSubscription, BillingTasklistInvoiceAddedSubscriptionVariables>(BillingTasklistInvoiceAddedDocument, baseOptions);
      }
export type BillingTasklistInvoiceAddedSubscriptionHookResult = ReturnType<typeof useBillingTasklistInvoiceAddedSubscription>;
export type BillingTasklistInvoiceAddedSubscriptionResult = ApolloReactCommon.SubscriptionResult<BillingTasklistInvoiceAddedSubscription>;
export const BillingTasklistTasksDeletedDocument = gql`
    subscription BillingTasklistTasksDeleted($companyId: Int) {
  tasksDeleted(where: {companyId: $companyId})
}
    `;
export type BillingTasklistTasksDeletedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<BillingTasklistTasksDeletedSubscription, BillingTasklistTasksDeletedSubscriptionVariables>, 'subscription'>;

    export const BillingTasklistTasksDeletedComponent = (props: BillingTasklistTasksDeletedComponentProps) => (
      <ApolloReactComponents.Subscription<BillingTasklistTasksDeletedSubscription, BillingTasklistTasksDeletedSubscriptionVariables> subscription={BillingTasklistTasksDeletedDocument} {...props} />
    );
    
export type BillingTasklistTasksDeletedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<BillingTasklistTasksDeletedSubscription, BillingTasklistTasksDeletedSubscriptionVariables>
    } & TChildProps;
export function withBillingTasklistTasksDeleted<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BillingTasklistTasksDeletedSubscription,
  BillingTasklistTasksDeletedSubscriptionVariables,
  BillingTasklistTasksDeletedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, BillingTasklistTasksDeletedSubscription, BillingTasklistTasksDeletedSubscriptionVariables, BillingTasklistTasksDeletedProps<TChildProps, TDataName>>(BillingTasklistTasksDeletedDocument, {
      alias: 'billingTasklistTasksDeleted',
      ...operationOptions
    });
};

/**
 * __useBillingTasklistTasksDeletedSubscription__
 *
 * To run a query within a React component, call `useBillingTasklistTasksDeletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useBillingTasklistTasksDeletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingTasklistTasksDeletedSubscription({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useBillingTasklistTasksDeletedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<BillingTasklistTasksDeletedSubscription, BillingTasklistTasksDeletedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<BillingTasklistTasksDeletedSubscription, BillingTasklistTasksDeletedSubscriptionVariables>(BillingTasklistTasksDeletedDocument, baseOptions);
      }
export type BillingTasklistTasksDeletedSubscriptionHookResult = ReturnType<typeof useBillingTasklistTasksDeletedSubscription>;
export type BillingTasklistTasksDeletedSubscriptionResult = ApolloReactCommon.SubscriptionResult<BillingTasklistTasksDeletedSubscription>;
export const GetCompanyHeaderDocument = gql`
    query GetCompanyHeader($id: Int!) {
  company(where: {id: $id}) {
    id
    name
    short
    imageUrl
    favorite
  }
}
    `;
export type GetCompanyHeaderComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCompanyHeaderQuery, GetCompanyHeaderQueryVariables>, 'query'> & ({ variables: GetCompanyHeaderQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCompanyHeaderComponent = (props: GetCompanyHeaderComponentProps) => (
      <ApolloReactComponents.Query<GetCompanyHeaderQuery, GetCompanyHeaderQueryVariables> query={GetCompanyHeaderDocument} {...props} />
    );
    
export type GetCompanyHeaderProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetCompanyHeaderQuery, GetCompanyHeaderQueryVariables>
    } & TChildProps;
export function withGetCompanyHeader<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCompanyHeaderQuery,
  GetCompanyHeaderQueryVariables,
  GetCompanyHeaderProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetCompanyHeaderQuery, GetCompanyHeaderQueryVariables, GetCompanyHeaderProps<TChildProps, TDataName>>(GetCompanyHeaderDocument, {
      alias: 'getCompanyHeader',
      ...operationOptions
    });
};

/**
 * __useGetCompanyHeaderQuery__
 *
 * To run a query within a React component, call `useGetCompanyHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyHeaderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyHeaderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyHeaderQuery, GetCompanyHeaderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyHeaderQuery, GetCompanyHeaderQueryVariables>(GetCompanyHeaderDocument, baseOptions);
      }
export function useGetCompanyHeaderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyHeaderQuery, GetCompanyHeaderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyHeaderQuery, GetCompanyHeaderQueryVariables>(GetCompanyHeaderDocument, baseOptions);
        }
export type GetCompanyHeaderQueryHookResult = ReturnType<typeof useGetCompanyHeaderQuery>;
export type GetCompanyHeaderLazyQueryHookResult = ReturnType<typeof useGetCompanyHeaderLazyQuery>;
export type GetCompanyHeaderQueryResult = ApolloReactCommon.QueryResult<GetCompanyHeaderQuery, GetCompanyHeaderQueryVariables>;
export const UpdateCompanyFavoriteDocument = gql`
    mutation UpdateCompanyFavorite($id: Int!, $favorite: Boolean!) {
  updateCompanyFavorite(where: {id: $id, favorite: $favorite}) {
    id
    favorite
  }
}
    `;
export type UpdateCompanyFavoriteMutationFn = ApolloReactCommon.MutationFunction<UpdateCompanyFavoriteMutation, UpdateCompanyFavoriteMutationVariables>;
export type UpdateCompanyFavoriteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateCompanyFavoriteMutation, UpdateCompanyFavoriteMutationVariables>, 'mutation'>;

    export const UpdateCompanyFavoriteComponent = (props: UpdateCompanyFavoriteComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateCompanyFavoriteMutation, UpdateCompanyFavoriteMutationVariables> mutation={UpdateCompanyFavoriteDocument} {...props} />
    );
    
export type UpdateCompanyFavoriteProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateCompanyFavoriteMutation, UpdateCompanyFavoriteMutationVariables>
    } & TChildProps;
export function withUpdateCompanyFavorite<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateCompanyFavoriteMutation,
  UpdateCompanyFavoriteMutationVariables,
  UpdateCompanyFavoriteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateCompanyFavoriteMutation, UpdateCompanyFavoriteMutationVariables, UpdateCompanyFavoriteProps<TChildProps, TDataName>>(UpdateCompanyFavoriteDocument, {
      alias: 'updateCompanyFavorite',
      ...operationOptions
    });
};

/**
 * __useUpdateCompanyFavoriteMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyFavoriteMutation, { data, loading, error }] = useUpdateCompanyFavoriteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      favorite: // value for 'favorite'
 *   },
 * });
 */
export function useUpdateCompanyFavoriteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCompanyFavoriteMutation, UpdateCompanyFavoriteMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCompanyFavoriteMutation, UpdateCompanyFavoriteMutationVariables>(UpdateCompanyFavoriteDocument, baseOptions);
      }
export type UpdateCompanyFavoriteMutationHookResult = ReturnType<typeof useUpdateCompanyFavoriteMutation>;
export type UpdateCompanyFavoriteMutationResult = ApolloReactCommon.MutationResult<UpdateCompanyFavoriteMutation>;
export type UpdateCompanyFavoriteMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCompanyFavoriteMutation, UpdateCompanyFavoriteMutationVariables>;
export const GetCompanyNamesDocument = gql`
    query GetCompanyNames {
  companies {
    ...CompanyNames
  }
}
    ${CompanyNamesFragmentDoc}`;
export type GetCompanyNamesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCompanyNamesQuery, GetCompanyNamesQueryVariables>, 'query'>;

    export const GetCompanyNamesComponent = (props: GetCompanyNamesComponentProps) => (
      <ApolloReactComponents.Query<GetCompanyNamesQuery, GetCompanyNamesQueryVariables> query={GetCompanyNamesDocument} {...props} />
    );
    
export type GetCompanyNamesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetCompanyNamesQuery, GetCompanyNamesQueryVariables>
    } & TChildProps;
export function withGetCompanyNames<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCompanyNamesQuery,
  GetCompanyNamesQueryVariables,
  GetCompanyNamesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetCompanyNamesQuery, GetCompanyNamesQueryVariables, GetCompanyNamesProps<TChildProps, TDataName>>(GetCompanyNamesDocument, {
      alias: 'getCompanyNames',
      ...operationOptions
    });
};

/**
 * __useGetCompanyNamesQuery__
 *
 * To run a query within a React component, call `useGetCompanyNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyNamesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyNamesQuery, GetCompanyNamesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyNamesQuery, GetCompanyNamesQueryVariables>(GetCompanyNamesDocument, baseOptions);
      }
export function useGetCompanyNamesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyNamesQuery, GetCompanyNamesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyNamesQuery, GetCompanyNamesQueryVariables>(GetCompanyNamesDocument, baseOptions);
        }
export type GetCompanyNamesQueryHookResult = ReturnType<typeof useGetCompanyNamesQuery>;
export type GetCompanyNamesLazyQueryHookResult = ReturnType<typeof useGetCompanyNamesLazyQuery>;
export type GetCompanyNamesQueryResult = ApolloReactCommon.QueryResult<GetCompanyNamesQuery, GetCompanyNamesQueryVariables>;
export const CompanyNameAddedDocument = gql`
    subscription CompanyNameAdded {
  companyAdded {
    ...CompanyNames
  }
}
    ${CompanyNamesFragmentDoc}`;
export type CompanyNameAddedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<CompanyNameAddedSubscription, CompanyNameAddedSubscriptionVariables>, 'subscription'>;

    export const CompanyNameAddedComponent = (props: CompanyNameAddedComponentProps) => (
      <ApolloReactComponents.Subscription<CompanyNameAddedSubscription, CompanyNameAddedSubscriptionVariables> subscription={CompanyNameAddedDocument} {...props} />
    );
    
export type CompanyNameAddedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CompanyNameAddedSubscription, CompanyNameAddedSubscriptionVariables>
    } & TChildProps;
export function withCompanyNameAdded<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CompanyNameAddedSubscription,
  CompanyNameAddedSubscriptionVariables,
  CompanyNameAddedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, CompanyNameAddedSubscription, CompanyNameAddedSubscriptionVariables, CompanyNameAddedProps<TChildProps, TDataName>>(CompanyNameAddedDocument, {
      alias: 'companyNameAdded',
      ...operationOptions
    });
};

/**
 * __useCompanyNameAddedSubscription__
 *
 * To run a query within a React component, call `useCompanyNameAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCompanyNameAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyNameAddedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useCompanyNameAddedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<CompanyNameAddedSubscription, CompanyNameAddedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<CompanyNameAddedSubscription, CompanyNameAddedSubscriptionVariables>(CompanyNameAddedDocument, baseOptions);
      }
export type CompanyNameAddedSubscriptionHookResult = ReturnType<typeof useCompanyNameAddedSubscription>;
export type CompanyNameAddedSubscriptionResult = ApolloReactCommon.SubscriptionResult<CompanyNameAddedSubscription>;
export const GetMeDocument = gql`
    query GetMe {
  me {
    ...Me
  }
}
    ${MeFragmentDoc}`;
export type GetMeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetMeQuery, GetMeQueryVariables>, 'query'>;

    export const GetMeComponent = (props: GetMeComponentProps) => (
      <ApolloReactComponents.Query<GetMeQuery, GetMeQueryVariables> query={GetMeDocument} {...props} />
    );
    
export type GetMeProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetMeQuery, GetMeQueryVariables>
    } & TChildProps;
export function withGetMe<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetMeQuery,
  GetMeQueryVariables,
  GetMeProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetMeQuery, GetMeQueryVariables, GetMeProps<TChildProps, TDataName>>(GetMeDocument, {
      alias: 'getMe',
      ...operationOptions
    });
};

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, baseOptions);
      }
export function useGetMeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, baseOptions);
        }
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = ApolloReactCommon.QueryResult<GetMeQuery, GetMeQueryVariables>;
export const MeUpdatedDocument = gql`
    subscription MeUpdated {
  meUpdated {
    ...Me
  }
}
    ${MeFragmentDoc}`;
export type MeUpdatedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<MeUpdatedSubscription, MeUpdatedSubscriptionVariables>, 'subscription'>;

    export const MeUpdatedComponent = (props: MeUpdatedComponentProps) => (
      <ApolloReactComponents.Subscription<MeUpdatedSubscription, MeUpdatedSubscriptionVariables> subscription={MeUpdatedDocument} {...props} />
    );
    
export type MeUpdatedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<MeUpdatedSubscription, MeUpdatedSubscriptionVariables>
    } & TChildProps;
export function withMeUpdated<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  MeUpdatedSubscription,
  MeUpdatedSubscriptionVariables,
  MeUpdatedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, MeUpdatedSubscription, MeUpdatedSubscriptionVariables, MeUpdatedProps<TChildProps, TDataName>>(MeUpdatedDocument, {
      alias: 'meUpdated',
      ...operationOptions
    });
};

/**
 * __useMeUpdatedSubscription__
 *
 * To run a query within a React component, call `useMeUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMeUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useMeUpdatedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<MeUpdatedSubscription, MeUpdatedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<MeUpdatedSubscription, MeUpdatedSubscriptionVariables>(MeUpdatedDocument, baseOptions);
      }
export type MeUpdatedSubscriptionHookResult = ReturnType<typeof useMeUpdatedSubscription>;
export type MeUpdatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<MeUpdatedSubscription>;
export const GetUserSettingsDocument = gql`
    query GetUserSettings {
  userSettings {
    id
    value
  }
}
    `;
export type GetUserSettingsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUserSettingsQuery, GetUserSettingsQueryVariables>, 'query'>;

    export const GetUserSettingsComponent = (props: GetUserSettingsComponentProps) => (
      <ApolloReactComponents.Query<GetUserSettingsQuery, GetUserSettingsQueryVariables> query={GetUserSettingsDocument} {...props} />
    );
    
export type GetUserSettingsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetUserSettingsQuery, GetUserSettingsQueryVariables>
    } & TChildProps;
export function withGetUserSettings<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetUserSettingsQuery,
  GetUserSettingsQueryVariables,
  GetUserSettingsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetUserSettingsQuery, GetUserSettingsQueryVariables, GetUserSettingsProps<TChildProps, TDataName>>(GetUserSettingsDocument, {
      alias: 'getUserSettings',
      ...operationOptions
    });
};

/**
 * __useGetUserSettingsQuery__
 *
 * To run a query within a React component, call `useGetUserSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserSettingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserSettingsQuery, GetUserSettingsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserSettingsQuery, GetUserSettingsQueryVariables>(GetUserSettingsDocument, baseOptions);
      }
export function useGetUserSettingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserSettingsQuery, GetUserSettingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserSettingsQuery, GetUserSettingsQueryVariables>(GetUserSettingsDocument, baseOptions);
        }
export type GetUserSettingsQueryHookResult = ReturnType<typeof useGetUserSettingsQuery>;
export type GetUserSettingsLazyQueryHookResult = ReturnType<typeof useGetUserSettingsLazyQuery>;
export type GetUserSettingsQueryResult = ApolloReactCommon.QueryResult<GetUserSettingsQuery, GetUserSettingsQueryVariables>;
export const UpdateUserSettingsDocument = gql`
    mutation UpdateUserSettings($id: UserSettingsKey!, $value: String) {
  updateUserSettings(where: {id: $id, value: $value}) {
    id
    value
  }
}
    `;
export type UpdateUserSettingsMutationFn = ApolloReactCommon.MutationFunction<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>;
export type UpdateUserSettingsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>, 'mutation'>;

    export const UpdateUserSettingsComponent = (props: UpdateUserSettingsComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables> mutation={UpdateUserSettingsDocument} {...props} />
    );
    
export type UpdateUserSettingsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>
    } & TChildProps;
export function withUpdateUserSettings<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateUserSettingsMutation,
  UpdateUserSettingsMutationVariables,
  UpdateUserSettingsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables, UpdateUserSettingsProps<TChildProps, TDataName>>(UpdateUserSettingsDocument, {
      alias: 'updateUserSettings',
      ...operationOptions
    });
};

/**
 * __useUpdateUserSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateUserSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSettingsMutation, { data, loading, error }] = useUpdateUserSettingsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateUserSettingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>(UpdateUserSettingsDocument, baseOptions);
      }
export type UpdateUserSettingsMutationHookResult = ReturnType<typeof useUpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationResult = ApolloReactCommon.MutationResult<UpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>;
export const AddUserSettingsDocument = gql`
    mutation AddUserSettings($id: UserSettingsKey!, $value: String) {
  addUserSettings(where: {id: $id, value: $value}) {
    id
    value
  }
}
    `;
export type AddUserSettingsMutationFn = ApolloReactCommon.MutationFunction<AddUserSettingsMutation, AddUserSettingsMutationVariables>;
export type AddUserSettingsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddUserSettingsMutation, AddUserSettingsMutationVariables>, 'mutation'>;

    export const AddUserSettingsComponent = (props: AddUserSettingsComponentProps) => (
      <ApolloReactComponents.Mutation<AddUserSettingsMutation, AddUserSettingsMutationVariables> mutation={AddUserSettingsDocument} {...props} />
    );
    
export type AddUserSettingsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AddUserSettingsMutation, AddUserSettingsMutationVariables>
    } & TChildProps;
export function withAddUserSettings<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddUserSettingsMutation,
  AddUserSettingsMutationVariables,
  AddUserSettingsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AddUserSettingsMutation, AddUserSettingsMutationVariables, AddUserSettingsProps<TChildProps, TDataName>>(AddUserSettingsDocument, {
      alias: 'addUserSettings',
      ...operationOptions
    });
};

/**
 * __useAddUserSettingsMutation__
 *
 * To run a mutation, you first call `useAddUserSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserSettingsMutation, { data, loading, error }] = useAddUserSettingsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useAddUserSettingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddUserSettingsMutation, AddUserSettingsMutationVariables>) {
        return ApolloReactHooks.useMutation<AddUserSettingsMutation, AddUserSettingsMutationVariables>(AddUserSettingsDocument, baseOptions);
      }
export type AddUserSettingsMutationHookResult = ReturnType<typeof useAddUserSettingsMutation>;
export type AddUserSettingsMutationResult = ApolloReactCommon.MutationResult<AddUserSettingsMutation>;
export type AddUserSettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<AddUserSettingsMutation, AddUserSettingsMutationVariables>;
export const GetTemplatesDocument = gql`
    query GetTemplates($templateType: TemplateType!) {
  templates(where: {templateType: $templateType}) {
    ...TemplateFragment
  }
}
    ${TemplateFragmentFragmentDoc}`;
export type GetTemplatesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetTemplatesQuery, GetTemplatesQueryVariables>, 'query'> & ({ variables: GetTemplatesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetTemplatesComponent = (props: GetTemplatesComponentProps) => (
      <ApolloReactComponents.Query<GetTemplatesQuery, GetTemplatesQueryVariables> query={GetTemplatesDocument} {...props} />
    );
    
export type GetTemplatesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetTemplatesQuery, GetTemplatesQueryVariables>
    } & TChildProps;
export function withGetTemplates<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetTemplatesQuery,
  GetTemplatesQueryVariables,
  GetTemplatesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetTemplatesQuery, GetTemplatesQueryVariables, GetTemplatesProps<TChildProps, TDataName>>(GetTemplatesDocument, {
      alias: 'getTemplates',
      ...operationOptions
    });
};

/**
 * __useGetTemplatesQuery__
 *
 * To run a query within a React component, call `useGetTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplatesQuery({
 *   variables: {
 *      templateType: // value for 'templateType'
 *   },
 * });
 */
export function useGetTemplatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTemplatesQuery, GetTemplatesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTemplatesQuery, GetTemplatesQueryVariables>(GetTemplatesDocument, baseOptions);
      }
export function useGetTemplatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTemplatesQuery, GetTemplatesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTemplatesQuery, GetTemplatesQueryVariables>(GetTemplatesDocument, baseOptions);
        }
export type GetTemplatesQueryHookResult = ReturnType<typeof useGetTemplatesQuery>;
export type GetTemplatesLazyQueryHookResult = ReturnType<typeof useGetTemplatesLazyQuery>;
export type GetTemplatesQueryResult = ApolloReactCommon.QueryResult<GetTemplatesQuery, GetTemplatesQueryVariables>;
export const UpdateOrAddTemplateDocument = gql`
    mutation UpdateOrAddTemplate($templateType: TemplateType!, $title: String!, $text: String!) {
  updateOrAddTemplateType(where: {templateType: $templateType, title: $title, text: $text}) {
    ...TemplateFragment
  }
}
    ${TemplateFragmentFragmentDoc}`;
export type UpdateOrAddTemplateMutationFn = ApolloReactCommon.MutationFunction<UpdateOrAddTemplateMutation, UpdateOrAddTemplateMutationVariables>;
export type UpdateOrAddTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateOrAddTemplateMutation, UpdateOrAddTemplateMutationVariables>, 'mutation'>;

    export const UpdateOrAddTemplateComponent = (props: UpdateOrAddTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateOrAddTemplateMutation, UpdateOrAddTemplateMutationVariables> mutation={UpdateOrAddTemplateDocument} {...props} />
    );
    
export type UpdateOrAddTemplateProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateOrAddTemplateMutation, UpdateOrAddTemplateMutationVariables>
    } & TChildProps;
export function withUpdateOrAddTemplate<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateOrAddTemplateMutation,
  UpdateOrAddTemplateMutationVariables,
  UpdateOrAddTemplateProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateOrAddTemplateMutation, UpdateOrAddTemplateMutationVariables, UpdateOrAddTemplateProps<TChildProps, TDataName>>(UpdateOrAddTemplateDocument, {
      alias: 'updateOrAddTemplate',
      ...operationOptions
    });
};

/**
 * __useUpdateOrAddTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateOrAddTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrAddTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrAddTemplateMutation, { data, loading, error }] = useUpdateOrAddTemplateMutation({
 *   variables: {
 *      templateType: // value for 'templateType'
 *      title: // value for 'title'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useUpdateOrAddTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrAddTemplateMutation, UpdateOrAddTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOrAddTemplateMutation, UpdateOrAddTemplateMutationVariables>(UpdateOrAddTemplateDocument, baseOptions);
      }
export type UpdateOrAddTemplateMutationHookResult = ReturnType<typeof useUpdateOrAddTemplateMutation>;
export type UpdateOrAddTemplateMutationResult = ApolloReactCommon.MutationResult<UpdateOrAddTemplateMutation>;
export type UpdateOrAddTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOrAddTemplateMutation, UpdateOrAddTemplateMutationVariables>;
export const TemplateUpdatedDocument = gql`
    subscription TemplateUpdated($templateType: TemplateType!) {
  templateUpdated(where: {templateType: $templateType}) {
    ...TemplateFragment
  }
}
    ${TemplateFragmentFragmentDoc}`;
export type TemplateUpdatedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<TemplateUpdatedSubscription, TemplateUpdatedSubscriptionVariables>, 'subscription'>;

    export const TemplateUpdatedComponent = (props: TemplateUpdatedComponentProps) => (
      <ApolloReactComponents.Subscription<TemplateUpdatedSubscription, TemplateUpdatedSubscriptionVariables> subscription={TemplateUpdatedDocument} {...props} />
    );
    
export type TemplateUpdatedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<TemplateUpdatedSubscription, TemplateUpdatedSubscriptionVariables>
    } & TChildProps;
export function withTemplateUpdated<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TemplateUpdatedSubscription,
  TemplateUpdatedSubscriptionVariables,
  TemplateUpdatedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, TemplateUpdatedSubscription, TemplateUpdatedSubscriptionVariables, TemplateUpdatedProps<TChildProps, TDataName>>(TemplateUpdatedDocument, {
      alias: 'templateUpdated',
      ...operationOptions
    });
};

/**
 * __useTemplateUpdatedSubscription__
 *
 * To run a query within a React component, call `useTemplateUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTemplateUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateUpdatedSubscription({
 *   variables: {
 *      templateType: // value for 'templateType'
 *   },
 * });
 */
export function useTemplateUpdatedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<TemplateUpdatedSubscription, TemplateUpdatedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<TemplateUpdatedSubscription, TemplateUpdatedSubscriptionVariables>(TemplateUpdatedDocument, baseOptions);
      }
export type TemplateUpdatedSubscriptionHookResult = ReturnType<typeof useTemplateUpdatedSubscription>;
export type TemplateUpdatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<TemplateUpdatedSubscription>;
export const TemplateAddedDocument = gql`
    subscription TemplateAdded($templateType: TemplateType!) {
  templateAdded(where: {templateType: $templateType}) {
    ...TemplateFragment
  }
}
    ${TemplateFragmentFragmentDoc}`;
export type TemplateAddedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<TemplateAddedSubscription, TemplateAddedSubscriptionVariables>, 'subscription'>;

    export const TemplateAddedComponent = (props: TemplateAddedComponentProps) => (
      <ApolloReactComponents.Subscription<TemplateAddedSubscription, TemplateAddedSubscriptionVariables> subscription={TemplateAddedDocument} {...props} />
    );
    
export type TemplateAddedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<TemplateAddedSubscription, TemplateAddedSubscriptionVariables>
    } & TChildProps;
export function withTemplateAdded<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TemplateAddedSubscription,
  TemplateAddedSubscriptionVariables,
  TemplateAddedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, TemplateAddedSubscription, TemplateAddedSubscriptionVariables, TemplateAddedProps<TChildProps, TDataName>>(TemplateAddedDocument, {
      alias: 'templateAdded',
      ...operationOptions
    });
};

/**
 * __useTemplateAddedSubscription__
 *
 * To run a query within a React component, call `useTemplateAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTemplateAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateAddedSubscription({
 *   variables: {
 *      templateType: // value for 'templateType'
 *   },
 * });
 */
export function useTemplateAddedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<TemplateAddedSubscription, TemplateAddedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<TemplateAddedSubscription, TemplateAddedSubscriptionVariables>(TemplateAddedDocument, baseOptions);
      }
export type TemplateAddedSubscriptionHookResult = ReturnType<typeof useTemplateAddedSubscription>;
export type TemplateAddedSubscriptionResult = ApolloReactCommon.SubscriptionResult<TemplateAddedSubscription>;
export const TemplateDeletedDocument = gql`
    subscription TemplateDeleted($templateType: TemplateType!) {
  templateDeleted(where: {templateType: $templateType}) {
    ...TemplateFragment
  }
}
    ${TemplateFragmentFragmentDoc}`;
export type TemplateDeletedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<TemplateDeletedSubscription, TemplateDeletedSubscriptionVariables>, 'subscription'>;

    export const TemplateDeletedComponent = (props: TemplateDeletedComponentProps) => (
      <ApolloReactComponents.Subscription<TemplateDeletedSubscription, TemplateDeletedSubscriptionVariables> subscription={TemplateDeletedDocument} {...props} />
    );
    
export type TemplateDeletedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<TemplateDeletedSubscription, TemplateDeletedSubscriptionVariables>
    } & TChildProps;
export function withTemplateDeleted<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TemplateDeletedSubscription,
  TemplateDeletedSubscriptionVariables,
  TemplateDeletedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withSubscription<TProps, TemplateDeletedSubscription, TemplateDeletedSubscriptionVariables, TemplateDeletedProps<TChildProps, TDataName>>(TemplateDeletedDocument, {
      alias: 'templateDeleted',
      ...operationOptions
    });
};

/**
 * __useTemplateDeletedSubscription__
 *
 * To run a query within a React component, call `useTemplateDeletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTemplateDeletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateDeletedSubscription({
 *   variables: {
 *      templateType: // value for 'templateType'
 *   },
 * });
 */
export function useTemplateDeletedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<TemplateDeletedSubscription, TemplateDeletedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<TemplateDeletedSubscription, TemplateDeletedSubscriptionVariables>(TemplateDeletedDocument, baseOptions);
      }
export type TemplateDeletedSubscriptionHookResult = ReturnType<typeof useTemplateDeletedSubscription>;
export type TemplateDeletedSubscriptionResult = ApolloReactCommon.SubscriptionResult<TemplateDeletedSubscription>;